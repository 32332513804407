import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private meta: Meta) { }

  ngOnInit() {
    this.meta.addTags([
        {name: 'description', content: 'The Legal Team is secure cloud-based software giving you a single view of your organisation’s performance and consists of these interrelated features: Compliance Team, Regulatory & Compliance Update Team, Policy Team, Permit Team, Report Team Audit Team, Training Team and Legal Library.'},   
        {name: 'keywords', content: 'Compliance Assessment,Internal Audit,Action Plan,E-Learning' }
      ]);
  }

}
