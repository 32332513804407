<div *ngFor="let item of items">
  <div class="d-flex">
    <div class="pe-2 text-center" style="min-width: 20px;">
      <a (click)="expand(item)">
        <span *ngIf="item.expanded">-</span>
        <span *ngIf="!item.expanded">+</span>
      </a>
    </div>
    <div class="flex-grow-1">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" [value]="item.item.id" id="library_{{item.item.id}}"
          name="libraryItems" [(ngModel)]="item.checked" (change)="itemChanged(item, $event)" [indeterminate]="item.checked == null"  />
        <a (click)="select(item)">
          {{item.item.title}}
        </a>
      </div>
      <ng-container *ngIf="item.expanded">
        <app-library-tree-list #childList [parentId]="item.item.id" [typeId]="_typeId"  [companyId]="_companyId" (selected)="select($event)" (changed)="childrenChanged(item.item.id, $event)"></app-library-tree-list>
      </ng-container>
    </div>
  </div>
</div>