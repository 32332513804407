import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  overlayImage: string = null;
  visible: boolean = false;

  overlays = [
    { url: '/dashboard/profile#changepassword', image: '/assets/overlays/the_legal_team_user_guide_change_password_page.png' },
    { url: '/dashboard/compliance-program', image: '/assets/overlays/the_legal_team_user_guide_compliance_program_page.png' },
    { url: '/dashboard/compliance-program#details', image: '/assets/overlays/the_legal_team_user_guide_compliance_program_required_page.png' },
    { url: '/dashboard/contact', image: '/assets/overlays/the_legal_team_user_guide_contact_us_page.png' },
    { url: '/dashboard/e-learning#details', image: '/assets/overlays/the_legal_team_user_guide_e_learning_assigned.png' },
    { url: '/dashboard/e-learning', image: '/assets/overlays/the_legal_team_user_guide_e_learning_page.png' },
    { url: '/login', image: '/assets/overlays/the_legal_team_user_guide_landing_page.png' },
    { url: '/dashboard/library', image: '/assets/overlays/the_legal_team_user_guide_legal_library_page.png' },
    { url: '/dashboard/profile', image: '/assets/overlays/the_legal_team_user_guide_my_profile_page.png' },
    { url: '/dashboard/self-assessment-checklist', image: '/assets/overlays/the_legal_team_user_guide_self_assessment_page.png' },
    { url: '/dashboard/self-assessment-checklist#details', image: '/assets/overlays/the_legal_team_user_guide_self_assessment_progress.png' }
  ];

  
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private token: TokenService
  ) {


    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        var loggedIn = this.token.loggedIn();
        var isUser = loggedIn ? this.token.isUser() : false;
        if (loggedIn && !isUser) {
          this.visible = false;
          this.overlayImage = null;
        }
        else {
          this.visible = true;
          var item = this.overlays.find(f => f.url == this.router.url);

          if (item) {
            this.overlayImage = item.image;
          } else {
            this.overlayImage = null;
          }
        }
      }
    });

  }

  ngOnInit() {


  }


  openModal(content: TemplateRef<any>) {

    this.modalService.open(content, { windowClass: 'fullscreenModal', backdrop: 'static' }).result.then((result) => {
    }, (reason) => {
    });
  }

}
