import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

//import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderRouterConfig } from 'ngx-ui-loader';
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import { CKEditorModule } from 'ckeditor4-angular';


import { Globals } from './globals';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ServicesComponent } from './pages/services/services.component';
import { HomeContactComponent } from './pages/home-contact/home-contact.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UsagePolicyComponent } from './pages/usage-policy/usage-policy.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
//import { ApiService } from './services/api.service';
import { AlertComponent } from './components/alert/alert.component';
import { AlertService } from './services/alert.service';
import { AuthGuard } from './guards/auth-guard';
import { TokenInterceptor } from './interceptors/token-interceptor';
import { ErrorInterceptor } from './interceptors/error-interceptor';
import { AuthService } from './services/auth.service';
import { ConfigurationComponent } from './pages/dashboard/configuration/configuration.component';
import { OrganisationComponent } from './pages/dashboard/configuration/organisation/organisation.component';
import { SpinnerService } from './services/spinner.service';
import { DashboardHomeComponent } from './pages/dashboard/dashboard-home/dashboard-home.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { EditOrganisationComponent } from './pages/dashboard/configuration/organisation/edit-organisation/edit-organisation.component';
import { ListOrganisationComponent } from './pages/dashboard/configuration/organisation/list-organisation/list-organisation.component';
import { AddOrganisationComponent } from './pages/dashboard/configuration/organisation/add-organisation/add-organisation.component';
import { AddCompanyComponent } from './pages/dashboard/configuration/company/add-company/add-company.component';
import { ListCompanyComponent } from './pages/dashboard/configuration/company/list-company/list-company.component';
import { EditCompanyComponent } from './pages/dashboard/configuration/company/edit-company/edit-company.component';
import { CompanyComponent } from './pages/dashboard/configuration/company/company.component';
import { OrganisationListItemComponent } from './components/organisation-list-item/organisation-list-item.component';
import { CompanyListItemComponent } from './components/company-list-item/company-list-item.component';
import { EventsService } from './services/events.service';
import { FileValueAccessor } from './accessors/file-value-accessor';
import { FileValidator } from './validators/file-input-validator';
//import { SelectModule } from 'ng2-select';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuestionnaireComponent } from './pages/dashboard/configuration/questionnaire/questionnaire.component';
import { RequestCacheInterceptor } from './interceptors/request-cache-interceptor';
import { RequestCacheService } from './services/request-cache.service';
import { OrgStructureComponent } from './pages/dashboard/configuration/org-structure/org-structure.component';
import { ChoosePasswordComponent } from './pages/choose-password/choose-password.component';
import { LibraryComponent } from './pages/dashboard/library/library.component';
import { UmbrellaReportComponent } from './pages/dashboard/umbrella-report/umbrella-report.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AdminComponent } from './pages/dashboard/admin/admin.component';
import { DocumentAdminComponent } from './pages/dashboard/admin/document-admin/document-admin.component';
import { AdminHomeComponent } from './pages/dashboard/admin/admin-home/admin-home.component';
import { ActAdminComponent } from './pages/dashboard/admin/act-admin/act-admin.component';
import { DocumentListItemComponent } from './components/document-list-item/document-list-item.component';
import { LibraryListItemComponent } from './components/library-list-item/library-list-item.component';
import { TokenService } from './services/token.service';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';
import { UserAdminComponent } from './pages/dashboard/admin/user-admin/user-admin.component';
import { ELearningAdminComponent } from './pages/dashboard/admin/elearning-admin/elearning-admin.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ELearningComponent } from './pages/dashboard/elearning/elearning.component';
import { ComplianceProgramComponent } from './pages/dashboard/compliance-program/compliance-program.component';
import { SelfAssessmentChecklistComponent } from './pages/dashboard/self-assessment-checklist/self-assessment-checklist.component';
import { ComplianceProgramAdminComponent } from './pages/dashboard/admin/compliance-program-admin/compliance-program-admin.component';
import { ImpactRatingComponent } from './components/impact-rating/impact-rating.component';
import { DonutComponent } from './components/donut/donut.component';
import { ProfileComponent } from './pages/dashboard/profile/profile.component';
import { DashboardContactComponent } from './pages/dashboard/dashboard-contact/dashboard-contact.component';
import { PuzzleWheelComponent } from './components/puzzle-wheel/puzzle-wheel.component';
import { SelfAssessmentAdminComponent } from './pages/dashboard/admin/self-assessment-admin/self-assessment-admin.component';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { OverlayComponent } from './components/overlay/overlay.component';
import { LibraryAdminComponent } from './pages/dashboard/admin/library-admin/library-admin.component';
import { LibraryTreeListComponent } from './components/library-tree-list/library-tree-list.component';
import { RequestContractComponent } from './pages/request-contract/request-contract.component';
import { ContractsOldComponent } from './pages/dashboard/contracts-old/contracts.component';
import { ContractTemplatesComponent } from './pages/dashboard/contracts-old/contract-templates/contract-templates.component';
import { ContractsHomeComponent } from './pages/dashboard/contracts-old/contracts-home/contracts-home.component';
import { ContractRequestsComponent } from './pages/dashboard/contracts-old/contract-requests/contract-requests.component';
import { ContractListComponent } from './pages/dashboard/contracts-old/contract-list/contract-list.component';
import { ContractOpenComponent } from './pages/dashboard/contracts-old/contract-open/contract-open.component';
import { ContractMessagesComponent } from './pages/dashboard/contracts-old/contract-messages/contract-messages.component';
import { MessageRowComponent } from './components/message-row/message-row.component';
import { GetComponent } from './pages/get/get.component';
import { ToolkitComponent } from './pages/toolkit/toolkit.component';
import { EnterpriseSupplierDevelopmentComponent } from './pages/enterprise-supplier-development/enterprise-supplier-development.component';
import { DocumentRegistryComponent } from './pages/dashboard/contracts-old/document-registry/document-registry.component';
import { ReportsComponent } from './pages/dashboard/reports/reports.component';
import { PeriodAdminComponent } from './pages/dashboard/admin/period-admin/period-admin.component';
import { UmbrellaReportWidgetComponent } from './components/umbrella-report-widget/umbrella-report-widget.component';
import { UmbrellaReportCompareComponent } from './pages/dashboard/umbrella-report/umbrella-report-compare/umbrella-report-compare.component';
import { ComplianceStatusComponent } from './components/compliance-status/compliance-status.component';
import { CompanyActsComponent } from './pages/dashboard/configuration/acts/company-acts/company-acts.component';
import { BusinessUnitListItemComponent } from './components/business-unit-list-item/business-unit-list-item.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RiskModalComponent } from './components/risk-modal/risk-modal.component';
import { DocumentsComponent } from './pages/dashboard/documents/documents.component';
import { ContractsPuzzleWheelComponent } from './components/contracts-puzzle-wheel/contracts-puzzle-wheel.component';
import { ContractsComponent } from './pages/dashboard/documents/contracts/contracts.component';
import { PaiaComponent } from './pages/dashboard/documents/paia/paia.component';
import { PermitsComponent } from './pages/dashboard/documents/permits/permits.component';
import { DocumentsHomeComponent } from './pages/dashboard/documents/documents-home/documents-home.component';
import { CommonModule } from '@angular/common';
import { ContractsAddEditComponent } from './pages/dashboard/documents/contracts/contracts-add-edit/contracts-add-edit.component';
import { PermitsAddEditComponent } from './pages/dashboard/documents/permits/permits-add-edit/permits-add-edit.component';
import { PaiaAddEditComponent } from './pages/dashboard/documents/paia/paia-add-edit/paia-add-edit.component';
import { FicaComponent } from './pages/dashboard/documents/fica/fica.component';
import { FicaAddEditComponent } from './pages/dashboard/documents/fica/fica-add-edit/fica-add-edit.component';
import { MatterManagementComponent } from './pages/dashboard/documents/matter-management/matter-management.component';
import { MatterManagementAddEditComponent } from './pages/dashboard/documents/matter-management/matter-management-add-edit/matter-management-add-edit.component';
import { PoliciesComponent } from './pages/dashboard/documents/policies/policies.component';
import { PoliciesAddEditComponent } from './pages/dashboard/documents/policies/policies-add-edit/policies-add-edit.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#cf5d1f",
  bgsOpacity: 0.5,
  bgsPosition: "bottom-right",
  bgsSize: 60,
  bgsType: SPINNER.ballSpinClockwise,
  fgsColor: '#cf5d1f',
  fgsPosition: POSITION.centerCenter,
  fgsType: SPINNER.ballSpinClockwise,
  fgsSize: 0,
  blur: 2,
  gap: 24,
  overlayColor: "rgba(0,0,0,0.75)",
  hasProgressBar: false,
  logoUrl: "/assets/My_Legal_Team_Icon.png",
  logoPosition: POSITION.centerCenter,
  logoSize: 80,
  //threshold: 1
};


@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        AboutComponent,
        HeaderComponent,
        FooterComponent,
        ServicesComponent,
        HomeContactComponent,
        LoginComponent,
        NotFoundComponent,
        UsagePolicyComponent,
        PrivacyPolicyComponent,
        SideBarComponent,
        DashboardComponent,
        AlertComponent,
        ConfigurationComponent,
        OrganisationComponent,
        DashboardHomeComponent,
        SubHeaderComponent,
        AddOrganisationComponent,
        ListOrganisationComponent,
        EditOrganisationComponent,
        EditOrganisationComponent,
        ListOrganisationComponent,
        AddOrganisationComponent,
        AddCompanyComponent,
        ListCompanyComponent,
        EditCompanyComponent,
        CompanyComponent,
        OrganisationListItemComponent,
        CompanyListItemComponent,
        FileValueAccessor,
        FileValidator,
        QuestionnaireComponent,
        OrgStructureComponent,
        ChoosePasswordComponent,
        LibraryComponent,
        UmbrellaReportComponent,
        PieChartComponent,
        SafeHtmlPipe,
        AdminComponent,
        DocumentAdminComponent,
        AdminHomeComponent,
        ActAdminComponent,
        DocumentListItemComponent,
        LibraryListItemComponent,
        CompanySelectorComponent,
        UserAdminComponent,
        CompanySelectorComponent,
        ELearningAdminComponent,
        NotificationsComponent,
        ELearningComponent,
        ComplianceProgramComponent,
        SelfAssessmentChecklistComponent,
        ComplianceProgramAdminComponent,
        SelfAssessmentAdminComponent,
        ImpactRatingComponent,
        DonutComponent,
        ProfileComponent,
        DashboardContactComponent,
        PuzzleWheelComponent,
        OverlayComponent,
        LibraryAdminComponent,
        LibraryTreeListComponent,
        RequestContractComponent,
        ContractsOldComponent,
        ContractTemplatesComponent,
        ContractsHomeComponent,
        ContractRequestsComponent,
        ContractListComponent,
        ContractOpenComponent,
        ContractMessagesComponent,
        MessageRowComponent,
        GetComponent,
        ToolkitComponent,
        EnterpriseSupplierDevelopmentComponent,
        DocumentRegistryComponent,
        ReportsComponent,
        PeriodAdminComponent,
        UmbrellaReportCompareComponent,
        UmbrellaReportWidgetComponent,
        ComplianceStatusComponent,
        CompanyActsComponent,
        BusinessUnitListItemComponent,
        RiskModalComponent,
        DocumentsComponent,
        ContractsPuzzleWheelComponent,
        ContractsComponent,
        PaiaComponent,
        PermitsComponent,
        DocumentsHomeComponent,
        ContractsAddEditComponent,
        PermitsAddEditComponent,
        PaiaAddEditComponent,
        FicaComponent,
        FicaAddEditComponent,
        MatterManagementComponent,
        MatterManagementAddEditComponent,
        PoliciesComponent,
        PoliciesAddEditComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        //SelectModule,
        NgSelectModule,
        NgbModule,
        CKEditorModule,
        //NgBootstrapFormValidationModule.forRoot(),
        //NgBootstrapFormValidationModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgIdleKeepaliveModule.forRoot(),
        NgxUiLoaderRouterModule], providers: [
        Globals,
        AuthService,
        //ApiService,
        AlertService,
        AuthGuard,
        SpinnerService,
        EventsService,
        RequestCacheService,
        TokenService,
        FileValueAccessor,
        FileValidator,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
