import { Component, OnInit } from '@angular/core';
import { Subject, Observable, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, map } from 'rxjs/operators'
import { ActApiService } from '../../../../services/act-api.service';
import { TaskItemApiService } from '../../../../services/taskitem-api.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { ActSectionApiService } from '../../../../services/act-section-api.service';
import { ChecklistApiService } from '../../../../services/checklist-api.service';
import { Act } from 'src/app/models/act';
import { ActSection } from 'src/app/models/actSection';
import { ChecklistItem } from 'src/app/models/checklistItem';

@Component({
  selector: 'app-self-assessment-admin',
  templateUrl: './self-assessment-admin.component.html',
  styleUrls: ['./self-assessment-admin.component.scss']
})
export class SelfAssessmentAdminComponent implements OnInit {

  section: boolean = false;
  checklist: boolean = false;
  add: boolean = false;
  edit: boolean = false;

  acts$: Observable<Act[]>;
  actsLoading = false;
  actsInput$ = new Subject<string>();
  actSections: Array<ActSection>;

  currentAct: Act;
  currentActSection: ActSection;
  currentChecklistItem: ChecklistItem;
  selectedActSection: ActSection;

  sectionSubmitted = false;

  sectionForm = this.formBuilder.group({
    title: ['', Validators.required],
    summary: ['', Validators.required]
  });

  checklistSubmitted = false;

  checklistForm = this.formBuilder.group({
    description: [''],
    complianceQuery: ['', Validators.required],
    weight: ['', Validators.required],
    documentUpload: ['']
  });

  constructor(
    private actApi: ActApiService,
    private actSectionApi: ActSectionApiService,
    private checklistApi: ChecklistApiService,
    private spinner: SpinnerService,
    private formBuilder: UntypedFormBuilder,
    private cache: RequestCacheService
  ) { }

  ngOnInit() {
    this.loadActs();
  }

  searchActs(term: string) {
    return this.actApi.ListActs(1, 50, term).pipe(
      map((r) => {
        return r.items;
      })
    );
  }

  private loadActs() {
    this.acts$ = concat(
      of([]), // default items
      this.actsInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.actsLoading = true),
        switchMap(term => this.searchActs(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.actsLoading = false)
        ))
      )
    );
  }

  getData() {
    this.spinner.startBackground();

    this.actSectionApi.ListByActId(this.currentAct.id).subscribe(data => {
      this.actSections = data;
      this.spinner.stopBackground();
    });
  }

  actSelected(event) {
    this.currentAct = event;
    this.getData();
  }

  addActSection() {
    this.section = true;
    this.add = true;
  }

  editActSection(item: ActSection) {
    this.section = true;
    this.edit = true;
    this.currentActSection = item;

    this.sectionForm.patchValue({
      title: item.title,
      summary: item.summary,
    });
  }

  cancel() {
    this.add = false;
    this.edit = false;
    this.section = false;
    this.checklist = false;
    this.sectionForm.reset();
    this.checklistForm.reset();
  }

  submitSection() {

    this.sectionSubmitted = true;

    if (this.sectionForm.invalid){
      return;
    }


    this.spinner.startBackground();

    if (this.add) {
      this.currentActSection = <ActSection>{};
      this.currentActSection.act = this.currentAct;
    }

    this.currentActSection.title = this.sectionForm.controls.title.value;
    this.currentActSection.summary = this.sectionForm.controls.summary.value;

    if (this.add) {
      this.actSectionApi.Add(this.currentActSection).subscribe(data => {
        this.add = false;
        this.edit = false;
        this.section = false;
        this.cache.clear();
        this.getData();
      });
    }
    else if (this.edit) {
      this.actSectionApi.Update(this.currentActSection.id, this.currentActSection).subscribe(data => {
        this.add = false;
        this.edit = false;
        this.section = false;
        this.cache.clear();
        this.getData();
      });
    }

    this.sectionForm.reset();
  }


  archiveActSection(item: ActSection) {

    if (!window.confirm("Are you sure?")) {
      return;
    }

    this.spinner.startBackground();

    item.active = false;

    this.actSectionApi.Update(item.id, item).subscribe(data => {
      var index = this.actSections.findIndex(f => f.id == item.id);
      this.actSections.splice(index, 1);
      this.spinner.stopBackground();

      this.actSectionApi.ListByActId(this.currentAct.id).subscribe(data => {
        this.actSections = data;
      });
    });

  }

  addChecklist(item: ActSection) {
    this.selectedActSection = item;
    this.checklist = true;
    this.add = true;
  }

  editChecklist(section: ActSection, item: ChecklistItem) {
    this.selectedActSection = section;
    this.edit = true;
    this.checklist = true;
    this.currentChecklistItem = item;

    this.checklistForm.patchValue({
      description: item.description,
      complianceQuery: item.complianceQuery,
      weight: item.weight,
      documentUpload: item.documentUpload
    });
  }

  submitChecklist() {

    this.checklistSubmitted = true;

    if (this.checklistForm.invalid){
      return;
    }

    this.spinner.startBackground();

    if (this.add) {
      this.currentChecklistItem = <ChecklistItem>{};

    }

    this.currentChecklistItem.actSection = this.selectedActSection;
    this.currentChecklistItem.actSection.checklistItems = new Array();
    this.currentChecklistItem.description = this.checklistForm.controls.description.value;
    this.currentChecklistItem.complianceQuery = this.checklistForm.controls.complianceQuery.value;
    this.currentChecklistItem.documentUpload = this.checklistForm.controls.documentUpload.value == true;
    this.currentChecklistItem.weight = this.checklistForm.controls.weight.value;

    if (this.add) {
      this.checklistApi.Add(this.currentChecklistItem).subscribe(data => {
        this.add = false;
        this.edit = false;
        this.checklist = false;
        this.cache.clear();
        this.getData();
      });
    }
    else if (this.edit) {
      this.checklistApi.Update(this.currentChecklistItem.id, this.currentChecklistItem).subscribe(data => {
        this.add = false;
        this.edit = false;
        this.checklist = false;
        this.cache.clear();
        this.getData();
      });
    }

    this.add = false;
    this.edit = false;
    this.checklist = false;
    this.checklistForm.reset();
  }

  archiveChecklist(section: ActSection, item: ChecklistItem) {

    if (!window.confirm("Are you sure?")) {
      return;
    }

    this.spinner.startBackground();

    item.active = false;
    item.actSection = section;
    item.actSection.checklistItems = new Array();

    this.checklistApi.Update(item.id, item).subscribe(data => {

      this.getData();
    });

  }


}
