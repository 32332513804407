import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../../services/events.service';
import { StatusApiService } from '../../../services/status-api.service';
import { RequestCacheService } from '../../../services/request-cache.service';
import { SubMenuItem } from 'src/app/models/sub-menu-item';
import { State } from 'src/app/enums/state';
//import { State } from '../../../enums/state';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  public items: Array<SubMenuItem>;

  constructor(
    //private organisationApi: OrganisationApiService,
    //private companyApi: CompanyApiService,
    private statusApi: StatusApiService,
    private events: EventsService,
    private cache: RequestCacheService
    ) {
    this.items = [
      { number: 1, text: "Configure Organisation", state: State.Default },
      { number: 2, text: "Configure Company", state: State.Default },
      { number: 3, text: "Company Questionaire", state: State.Default },
      { number: 4, text: "Personalise Org Structure", state: State.Default },
    ];

    this.events.genericEvent$.subscribe(
      command => {
        if (command == 'configuration-submenu-update'){
          this.cache.clear();
          this.checkMenuItemStates();
        }
      });
  }


  
  ngOnInit() {
    this.checkMenuItemStates();
  }

  checkMenuItemStates(){

    this.statusApi.getStatus().subscribe(r => {
      
      r.configuration.organisations.forEach(o => {
        if (this.items[0].state == State.Default && (o.state == State.Inactive || o.state == State.Incomplete)){
          this.items[0].state = o.state;
          //this.items[1].state = o.state;
          //this.items[2].state = o.state;
          //this.items[3].state = o.state;
          return;
        }  

        o.companies.forEach(c => {
          if (this.items[1].state == State.Default && (c.state == State.Inactive || c.state == State.Incomplete)){
            this.items[1].state = c.state;
            //this.items[2].state = c.state;
            //this.items[3].state = c.state;
            return;

          } 

          if ((this.items[2].state == State.Default || this.items[2].state == State.Inactive) && (c.questionnaire.state == State.Inactive || c.questionnaire.state == State.Incomplete)){
            this.items[2].state = c.questionnaire.state;
          } 
          if ((this.items[3].state == State.Default || this.items[3].state == State.Inactive) && (c.roles.state == State.Inactive || c.roles.state == State.Incomplete)){
            this.items[3].state = c.roles.state;
          } 
          
        });
      });

      this.items.forEach(i => {
        if (i.state == State.Default){
          i.state = State.Complete
        }
      });

    });


    // forkJoin(
    //   this.organisationApi.List(),
    //   this.companyApi.List()
    // ).subscribe(r => {
    //   this.items[0].state = State.Incomplete;
    //   if (r[0] && r[0].length > 0){
    //     this.items[0].state = State.Complete;
    //     this.items[1].state = State.Incomplete;
    //   }
    //   if (r[1] && r[1].length > 0){
    //     this.items[1].state = State.Complete;
    //     this.items[2].state = State.Incomplete;
    //   }
    // });
  
  }
}
