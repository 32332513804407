import { Component, OnInit, Input } from '@angular/core';
import { ContractMessage } from 'src/app/models/contractMessage';

@Component({
  selector: 'app-message-row',
  templateUrl: './message-row.component.html',
  styleUrls: ['./message-row.component.scss']
})
export class MessageRowComponent implements OnInit {

  public _message: ContractMessage;
  constructor() { }

  @Input()
  set item(message: ContractMessage) {
    this._message = message;
  }
  
  ngOnInit() {
  }

}
