import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { ActFull } from '../models/actFull';
import { ActResult } from '../models/actResult';

@Injectable({
  providedIn: 'root'
})
export class ActApiService extends ApiService<ActFull>{

  _controller: string = 'acts';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('acts', http, env);
  }

  public ListActs(page: number = 1, perPage: number = 20, search: string = null): Observable<ActResult> {
    let queryString = `page=${page}&perPage=${perPage}`;

    if (search) {
      queryString = `${queryString}&search=${search}`;
    }

    return this.get<ActResult>(`api/${this._controller}/?${queryString}`);
  }

  public GetById(id: number): Observable<ActFull> {
    return this.get<ActFull>(`api/${this._controller}/${id}`);
  }
}
