<div class="container">
  <h2 class="text-center">Act Admin</h2>

  <ng-container *ngIf="!this.edit && !this.add">
    <div class="d-flex  align-items-center justify-content-center mb-2">
      <div class="px-1"><span class="icon icon-search-red"></span></div>
      <div class="px-1">Search acts:</div>
      <div class="px-1"><input class="form-control" name="search" type="text" [(ngModel)]="searchTerm" /></div>
      <div class="px-1"><button class="btn btn-primary btn-small" type="button" [disabled]="searchTerm == ''"
          (click)="search()">SEARCH</button></div>
      <div class="px-1"><button class="btn btn-primary btn-small" type="button" (click)="addItem()">ADD NEW</button>
      </div>
    </div>
    <hr />
    <ng-container *ngIf="result && result.items">
      <div class="mb-1 d-flex  align-items-center" *ngFor="let item of result.items">

        <div class="listing flex-grow-1 p-2">
          <div class="text-start"><button style="white-space: normal;" class="text-start btn btn-link p-0"
              (click)="editItem(item)"><strong>{{item.name}}</strong></button></div>
        </div>
        <div class="px-3 d-flex flex-column">
          <button class="btn btn-link p-0" (click)="editItem(item)" title="Edit"><span
              class="icon icon-edit-red"></span></button>
        </div>
      </div>

    </ng-container>
  </ng-container>

  <ng-container *ngIf="this.edit || this.add">

    <div class="col-12 col-sm-10 col-md-8 offset-sm-1 offset-md-2">
      <form [formGroup]="mainForm" (ngSubmit)="submit()">

        <ul ngbNav #nav="ngbNav" class="nav-tabs">
          <li ngbNavItem>
            <a ngbNavLink>
              <span [class.text-green]="this.mainForm.controls.details.valid && this.mainForm.controls.details.touched"
                [class.text-red]="!this.mainForm.controls.details.valid && this.mainForm.controls.details.touched">Details</span>
            </a>
            <ng-template ngbNavContent>

              <div formGroupName="details">
                <div class="form-group row">
                  <label for="name" class="col-12 col-form-label text-startcol-1">Name</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="name" formControlName="name"
                      [class.is-invalid]="submitted && mainForm.controls.details.controls.name.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.details.controls.name.errors">
                      <div *ngIf="mainForm.controls.details.controls.name.errors.required">Required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="industry" class="col-12 col-form-label text-startcol-1">Industry</label>
                  <div class="col-12">
                    <ng-select formControlName="industry" [items]="industries" bindLabel="name" bindValue="id"
                      placeholder="All industries applicable">
                      <ng-template ng-header-tmp>
                        <div class="text-end">
                          <button (click)="openIndustryModal()" class="btn btn-sm btn-secondary">Add</button>
                        </div>
                      </ng-template>
                    </ng-select>

                    <small id="industriesHelp" class="form-text text-muted">Please accurately specify the act's
                      industry. If no applicable industry present, leave blank.</small>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="entityTypes" class="col-12 col-form-label text-startcol-1">Entity Types</label>
                  <div class="col-12">
                    <ng-select formControlName="entityTypes" [items]="entityTypes" [multiple]="true"
                      [closeOnSelect]="false" [searchable]="true" bindLabel="name" bindValue="id"
                      placeholder="No Entity Types selected"
                      [class.is-invalid]="submitted && mainForm.controls.details.controls.entityTypes.invalid">
                    </ng-select>

                    <div class="invalid-feedback text-end"
                      *ngIf="mainForm.controls.details.controls.entityTypes.errors">
                      <div *ngIf="mainForm.controls.details.controls.entityTypes.errors.required">Required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="departments" class="col-12 col-form-label text-startcol-1">Departments</label>
                  <div class="col-12">
                    <ng-select formControlName="departments" [items]="departments" [multiple]="true"
                      [closeOnSelect]="false" [searchable]="true" bindLabel="name" bindValue="id"
                      placeholder="No Departments selected"
                      [class.is-invalid]="submitted && mainForm.controls.details.controls.departments.invalid">
                    </ng-select>
                    <div class="invalid-feedback text-end"
                      *ngIf="mainForm.controls.details.controls.departments.errors">
                      <div *ngIf="mainForm.controls.details.controls.departments.errors.required">Required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="about" class="col-12 col-form-label text-startcol-1">About the law</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="about" formControlName="about">

                  </div>
                </div>
                <div class="form-group row">
                  <label for="purpose" class="col-12 col-form-label text-startcol-1">Purpose / Regulates (What the law
                    covers and regulates)</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="purpose" formControlName="purpose">

                  </div>
                </div>
                <div class="form-group row">
                  <label for="regulator" class="col-12 col-form-label text-startcol-1">Regulator</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="regulator" formControlName="regulator">

                  </div>
                </div>
                <div class="form-group row">
                  <label for="regulatorWebsite" class="col-12 col-form-label text-startcol-1">Regulator Website</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="regulatorWebsite" formControlName="regulatorWebsite">

                  </div>
                </div>
                <div class="form-group row">
                  <label for="exclusions" class="col-12 col-form-label text-startcol-1">Exclusions</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="exclusions" formControlName="exclusions">

                  </div>
                </div>
                <div class="form-group row">
                  <label for="appliesTo" class="col-12 col-form-label text-startcol-1">Applies To</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="appliesTo" formControlName="appliesTo">

                  </div>
                </div>
                <div class="">

                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="mandatoryTraining"
                      formControlName="mandatoryTraining">
                    <label class="form-check-label" for="mandatoryTraining">
                      Mandatory Training?
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="directorLiability"
                      formControlName="directorLiability">
                    <label class="form-check-label" for="directorLiability">
                      Director Liability
                    </label>
                  </div>
                  <div class="form-check  form-check-inline">
                    <input class="form-check-input" type="checkbox" id="popiaImplication"
                      formControlName="popiaImplication">
                    <label class="form-check-label" for="popiaImplication">
                      POPIA Implication
                    </label>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="questions" class="col-12 col-form-label text-startcol-1">Questions</label>
                  <div class="col-12">
                    <ng-select formControlName="questions" [items]="questions" [multiple]="true" [closeOnSelect]="false"
                      [searchable]="true" bindLabel="questionText" bindValue="id" placeholder="No Questions selected">
                      <ng-template ng-header-tmp>
                        <div class="text-end">
                          <button (click)="openQuestionModal($event)" class="btn btn-sm btn-secondary">Add</button>
                        </div>
                      </ng-template>
                    </ng-select>

                  </div>
                </div>

                <div class="form-group row">
                  <label for="documentRetentionPeriod" class="col-12 col-form-label text-startcol-1">Document Retention
                    Period (years)</label>
                  <div class="col-12">

                    <input type="number" min="0" class="form-control" id="documentRetentionPeriod"
                      formControlName="documentRetentionPeriod"
                      [class.is-invalid]="submitted && mainForm.controls.details.controls.documentRetentionPeriod.invalid">
                    <div class="invalid-feedback text-end"
                      *ngIf="mainForm.controls.details.controls.documentRetentionPeriod.errors">
                      <div *ngIf="mainForm.controls.details.controls.documentRetentionPeriod.errors.min">Invalid</div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="changes" class="col-12 col-form-label text-startcol-1">Changes</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="changes" formControlName="changes">

                  </div>
                </div>

                <div class="form-group row">
                  <label for="listingId" class="col-12 col-form-label text-start">Library Item</label>
                  <div class="col-12">
                    <ng-select [items]="listings$ | async" bindLabel="title" [multiple]="false" [hideSelected]="true"
                      [loading]="listingsLoading" [typeahead]="listingsInput$" formControlName="listingId"
                      placeholder="No Library Item selected">
                    </ng-select>

                  </div>
                </div>

              </div>

            </ng-template>
          </li>


          <li ngbNavItem>
            <a ngbNavLink>
              <span
                [class.text-green]="this.mainForm.controls.riskControls.valid && this.mainForm.controls.riskControls.touched"
                [class.text-red]="!this.mainForm.controls.riskControls.valid && this.mainForm.controls.riskControls.touched">Risk
                &amp; Controls</span>
            </a>
            <ng-template ngbNavContent>
              <div formGroupName="riskControls">
                <div class="form-group row">
                  <label for="impactRating" class="col-12 col-form-label text-startcol-1">Impact Rating</label>
                  <div class="col-12">
                    <ng-select formControlName="impactRating" [items]="impactRatings" bindLabel="name" bindValue="id"
                      placeholder="No Impact Rating selected" (open)="impactRatingOpen()" #impactRating
                      [class.is-invalid]="submitted && mainForm.controls.riskControls.controls.impactRating.invalid">
                    </ng-select>

                    <div class="invalid-feedback text-end"
                      *ngIf="mainForm.controls.riskControls.controls.impactRating.errors">
                      <div *ngIf="mainForm.controls.riskControls.controls.impactRating.errors.required">Required</div>
                    </div>

                  </div>
                </div>


                <div class="form-group row">
                  <label for="riskDescription" class="col-12 col-form-label text-startcol-1">Risk Description (RISK OF
                    NON
                    COMPLIANCE)</label>
                  <div class="col-12">
                    <textarea class="form-control" id="riskDescription" formControlName="riskDescription"
                      rows="6"></textarea>

                  </div>
                </div>

                <div class="form-group row">
                  <label for="implications" class="col-12 col-form-label text-startcol-1">Impact (CONSEQUENCES ON NON
                    COMPLIANCE)</label>
                  <div class="col-12">
                    <textarea class="form-control" id="implications" formControlName="implications" rows="6"></textarea>

                  </div>
                </div>

                <div class="form-group row">
                  <label for="controls" class="col-12 col-form-label text-startcol-1">Control Description
                    (CONTROLS)</label>
                  <div class="col-12">
                    <textarea class="form-control" id="controls" formControlName="controls" rows="6"></textarea>

                  </div>
                </div>




              </div>

            </ng-template>
          </li>

          <li ngbNavItem>
            <a ngbNavLink>
              <span
                [class.text-green]="this.mainForm.controls.requirements.valid && this.mainForm.controls.requirements.touched"
                [class.text-red]="!this.mainForm.controls.requirements.valid && this.mainForm.controls.requirements.touched">Requirements</span>
            </a>
            <ng-template ngbNavContent>

              <div formGroupName="requirements">
                <div class="form-group row">
                  <label for="policies" class="col-12 col-form-label text-start">Policies</label>
                  <div class="col-12">
                    <ng-select [items]="policies$ | async" bindLabel="title" [multiple]="true" [hideSelected]="true"
                      [loading]="policiesLoading" [typeahead]="policiesInput$" formControlName="policies"
                      placeholder="No Policies selected">
                    </ng-select>

                  </div>
                </div>
                <div class="form-group row">
                  <label for="permits" class="col-12 col-form-label text-start">Permits</label>
                  <div class="col-12">
                    <ng-select [items]="permits$ | async" bindLabel="title" [multiple]="true" [hideSelected]="true"
                      [loading]="permitsLoading" [typeahead]="permitsInput$" formControlName="permits"
                      placeholder="No Permits selected">
                    </ng-select>

                  </div>
                </div>
                <!-- <div class="form-group row">
                  <label for="policy" class="col-12 col-form-label text-startcol-1">Policy Name</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="policy" formControlName="policy">
                    
                  </div>
                </div> -->
                <!-- <div class="form-group row">
                  <label for="permits" class="col-12 col-form-label text-startcol-1">Permits</label>
                  <div class="col-12">
                    <ng-select formControlName="permits" [items]="permits" [multiple]="true" [closeOnSelect]="false"
                      [searchable]="true" bindLabel="name" bindValue="id" placeholder="No Permits selected">
                      <ng-template ng-header-tmp>
                        <div class="text-end">
                          <button (click)="openPermitModal($event)" class="btn btn-sm btn-secondary">Add</button>
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div class='d-flex'>
                          <div class="flex-grow-1">{{item.name}}</div>
                          <div><button (click)="openPermitModal($event, item)"
                              class="btn btn-sm btn-secondary">Edit</button>
                          </div>
                        </div>

                      </ng-template>
                    </ng-select>
                    
                  </div>
                </div> -->
                <div class="form-group row">
                  <label for="prescribedForms" class="col-12 col-form-label text-startcol-1">Prescribed Forms</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="prescribedForms" formControlName="prescribedForms">

                  </div>
                </div>

                <div class="form-group row">
                  <label for="reportingRequirements" class="col-12 col-form-label text-startcol-1">Reporting
                    Requirements</label>
                  <div class="col-12">
                    <ng-select formControlName="reportingRequirements" [items]="reportingRequirements" [multiple]="true"
                      [closeOnSelect]="false" [searchable]="true" bindLabel="name" bindValue="id"
                      placeholder="No Reporting Requirements selected">
                      <ng-template ng-header-tmp>
                        <div class="text-end">
                          <button (click)="openReportingRequirementModal($event)"
                            class="btn btn-sm btn-secondary">Add</button>
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div class='d-flex'>
                          <div class="flex-grow-1">{{item.name}}</div>
                          <div><button (click)="openReportingRequirementModal($event, item)"
                              class="btn btn-sm btn-secondary">Edit</button>
                          </div>
                        </div>

                      </ng-template>
                    </ng-select>

                  </div>
                </div>

                <!-- <div class="form-group row">
                  <label for="reportingRequirementName" class="col-12 col-form-label text-startcol-1">
                    Reporting Requirement Name
                  </label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="reportingRequirementName"
                      formControlName="reportingRequirementName">
                    
                  </div>
                </div>
                
                <div class="form-group row">
                  <label for="reportingDescription" class="col-12 col-form-label text-startcol-1">Reporting
                    Description</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="reportingDescription"
                      formControlName="reportingDescription">
                    
                  </div>
                </div> -->
                <div class="form-group row">
                  <label for="frequency" class="col-12 col-form-label text-startcol-1">Frequency or timing</label>
                  <div class="col-12">
                    <input type="text" class="form-control" id="frequency" formControlName="frequency">

                  </div>
                </div>
              </div>


            </ng-template>
          </li>

        </ul>
        <div [ngbNavOutlet]="nav"></div>


        <!-- <ngb-tabset justify="fill">
          

          <ngb-tab>
            <ng-template ngbTabTitle>
            </ng-template>
            <ng-template ngbTabContent>
              

            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              
            </ng-template>
            <ng-template ngbTabContent>

              

            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              
            </ng-template>

            <ng-template ngbTabContent>
              
            </ng-template>
          </ngb-tab>

          < !-- <ngb-tab>
            <ng-template ngbTabTitle>
              <span>Actions &amp; Tasks</span>
            </ng-template>
            <ng-template ngbTabContent>

              <div class="text-end py-2">
                <span class="p-4" *ngIf="!taskItems || taskItems.length == 0">No tasks</span>
                <button type="button" class="btn btn-default" (click)="addTask()">Add</button>
              </div>


              <div class="mb-2">
                <div class="task-item my-1 d-flex align-items-center" *ngFor="let task of taskItems">

                  <div class="p-2 flex-grow-1">
                    <div class="text-start">{{task.complianceQuery}}</div>
                  </div>
                  <div class="px-3 d-flex flex-row">
                    <button class="btn btn-link p-0" (click)="editTask(task)" title="Edit"><span class="icon icon-edit-red"></span></button>
                    <button class="btn btn-link p-0" (click)="removeTask(task)" title="Remove"><span class="icon icon-hide-red"></span></button>
                  </div>
                </div>

              </div>


            </ng-template>
          </ngb-tab> -- >

        </ngb-tabset> -->

        <div class="text-end">
          <button type="button" class="btn btn-default me-2" (click)="cancel()">Cancel</button>
          <button type="submit" class="btn btn-default">Save</button>
        </div>
      </form>
      <hr />
    </div>
  </ng-container>
</div>


<ng-template #riskModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Impact Risk</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <app-impact-rating (onSelect)="riskUpdated($event)" [value]="newRating"></app-impact-rating>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" [disabled]="!newRating || !newRating.id"
      (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<ng-template #industryModal let-modal>
  <form [formGroup]="industryForm" (ngSubmit)="submitIndustry()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Industry</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">

      <div class="form-group row">
        <label for="name" class="col-12 col-form-label text-startcol-1">Name</label>
        <div class="col-12">
          <input type="text" class="form-control" id="name" formControlName="name"
            [class.is-invalid]="industrySubmitted && industryForm.controls.name.invalid">
          <div class="invalid-feedback text-end" *ngIf="industryForm.controls.name.errors">
            <div *ngIf="industryForm.controls.name.required">Required</div>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" ngbAutofocus class="btn btn-primary" [disabled]="!industryForm.valid">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #questionModal let-modal>
  <form [formGroup]="questionForm" (ngSubmit)="submitQuestion()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Question</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">

      <div class="form-group row">
        <label for="name" class="col-12 col-form-label text-startcol-1">Question</label>
        <div class="col-12">
          <input type="text" class="form-control" id="question" formControlName="question">
          
        </div>
      </div>
      <div class="form-group row">
        <label for="industry" class="col-12 col-form-label text-startcol-1">Industry</label>
        <div class="col-12">
          <ng-select formControlName="industry" [items]="industries" bindLabel="name" bindValue="id"
            placeholder="All industries applicable">
          </ng-select>
          
          <small id="industriesHelp" class="form-text text-muted">Please specify the industry this question aplies to,
            leave blank if it applies to all industries.<br />
            If no applicable industries present, leave blank.</small>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" ngbAutofocus class="btn btn-primary" [disabled]="!questionForm.valid">Save</button>
    </div>
  </form>
</ng-template>


<ng-template #permitModal let-modal>
  <form [formGroup]="permitForm" (ngSubmit)="submitPermit()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Permit</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">

      <div class="form-group row">
        <label for="name" class="col-12 col-form-label text-startcol-1">Name</label>
        <div class="col-12">
          <input type="text" class="form-control" id="name" formControlName="name">
        </div>
      </div>

      <div class="form-group row">
        <label for="name" class="col-12 col-form-label text-startcol-1">Description</label>
        <div class="col-12">
          <input type="text" class="form-control" id="description" formControlName="description">
          
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" ngbAutofocus class="btn btn-primary" [disabled]="!permitForm.valid">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #reportingRequirementModal let-modal>
  <form [formGroup]="reportingRequirementForm" (ngSubmit)="submitReportingRequirement()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Reporting Requirement</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">

      <div class="form-group row">
        <label for="name" class="col-12 col-form-label text-startcol-1">Name</label>
        <div class="col-12">
          <input type="text" class="form-control" id="name" formControlName="name">
          
        </div>
      </div>

      <div class="form-group row">
        <label for="name" class="col-12 col-form-label text-startcol-1">Description</label>
        <div class="col-12">
          <input type="text" class="form-control" id="description" formControlName="description">
          
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" ngbAutofocus class="btn btn-primary"
        [disabled]="!reportingRequirementForm.valid">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #taskItemModal let-modal>
  <form [formGroup]="taskItemForm" (ngSubmit)="submitTaskItem()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Task Item</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="form-group row">
        <label for="complianceQuery" class="col-12 col-form-label text-startcol-1">Compliance Query</label>
        <div class="col-12">
          <input type="text" class="form-control" id="complianceQuery" formControlName="complianceQuery">
          
        </div>
      </div>
      <div class="form-group row">
        <label for="description" class="col-12 col-form-label text-startcol-1">Description</label>
        <div class="col-12">
          <input type="text" class="form-control" id="description" formControlName="description">
          
        </div>
      </div>
      <div class="form-group row">
        <div class="form-check d-flex align-items-center ms-3">
          <input class="form-check-input" type="checkbox" id="documentUpload" formControlName="documentUpload">
          <label class="form-check-label" for="documentUpload">
            Document Upload?
          </label>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" ngbAutofocus class="btn btn-primary" [disabled]="!taskItemForm.valid">Save</button>
    </div>
  </form>
</ng-template>