<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 offset-sm-1 offset-md-2 offset-lg-3 offset-xl-4">
      <ng-container *ngIf="view == 'login'">
        <h3 class="text-uppercase text-center">Welcome - please enter details</h3>
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="form-group row">
            <label for="email" class="col-sm-2 col-form-label ms-auto form-label" >Email</label>
            <div class="col-sm-10">
              <input title="Email" type="email" class="form-control" formControlName="email" [class.is-invalid]="loginSubmitted && loginForm.controls.email.invalid">
              
              <div class="invalid-feedback text-end" *ngIf="loginForm.controls.email.errors">
                <div *ngIf="loginForm.controls.email.errors.required">Required</div>
                <div *ngIf="loginForm.controls.email.errors.pattern">Enter a valid email address</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="password" class="col-sm-2 col-form-label form-label">Password</label>
            <div class="col-sm-10">
              <input title="Password" type="password" class="form-control" formControlName="password" [class.is-invalid]="loginSubmitted && loginForm.controls.password.invalid">
              <div class="invalid-feedback text-end" *ngIf="loginForm.controls.password.errors">
                <div *ngIf="loginForm.controls.password.errors.required">Required</div>
              </div>
            </div>
          </div>
          <div class="text-end">
            <a class="btn btn-link" (click)="view = 'forgot-password';">Forgot Password</a>&nbsp;&nbsp;<button type="submit" class="btn btn-default">Log In</button>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="view == 'mfa'">
        <h3 class="text-uppercase text-center">Please enter MFA Pin</h3>
        <p class=" text-center">If you have not yet set up your Multi-Factor Authentication via the Google Authenticator app, please click on the Set up MFA button below.</p>
        <form [formGroup]="mfaForm" (ngSubmit)="mfa()">
          <div class="form-group row">
            <label for="pin" class="col-sm-2 col-form-label ms-auto form-label" >PIN</label>
            <div class="col-sm-10">
              <input title="PIN" type="string" class="form-control" formControlName="pin" [class.is-invalid]="mfaSubmitted && mfaForm.controls.pin.invalid">
              
              <div class="invalid-feedback text-end" *ngIf="mfaForm.controls.pin.errors">
                <div *ngIf="mfaForm.controls.pin.errors.required">Required</div>
              </div>
            </div>
          </div>
          <div class="text-end">
            <a class="btn btn-link" href="#" (click)="setupMFA($event)">Set Up MFA</a>&nbsp;&nbsp;<button type="submit" class="btn btn-default">Continue</button>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="view == 'setup-mfa'">
        <h3 class="text-uppercase text-center">Setup MFA</h3>
        <div class="text-center">
          <p>Download the Google Authenticator app and scan the below QR Code or enter the Pin to complete the setup. Once the account has been added to your Google Authenticator app, click on the Continue button to proceed.</p>
          <p>
            <a class="btn btn-default" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US">Google Play Store</a>&nbsp;<a class="btn btn-default" href="https://apps.apple.com/us/app/google-authenticator/id388497605">Apple App Store</a>
          </p>
          <img *ngIf="qrCode" [src]="qrCode" class="img-fluid" alt="QR COde" />
          <p *ngIf="manualPin"><small style="overflow-wrap: break-word;">{{manualPin}}</small></p>
          <button class="btn btn-default" (click)="view = 'mfa'">Continue</button>
        </div>
      </ng-container>
      <ng-container *ngIf="view == 'forgot-password'">
        <h3 class="text-uppercase text-center">Forgot password</h3>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
          <div class="form-group row">
            <label for="email" class="col-sm-2 col-form-label ms-auto">Email</label>
            <div class="col-sm-10">
              <input title="email" type="email" class="form-control" formControlName="email" [class.is-invalid]="forgotPasswordSubmitted && forgotPasswordForm.controls.email.invalid">
              <div class="invalid-feedback text-end" *ngIf="loginForm.controls.email.errors">
                <div *ngIf="loginForm.controls.email.errors.required">Required</div>
                <div *ngIf="loginForm.controls.email.errors.pattern">Enter a valid email address</div>
              </div>
            </div>
          </div>
          <div class="text-end">
            <a class="btn btn-link" (click)="showForgotPassword = false;">Back to Login</a>&nbsp;&nbsp;<button type="submit" class="btn btn-default">Submit</button>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</div>
