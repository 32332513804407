import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompanyApiService } from '../../services/company-api.service';
import { SpinnerService } from '../../services/spinner.service';
import { EventsService } from '../../services/events.service';
import { CompanyStatus } from 'src/app/models/status';

@Component({
  selector: 'app-company-list-item',
  templateUrl: './company-list-item.component.html',
  styleUrls: ['./company-list-item.component.scss']
})
export class CompanyListItemComponent implements OnInit {

  _company: CompanyStatus;
  _level: number = 0;
  label: string = 'Company';
  route: string = 'company';
  constructor(private companyApi: CompanyApiService, private spinner: SpinnerService, private events: EventsService) { }

  @Input()
  set level(level: number){
    
    this._level = level;
    this.setLabelAndRoute();
  }

  @Input()
  set company(company: CompanyStatus){
    this._company = company;
  }

  @Output()
  change = new EventEmitter();
  
  ngOnInit() {
    this.setLabelAndRoute();
  }

  setLabelAndRoute(){
    if (!this._level){
      this._level = 0;
    }
    switch (this._level){
      case 0: //Company
        this.label = 'Sub Company';
        this.route = 'sub-company';
        break;
      case 1: //Sub Company
        this.label = 'Business Unit';
        this.route = 'business-unit';
        break;
      case 2: //Business Unit
        this.label = 'Area';
        this.route = 'area';
        break;
      case 3: //Area
        this.label = 'Legal Category';
        this.route = 'legal-category';
        break;
    }
  }

  archive(company: CompanyStatus){
    if (!window.confirm("Are you sure?")){
      return;
    }
    
    this.spinner.startBackground();

    company.active = false;
    this.companyApi.Archive(company.id).subscribe(() => {
      //this._company = null;
      this.change.emit();
      this.spinner.stopBackground();

      this.events.fireEvent('configuration-submenu-update');
      this.events.fireEvent('update-company-list');

    });
  }

  updateChildList(){
    this.change.emit();
  }

  getLabel(){

  }
}
