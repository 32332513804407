<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.apiCompanyId">

    <div class="card mb-3" *ngIf="currentDocumentId != 0">
        <div class="card-body">
            <h4 class="card-title">Workflow</h4>
            <div class="mb-3 d-flex justify-content-between align-items-center">
                Correspondence
                <button class="btn btn-primary" (click)="sendCorrespondence(content)">Send</button>
            </div>
            <hr />
            <div class="mb-3 d-flex justify-content-between align-items-center">
                History
                <div>

                    <button class="btn btn-primary me-2" (click)="viewCorrespondence()">View</button>
                    <button class="btn btn-primary" (click)="exportCorrespondence()">Export</button>
                </div>


            </div>
            <div *ngIf="showCorrespondence">

                <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                    <tr>
                        <th>DATE/TIME</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>SENDER</th>
                        <th>TYPE</th>
                        <th>DOCUMENT</th>
                        <th>ATTACHMENTS</th>
                    </tr>
                    <tr *ngFor="let item of correspondence">
                        <td>{{item.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.sender}}</td>
                        <td>{{item.documentCorrespondenceType}}</td>

                        <td class="text-center"><a href="#" (click)="download($event, item.id)"
                                class="d-flex align-items-center"><span class="icon icon-view-red me-2"></span></a></td>
                        <td class="text-center">
                            <div class="d-flex">
                                <a href="#" *ngFor="let attachment of item.attachments"
                                    (click)="downloadAttachment($event, attachment.id, item.id)"
                                    class="d-flex align-items-center">
                                    <span class="icon icon-view-red me-1"></span>
                                </a>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>

    <form [formGroup]="mainForm" (ngSubmit)="submit()">

        <div class="card mb-3" *ngIf="currentDocumentId == 0">
            <div class="card-body">
                <h4 class="card-title">Request Form</h4>




                <div class="mb-3">
                    <label for="documentFileData" class="form-label"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid">Request
                        Form</label>
                    <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                        (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                        <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                    </div>


                </div>





            </div>
        </div>

        <div class="card mb-3" *ngIf="currentDocumentId > 0">
            <div class="card-body">
                <h4 class="card-title">Request Form</h4>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="documentFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid">Update
                            Request Form</label>
                    </div>

                    <div *ngIf="currentDocument?.documentFilePath" class="mb-2">

                        

                        <div class="d-flex align-items-center">
                            <button *ngIf="currentDocument.documentFilePath" type="button"
                                (click)="downloadDocumentFile($event, 'Document')"
                                class="btn btn-link d-flex justify-content-between align-items-center me-2"><span
                                    class="icon icon-view-red"></span>&nbsp;DOWNLOAD CURRENT</button>
                            <button type="button" class="btn btn-primary me-2"
                                (click)="showHistory = !showHistory; showDocumentUpload = false;">{{showHistory
                                ? 'HIDE' : 'SHOW'}} History</button>
                            <button *ngIf="!showDocumentUpload" type="button" class="btn btn-primary"
                                (click)="showDocumentUpload = true; showHistory = false;">Upload new</button>
                            <button *ngIf="showDocumentUpload" type="button" class=" btn btn-primary"
                                (click)="showDocumentUpload = false;">Cancel</button>

                        </div>
                        <div *ngIf="showHistory" class="mb-2">
                            <button *ngFor="let doc of currentDocument.documentHistory" type="button"
                                (click)="downloadDocumentHistoryFile($event, doc.id)"
                                class="btn btn-link d-flex justify-content-between align-items-center"><span
                                    class="icon icon-view-red"></span>&nbsp;{{doc.createdAt | date:'yyyy-MM-dd HH:mm:ss'
                                }}</button>
                        </div>

                        <div class="mb-2">
                            
                        </div>
                        
                        <div *ngIf="!currentDocument?.documentFilePath || showDocumentUpload" class="mb-2">
                            <input type="file" class="form-control" id="documentFileData"
                                formControlName="documentFileData"
                                [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                                (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                                <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>



        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Accountable recipient of the request</h4>

                <div class="mb-3">
                    <label for="recipientFirstname" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientFirstname.touched && mainForm.controls.recipientFirstname.invalid">First
                        Name</label>
                    <input type="text" class="form-control" id="recipientFirstname" formControlName="recipientFirstname"
                        [class.is-invalid]="mainForm.controls.recipientFirstname.touched && mainForm.controls.recipientFirstname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientFirstname.errors">
                        <div *ngIf="mainForm.controls.recipientFirstname.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="recipientLastname" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientLastname.touched && mainForm.controls.recipientLastname.invalid">Last
                        Name</label>
                    <input type="text" class="form-control" id="recipientLastname" formControlName="recipientLastname"
                        [class.is-invalid]="mainForm.controls.recipientLastname.touched && mainForm.controls.recipientLastname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientLastname.errors">
                        <div *ngIf="mainForm.controls.recipientLastname.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="recipientContactNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientContactNumber.touched && mainForm.controls.recipientContactNumber.invalid">Contact
                        Number</label>
                    <input type="text" class="form-control" id="recipientContactNumber"
                        formControlName="recipientContactNumber"
                        [class.is-invalid]="mainForm.controls.recipientContactNumber.touched && mainForm.controls.recipientContactNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientContactNumber.errors">
                        <div *ngIf="mainForm.controls.recipientContactNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="recipientEmail" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientEmail.touched && mainForm.controls.recipientEmail.invalid">Email</label>
                    <input type="text" class="form-control" id="recipientEmail" formControlName="recipientEmail"
                        [class.is-invalid]="mainForm.controls.recipientEmail.touched && mainForm.controls.recipientEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientEmail.errors">
                        <div *ngIf="mainForm.controls.recipientEmail.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.recipientEmail.errors.email">Invalid</div>
                    </div>
                </div>

            </div>
        </div>

        <!-- <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Applicable facility or department</h4>

                <div class="mb-3">
                    <label for="documentRegionId" class="form-label">Region</label>
                    <ng-select [items]="regions" bindLabel="name" bindValue="id" id="documentRegionId"
                        formControlName="documentRegionId" placeholder="Select a region" [clearable]="false"
                        [class.is-invalid]="mainForm.controls.documentRegionId.touched && mainForm.controls.documentRegionId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentRegionId.errors">
                        <div *ngIf="mainForm.controls.documentRegionId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="documentFacilityId" class="form-label">Facility</label>
                    <ng-select [items]="facilities" bindLabel="name" bindValue="id" id="documentFacilityId"
                        formControlName="documentFacilityId" placeholder="Select a facility" [clearable]="false"
                        [readonly]="facilities == undefined"
                        [class.is-invalid]="mainForm.controls.documentFacilityId.touched && mainForm.controls.documentFacilityId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFacilityId.errors">
                        <div *ngIf="mainForm.controls.documentFacilityId.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div> -->

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Request Details</h4>


                <div class="mb-3">
                    <label for="receivedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.receivedDate.touched && mainForm.controls.receivedDate.invalid">Date
                        Request was Received</label>
                    <input type="date" class="form-control" id="receivedDate" formControlName="receivedDate"
                        [class.is-invalid]="mainForm.controls.receivedDate.touched && mainForm.controls.receivedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.receivedDate.errors">
                        <div *ngIf="mainForm.controls.receivedDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="acknowledgedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.acknowledgedDate.touched && mainForm.controls.acknowledgedDate.invalid">Date
                        Request was Acknowledged</label>
                    <input type="date" class="form-control" id="acknowledgedDate" formControlName="acknowledgedDate"
                        [class.is-invalid]="mainForm.controls.acknowledgedDate.touched && mainForm.controls.acknowledgedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.acknowledgedDate.errors">
                        <div *ngIf="mainForm.controls.acknowledgedDate.errors.required">Required</div>
                    </div>
                </div>

                <h5>Company / Person Details</h5>

                <div class="mb-3">
                    <label for="companyPersonIDNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.companyPersonIDNumber.touched && mainForm.controls.companyPersonIDNumber.invalid">ID
                        Number / Company Registration Number</label>
                    <input type="text" class="form-control" id="companyPersonIDNumber" formControlName="companyPersonIDNumber"
                        [class.is-invalid]="mainForm.controls.companyPersonIDNumber.touched && mainForm.controls.companyPersonIDNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.companyPersonIDNumber.errors">
                        <div *ngIf="mainForm.controls.companyPersonIDNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="companyPersonName" class="form-label"
                        [class.is-invalid]="mainForm.controls.companyPersonName.touched && mainForm.controls.companyPersonName.invalid">Name
                        & Surname / Company Name</label>
                    <input type="text" class="form-control" id="companyPersonName" formControlName="companyPersonName"
                        [class.is-invalid]="mainForm.controls.companyPersonName.touched && mainForm.controls.companyPersonName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.companyPersonName.errors">
                        <div *ngIf="mainForm.controls.companyPersonName.errors.required">Required</div>
                    </div>
                </div>


                <hr />

                <h5>Requestor</h5>

                <div class="mb-3">
                    <label for="requestorIDNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestorIDNumber.touched && mainForm.controls.requestorIDNumber.invalid">ID
                        Number</label>
                    <input type="text" class="form-control" id="requestorIDNumber" formControlName="requestorIDNumber"
                        [class.is-invalid]="mainForm.controls.requestorIDNumber.touched && mainForm.controls.requestorIDNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorIDNumber.errors">
                        <div *ngIf="mainForm.controls.requestorIDNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorFirstname" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestorFirstname.touched && mainForm.controls.requestorFirstname.invalid">First
                        Name</label>
                    <input type="text" class="form-control" id="requestorFirstname" formControlName="requestorFirstname"
                        [class.is-invalid]="mainForm.controls.requestorFirstname.touched && mainForm.controls.requestorFirstname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorFirstname.errors">
                        <div *ngIf="mainForm.controls.requestorFirstname.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorLastname" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestorLastname.touched && mainForm.controls.requestorLastname.invalid">Last
                        Name</label>
                    <input type="text" class="form-control" id="requestorLastname" formControlName="requestorLastname"
                        [class.is-invalid]="mainForm.controls.requestorLastname.touched && mainForm.controls.requestorLastname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorLastname.errors">
                        <div *ngIf="mainForm.controls.requestorLastname.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorCompanyRegistrationNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestorCompanyRegistrationNumber.touched && mainForm.controls.requestorCompanyRegistrationNumber.invalid">Company
                        Registration
                        Number</label>
                    <input type="text" class="form-control" id="requestorCompanyRegistrationNumber"
                        formControlName="requestorCompanyRegistrationNumber"
                        [class.is-invalid]="mainForm.controls.requestorCompanyRegistrationNumber.touched && mainForm.controls.requestorCompanyRegistrationNumber.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.requestorCompanyRegistrationNumber.errors">
                        <div *ngIf="mainForm.controls.requestorCompanyRegistrationNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorCompany" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestorCompany.touched && mainForm.controls.requestorCompany.invalid">Company
                        Name</label>
                    <input type="text" class="form-control" id="requestorCompany" formControlName="requestorCompany"
                        [class.is-invalid]="mainForm.controls.requestorCompany.touched && mainForm.controls.requestorCompany.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorCompany.errors">
                        <div *ngIf="mainForm.controls.requestorCompany.errors.required">Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="requestorAddress" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestorAddress.touched && mainForm.controls.requestorAddress.invalid">Address</label>
                    <textarea type="text" class="form-control" id="requestorAddress" formControlName="requestorAddress"
                        [class.is-invalid]="mainForm.controls.requestorAddress.touched && mainForm.controls.requestorAddress.invalid"></textarea>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorAddress.errors">
                        <div *ngIf="mainForm.controls.requestorAddress.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorEmail" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestorEmail.touched && mainForm.controls.requestorEmail.invalid">Email</label>
                    <input type="text" class="form-control" id="requestorEmail" formControlName="requestorEmail"
                        [class.is-invalid]="mainForm.controls.requestorEmail.touched && mainForm.controls.requestorEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorEmail.errors">
                        <div *ngIf="mainForm.controls.requestorEmail.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorContactNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestorContactNumber.touched && mainForm.controls.requestorContactNumber.invalid">Contact
                        Number</label>
                    <input type="text" class="form-control" id="requestorContactNumber"
                        formControlName="requestorContactNumber"
                        [class.is-invalid]="mainForm.controls.requestorContactNumber.touched && mainForm.controls.requestorContactNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorContactNumber.errors">
                        <div *ngIf="mainForm.controls.requestorContactNumber.errors.required">Required</div>
                    </div>
                </div>

                <hr />

                <h5>Details of Case Request</h5>

                
                <div class="mb-3">
                    <label for="requestType" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestType.touched && mainForm.controls.requestType.invalid">Type
                        of request</label>
                    <input type="text" class="form-control" id="requestType" formControlName="requestType"
                        [class.is-invalid]="mainForm.controls.requestType.touched && mainForm.controls.requestType.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestType.errors">
                        <div *ngIf="mainForm.controls.requestType.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestDetails" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestDetails.touched && mainForm.controls.requestDetails.invalid">Describe
                        what information and records are
                        required</label>
                    <textarea type="text" class="form-control" id="requestDetails" formControlName="requestDetails"
                        [class.is-invalid]="mainForm.controls.requestDetails.touched && mainForm.controls.requestDetails.invalid"></textarea>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestDetails.errors">
                        <div *ngIf="mainForm.controls.requestDetails.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestReason" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestReason.touched && mainForm.controls.requestReason.invalid">Describe
                        the reasons why the information and records
                        have been requested</label>
                    <textarea type="text" class="form-control" id="requestReason" formControlName="requestReason"
                        [class.is-invalid]="mainForm.controls.requestReason.touched && mainForm.controls.requestReason.invalid"></textarea>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestReason.errors">
                        <div *ngIf="mainForm.controls.requestReason.errors.required">Required</div>
                    </div>
                </div>

                <hr />

                <div class="mb-3">
                    <label for="incidentDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.incidentDate.touched && mainForm.controls.incidentDate.invalid">Date
                        of the incident/admission to which the request
                        relates</label>
                    <input type="date" class="form-control" id="incidentDate" formControlName="incidentDate"
                        [class.is-invalid]="mainForm.controls.incidentDate.touched && mainForm.controls.incidentDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.incidentDate.errors">
                        <div *ngIf="mainForm.controls.incidentDate.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Status of request</h4>

                <div class="mb-3">
                    <label for="matterManagementDocumentStatusId" class="form-label"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentStatusId.touched && mainForm.controls.matterManagementDocumentStatusId.invalid">What
                        is the current status of the
                        request</label>
                    <ng-select [items]="statuses" bindLabel="name" bindValue="id" id="matterManagementDocumentStatusId"
                        formControlName="matterManagementDocumentStatusId" placeholder="Select a request status" [clearable]="false"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentStatusId.touched && mainForm.controls.matterManagementDocumentStatusId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.matterManagementDocumentStatusId.errors">
                        <div *ngIf="mainForm.controls.matterManagementDocumentStatusId.errors.required">Required</div>
                    </div>
                </div>



            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
              
                <div class="mb-3">
                    <label for="fees" class="card-title h4 form-label"
                        [class.is-invalid]="mainForm.controls.fees.touched && mainForm.controls.fees.invalid">Request
                        Fee</label>
                    <input type="text" class="form-control" id="fees" formControlName="fees"
                        [class.is-invalid]="mainForm.controls.fees.touched && mainForm.controls.fees.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.fees.errors">
                        <div *ngIf="mainForm.controls.fees.errors.required">Required</div>
                    </div>
                </div>

                
            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Response Outcome/ Decision</h4>

                <div class="mb-3">
                    <label for="decision" class="form-label"
                        [class.is-invalid]="mainForm.controls.decision.touched && mainForm.controls.decision.invalid">Outcome
                        of decision</label>
                        <div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="decision" id="decisionNo"
                                    [value]="false" formControlName="decision"
                                    [class.is-invalid]="mainForm.controls.decision.touched && mainForm.controls.decision.invalid">
                                <label class="form-check-label" for="decisionNo">Open</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="decision" id="decisionYes"
                                    [value]="true" formControlName="decision"
                                    [class.is-invalid]="mainForm.controls.decision.touched && mainForm.controls.decision.invalid">
                                <label class="form-check-label" for="decisionYes">Settled</label>
                            </div>
                            
                        </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decision.errors">
                        <div *ngIf="mainForm.controls.decision.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="decisionReason" class="form-label"
                        [class.is-invalid]="mainForm.controls.decisionReason.touched && mainForm.controls.decisionReason.invalid">Reason
                        for outcome</label>
                    <input type="text" class="form-control" id="decisionReason" formControlName="decisionReason"
                        [class.is-invalid]="mainForm.controls.decisionReason.touched && mainForm.controls.decisionReason.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decisionReason.errors">
                        <div *ngIf="mainForm.controls.decisionReason.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="decisionDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.decisionDate.touched && mainForm.controls.decisionDate.invalid">Date
                        of decision</label>
                    <input type="date" class="form-control" id="decisionDate" formControlName="decisionDate"
                        [class.is-invalid]="mainForm.controls.decisionDate.touched && mainForm.controls.decisionDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decisionDate.errors">
                        <div *ngIf="mainForm.controls.decisionDate.errors.required">Required</div>
                    </div>
                </div>


                

                <div class="mb-3">
                    <label for="recordsLocation" class="form-label"
                        [class.is-invalid]="mainForm.controls.recordsLocation.touched && mainForm.controls.recordsLocation.invalid">Where
                        are the records housed?</label>
                    <input type="text" class="form-control" id="recordsLocation" formControlName="recordsLocation"
                        [class.is-invalid]="mainForm.controls.recordsLocation.touched && mainForm.controls.recordsLocation.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recordsLocation.errors">
                        <div *ngIf="mainForm.controls.recordsLocation.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="matterManagementDocumentAppealStatusId" class="form-label"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentAppealStatusId.touched && mainForm.controls.matterManagementDocumentAppealStatusId.invalid">Appeal
                        Status</label>
                    <ng-select [items]="appealStatuses" bindLabel="name" bindValue="id" id="matterManagementDocumentAppealStatusId"
                        formControlName="matterManagementDocumentAppealStatusId" placeholder="Select an appeal status"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentAppealStatusId.touched && mainForm.controls.matterManagementDocumentAppealStatusId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.matterManagementDocumentAppealStatusId.errors">
                        <div *ngIf="mainForm.controls.matterManagementDocumentAppealStatusId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="matterManagementDocumentReferralId" class="form-label"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentReferralId.touched && mainForm.controls.matterManagementDocumentReferralId.invalid">Referrals</label>
                    <ng-select [items]="referrals" bindLabel="name" bindValue="id" id="matterManagementDocumentReferralId"
                        formControlName="matterManagementDocumentReferralId" placeholder="Select a referral" [clearable]="false"
                        [class.is-invalid]="mainForm.controls.matterManagementDocumentReferralId.touched && mainForm.controls.matterManagementDocumentReferralId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.matterManagementDocumentReferralId.errors">
                        <div *ngIf="mainForm.controls.matterManagementDocumentReferralId.errors.required">Required</div>
                    </div>
                </div>


            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Important Dates</h4>


                <div class="mb-3">
                    <label for="caseOpenedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.caseOpenedDate.touched && mainForm.controls.caseOpenedDate.invalid">Date
                        case was opened</label>
                    <input type="date" class="form-control" id="caseOpenedDate" formControlName="caseOpenedDate"
                        [class.is-invalid]="mainForm.controls.caseOpenedDate.touched && mainForm.controls.caseOpenedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.caseOpenedDate.errors">
                        <div *ngIf="mainForm.controls.caseOpenedDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="decisionProvidedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.decisionProvidedDate.touched && mainForm.controls.decisionProvidedDate.invalid">Date
                        decision was provided to the
                        requestor</label>
                    <input type="date" class="form-control" id="decisionProvidedDate"
                        formControlName="decisionProvidedDate">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decisionProvidedDate.errors"
                        [class.is-invalid]="mainForm.controls.decisionProvidedDate.touched && mainForm.controls.decisionProvidedDate.invalid">
                        <div *ngIf="mainForm.controls.decisionProvidedDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="caseClosedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.caseClosedDate.touched && mainForm.controls.caseClosedDate.invalid">Closed
                        Date</label>
                    <input type="date" class="form-control" id="caseClosedDate" formControlName="caseClosedDate"
                        [class.is-invalid]="mainForm.controls.caseClosedDate.touched && mainForm.controls.caseClosedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.caseClosedDate.errors">
                        <div *ngIf="mainForm.controls.caseClosedDate.errors.required">Required</div>
                    </div>
                </div>

                <!-- <div class="mb-3">
                    <label for="renewalDate" class="form-label">Renewal Date</label>
                    <input type="date" class="form-control" id="renewalDate" formControlName="renewalDate"
                        [class.is-invalid]="mainForm.controls.renewalDate.touched && mainForm.controls.renewalDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.renewalDate.errors">
                        <div *ngIf="mainForm.controls.renewalDate.errors.required">Required</div>
                    </div>
                </div> -->



            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Additional Supporting Documents</h4>


                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="form2FileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.form2FileData.touched && mainForm.controls.form2FileData.invalid">Form
                            2</label>

                    </div>
                    <div *ngIf="currentDocument?.form2FilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.form2FilePath" type="button"
                            (click)="downloadDocumentFile($event, 'Form2')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showForm2Upload" type="button" class="btn btn-primary"
                            (click)="showForm2Upload = true;">Upload new</button>
                        <button *ngIf="showForm2Upload" type="button" class=" btn btn-primary"
                            (click)="showForm2Upload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.form2FilePath || showForm2Upload">
                        <input type="file" class="form-control" id="form2FileData" formControlName="form2FileData"
                            (change)="fileUpload(mainForm.controls.form2FileData, $event)"
                            [class.is-invalid]="mainForm.controls.form2FileData.touched && mainForm.controls.form2FileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.form2FileData.errors">
                            <div *ngIf="mainForm.controls.form2FileData.errors.required">Required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="letterheadFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.letterheadFileData.touched && mainForm.controls.letterheadFileData.invalid">Certified
                            Copy of data subject
                            ID/Company
                            Letterhead </label>
                    </div>

                    <div *ngIf="currentDocument?.letterheadFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.letterheadFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'Letterhead')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showLetterheadUpload" type="button" class="btn btn-primary"
                            (click)="showLetterheadUpload = true;">Upload new</button>
                        <button *ngIf="showLetterheadUpload" type="button" class=" btn btn-primary"
                            (click)="showLetterheadUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.letterheadFilePath || showLetterheadUpload">
                        <input type="file" class="form-control" id="letterheadFileData"
                            formControlName="letterheadFileData"
                            (change)="fileUpload(mainForm.controls.letterheadFileData, $event)"
                            [class.is-invalid]="mainForm.controls.letterheadFileData.touched && mainForm.controls.letterheadFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.letterheadFileData.errors">
                            <div *ngIf="mainForm.controls.letterheadFileData.errors.required">Required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="consentFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.consentFileData.touched && mainForm.controls.consentFileData.invalid">Signed
                            consent or power of attorney
                            including
                            proof of relationship</label>
                    </div>

                    <div *ngIf="currentDocument?.consentFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.consentFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'Consent')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showConsentUpload" type="button" class="btn btn-primary"
                            (click)="showConsentUpload = true;">Upload new</button>
                        <button *ngIf="showConsentUpload" type="button" class=" btn btn-primary"
                            (click)="showConsentUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.consentFilePath || showConsentUpload">
                        <input type="file" class="form-control" id="consentFileData" formControlName="consentFileData"
                            (change)="fileUpload(mainForm.controls.consentFileData, $event)"
                            [class.is-invalid]="mainForm.controls.consentFileData.touched && mainForm.controls.consentFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.consentFileData.errors">
                            <div *ngIf="mainForm.controls.consentFileData.errors.required">Required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="kycFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.kycFileData.touched && mainForm.controls.kycFileData.invalid">KYC, Due Dilligence, FICA or Otherwise</label>
                    </div>

                    <div *ngIf="currentDocument?.kycFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.kycFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'KYC')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showKYCUpload" type="button" class="btn btn-primary"
                            (click)="showKYCUpload = true;">Upload new</button>
                        <button *ngIf="showKYCUpload" type="button" class=" btn btn-primary"
                            (click)="showKYCUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.kycFilePath || showKYCUpload">
                        <input type="file" class="form-control" id="kycFileData" formControlName="kycFileData"
                            (change)="fileUpload(mainForm.controls.kycFileData, $event)"
                            [class.is-invalid]="mainForm.controls.kycFileData.touched && mainForm.controls.kycFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.kycFileData.errors">
                            <div *ngIf="mainForm.controls.kycFileData.errors.required">Required</div>
                        </div>
                    </div>
                </div>



            </div>
        </div>

        <div class="text-end">
            <button type="button" class="btn btn-default" routerLink="/dashboard/documents/matter-management">Cancel</button>&nbsp;
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
    </form>




</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Send Correspondence</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="correspondenceForm" (ngSubmit)="submitCorrespondence()">

            <div class="mb-3">
                <label for="documentCorrespondenceTypeId" class="form-label"
                    [class.is-invalid]="correspondenceForm.controls.documentCorrespondenceTypeId.touched && correspondenceForm.controls.documentCorrespondenceTypeId.invalid">Type</label>
                <ng-select [items]="documentCorrespondenceTypes" bindLabel="name" bindValue="id"
                    id="documentCorrespondenceTypeId"
                    [class.is-invalid]="correspondenceForm.controls.documentCorrespondenceTypeId.touched && correspondenceForm.controls.documentCorrespondenceTypeId.invalid"
                    formControlName="documentCorrespondenceTypeId" placeholder="Select a template"></ng-select>
                <div class="invalid-feedback text-end"
                    *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors">
                    <div *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors.required">Required</div>
                </div>

            </div>

            <div class="mb-3">
                <label for="sender" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.sender.touched) && correspondenceForm.controls.sender.invalid">Sender</label>
                <input type="text" class="form-control" id="sender" formControlName="sender"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.sender.touched) && correspondenceForm.controls.sender.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.sender.errors">
                    <div *ngIf="correspondenceForm.controls.sender.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.name.touched) && correspondenceForm.controls.name.invalid">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.name.touched) && correspondenceForm.controls.name.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.name.errors">
                    <div *ngIf="correspondenceForm.controls.name.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="email" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.email.touched) && correspondenceForm.controls.email.invalid">Email</label>
                <input type="text" class="form-control" id="email" formControlName="email"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.email.touched) && correspondenceForm.controls.email.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.email.errors">
                    <div *ngIf="correspondenceForm.controls.email.errors.required">Required</div>
                    <div *ngIf="correspondenceForm.controls.email.errors.email">Invalid</div>
                </div>
            </div>

            <div class="mb-3">
                <label for="documentFileData" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.documentFileData.touched) && correspondenceForm.controls.documentFileData.invalid">Document</label>
                <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                    (change)="fileUpload(correspondenceForm.controls.documentFileData, $event)"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.documentFileData.touched) && correspondenceForm.controls.documentFileData.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.documentFileData.errors">
                    <div *ngIf="correspondenceForm.controls.documentFileData.errors.required">Required</div>
                </div>
            </div>

            <div *ngFor="let attachment of correspondenceForm.controls.attachments.controls; let i = index"
                formArrayName="attachments">
                <div class="mb-3">
                    <label for="attachmentFileData" class="form-label"
                        [class.is-invalid]="(correspondenceFormSubmitted || attachment.touched) && attachment.invalid">Attachment
                        {{i+1}}</label>
                    <div class="input-group">
                        <input type="file" class="form-control" id="attachmentFileData" [formControl]="attachment"
                            (change)="fileUpload(attachment, $event)"
                            [class.is-invalid]="(correspondenceFormSubmitted || attachment.touched) && attachment.invalid">
                        <button type="button" class="btn btn-danger" (click)="removeAttachement(i)">X</button>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="attachment.errors">
                        <div *ngIf="attachment.errors.required">Required</div>
                    </div>

                </div>

            </div>
            <div class="text-end">
                <button type="button" class="btn btn-link" (click)="addAttachement()">+ Add Attachment</button>
            </div>


            <div class="text-end">
                <button type="button" class="btn btn-default" (click)="modal.dismiss()">Cancel</button>&nbsp;
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>

</ng-template>