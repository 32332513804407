import { Component, Input, OnInit } from '@angular/core';
import { ActReportItemListResult } from 'src/app/models/actReportItemListResult';
import { CompanyDetailStatus } from 'src/app/models/companyDetailStatus';
import { ImpactRating } from 'src/app/models/impactRating';
import { Period } from 'src/app/models/period';
import { CompanyApiService } from 'src/app/services/company-api.service';
import { ReportsApiService } from 'src/app/services/reports-api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StatusApiService } from 'src/app/services/status-api.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-umbrella-report-widget',
  templateUrl: './umbrella-report-widget.component.html',
  styleUrls: ['./umbrella-report-widget.component.scss']
})
export class UmbrellaReportWidgetComponent implements OnInit {

  @Input()
  set companyId(id: number){
    this.apiCompanyId = id;
    this.loadPeriods();
    this.getStatus();
  }

  @Input()
  set periodIndex(index: number){
    this.index = index;
    this.loadPeriods();
    this.getStatus();
  }

  index: number = -1;
  apiCompanyId: number;
  status: CompanyDetailStatus;

  selectedPeriodId?: number = null;
  selectedPeriod: Period;
  periods: Period[];
  periodsLoading = false;
  public orgId: number = 0;

  superAdmin: boolean = false;

  report: ActReportItemListResult;

  constructor(
    private statusApi: StatusApiService,
    private reportsApi: ReportsApiService,
    private companyApi: CompanyApiService,
    private spinner: SpinnerService,
    public token: TokenService) { }

  ngOnInit() {
    this.superAdmin = this.token.isSuperAdmin();

    if (this.superAdmin) {
      this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.orgId = this.token.organisationId();
    }
    
  }

  periodSelected(event){
    
    this.selectedPeriodId = this.selectedPeriod ? this.selectedPeriod.id : null;

    this.getStatus();
  }

  private loadPeriods() {
    if (this.index == -1){
      return;
    }
    this.periodsLoading = true;
    return this.companyApi.GetCompanyPeriods(this.apiCompanyId).subscribe(data => {
      let current:Period = { id: 0, name: 'Current'};

      this.periods = [current].concat(data);

      this.selectedPeriod = this.periods[this.index];
      this.periodsLoading = false;
      
    });
  }

  getStatus() {

    this.spinner.startBackground();
    this.statusApi.getCompanyStatus(this.apiCompanyId, null, null, this.selectedPeriodId).subscribe((data) => {
      this.status = data;
      this.spinner.stopBackground();
    });

    this.reportsApi.ActReport(1, 1000, this.apiCompanyId, null, null, null).subscribe(data => {
      this.report = data;
    })

  }
  displayImpactRating(impactRating: ImpactRating) {
    if (impactRating && impactRating.name) {
      return impactRating.name;
    }
    else {
      return ' ';
    }
  }
  
}
