<div class="mb-1 d-flex  align-items-center">
  <div class="listing flex-grow-1 p-2">
    <div class="text-start" *ngIf="this._select"><button class="btn btn-link p-0" (click)="selectClick()"><strong>{{_listing.title}}</strong></button></div>
    <div class="text-start" *ngIf="!this._select"><strong>{{_listing.title}}</strong></div>
    <div class="text-start">{{_listing.description}}&nbsp;</div>
  </div>
  <div class="px-3 d-flex flex-column">
    <button *ngIf="this._select" class="btn btn-link p-0" (click)="selectClick()" title="Select"><span class="icon icon-view-red"></span></button>
    <button *ngIf="this._edit" class="btn btn-link p-0" (click)="editClick()" title="Edit"><span class="icon icon-edit-red"></span></button>
  </div>
</div>