import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { ListingItemAdmin } from '../models/listing-item-admin';
import { ListingItem } from '../models/listingItem';
import { ListingItemFull } from '../models/listingItemFull';
import { ListingResult } from '../models/listingResult';
import { ListingsBulkUpdate } from '../models/listingsBulkUpdate';

@Injectable({
  providedIn: 'root'
})
export class LibraryApiService extends ApiService<ListingItemFull> {

  _controller: string = 'library';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('library', http, env);
  }

  public ListItems(parentId?: number, page: number = 1, perPage: number = 20, reverse: boolean = false, listingTypeId: number = null, search: string = null): Observable<ListingResult> {
    let queryString = '';

    if (parentId) {
      queryString = `${queryString}&parentId=${parentId}&`
    }

    queryString = `${queryString}page=${page}&perPage=${perPage}&reverse=${reverse}`;

    if (listingTypeId) {
      queryString = `${queryString}&listingTypeId=${listingTypeId}&search=${search}`;
    }

    if (search) {
      queryString = `${queryString}&search=${search}`;
    }

    return this.get<ListingResult>(`api/${this._controller}/?${queryString}`);
  }

  public ListItemsAdmin(companyId:number, parentId?: number, listingTypeId: number = null): Observable<ListingItemAdmin[]> {
    
    let queryString = ``;

    if (parentId) {
      queryString = `${queryString}&parentId=${parentId}&`
    }

    if (listingTypeId) {
      queryString = `${queryString}&listingTypeId=${listingTypeId}`;
    }

    return this.get<ListingItemAdmin[]>(`api/${this._controller}/listadmin/${companyId}?${queryString}`);
  }



  public GetById(id: number): Observable<ListingItemFull> {
    return this.get<ListingItemFull>(`api/${this._controller}/${id}`);
  }

  public Archive(id: number): Observable<{}> {
    return this.post<{}, {}>(`api/${this._controller}/archive/${id}`, {});
  }

  public Breadcrumb(id: number): Observable<Array<ListingItem>> {
    return this.get<Array<ListingItem>>(`api/${this._controller}/breadcrumb/${id}`);
  }

  public BulkUpdate(data: ListingsBulkUpdate): Observable<{}> {
    return this.post<{}, {}>(`api/${this._controller}/bulkupdate`, data);
  }
}
