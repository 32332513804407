import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { ChecklistItem } from '../models/checklistItem';

@Injectable({
  providedIn: 'root'
})
export class ChecklistApiService extends ApiService<ChecklistItem> {

  _controller: string = 'checklistitems';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('checklistitems', http, env);
  }

  public ListByActSectionId(actSectionId: number): Observable<Array<ChecklistItem>> {
    return this.get<Array<ChecklistItem>>(`api/${this._controller}/?actsectionid=${actSectionId}`);
  }
}
