import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { TaskItem } from '../models/taskItem';

@Injectable({
  providedIn: 'root'
})
export class TaskItemApiService extends ApiService<TaskItem> {

  _controller: string = 'taskitems';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('taskitems', http, env);
  }

  public ListByActId(actId: number): Observable<Array<TaskItem>> {
    return this.get<Array<TaskItem>>(`api/${this._controller}/?actid=${actId}`);
  }
}
