    <div class="row">
      <div class="col-lg-6">
        <app-impact-rating (onSelect)="riskUpdated($event)" [value]="_rating" [readOnly]="_readOnly"></app-impact-rating>
      </div>
      <div class="col-lg-6" *ngIf="_residualModal">
        <div class="py-3">
          <app-compliance-status [status]="_status" [act]="act" [modal]="true"></app-compliance-status>
        </div>
      </div>
      <div class="col-lg-6" *ngIf="!_residualModal">
        <div class="mb-2">
          <strong>Risks</strong>
          <p>{{_riskData.riskDescription}}</p>
        </div>
        <div class="mb-2">
          <strong>Consequence of Non-compliance</strong>
          <p>{{_riskData.implications}}</p>
        </div>
        <div class="mb-2">
          <strong>Controls</strong>
          <p>{{_riskData.controls}}</p>
        </div>
      </div>
    </div>
