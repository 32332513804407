import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EnvironmentService } from './environment.service';
import { ActResult } from '../models/actResult';
import { ActListingResult } from '../models/actListingResult';
import { AnswerTaskItem } from '../models/answerTaskItem';
import { AssignCompanyChecklistItemUser } from '../models/assignCompanyChecklistItemUser';
import { AssignCompanyTaskItemUser } from '../models/assignCompanyTaskItemUser';
import { AssignLearningModuleUser } from '../models/assignLearningModuleUsers';
import { Company, CompanyBase } from '../models/company';
import { CompanyActListResult } from '../models/companyActListResult';
import { CompanyChecklistItemActResult } from '../models/companyChecklistItemActResult';
import { CompanyChecklistItemResult } from '../models/companyChecklistItemResult';
import { CompanyChecklistUserResult } from '../models/companyChecklistUserResult';
import { CompanyLearningModule } from '../models/companyLearningModule';
import { CompanyQuestion } from '../models/companyQuestion';
import { CompanyRoleOwner } from '../models/companyRoleOwner';
import { CompanyTaskItemResult } from '../models/companyTaskItemResult';
import { CompanyTaskItemSummaryResult } from '../models/companyTaskItemSummaryResult';
import { Department } from '../models/department';
import { LearningModuleResults } from '../models/learningModuleResults';
import { ReportingRequirementResult } from '../models/reportingRequirementResult';
import { UpdateCompanyActControls } from '../models/updateCompanyActControls';
import { UpdateCompanyActImpactRating } from '../models/updateCompanyActImpactRating';
import { UpdateCompanyActImplications } from '../models/updateCompanyActImplications';
import { UpdateCompanyActNotes } from '../models/updateCompanyActNotes';
import { UpdateCompanyRiskDescription } from '../models/updateCompanyRiskDescription';
import { User } from '../models/user';
import { Period } from '../models/period';
import { UpdateCompanyActDocumentRetentionPeriod } from '../models/updateCompanyActDocumentRetentionPeriod';
import { CompanyTaskItemMark } from '../models/companyTaskItemMark';
import { CompanyActAllocation } from '../models/company-act-allocation';
import { AnswerChecklistItem } from '../models/answerChecklistItem';
import { CompanyAct } from '../models/companyAct';

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService extends ApiService<Company> {

  private controllerName: string = 'companies';
  constructor(http: HttpClient, env: EnvironmentService) {
    super('companies', http, env);
  }

  public ListCompanies(): Observable<Array<CompanyBase>> {
    return this.get<Array<CompanyBase>>(`api/${this.controllerName}`);
  }

  public ListByOrganisationId(id: number): Observable<Array<Company>> {
    return this.get<Array<Company>>(`api/${this.controllerName}/organisation/${id}`);
  }

  public ListByCompanyId(id: number): Observable<Array<Company>> {
    return this.get<Array<Company>>(`api/${this.controllerName}/businessunits/${id}`);
  }

  public GetCompanyQuestions(id: number): Observable<Array<CompanyQuestion>> {
    return this.get<Array<CompanyQuestion>>(`api/${this.controllerName}/${id}/questions/`);
  }

  public SubmitCompanyQuestions(id: number, questions: Array<CompanyQuestion>): Observable<any> {
    return this.post<Array<CompanyQuestion>, any>(`api/${this.controllerName}/${id}/questions/`, questions);
  }

  public Archive(id: number): Observable<{}> {
    return this.post(`api/${this._controller}/${id}/archive`, {});
  }

  public GetCompanyRoleOwners(companyId?: number): Observable<Array<CompanyRoleOwner>> {
    if (companyId) {
      return this.get<Array<CompanyRoleOwner>>(`api/${this.controllerName}/${companyId}/roleowners/`);
    }
    else {
      return this.get<Array<CompanyRoleOwner>>(`api/${this.controllerName}/roleowners/`);
    }
  }

  public GetValidCompanyRoleOwners(companyId?: number): Observable<Array<CompanyRoleOwner>> {
    if (companyId) {
      return this.get<Array<CompanyRoleOwner>>(`api/${this.controllerName}/${companyId}/validroleowners/`);
    }
    else {
      return this.get<Array<CompanyRoleOwner>>(`api/${this.controllerName}/validroleowners/`);
    }
  }

  public SubmitCompanyRoleOwners(id: number, roleOwners: Array<CompanyRoleOwner>): Observable<string[]> {
    return this.post<Array<CompanyRoleOwner>, string[]>(`api/${this.controllerName}/${id}/roleowners/`, roleOwners);
  }

  public GetCompanyActs(page: number = 1, perPage: number = 10, companyId?: number, departmentId?: number, userId?: number, impactRatingId?: number, descending?: boolean): Observable<CompanyActListResult> {

    let url = '';
    if (companyId) {
      url = `api/${this.controllerName}/${companyId}/acts/?page=${page}&perPage=${perPage}`;
    }
    else {
      url = `api/${this.controllerName}/acts/?page=${page}&perPage=${perPage}`;
    }

    if (departmentId) {
      url = url + `&departmentId=${departmentId}`
    }

    if (userId) {
      url = url + `&userId=${userId}`
    }

    if (impactRatingId) {
      url = url + `&impactRatingId=${impactRatingId}`
    }

    if (descending) {
      url = url + `&descending=${descending}`
    }

    return this.get<CompanyActListResult>(url);
  }

  public GetCompanyAct(actId: number, companyId?: number, ): Observable<CompanyAct> {

    let url = '';
    if (companyId) {
      url = `api/${this.controllerName}/${companyId}/acts/${actId}`;
    }
    else {
      url = `api/${this.controllerName}/acts/${actId}`;
    }
    
    return this.get<CompanyAct>(url);
  }

  public UpdateImpactRating(data: UpdateCompanyActImpactRating): Observable<any> {
    return this.post<UpdateCompanyActImpactRating, any>(`api/${this.controllerName}/updateimpactrating`, data);
  }

  public UpdateResidualRiskRating(data: UpdateCompanyActImpactRating): Observable<any> {
    return this.post<UpdateCompanyActImpactRating, any>(`api/${this.controllerName}/updateresidualriskrating`, data);
  }

  public UpdateControls(data: UpdateCompanyActControls): Observable<any> {
    return this.post<UpdateCompanyActControls, any>(`api/${this.controllerName}/updatecontrols`, data);
  }

  public UpdateDocumentRetentionPeriod(data: UpdateCompanyActDocumentRetentionPeriod): Observable<any> {
    return this.post<UpdateCompanyActDocumentRetentionPeriod, any>(`api/${this.controllerName}/updatedocumentretentionperiod`, data);
  }

  public UpdateNotes(data: UpdateCompanyActNotes): Observable<any> {
    return this.post<UpdateCompanyActNotes, any>(`api/${this.controllerName}/updatenotes`, data);
  }

  public UpdateImplications(data: UpdateCompanyActImplications): Observable<any> {
    return this.post<UpdateCompanyActImplications, any>(`api/${this.controllerName}/updateimplications`, data);
  }

  public UpdateRiskDescription(data: UpdateCompanyRiskDescription): Observable<any> {
    return this.post<UpdateCompanyRiskDescription, any>(`api/${this.controllerName}/updateriskdescription`, data);
  }

  public GetCompanyUsers(id: number): Observable<User[]> {
    return this.get<User[]>(`api/${this.controllerName}/${id}/users/`);
  }

  public AddCompanyUser(user: User): Observable<{}> {
    return this.post<User, {}>(`api/${this.controllerName}/adduser`, user)
  }

  public UpdateCompanyUser(user: User): Observable<{}> {
    return this.post<User, {}>(`api/${this.controllerName}/updateuser`, user)
  }

  public MarkCompanyTaskItem(data: CompanyTaskItemMark): Observable<{}> {

    return this.post<CompanyTaskItemMark, {}>(`api/${this.controllerName}/taskitems/mark`, data);
  
  }

  public GetCompanyLearningModules(companyId?: number): Observable<CompanyLearningModule[]> {
    if (companyId) {
      return this.get<CompanyLearningModule[]>(`api/${this.controllerName}/${companyId}/learningmodules/`);
    }
    else {
      return this.get<CompanyLearningModule[]>(`api/${this.controllerName}/learningmodules/`);
    }

  }

  public GetCompanyAssignedLearningModules(companyId?: number): Observable<CompanyLearningModule[]> {
    if (companyId) {
      return this.get<CompanyLearningModule[]>(`api/${this.controllerName}/${companyId}/assignedlearningmodules/`);
    }
    else {
      return this.get<CompanyLearningModule[]>(`api/${this.controllerName}/assignedlearningmodules/`);
    }

  }

  public GetCompanyLearningModuleResults(learningModuleId: number, companyId?: number, page: number = 1, perPage: number = 10): Observable<LearningModuleResults> {
    if (companyId) {
      return this.get<LearningModuleResults>(`api/${this.controllerName}/${companyId}/learningmodules/${learningModuleId}/results/?page=${page}&perPage=${perPage}`);
    }
    else {
      return this.get<LearningModuleResults>(`api/${this.controllerName}/learningmodules/${learningModuleId}/results/?page=${page}&perPage=${perPage}`);
    }
  }


  public AssignCompanyLearningModule(module: CompanyLearningModule): Observable<{}> {
    return this.post<CompanyLearningModule, {}>(`api/${this.controllerName}/assignlearningmodule`, module);
  }

  public UnassignCompanyLearningModule(module: CompanyLearningModule): Observable<{}> {
    return this.post<CompanyLearningModule, {}>(`api/${this.controllerName}/unassignlearningmodule`, module);
  }

  public AssignUsersToLearningModule(data: AssignLearningModuleUser, companyId?: number): Observable<{}> {
    if (companyId) {
      return this.post<AssignLearningModuleUser, {}>(`api/${this.controllerName}/${companyId}/AssignUsersToLearningModule`, data);
    }
    else {
      return this.post<AssignLearningModuleUser, {}>(`api/${this.controllerName}/AssignUsersToLearningModule`, data);
    }
  }

  public GetCompanyTaskItems(companyId?: number, filter: number = 0, page: number = 1, perPage: number = 10, actId?: number, periodId: number = null): Observable<CompanyTaskItemResult> {

    let url = '';
    if (companyId) {
      url = `api/${this.controllerName}/${companyId}/taskitems/?filter=${filter}&page=${page}&perPage=${perPage}`;
    }
    else {
      url = `api/${this.controllerName}/taskitems/?filter=${filter}&page=${page}&perPage=${perPage}`;
    }

    if (actId) {
      url = url + `&actId=${actId}`
    }

    if (periodId) {
      url = url + `&periodId=${periodId}`
    }

    return this.get<CompanyTaskItemResult>(url);

  }

  public ExportCompanyTaskItems(companyId?: number, actId?: number, periodId: number = null, filter: number = 0, format?: 'xlsx' | 'pdf'): Observable<{}> {

    let url = `${this.env.getApiUrl()}/`;

    if (companyId) {
      url = url + `api/${this.controllerName}/${companyId}/taskitems/export?filter=${filter ?? 0}`;
    }
    else {
      url = url + `api/${this.controllerName}/taskitems/export?filter=${filter ?? 0}`;
    }

    if (actId) {
      url = url + `&actId=${actId}`
    }

    if (periodId) {
      url = url + `&periodId=${periodId}`
    }

    if (format) {
      url = url + `&format=${format}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });

  }

  public GetCompanyTaskItemSummary(companyId?: number, page: number = 1, perPage: number = 10, actId?: number, periodId: number = null): Observable<CompanyTaskItemSummaryResult> {

    let url = '';
    if (companyId) {
      url = `api/${this.controllerName}/${companyId}/taskitems/summary/?page=${page}&perPage=${perPage}`;
    }
    else {
      url = `api/${this.controllerName}/taskitems/summary/?page=${page}&perPage=${perPage}`;
    }

    if (actId) {
      url = url + `&actId=${actId}`
    }

    if (periodId) {
      url = url + `&periodId=${periodId}`
    }

    return this.get<CompanyTaskItemSummaryResult>(url);

  }

  public AssignCompanyTaskItemUser(data: AssignCompanyTaskItemUser, companyId?: number): Observable<{}> {
    if (companyId) {
      return this.post<AssignCompanyTaskItemUser, {}>(`api/${this.controllerName}/${companyId}/taskitems/assign`, data)
    }
    else {
      return this.post<AssignCompanyTaskItemUser, {}>(`api/${this.controllerName}/taskitems/assign`, data)
    }
  }

  public SubmitCompanyTaskItem(data: AnswerTaskItem): Observable<{}> {
    return this.post<AnswerTaskItem, {}>(`api/${this.controllerName}/taskitems/answer`, data);
  }

  public GetTaskItemDocument(id: number): Observable<{}> {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/taskitems/document/${id}`;
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }



  public GetCompanyChecklistItems(companyId?: number, completed: boolean = null, page: number = 1, perPage: number = 10, actId?: number, userId?: number, periodId: number = null): Observable<CompanyChecklistItemResult> {

    let url = '';

    if (companyId) {
      url = `api/${this.controllerName}/${companyId}/checklistitems/?page=${page}&perPage=${perPage}`;
    }
    else {
      url = `api/${this.controllerName}/checklistitems/?page=${page}&perPage=${perPage}`;
    }

    if (actId) {
      url = url + `&actId=${actId}`
    }

    if (userId) {
      url = url + `&userId=${userId}`
    }

    if (completed != null) {
      url = url + `&completed=${completed}`
    }

    if (periodId != null) {
      url = url + `&periodId=${periodId}`
    }

    return this.get<CompanyChecklistItemResult>(url);
  }

  public GetCompanyChecklistItemActs(companyId?: number, completed: boolean = null, page: number = 1, perPage: number = 10, periodId: number = null): Observable<CompanyChecklistItemActResult> {

    let url = '';

    if (companyId) {
      url = `api/${this.controllerName}/${companyId}/checklistitemacts/?page=${page}&perPage=${perPage}`;
    }
    else {
      url = `api/${this.controllerName}/checklistitemacts/?page=${page}&perPage=${perPage}`;
    }

    if (completed != null) {
      url = url + `&completed=${completed}`
    }

    if (periodId != null) {
      url = url + `&periodId=${periodId}`
    }

    return this.get<CompanyChecklistItemActResult>(url);
  }




  public AssignCompanyChecklistItemUser(data: AssignCompanyChecklistItemUser, companyId?: number): Observable<{}> {
    if (companyId) {
      return this.post<AssignCompanyChecklistItemUser, {}>(`api/${this.controllerName}/${companyId}/checklistitems/assign`, data)
    }
    else {
      return this.post<AssignCompanyChecklistItemUser, {}>(`api/${this.controllerName}/checklistitems/assign`, data)
    }
  }

  public SubmitCompanyChecklistItem(data: AnswerChecklistItem): Observable<{}> {
    return this.post<AnswerChecklistItem, {}>(`api/${this.controllerName}/checklistitems/answer`, data);
  }

  public GetChecklistItemDocument(id: number): Observable<{}> {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/checklistitems/document/${id}`;
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public DownloadCompanyChecklistItems(completed: boolean = null, companyId?: number, userId?: number, actId?: number, format?: 'xlsx'|'pdf'): Observable<{}> {

    let url = '';

    if (companyId) {
      url = `${this.env.getApiUrl()}/api/${this._controller}/${companyId}/checklistitems/download/?`;
    }
    else {
      url = `${this.env.getApiUrl()}/api/${this._controller}/checklistitems/download/?`;

    }

    if (completed != null) {
      url = `${url}completed=${completed}&`;
    }

    if (userId != null) {
      url = `${url}userId=${userId}&`;
    }

    if (actId != null) {
      url = `${url}actId=${actId}&`;
    }

    if (format != null) {
      url = `${url}format=${format}`;
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }


  public GetCompanyPermits(page: number = 1, perPage: number = 10, companyId?: number): Observable<ActListingResult> {

    if (companyId) {
      return this.get<ActListingResult>(`api/${this.controllerName}/${companyId}/permits/?page=${page}&perPage=${perPage}`);
    }
    else {
      return this.get<ActListingResult>(`api/${this.controllerName}/permits/?page=${page}&perPage=${perPage}`);
    }
  }

  public GetCompanyPolicies(page: number = 1, perPage: number = 10, companyId?: number): Observable<ActListingResult> {

    if (companyId) {
      return this.get<ActListingResult>(`api/${this.controllerName}/${companyId}/policies/?page=${page}&perPage=${perPage}`);
    }
    else {
      return this.get<ActListingResult>(`api/${this.controllerName}/policies/?page=${page}&perPage=${perPage}`);
    }
  }

  public GetCompanyReportingRequirements(page: number = 1, perPage: number = 10, companyId?: number): Observable<ReportingRequirementResult> {

    if (companyId) {
      return this.get<ReportingRequirementResult>(`api/${this.controllerName}/${companyId}/reportingrequirements/?page=${page}&perPage=${perPage}`);
    }
    else {
      return this.get<ReportingRequirementResult>(`api/${this.controllerName}/reportingrequirements/?page=${page}&perPage=${perPage}`);
    }
  }

  public GetCompanyActDepartments(companyId?: number): Observable<Array<Department>> {

    if (companyId) {
      return this.get<Array<Department>>(`api/${this.controllerName}/${companyId}/actdepartments/`);
    }
    else {
      return this.get<Array<Department>>(`api/${this.controllerName}/actdepartments/`);
    }
  }

  public GetCompanyActUsers(companyId?: number): Observable<Array<User>> {

    if (companyId) {
      return this.get<Array<User>>(`api/${this.controllerName}/${companyId}/actusers/`);
    }
    else {
      return this.get<Array<User>>(`api/${this.controllerName}/actusers/`);
    }
  }


  public ListCompanyChecklistActs(companyId?: number, page: number = 1, perPage: number = 20, search: string = null, periodId: number = null): Observable<ActResult> {
    let queryString = `page=${page}&perPage=${perPage}`;

    if (search) {
      queryString = `${queryString}&search=${search}`;
    }

    let url = '';

    if (companyId) {
      url = `api/${this.controllerName}/${companyId}/ListChecklistItemActs/`;
    }
    else {
      url = `api/${this.controllerName}/ListChecklistItemActs/`;
    }

    if (periodId) {
      queryString = queryString + `&periodId=${periodId}`
    }

    return this.get<ActResult>(`${url}?${queryString}`);
  }


  public GetCompanyChecklistUserResults(companyId?: number, actId?: number, periodId: number = null): Observable<CompanyChecklistUserResult[]> {
    let url = '';

    if (companyId) {
      url = `api/${this.controllerName}/${companyId}/GetCompanyChecklistUserResults/`;
    }
    else {
      url = `api/${this.controllerName}/GetCompanyChecklistUserResults/`;
    }

    if (actId) {
      url = `${url}?actId=${actId}`;
    }

    if (periodId) {
      url = url + `&periodId=${periodId}`
    }

    return this.get<CompanyChecklistUserResult[]>(url);
  }

  public GetAllRoles(): Observable<Array<string>> {

    return this.get<Array<string>>(`api/${this.controllerName}/allroles/`);
  }


  public ListCompanyTaskItemActs(companyId?: number, page: number = 1, perPage: number = 20, search: string = null): Observable<ActResult> {
    let queryString = `page=${page}&perPage=${perPage}`;

    if (search) {
      queryString = `${queryString}&search=${search}`;
    }

    let url = '';

    if (companyId) {
      url = `api/${this.controllerName}/${companyId}/ListTaskItemActs/`;
    }
    else {
      url = `api/${this.controllerName}/ListTaskItemActs/`;
    }

    return this.get<ActResult>(`${url}?${queryString}`);
  }


  public GetCompanyPeriods(companyId?: number): Observable<Array<Period>> {
    let url = '';

    if (companyId) {
      url = `api/${this.controllerName}/${companyId}/Periods/`;
    }
    else {
      url = `api/${this.controllerName}/Periods/`;
    }


    return this.get<Array<Period>>(url);
  }

  public AddCompanyPeriod(companyId: number, data: Period): Observable<{}> {
    return this.post<Period, {}>(`api/${this.controllerName}/${companyId}/AddPeriod`, data);
  }

  public UpdateCompanyPeriod(companyId: number, data: Period): Observable<{}> {
    return this.post<Period, {}>(`api/${this.controllerName}/${companyId}/UpdatePeriod`, data);
  }

  public ListCompanyActAllocations(companyId: number){
    return this.get<CompanyActAllocation[]>(`api/${this.controllerName}/${companyId}/ActAllocation`);
  }

  public AddCompanyActAllocation(companyId: number, actId: number){
    return this.post<{}, CompanyActAllocation[]>(`api/${this.controllerName}/${companyId}/AddActAllocation/${actId}`, {});
  }

  public RemoveCompanyActAllocation(companyId: number, actId: number){
    return this.post<{}, CompanyActAllocation[]>(`api/${this.controllerName}/${companyId}/RemoveActAllocation/${actId}`, {});
  }
}



