<div class="container">
  <h2 class="text-center">Document Admin</h2>

  <ng-container *ngIf="!this.edit && !this.add">
    <div class="d-flex  align-items-center justify-content-center mb-2">
      <div class="px-1"><span class="icon icon-search-red"></span></div>
      <div class="px-1">Search documents:</div>
      <div class="px-1"><input class="form-control" name="search" type="text" [(ngModel)]="searchTerm" /></div>
      <div class="px-1"><button class="btn btn-primary btn-small" type="button" [disabled]="searchTerm == ''" (click)="search()">SEARCH</button></div>
      <div class="px-1"><button class="btn btn-primary btn-small" type="button" (click)="addDocument()">ADD NEW</button></div>
    </div>
    <hr />
    <ng-container *ngIf="result && result.items">
      <app-document-list-item *ngFor="let doc of result.items" [document]="doc" [edit]="true" (onEdit)="editDocument(doc)"></app-document-list-item>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="this.edit || this.add">
    <div class="col-12 col-sm-10 col-md-8   offset-sm-1 offset-md-2  ">
      <form [formGroup]="mainForm" (ngSubmit)="submit()">
        <div class="form-group row">
          <label for="name" class="col-sm-4 col-form-label text-start text-sm-right">Title</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" id="title" formControlName="title" [class.is-invalid]="submitted && mainForm.controls.title.invalid">
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.title.errors">
              <div *ngIf="mainForm.controls.title.errors.required">Required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="description" class="col-sm-4 col-form-label text-start text-sm-right">Description</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" id="description" formControlName="description">
          </div>
        </div>
        <div class="form-group row">
          <label for="document" class="col-sm-4 col-form-label text-start text-sm-right">Document</label>
          <div class="col-sm-8">
            <div class="custom-file">
              <input type="file" class="form-control" formControlName="document" (change)="fileUpload($event)"  [class.is-invalid]="submitted && mainForm.controls.documentData.invalid">
              
              <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentData.errors">
                <div *ngIf="mainForm.controls.documentData.errors.required">Required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-end">
          <button type="button" class="btn btn-default me-2" (click)="cancel()">Cancel</button>
          <button type="submit" class="btn btn-default">Save</button>
        </div>
      </form>
      <hr />
    </div>
  </ng-container>
</div>