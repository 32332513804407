import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CompanyActAllocation } from 'src/app/models/company-act-allocation';
import { CompanyApiService } from 'src/app/services/company-api.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-company-acts',
  templateUrl: './company-acts.component.html',
  styleUrls: ['./company-acts.component.scss']
})
export class CompanyActsComponent implements OnInit {

  private companyId: number;
  companyActAllocations: CompanyActAllocation[];
  filteredCompanyActAllocations: CompanyActAllocation[];

  currentFilter: string;
  
  constructor(
    private companyApi: CompanyApiService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void {
    

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.companyId = +params.get('id');
      this.getData();
      
    });
  }

  getData(){
    this.spinner.startBackground();

    this.companyApi.ListCompanyActAllocations(this.companyId).subscribe(data => {
      this.companyActAllocations = data;
      this.filter();

      this.changeDetection.detectChanges();
      this.spinner.stopBackground();
    });
  }

  filter(){
    if (this.currentFilter && this.currentFilter.length > 0){
      this.filteredCompanyActAllocations = this.companyActAllocations.filter(f => f.name.toLocaleLowerCase().indexOf(this.currentFilter.toLocaleLowerCase()) > -1);
    }
    else{
      this.filteredCompanyActAllocations = this.companyActAllocations;
    }
  }

  back() {
    window.history.back();
  }

  add(item: CompanyActAllocation){
    if (!window.confirm(`Are you sure you want to add: ${item.name}?`)){
      return;
    }

    this.spinner.start();
    this.companyApi.AddCompanyActAllocation(this.companyId, item.id).subscribe((data) => {
      
      this.companyActAllocations = data;
      this.filter();
      
      this.spinner.stop();
      
    });
  }

  remove(item: CompanyActAllocation){
    if (!window.confirm(`Are you sure you want to remove: ${item.name}?\r\nThis will remove all associated task items and checklist items.\r\n\r\nACTION CANNOT BE UNDONE!`)){
      return;
    }

    this.spinner.start();
    this.companyApi.RemoveCompanyActAllocation(this.companyId, item.id).subscribe((data) => {

      this.companyActAllocations = data;
      this.filter();

      this.spinner.stop();
      
    });
  }

}
