import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ApiBaseService } from './api-base.service';
import { EnvironmentService } from './environment.service';

// @Injectable({
//   providedIn: 'root'
// })
export class ApiReadOnlyService<T> extends ApiBaseService {

  protected _controller: string;

  constructor(private controller: string, protected http: HttpClient, protected env: EnvironmentService) {
    super(http, env);
    this._controller = controller;
  }

  protected setController(controller) {
    this._controller = controller;
  }

  // protected get<O>(path: string): Observable<O> {
  //   return this.http.get<O>(`${this.env.getApiUrl()}/${path}`).pipe(
  //     map((r) => {
  //       return r;
  //       })
  //   );
  // }

  public List(): Observable<Array<T>> {
    return this.get<Array<T>>(`api/${this._controller}/`);
  }

  public GetById(id: number): Observable<T> {
    return this.get<T>(`api/${this._controller}/${id}`);
  }

  public GetBySlug(slug: string): Observable<T> {
    return this.get<T>(`api/${this._controller}/slug/${slug}`);
  }
}
