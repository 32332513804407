<div class="mb-1">
  <app-company-selector #companySelector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container pt-4" *ngIf="company">
  <h2 class="text-center">Library Admin</h2>

  <div class="row mb-2">
    <div class="col-6 text-center mx-auto">
      <select class="form-control" [(ngModel)]="currentListingTypeId" name="listingTypes"
        (change)="listingTypeChanged($event)">
        <option *ngFor="let listingType of listingTypes" [selected]="listingType.id == currentListingTypeId"
          [value]="listingType.id">
          {{listingType.description}}
        </option>
      </select>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-6 text-center mx-auto">
      <div class="mt-4 text-start overflow">
        <app-library-tree-list #libraryTreeList [parentId]="null" [typeId]="currentListingTypeId"
          [companyId]="currentCompanyId" (selected)="itemSelected($event)" (changed)="itemsChanged($event)">
        </app-library-tree-list>
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-6 text-center mx-auto">
      <div class="mt-4 text-center">
        <button class="btn btn-primary" (click)="submit()"
          [disabled]="!changedItems || changedItems.length == 0 || busy">SUBMIT</button>
      </div>
    </div>

  </div>





  <!-- <div class="row">
    <div class="col-6 text-center">
      <h4>Library</h4>
      <hr />
    </div>
    <div class="col-6 text-center">
      <h4>Companies</h4>
      <hr />
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-6 text-center">
      <select class="form-control" [(ngModel)]="currentListingTypeId" name="listingTypes" (change)="listingTypeChanged($event)">
        <option *ngFor="let listingType of listingTypes" [selected]="listingType.id == currentListingTypeId"
          [value]="listingType.id">
          {{listingType.description}}
        </option>
      </select>
    </div>
    <div class="col-6 text-center">
      <input type="text" class="form-control" [(ngModel)]="companyFilter" placeholder="Type to filter companies" (input)="filterCompanies($event)">
    </div>
  </div>
  <div class="row">
    <div class="col-6 text-center">
      <div class="text-start overflow">
        <app-library-tree-list #libraryTreeList [parentId]="null" [typeId]="currentListingTypeId" (selected)="itemSelected($event)" (checked)="itemsChecked($event)"></app-library-tree-list>
      </div>
    </div>
    <div class="col-6 text-center">
      <div class="text-start overflow">
        <div class="form-check" *ngFor="let company of filteredCompanies">
          <input class="form-check-input" type="checkbox" [value]="company.id" id="company_{{company.id}}"
            name="companyItems" [(ngModel)]="company.selected" (change)="companyChanged(item)" />
          <label class="form-check-label" for="company_{{company.id}}">
            {{company.name}}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4" *ngIf="selectedItem">
    <div class="col">
      <p><strong>{{selectedItem.title}}</strong> is available to the following companies:</p>
      <p *ngIf="selectedItem.sharedCompanies && selectedItem.sharedCompanies.length > 0"><span *ngFor="let c of selectedItem.sharedCompanies; let i = index"><ng-container *ngIf="i > 0">,&nbsp;</ng-container>{{c.name}}</span></p>
      <p *ngIf="!selectedItem.sharedCompanies || selectedItem.sharedCompanies.length == 0">NONE</p>
    </div>
  </div>
  
  <div class="row mt-4">
    <div class="col text-center d-flex justify-content-center">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" [(ngModel)]="removeChecked">
        <label class="form-check-label" for="mandatoryTraining" ngbTooltip="If this check box is selected, companies selected on the right will be removed from items selected on the left">
          Remove Companies From Items 
        </label>
      </div>
      <div class="form-check ms-4">
        <input class="form-check-input" type="checkbox" [(ngModel)]="keepOriginalChecked">
        <label class="form-check-label" for="directorLiability" ngbTooltip="If this option is checked, Companies assigned to the selected items (in the list on the right) will keep their existing assignments. If unchecked, previously assigned Companies will be removed if they are not selected from the Companies list on the right.">
          Keep Original Assignments
        </label>
      </div>
      <div class="form-check ms-4">
        <input class="form-check-input" type="checkbox" [(ngModel)]="childItemsChecked">
        <label class="form-check-label" for="popiaImplication" ngbTooltip="If checked, all child items of a selected items (from the list on the left) will recieve the same Company assignments as the selected items">
          Include Child Items
        </label>
      </div>
    </div>
  </div>
  <div class="row mt-4">
      <div class="col text-center">
        <button class="btn btn-primary" (click)="submit()" [disabled]="!checkedItems || checkedItems.length == 0 || busy">SUBMIT</button>
      </div>
    </div> -->
</div>