import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiReadOnlyService } from './api-read-only.service';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { NotificationMessage } from '../models/notificationMessage';

@Injectable({
  providedIn: 'root'
})
export class NotificationsApiService extends ApiReadOnlyService<NotificationMessage> {

  _controller: string = 'notifications';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('notifications', http, env);
  }


  Delete(id: number): Observable<{}> {
    return this.post<{}, {}>(`api/${this._controller}/${id}/delete`, {});
  }

  MarkAsRead(id: number): Observable<{}> {
    return this.post<{}, {}>(`api/${this._controller}/${id}/markasread`, {});
  }

  MarkMultipleAsRead(ids: Array<number>): Observable<{}> {
    return this.post<Array<number>, {}>(`api/${this._controller}/markmultipleasread`, ids);
  }
}
