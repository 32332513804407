<tr>
  <td style="width: 40px;"  class="text-center">
    <span *ngIf="!_message.parentId" class="icon icon-email-grey"></span>
    <span *ngIf="_message.parentId" class="icon icon-email-red"></span>
  </td>
  <td style="width: 140px;" class="text-center">{{_message.createdAt | date:'yyyy-MM-dd HH:mm:ss' }}</td>
  <td>
    {{_message.message}}
  </td>
  <td class="text-center">
      {{_message.name}} {{_message.surname}}<br/>
      {{_message.emailAddress}}
  </td>
</tr>