<div class="container py-4">
  <div class="pb-4">
      <button class="btn btn-default" (click)="back()">&lt; Back</button>
  </div>
  <table class="messages-table w-100">
    <tr>
      <th>&nbsp;</th>
      <th  class="text-center">Date</th>
      <th  class="text-center">Message</th>
      <th  class="text-center">User</th>
    </tr>
    <ng-container *ngIf="contractMessages" >
      <ng-container *ngFor="let message of filterMessages(0)">
        
        <tr>
          <td style="width: 40px;"  class="text-center"><span class="icon icon-email-grey"></span></td>
          <td style="width: 140px;" class="text-center">{{message.createdAt | date:'yyyy-MM-dd HH:mm:ss' }}</td>
          <td>
            {{message.message}}
          </td>
          <td class="text-center">
              {{message.name}} {{message.surname}}<br/>
              {{message.emailAddress}}
          </td>
        </tr>
        <tr *ngFor="let childmessage of filterMessages(message.id)">
          <td style="width: 40px;"  class="text-center"><span class="icon icon-left-arrow-grey"></span></td>
          <td style="width: 140px;" class="text-center">{{childmessage.createdAt | date:'yyyy-MM-dd HH:mm:ss' }}</td>
          <td>
            {{childmessage.message}}
          </td>
          <td class="text-center">
              {{childmessage.name}} {{childmessage.surname}}<br/>
              {{childmessage.emailAddress}}
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </table>
</div>