import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import $ from 'jquery';
import { OrganisationApiService } from '../../../../../services/organisation-api.service';
import { SpinnerService } from '../../../../../services/spinner.service';
import { Router } from '@angular/router';
import { EventsService } from '../../../../../services/events.service';
import { FileValidator } from '../../../../../validators/file-input-validator';
import { Organisation } from 'src/app/models/organisation';

@Component({
  selector: 'app-add-organisation',
  templateUrl: './add-organisation.component.html',
  styleUrls: ['./add-organisation.component.scss']
})
export class AddOrganisationComponent implements OnInit {

  mainForm = this.formBuilder.group({
    name: ['', Validators.required],
    logo: ['', FileValidator.validate],
    logoData: ['', Validators.required],
    companies: ['', [Validators.required, Validators.min(1), Validators.max(99)]]
  });

  submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private spinner: SpinnerService,
    private router: Router,
    private organisationApi: OrganisationApiService,
    private events: EventsService) {

    

  }

  ngOnInit() {

    // $(document).ready(function () {
    //   $('.custom-file-input').on('change', function () {
    //     let fileName = $(this).val().split('\\').pop();
    //     $(this).next('.custom-file-label').addClass("selected").html(fileName);
    //   });
    // });
    

  }

  fileUpload(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) //2MB
      {
        this.mainForm.controls.logo.setValue('');
        this.mainForm.controls.logo.setErrors({ 'required': true });
        return;
      }
      
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.mainForm.patchValue({
          logoData: reader.result
        });

        $('.custom-file-label').addClass("selected").html(file.name);

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  wizard: boolean = false;
  setWizard(wzrd: boolean){
    this.wizard = wzrd;
  }

  submit() {

    this.submitted = true;

    if (this.mainForm.invalid){
      return;
    }
    
    this.spinner.start();

    let org: Organisation = {
      active: true,
      logoUrl: this.mainForm.controls.logoData.value,
      name: this.mainForm.controls.name.value
    };

    this.organisationApi.Add(org).subscribe((r) => {
      this.events.fireEvent('configuration-submenu-update');
      this.events.fireEvent('update-org-list');

      if (this.wizard){
        this.router.navigateByUrl(`/dashboard/configuration/company/add/${r.id}`);
      }
      else{
        this.router.navigateByUrl('/dashboard/configuration/organisation/list');
      }
      
    });
  }
}
