import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LookupApiService } from '../../../../../services/lookup-api.service';
import { CompanyApiService } from '../../../../../services/company-api.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { SpinnerService } from '../../../../../services/spinner.service';
import { Router, ActivatedRoute, ParamMap, Data } from '@angular/router';
import { EventsService } from '../../../../../services/events.service';
import $ from 'jquery';
import { FileValidator } from '../../../../../validators/file-input-validator';
import { Company } from 'src/app/models/company';
import { Country } from 'src/app/models/country';
import { EntityType } from 'src/app/models/entityType';
import { Industry } from 'src/app/models/industry';


@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {

  countries: Array<Country>;
  industries: Array<Industry>;
  entityTypes: Array<EntityType>;
  ready: boolean = false;
  organisationId: number;
  parentCompanyId: number;

  companyTypeId = 1;
  companyLabel = 'Company'

  submitted = false;
  //tradingRegionsList: Array<any> = [];
  //industriesList: Array<any> = [];

  mainForm = this.formBuilder.group({
    name: ['', Validators.required],
    logo: ['', FileValidator.validate],
    logoData: ['', Validators.required],
    registrationNumber: ['', Validators.required],
    vatNumber: ['', Validators.required],
    employees: ['', [Validators.required, Validators.min(1)]],
    entityType: ['', Validators.required],
    tradingRegions: ['', Validators.required],
    industries: [''],
    address: ['', Validators.required],
    contactNumber: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]

  });

  constructor(
    private lookupService: LookupApiService,
    private companyService: CompanyApiService,
    private formBuilder: UntypedFormBuilder,
    private spinner: SpinnerService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private events: EventsService
  ) { }

  ngOnInit() {


    this.spinner.startBackground();
    this.route.data.subscribe((data: Data) => {
      switch (data.level) {
        case 1:
          this.companyTypeId = 2;
          this.companyLabel = 'Sub Company'
          break;
        case 2:
          this.companyTypeId = 3;
          this.companyLabel = 'Business Unit'
          break;
        case 3:
          this.companyTypeId = 4;
          this.companyLabel = 'Area'
          break;
        case 4:
          this.companyTypeId = 5;
          this.companyLabel = 'Legal Category'
          break;
        default:
          this.companyTypeId = 1;
          this.companyLabel = 'Company'
          break;
      }
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.organisationId = +params.get('organisationid');
      this.parentCompanyId = +params.get('companyid');


    });



    forkJoin([
      this.lookupService.ListIndustries(),
      this.lookupService.ListCountries(),
      this.lookupService.ListEntityTypes()
    ]).subscribe((r) => {
      this.industries = r[0];
      this.countries = r[1];
      this.entityTypes = r[2];

      // this.industries.forEach(f => {
      //   this.industriesList.push({id: f.id, text: f.name });
      // });

      // this.countries.forEach(f => {
      //   this.tradingRegionsList.push({id: f.id, text: f.name });
      // });

      this.ready = true;
      this.spinner.stopBackground();
    });
  }

  fileUpload(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) //2MB
      {
        this.mainForm.controls.logo.setValue('');
        this.mainForm.controls.logo.setErrors({ 'required': true });
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.mainForm.patchValue({
          logoData: reader.result
        });

        $('.custom-file-label').addClass("selected").html(file.name);

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  wizard: boolean = false;
  setWizard(wzrd: boolean) {
    this.wizard = wzrd;
  }
  submit() {
    this.submitted = true;

    if (this.mainForm.invalid) {
      return;
    }

    this.spinner.start();

    let c = this.countries.filter(c => this.mainForm.controls.tradingRegions.value.indexOf(c.id) >= 0);
    let i = this.industries.filter(c => this.mainForm.controls.industries.value.indexOf(c.id) >= 0);

    let company: Company = {
      active: true,
      countries: c,
      industries: i,
      logoUrl: this.mainForm.controls.logoData.value,
      name: this.mainForm.controls.name.value,
      numberOfEmployees: this.mainForm.controls.employees.value,
      entityType: { id: this.mainForm.controls.entityType.value },
      organisation: { id: this.organisationId },
      primaryAddress: this.mainForm.controls.address.value,
      primaryContactNumber: this.mainForm.controls.contactNumber.value,
      registrationNumber: this.mainForm.controls.registrationNumber.value,
      vatNumber: this.mainForm.controls.vatNumber.value,
      parentCompanyId: this.parentCompanyId,
      companyTypeId: this.parentCompanyId ? 2 : 1,
    };

    this.companyService.Add(company).subscribe((r) => {
      this.events.fireEvent('configuration-submenu-update');
      this.events.fireEvent('update-company-list');

      if (this.wizard && !this.parentCompanyId) {
        this.router.navigateByUrl(`/dashboard/configuration/questionnaire/${r.id}`);
      }
      else {
        this.router.navigateByUrl(`/dashboard/configuration/company/list/${this.organisationId}`);
      }

    });
  }
}
