import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormSubmitService } from './form-submit.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(private loader: NgxUiLoaderService, private formSubmitService: FormSubmitService) {
  }

  start() {
    
    this.loader.start();
    this.formSubmitService.disableForm();
  }

  startBackground() {
    this.loader.startBackground();
    this.formSubmitService.disableForm();
  }

  stop() {
    this.loader.stop();
    this.formSubmitService.enableForm();
  }

  stopBackground() {
    this.loader.stopBackground();
    this.formSubmitService.enableForm();
  }

  stopAll() {
    this.loader.stopAll();
    this.formSubmitService.enableForm();
  }
}
