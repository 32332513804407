<div *ngIf="status" [class.alt-colors]="orgId == 89">
    <div class="row">
      
      <div class="col-12">
        <div class="detail-block p-4 m-4">
          <div class="logo text-center">
            <img [src]="status.logoUrl" class="img-fluid company-logo" />
          </div>
          <hr />
          <div class="text-center d-flex align-items-center justify-content-center">
            <span class="me-3">Period:</span> 
            <ng-select [items]="periods" bindLabel="name" [multiple]="false" [loading]="periodsLoading"
            (change)="periodSelected($event)" [(ngModel)]="selectedPeriod" [clearable]="false">
          </ng-select>
          </div>
          <div class="detail-block-items px-4 mx-4">
            <div class="detail-block-item d-flex align-items-center my-4">
              <div class="text-uppercase text-danger"><strong>APPLICABLE ACTS</strong></div>
              <div class="text-end flex-grow-1"><span
                  class="badge rounded-pill text-bg-light">{{status.umbrellaReport.applicableActs}}</span></div>
              
            </div>
            <div class="detail-block-item d-flex align-items-center my-4">
              <div class="text-uppercase text-danger"><strong>RAW RISK</strong></div>
              <div class="text-end flex-grow-1"><span
                  class="badge rounded-pill {{status.umbrellaReport.overallRisk.toLowerCase()}} text-uppercase">{{status.umbrellaReport.overallRisk}}</span>
              </div>
              
            </div>
            <div class="detail-block-item d-flex align-items-center my-4">
              <div class="text-uppercase text-danger"><strong>RESIDUAL RISK</strong></div>
              <div class="text-end flex-grow-1"><span
                  class="badge rounded-pill {{status.umbrellaReport.residualRisk.toLowerCase()}} text-uppercase">{{status.umbrellaReport.residualRisk}}</span>
              </div>
              
            </div>
            <div class="detail-block-item d-flex align-items-center my-4">
              <div class="text-uppercase text-danger"><strong>IMPACTED DEPARTMENTS</strong></div>
              <div class="text-end flex-grow-1"><span
                  class="badge rounded-pill text-bg-light">{{status.umbrellaReport.impactedDepartments}}</span></div>
              
            </div>
            <div class="detail-block-item d-flex align-items-center my-4">
              <div class="text-uppercase text-danger"><strong>IMPACTED ROLES</strong></div>
              <div class="text-end flex-grow-1"><span
                  class="badge rounded-pill text-bg-light">{{status.umbrellaReport.applicableRoles}}</span></div>
              
            </div>
            <div class="detail-block-item d-flex align-items-center my-4">
              <div class="text-uppercase text-danger"><strong>REQUIRED PERMITS</strong></div>
              <div class="text-end flex-grow-1"><span
                  class="badge rounded-pill text-bg-light">{{status.umbrellaReport.requiredPermits}}</span></div>
              
            </div>
            <div class="detail-block-item d-flex align-items-center my-4">
              <div class="text-uppercase text-danger"><strong>REQUIRED POLICIES</strong></div>
              <div class="text-end flex-grow-1"><span
                  class="badge rounded-pill text-bg-light">{{status.umbrellaReport.requiredPolicies}}</span></div>
              
            </div>
            <div class="detail-block-item d-flex align-items-center my-4">
              <div class="text-uppercase text-danger"><strong>REQUIRED REPORTS</strong></div>
              <div class="text-end flex-grow-1"><span
                  class="badge rounded-pill text-bg-light">{{status.umbrellaReport.requiredReports}}</span></div>
              
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <app-puzzle-wheel [percentage]="this.status.umbrellaReport.percentage" [section]="'umbrella_report'">
        </app-puzzle-wheel>
      </div>

      <div class="col-12" *ngIf="report">
        
        <table class="table table-bordered bg-white table-sm reports-table table-hover">
          <tr>
            <th>APPLICABLE<br />LAW</th>
            <th>RISK</th>
            <th>COMPLIANCE<br />STATUS</th>
          </tr>
          <tr *ngFor="let item of report.items">
            <td style="width: 70% !important">{{item.act.name}}</td>
            <td style="width: 20% !important">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <span class=" badge w-100 p-2"
                    [ngClass]="displayImpactRating(item.impactRating).toLowerCase()">{{displayImpactRating(item.impactRating)}}</span>
                </div>
              </div>
            </td>
            <td style="width: 10% !important">{{item.complianceStatus}}%</td>
    
        </table>

      </div>

    </div>
  </div>