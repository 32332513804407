import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { StatusApiService } from '../../../services/status-api.service';
import { CompanyApiService } from '../../../services/company-api.service';
import { SpinnerService } from '../../../services/spinner.service';
import { RequestCacheService } from '../../../services/request-cache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenService } from '../../../services/token.service';
import { ReportsApiService } from '../../../services/reports-api.service';
import { LookupApiService } from '../../../services/lookup-api.service';
import { ActReportItem } from 'src/app/models/actReportItem';
import { UpdateCompanyActNotes } from 'src/app/models/updateCompanyActNotes';
import { ImpactRating } from 'src/app/models/impactRating';
import { ActReportItemListResult } from 'src/app/models/actReportItemListResult';
import { CompanyTaskItemResult } from 'src/app/models/companyTaskItemResult';
import { CompanyTaskItem } from 'src/app/models/companyTaskItem';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  companyId: number;
  apiCompanyId?: number;
  superAdmin: boolean = false;
  orgAdmin: boolean = false;
  companyAdmin: boolean = false;
  user: boolean = false;
  userId: number;
  reportData: ActReportItemListResult | CompanyTaskItemResult = null;

  reportType: string = 'full';

  currentPage: number = 1;

  impactRatings: Array<ImpactRating>;

  selectedImpactRating: number;
  sortBy: string = "Name";
  orderDecending: boolean = false;

  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;

  perPageItems = [5, 10, 20, 50, 100];
  perPage = 10;

  orgId: number;

  currentNotes: string;

  constructor(private route: ActivatedRoute,
    private reportsApi: ReportsApiService,
    private lookupApi: LookupApiService,
    private companyApi: CompanyApiService,
    private spinner: SpinnerService,
    private cache: RequestCacheService,
    public token: TokenService,
    private modalService: NgbModal,
    private router: Router) { }

  ngOnInit() {

    if (localStorage["PER_PAGE"]) {
      this.perPage = localStorage["PER_PAGE"];
    }

    if (this.superAdmin) {
      this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.orgId = this.token.organisationId();
    }

    // this.lookupApi.ListImpactRatings(this.orgId).subscribe((data) => {
    //   this.impactRatings = data;
    // });

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.getData();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.getData();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }

  }

  companySelected(company) {

    if (company) {

      if (this.superAdmin) {
        this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
      }
      else {
        this.orgId = this.token.organisationId();
      }

      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.getData();
    }
    else {
      this.reportData = null;
    }
  }

  impactRatingChange(ev: Event) {
    this.currentPage = 1;
    this.getData();
  }

  complianceStatusChange() {
    this.currentPage = 1;
    this.getData();
  }

  orderChange(ev: Event) {
    this.currentPage = 1;
    this.getData();
  }

  sortChange(ev: Event) {
    this.currentPage = 1;
    this.getData();
  }

  reportTypeChange(ev: Event) {
    this.currentPage = 1;
    this.getData();
  }

  perPageChange(event) {
    localStorage["PER_PAGE"] = this.perPage;
    this.currentPage = 1;
    this.getData();
  }


  getData() {

    this.lookupApi.ListImpactRatings(this.orgId).subscribe((data) => {
      this.impactRatings = data;
    });

    this.reportData = null;
    this.spinner.startBackground();

    if (this.reportType == 'corrective-action') {
      this.companyApi.GetCompanyTaskItems(this.apiCompanyId, 6, this.currentPage, this.perPage, null, null).subscribe(data => {

        this.reportData = data;

        this.firstEnabled = this.reportData.currentPage > 1;
        this.prevEnabled = this.reportData.currentPage > 1;

        this.nextEnabled = this.reportData.currentPage < data.totalPages;
        this.lastEnabled = this.reportData.currentPage < data.totalPages;

        this.spinner.stopBackground();

      });
    }
    else {
      this.reportsApi.ActReport(this.currentPage, this.perPage, this.apiCompanyId, this.selectedImpactRating, this.sortBy, this.orderDecending).subscribe(data => {
        this.reportData = data;

        this.firstEnabled = this.reportData.currentPage > 1;
        this.prevEnabled = this.reportData.currentPage > 1;

        this.nextEnabled = this.reportData.currentPage < data.totalPages;
        this.lastEnabled = this.reportData.currentPage < data.totalPages;

        this.spinner.stopBackground();

      });

    }

  }

  export(ev: Event, format?: 'xlsx' | 'pdf') {

    this.spinner.startBackground();

    if (this.reportType == 'corrective-action') {
      this.companyApi.ExportCompanyTaskItems(this.apiCompanyId, null, null, 6, format).subscribe(data => {
        var url = URL.createObjectURL(data as Blob);
        window.open(url);
        this.spinner.stopBackground();

      });
    }
    else {
      this.reportsApi.ExportActReport(this.apiCompanyId, this.selectedImpactRating, this.sortBy, this.orderDecending, this.reportType, format).subscribe(data => {
        var url = URL.createObjectURL(data as Blob);
        window.open(url);
        this.spinner.stopBackground();

      });
    }

  }

  first() {
    this.currentPage = 1;
    this.getData();
  }

  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.getData();
  }

  next() {

    if (this.currentPage < this.reportData.totalPages) {
      this.currentPage++;
    }
    this.getData();
  }

  last() {

    this.currentPage = this.reportData.totalPages;
    this.getData();
  }

  truncate(str?: string) {
    if (!str) {
      return str;
    }
    if (str && str.length > 40)
      return str.substring(0, 40) + '...';
    else
      return str;
  };

  displayImpactRating(impactRating: ImpactRating, likelihood?: number, impact?: number) {
    if (impactRating && impactRating.name) {
      return this.lookupApi.GetImpactRating(this.orgId, impactRating.name, likelihood, impact);
      //return impactRating.name;
    }
    else {
      return ' ';
    }
  }


  openNotesModal(content, item: ActReportItem) {
    this.currentNotes = item.notes;
    this.modalService.open(content, { size: 'sm' }).result.then((result) => {
      let newNotes = this.currentNotes;
      let data: UpdateCompanyActNotes = {
        actId: item.actId,
        companyId: this.apiCompanyId,
        notes: newNotes
      };

      this.spinner.startBackground();

      this.companyApi.UpdateNotes(data).subscribe(() => {
        this.cache.clear();
        this.getData();
      });
    }, (reason) => {
    });
  }

  isOverDue(item: CompanyTaskItem) {
    if (!item || !item.dueDate) {
      return false;
    }

    if (item.compliant === true) {
      return false;
    }
    return new Date(item.dueDate).getTime() <= (new Date()).getTime();
  }

  getUserString(user: User[]) {
    return user.map(m => `${m.firstname} ${m.lastname}`).join(', ');
  }

}
