<div class="mb-1">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<ng-container *ngIf="company">

    <div class="company-info mb-1 p-2 ">
        <div class="container d-flex  align-items-center">
            <div class="company-logo flex-grow-1">
                <img [src]="company.logoUrl">
            </div>
            <div class="d-flex  align-items-center">
                <div class="px-1"><span class="icon icon-search-red"></span></div>
                <div class="px-1">Search users:</div>
                <div class="px-1"><input class="form-control" name="search" type="text" [(ngModel)]="searchTerm" /></div>
                <div class="px-1"><button class="btn btn-primary btn-small" type="button" (click)="search()">SEARCH</button></div>
                <div class="px-1"><button class="btn btn-primary btn-small" type="button" (click)="add()">ADD USER</button></div>

            </div>
        </div>
    </div>
    <ng-container *ngIf="finalUsers">
        <div class="container">
            <div *ngIf="!finalUsers || finalUsers.length == 0" class="text-center">No users</div>
            <div *ngIf="addUser" class="py-2 d-flex  align-items-center">
                    <span class="icon icon-user-red user-icon pe-1"></span>
                    <div class="py-2 d-flex flex-grow-1">
                        <input type="text" class="form-control mx-2 pe-1  flex-fill w-25" placeholder="Name" [(ngModel)]="newUser.firstname" />
                        <input type="text" class="form-control mx-2 pe-1  flex-fill w-25" placeholder="Surname" [(ngModel)]="newUser.lastname" />
                        <input type="email" class="form-control mx-2 pe-1  flex-fill w-25" placeholder="Email" [(ngModel)]="newUser.email" />
                        <ng-select [items]="roles" [multiple]="true" [(ngModel)]="newUser.roles" class="  mx-2 pe-1 flex-fill w-25"
                            [closeOnSelect]="false" [searchable]="true" 
                            placeholder="No Roles selected">
                        </ng-select>
                    </div>
                    <button style="margin-right: 2.65rem;" class="btn btn-link pe-1" (click)="updateUser(newUser)"><span class="icon icon-tick-red" title="Add"></span></button>
                </div>
            <div *ngFor="let user of finalUsers" class="py-2 d-flex  align-items-center">
                <span class="icon icon-user-red user-icon pe-1"></span>
                <div class="py-2 d-flex flex-grow-1">
                    <input type="text" class="form-control mx-2 pe-1  flex-fill w-25" placeholder="Name" [(ngModel)]="user.firstname" />
                    <input type="text" class="form-control mx-2 pe-1  flex-fill w-25" placeholder="Surname" [(ngModel)]="user.lastname" />
                    <input type="email" class="form-control mx-2 pe-1  flex-fill w-25" placeholder="Email" readonly [(ngModel)]="user.email" />
                    <ng-select [items]="roles" [multiple]="true" [(ngModel)]="user.roles" class="  mx-2 pe-1 flex-fill w-25"
                        [closeOnSelect]="false" [searchable]="true" 
                        placeholder="No Roles selected">
                    </ng-select>
                </div>
                <!-- <div class="form-check d-flex align-items-center">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="user.companyAdmin">
                    <label class="form-check-label">
                        Company Admin
                    </label>
                </div> -->
                <button class="btn btn-link pe-1" (click)="update(user)"><span class="icon icon-tick-red" title="Update"></span></button>
                <button *ngIf="user.active" class="btn btn-link pe-1" (click)="archive(user)"><span class="icon icon-hide-red"
                        title="Archive"></span></button>
                <button *ngIf="!user.active" class="btn btn-link pe-1" (click)="activate(user)"><span class="icon icon-hide-grey"
                        title="Activate"></span></button>
            </div>
        </div>
    </ng-container>
    
</ng-container>