import { Component, OnInit } from '@angular/core';
import { OrganisationApiService } from '../../../../../services/organisation-api.service';
import { SpinnerService } from '../../../../../services/spinner.service';
import { Router } from '@angular/router';
import { StatusApiService } from '../../../../../services/status-api.service';
import { EventsService } from '../../../../../services/events.service';
import { OrganisationStatus } from 'src/app/models/status';

@Component({
  selector: 'app-list-organisation',
  templateUrl: './list-organisation.component.html',
  styleUrls: ['./list-organisation.component.scss']
})
export class ListOrganisationComponent implements OnInit {

  public organisations: Array<OrganisationStatus>;

  constructor(
    private statusApi: StatusApiService,
    private router: Router,
    private spinner: SpinnerService,
    private events: EventsService) {

      this.events.genericEvent$.subscribe(
        command => {
          if (command == 'update-org-list'){
            this.getData();
          }
        });
  }

  ngOnInit() {
    this.getData();
  }

  getData(){
    this.spinner.startBackground();
      this.statusApi.getStatus().subscribe((r) => {
        this.organisations = r.configuration.organisations;

        if (!this.organisations || this.organisations.length == 0){
          this.router.navigateByUrl('/dashboard/configuration/organisation/add');
        }
        
        this.spinner.stopBackground();
      });
  }

}
