import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-documents-home',
  templateUrl: './documents-home.component.html',
  styleUrl: './documents-home.component.scss'
})
export class DocumentsHomeComponent {
  companyId: number;
  orgId: number;
  apiCompanyId?: number;

  userId: number;
  user: boolean = false;
  contractAdmin: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  constructor(private token: TokenService,  private router: Router, private ngZone: NgZone, private route: ActivatedRoute) {
    
  }

  ngOnInit(){
    
    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user || this.contractAdmin) {
      this.companyId = this.token.companyId();
      this.apiCompanyId = this.companyId;
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.init();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }
  }

  init() {

    if (this.superAdmin) {
      this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.orgId = this.token.organisationId();
    }
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    else {
      
    }
  }
}
