import { Component, OnInit } from '@angular/core';
import { CompanyApiService } from '../../../../services/company-api.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { ActivatedRoute, ParamMap, Router, RoutesRecognized } from '@angular/router';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { CompanyQuestion } from 'src/app/models/companyQuestion';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  private companyId: number;
  public currentQuestion: number = 0;
  public questions: Array<CompanyQuestion>; 

  constructor(
    private companyApi: CompanyApiService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private cache: RequestCacheService
    ) { 
      
    this.currentQuestion = 0;
      
  }

  ngOnInit() {

    this.spinner.startBackground();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.companyId = +params.get('id');

      this.companyApi.GetCompanyQuestions(this.companyId).subscribe((r) => {
        this.questions = r;
        this.spinner.stopBackground();
      });

    });

  }

  prev(){
    this.currentQuestion--;
  }

  next(){
    this.currentQuestion++;
  }

  submit(){

    this.spinner.start();

    this.companyApi.SubmitCompanyQuestions(this.companyId, this.questions).subscribe((r) => {
      this.cache.clear();
      
      let orgId = +localStorage.getItem('CURRENT_ORG_ID');

      if (orgId && orgId > 0){
        this.router.navigateByUrl(`/dashboard/configuration/company/list/${orgId}`);
      }
      else{
        this.router.navigateByUrl(`/dashboard/configuration/organisation/list`);
      }
      //this.router.navigateByUrl(`/dashboard/configuration/organisation/list`);
    });
  }

}
