<div class="org-list-item d-flex flex-row py-1">
  <div class="d-flex  justify-content-around align-items-center px-2">
    <div>
      <a [routerLink]="['/dashboard/configuration/organisation/edit', _organisation.id]" title="Edit">
        <span class="icon icon-small icon-edit-red"></span>
      </a><br />
      <a (click)="archive(_organisation)" title="Archive">
        <span class="icon icon-small icon-hide-red"></span>
      </a>
    </div>
  </div>

  <div class="org-logo text-center">
    <img [src]="_organisation.logoUrl" [alt]="_organisation.name" [title]="_organisation.name" />
  </div>

  <div class="d-flex align-items-center px-2">
    <a [routerLink]="['/dashboard/configuration/company/list', _organisation.id]" title="Companies">
      <h3 class="mb-0">{{_organisation.name}}</h3>
    </a>
  </div>
</div>