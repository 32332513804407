<div class="mb-1" *ngIf="superAdmin || orgAdmin">
  <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4">
  <div class="row" *ngIf="this.status">
    <div class="col-6 px-4">
      <app-puzzle-wheel [percentage]="this.status.compliance.percentage" [section]="'compliance_program'">
      </app-puzzle-wheel>
    </div>
    <div class="col-6">
      <div class="detail-block p-4 m-4">
        <div class="logo text-center">
          <img [src]="this.status.logoUrl" class="img-fluid company-logo" [alt]="this.status.name" />
        </div>
        <hr />
        <div class="text-center d-flex align-items-center justify-content-center">
          <span class="me-3">Period:</span>
          <ng-select [items]="periods" bindLabel="name" [multiple]="false" [loading]="periodsLoading"
            (change)="periodSelected($event)" [(ngModel)]="selectedPeriod" [clearable]="false">
          </ng-select>
        </div>
        <app-compliance-status (onFilter)="getCompanyAudits($event)" (onExport)="export()" [status]="status" [act]="currentAct" (onUpdate)="statusUpdated()">
        </app-compliance-status>

      </div>
    </div>
  </div>
  <div class="row mt-4" class="row" *ngIf="(result && result.items) || (summaryResult && summaryResult.items)">
    <div class="col">
      <ng-select [items]="acts" bindLabel="name" bindValue="id" [multiple]="false" [loading]="actsLoading"
        placeholder="Filter by Act" (change)="resultsActSelected($event)" [(ngModel)]="selectedAct" [clearable]="true">
      </ng-select>
      <!-- <ng-select [items]="acts$ | async" bindLabel="name" [multiple]="false" [hideSelected]="true"
        [loading]="actsLoading" [typeahead]="actsInput$" placeholder="Filter by Act" (change)="actSelected($event)">
      </ng-select> -->
    </div>
  </div>
</div>
<div class="container-fluid py-4">
  <div class="row mt-2" *ngIf="summary && summaryResult && summaryResult.items">

    <div class="col">
      <table class="table table-bordered table-hover bg-white table-sm company-acts-table">
        <tr>
          <th>ACT</th>
          <th>NUMBER OF TASKS</th>
          <th>ALLOCATED</th>
          <th>COMPLETED</th>
          <th>COMPLIANT</th>
          <th>COMPLIANCE</th>
        </tr>
        <tr *ngFor="let item of summaryResult.items">
          <td><a href="#" (click)="summaryItemSelected($event, item)">{{item.act.name}}</a></td>
          <td class="text-center">{{item.tasks}}</td>
          <td class="text-center">{{item.allocated}}</td>
          <td class="text-center">{{item.completed}}</td>
          <td class="text-center">{{item.compliant}}</td>
          <td class="text-center">{{item.compliance | percent }}</td>
        </tr>
      </table>

      <div class="pager-bar py-4 mb-1">
        <div class="container text-center d-flex flex-row align-items-center justify-content-center"
          *ngIf="summaryResult.items.length > 0">
          <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
          <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
          <span class="px-4">Page {{summaryResult.currentPage}} of {{summaryResult.totalPages}}</span>
          <div class="px-4 d-flex flex-row align-items-center">
            <div class="pe-2">Items per page:
            </div>
            <div>
              <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
            </div>
          </div>
          <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
          <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
        </div>
      </div>

    </div>
  </div>

  <div class="row mt-2" *ngIf="!summary && result && result.items">

    <div class="col">
      <div class="text-end mb-2">
        <button (click)="backToSummary()" class="btn btn-default">&lt; Back</button> &nbsp;
        <button (click)="export('pdf')" class="btn btn-default">Export (PDF)</button> &nbsp;
         <button (click)="export('xlsx')" class="btn btn-default">Export (XLSX)</button>
      </div>
      <div class="table-responsive">
      <table class="table table-bordered bg-white table-sm company-acts-table">
        <tr>
          <th>#</th>
          <th>ACT</th>
          <th>COMPLIANCE ITEM</th>
          <th>DESCRIPTION</th>
          <th *ngIf="!user">ASSIGNED TO</th>
          <th style="width: 80px">DUE DATE</th>
          <th>COMPLIANT</th>
          
          <th>CONTROL<br/>TYPE</th>
          <th>CONTROL<br/>FREQUENCY</th>
          <th>CONTROL<br/>AUTOMATION </th>
          <th>CONTROL<br/>CATEGORY</th>
          <th>KEY<br/>CONTROL</th>
          
          <th>COMMENTS</th>
          <th>DOCUMENTS</th>
          
          <th>MARKER<br/>NAME</th>
          <th>MARKER<br/>MITIGATES</th>
          <th>MARKER<br/>DESIGN</th>
          <th>MARKER<br/>TIMELY</th>
          <th>MARKER<br/>EVIDENCED</th>
          
          <th>EFFICIENCY RATE</th>
          <th>MARKER COMMENTS</th>
          <th>CORRECTIVE ACTION PLAN</th>
          <th>CORRECTIVE ACTION DUE DATE</th>

        </tr>
        <tr *ngFor="let item of result.items">
          <td>{{item.sequence}}</td>
          <td><span placement="top" [ngbTooltip]="item.taskItem.act.name">{{truncate(item.taskItem.act.name,
              40)}}</span></td>
          <td><span placement="top"
              [ngbTooltip]="item.taskItem.complianceQuery">{{truncate(item.taskItem.complianceQuery,
              40)}}</span></td>
          <td><a (click)="openTextModal(textModal, item.taskItem.description)"><span>{{truncate(item.taskItem.description,
                40)}}</span></a></td>
          <td *ngIf="!user">
            <div class="d-flex justify-content-between align-items-center">
            <span *ngIf="item.users && item.users.length > 0" class="text-blue">{{getUserString(item.users)}}</span>
            <div class="d-flex" *ngIf="!item.users || item.users.length == 0">
              <span class="text-red">UNASSIGNED</span>
              
            </div>
            <a class="ms-1 icon-link" *ngIf="!selectedPeriodId" (click)="openAssignModal(assignModal, item)"><span
              class="icon icon-small icon-edit-red"></span></a>
            </div>
          </td>
          <td><span [class.text-red]="isOverDue(item)">{{item.dueDate | date:'yyyy-MM-dd'}}</span></td>
          <td>

            <div class="d-flex justify-content-between">
              <span class="text-blue" *ngIf="item.compliant == null && !item.user">UNDETERMINED</span>
              <span class="text-blue" *ngIf="item.compliant == null && item.user">N/A</span>
              <span class="text-red" *ngIf="item.compliant == false">NO</span>
              <span class="text-green" *ngIf="item.compliant == true">YES</span>

              <a *ngIf="canShowAnswerModal(item)" class="ms-1 icon-link"
                (click)="openAnswerModal(answerModal, item)"><span class="icon icon-small icon-edit-red"></span></a>
            </div>
          </td>
          
            <td>{{item.controlType}}</td>
            <td>{{item.controlFrequency}}</td>
            <td>{{item.controlAutomation}}</td>
            <td>{{item.controlCategory}}</td>
            <td>
              <span class="text-blue" *ngIf="item.controlKeyControl == null">N/A</span>
              <span class="text-red" *ngIf="item.controlKeyControl == false">NO</span>
              <span class="text-green" *ngIf="item.controlKeyControl == true">YES</span>
            </td>
          
          <td>
            <span placement="top" [ngbTooltip]="item.comment">{{truncate(item.comment, 40)}}</span>
          </td>
          <td>
            <a *ngFor="let doc of item.documents" (click)="getDocument(doc)" class="d-block mb-1">{{doc.filename}}</a>
          </td>

          <td>
            <span *ngIf="item.markerUser">{{item.markerUser?.firstname}} {{item.markerUser?.lastname}}</span>
            <span *ngIf="!item.markerUser">&nbsp;</span>
          </td>
            <td>
              <span class="text-blue" *ngIf="item.markerMitigates== null">N/A</span>
              <span class="text-red" *ngIf="item.markerMitigates == false">NO</span>
              <span class="text-green" *ngIf="item.markerMitigates == true">YES</span>
            </td>
            <td>
              <span class="text-blue" *ngIf="item.markerDesign== null">N/A</span>
              <span class="text-red" *ngIf="item.markerDesign == false">NO</span>
              <span class="text-green" *ngIf="item.markerDesign == true">YES</span>
            </td>
            <td>
              <span class="text-blue" *ngIf="item.markerTimely== null">N/A</span>
              <span class="text-red" *ngIf="item.markerTimely == false">NO</span>
              <span class="text-green" *ngIf="item.markerTimely == true">YES</span>
            </td>
            <td>
              <span class="text-blue" *ngIf="item.markerEvidenced== null">N/A</span>
              <span class="text-red" *ngIf="item.markerEvidenced == false">NO</span>
              <span class="text-green" *ngIf="item.markerEvidenced == true">YES</span>
            </td>
          


          <td>{{item.efficiencyRate?.name}}</td>
          <td>
            <span placement="top" [ngbTooltip]="item.markersComment">{{truncate(item.markersComment, 40)}}</span>
          </td>
         
          <td>
            {{item.correctiveActionPlan}}
          </td>
          <td class="d-flex justify-content-between">

            <span>{{item.correctiveActionPlanDueDate | date:'yyyy-MM-dd'}}</span>

            <a class="ms-1 icon-link" *ngIf="!selectedPeriodId && !user"
              (click)="openMarkerModal(markerModal, item)"><span class="icon icon-small icon-edit-red"></span></a>
          </td>
        </tr>
      </table>
    </div>
      <div class="pager-bar py-4 mb-1">
        <div class="container text-center d-flex flex-row align-items-center justify-content-center"
          *ngIf="result.items.length > 0">
          <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
          <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
          <span class="px-4">Page {{result.currentPage}} of {{result.totalPages}}</span>
          <div class="px-4 d-flex flex-row align-items-center">
            <div class="pe-2">Items per page:
            </div>
            <div>
              <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
            </div>
          </div>
          <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
          <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
        </div>
      </div>

      <!-- <div class="pager-bar py-4 mb-1">
        <div class="container text-center d-flex flex-row align-items-center justify-content-center" *ngIf="companyActs.items.length > 0">
          <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
          <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
          <span class="px-4">Page {{companyActs.currentPage}} of {{companyActs.totalPages}}</span>
          <div class="px-4 d-flex flex-row align-items-center">
            <div class="pe-2">Items per page:
            </div>
            <div>
              <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)" [clearable]="false"
                [dropdownPosition]="'top'"></ng-select>
            </div>
          </div>
          <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
          <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
        </div>
      </div> -->
    </div>
  </div>
</div>

<ng-template #assignModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Assign User</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <ng-select [items]="roleOwners" name="roleOwner" [(ngModel)]="currentRoleOwners" [multiple]="true"
          [clearable]="true">
          <ng-template ng-label-tmp let-item="item">
            <span *ngIf="item.customTitle">{{item.customTitle}}</span>
            <span *ngIf="!item.customTitle">{{item.department.name}}</span>
            <span> ({{item.user.firstname}} {{item.user.lastname}})</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <span *ngIf="item.customTitle">{{item.customTitle}}</span>
            <span *ngIf="!item.customTitle">{{item.department.name}}</span>
            <span> ({{item.user.firstname}} {{item.user.lastname}})</span>
          </ng-template>
        </ng-select>
      </div>
      <div class="form-group">
        <input class="form-control" [ngModel]="dueDate | date:'yyyy-MM-dd'" (ngModelChange)="dueDate = $event"
          type="date" id="dueDate" name="dueDate" placeholder="Due Date">
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" [(ngModel)]="assignAll" id="assignAll" name="assignAll">
        <label class="form-check-label" for="assignAll">
          Assign this user and due date to all unassigned compliance items for selected act:<br />
          {{currentAssignItem.taskItem.act.name}}
        </label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary"
      [disabled]="disableAssignButton"
      (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<ng-template #answerModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Compliance</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="answerForm" (ngSubmit)="submitAnswer()">
      <!-- <div class="form-group row" [customErrorMessages]="customErrorMessages"> -->
        <div class="form-group row">
        <label for="compliant"
          class="col-12 col-form-label text-startcol-1">{{currentItem.taskItem.complianceQuery}}</label>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="compliant" id="compliantTrue" [value]="true"
              formControlName="compliant" (change)="compliantChange($event)">
            <label class="form-check-label" for="compliantTrue">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="compliant" id="compliantFalse" [value]="false"
              formControlName="compliant" (change)="compliantChange($event)">
            <label class="form-check-label" for="compliantFalse">No</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="compliant" id="compliantFalse" [value]="null"
              formControlName="compliant" (change)="compliantChange($event)">
            <label class="form-check-label" for="compliantFalse">N/A</label>
          </div>
          
        </div>
      </div>

      
        <label class="col-12 col-form-label text-startcol-1 text-bold">Type of Control</label>
        <div class="col-6">
          <label for="controlType">Type</label>
          <ng-select [multiple]="false" formControlName="controlType" [clearable]="false">
            <ng-option value="Directive">Directive</ng-option>
            <ng-option value="Preventative">Preventative</ng-option>
            <ng-option value="Detective">Detective</ng-option>
            <ng-option value="Corrective / Contingent">Corrective / Contingent</ng-option>
          </ng-select>
        </div>
        <div class="col-6">
          <label for="controlFrequency">Frequency</label>
          <ng-select [multiple]="false" formControlName="controlFrequency" [clearable]="false">
            <ng-option value="Multiple">Multiple</ng-option>
            <ng-option value="Daily">Daily</ng-option>
            <ng-option value="Weekly">Weekly</ng-option>
            <ng-option value="Monthly">Monthly</ng-option>
            <ng-option value="Bi-Annually">Bi-Annually</ng-option>
            <ng-option value="Annually">Annually</ng-option>
            <ng-option value="System">System</ng-option>
          </ng-select>
        </div>
        <div class="col-6">
          <label for="controlAutomation">Automation</label>
          <ng-select [multiple]="false" formControlName="controlAutomation" [clearable]="false">
            <ng-option value="Manual">Manual</ng-option>
            <ng-option value="Automated">Automated</ng-option>
            <ng-option value="Combination">Combination</ng-option>
          </ng-select>
        </div>
        <div class="col-6">
          <label for="controlCategory">Category</label>
          <ng-select [multiple]="false" formControlName="controlCategory" [clearable]="false">
            <ng-option value="People">People</ng-option>
            <ng-option value="Process">Process</ng-option>
            <ng-option value="System">System</ng-option>
            <ng-option value="Information">Information</ng-option>
          </ng-select>
        </div>
        <label class="col-12" for="controlKeyControl">Key Control</label>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="controlKeyControl" id="controlKeyControlTrue" [value]="true"
              formControlName="controlKeyControl">
            <label class="form-check-label" for="controlKeyControlTrue">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="controlKeyControl" id="controlKeyControlFalse" [value]="false"
              formControlName="controlKeyControl">
            <label class="form-check-label" for="controlKeyControlFalse">No</label>
          </div>
        </div>
      


      <div class="form-group row">
        <label for="comments" class="col-12 col-form-label text-startcol-1">Comments</label>
        <div class="col-12">

          <textarea class="form-control" name="comments" id="comments" formControlName="comments"></textarea>

          
        </div>
      </div>

      <!-- <div class="form-group row" [customErrorMessages]="customErrorMessages"> -->
        <div class="form-group row">
        <div class="col-12">
          <div class="custom-file">
            <label for="documents">
              Select files...<span *ngIf="currentItem.taskItem.documentUpload">*</span>
            </label>
            <input type="file" class="form-control" formControlName="documents" (change)="fileUpload($event)"
              multiple  [class.is-invalid]="submitted && answerForm.controls.documents.invalid">
              
              <div class="invalid-feedback text-end" *ngIf="answerForm.controls.documents.errors">
                <div *ngIf="answerForm.controls.documents.errors.required">Required</div>
              </div>
            
          </div>
          
          <div class="mt-2">
            <div class="d-flex mt-1" *ngFor="let file of currentItem.documents">
              <div class="flex-grow-1">{{file.filename}}</div>
              <div>
                <a placement="top" [ngbTooltip]="file.filename" (click)="getDocument(file)" class="pe-1"><span
                    class="icon icon-view-red"></span></a>
              </div>
            </div>
            <div class="d-flex mt-1" *ngFor="let file of files">
              <div class="flex-grow-1">{{file.filename}}</div>
              <div><a class="ms-1 icon-link text-danger" (click)="removeFile(file)">Remove</a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end">
        <button type="submit" ngbAutofocus class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>

</ng-template>

<ng-template #markerModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Marker</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="markerForm" (ngSubmit)="submitMark()">
      <!-- <div class="form-group row" [customErrorMessages]="customErrorMessages"> -->
        <div class="form-group row">
        <label for="compliant"
          class="col-12 col-form-label text-startcol-1">Reallocate?</label>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="compliant" id="compliantTrue" [value]="true"
              formControlName="compliant">
            <label class="form-check-label" for="compliantTrue">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="compliant" id="compliantFalse" [value]="false"
              formControlName="compliant">
            <label class="form-check-label" for="compliantFalse">No</label>
          </div>
          
          
        </div>
      </div>

      
        
        <label class="col-12" for="markerMitigates">Control mitigates the stated risk?</label>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="markerMitigates" id="markerMitigatesTrue" [value]="true"
              formControlName="markerMitigates">
            <label class="form-check-label" for="markerMitigatesTrue">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="markerMitigates" id="markerMitigatesFalse" [value]="false"
              formControlName="markerMitigates">
            <label class="form-check-label" for="markerMitigatesFalse">No</label>
          </div>
        </div>

        <label class="col-12" for="markerDesign">Stated control has been appropriately designed?</label>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="markerDesign" id="markerDesignTrue" [value]="true"
              formControlName="markerDesign">
            <label class="form-check-label" for="markerDesignTrue">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="markerDesign" id="markerDesignFalse" [value]="false"
              formControlName="markerDesign">
            <label class="form-check-label" for="markerDesignFalse">No</label>
          </div>
        </div>

        <label class="col-12" for="markerTimely">Control is performed in a timely manner?</label>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="markerTimely" id="markerTimelyTrue" [value]="true"
              formControlName="markerTimely">
            <label class="form-check-label" for="markerTimelyTrue">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="markerTimely" id="markerTimelyFalse" [value]="false"
              formControlName="markerTimely">
            <label class="form-check-label" for="markerTimelyFalse">No</label>
          </div>
        </div>

        <label class="col-12" for="markerEvidenced">Evidence exists that control has been carried out?</label>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="markerEvidenced" id="markerEvidencedTrue" [value]="true"
              formControlName="markerEvidenced">
            <label class="form-check-label" for="markerEvidencedTrue">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="markerEvidenced" id="markerEvidencedFalse" [value]="false"
              formControlName="markerEvidenced">
            <label class="form-check-label" for="markerEvidencedFalse">No</label>
          </div>
        </div>

      

      <div class="form-group row">
        <label for="efficiencyRateId" class="col-12 col-form-label text-startcol-1">Efficiency Rate</label>
        <div class="col-12">
          <ng-select formControlName="efficiencyRateId" [items]="efficiencyRatings" [multiple]="false"
            [closeOnSelect]="true" [searchable]="false" bindLabel="name" bindValue="id" placeholder="Select..."
            name="efficiencyRateId" id="efficiencyRateId">
          </ng-select>
        </div>
      </div>

      <div class="form-group row">
        <label for="comments" class="col-12 col-form-label text-startcol-1">Comments</label>
        <div class="col-12">
          <textarea class="form-control" name="comments" id="comments" formControlName="comments"></textarea>
          
        </div>
      </div>

      <div class="form-group row">
        <label for="correctiveActionPlan" class="col-12 col-form-label text-startcol-1">Corrective Action Plan</label>
        <div class="col-12">
          <textarea class="form-control" name="correctiveActionPlan" id="correctiveActionPlan" formControlName="correctiveActionPlan"></textarea>
          
        </div>
      </div>

      <div class="form-group row">
        <label for="correctiveActionPlanDueDate" class="col-12 col-form-label text-startcol-1">Corrective Action Plan Due Date</label>
        <div class="col-12">
          <input type="date" class="form-control" name="correctiveActionPlanDueDate" id="correctiveActionPlanDueDate" formControlName="correctiveActionPlanDueDate" />
          
        </div>
      </div>

      <div class="text-end">
        <button type="submit" ngbAutofocus class="btn btn-primary">Submit</button>
      </div>

    </form>
  </div>

</ng-template>


<ng-template #textModal let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div [innerHtml]="textModalBody"></div>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.dismiss('Close click')">Close</button>
  </div>
</ng-template>


