<div class="home py-4 d-flex align-items-center text-center">
  <div class="container-fluid text-uppercase">
    <h1 class="display-3">The future of legal compliance is now!</h1>
    <img class="py-2 img-fluid" src="/assets/start_white.png" />
    <h1 class="display-4">Get started</h1>
  </div>
</div>
<div class="container">
<div class="row">
  <div class="col-md-6 text-center py-4">
    <p>
      <a href="https://www.leescompliance.co.za/" target="_blank"><img src="/assets/lees_logo.png" class="img-fluid" /></a>
    </p>
    <p class="mt-md-5">
      Lee's Compliance was established in 2004 and is owned and managed by Admitted Attorney, Alison Lee. We offer clients a full suite of compliance services and solutions that will assist companies - large and small - with all their regulatory and compliance related needs, requirements and tasks.
    </p>
    <p>
      Lee's Compliance also facilitates training in the form of interactive on-site workshops or e-learning training solutions. Lee's Compliance is situated in Berario, Johannesburg and is complemented by two Attorneys and three administrative assistants.
    </p>
    <p><a href="https://www.leescompliance.co.za/" target="_blank">Click here</a> to view the latest Legal Movements</p>
  </div>
  <div class="col-md-6 text-center py-4">
      <p class="mb-0">
        <a routerLink="/about">
        <img src="/assets/the-legal-team-logo.png" class="img-fluid" />
        </a>
      </p>
      <p class="mb-0"><img src="/assets/hiil.png" class="img-fluid" style="max-height: 8rem;" /></p>
      <p>
          The Legal Team is a secure cloud-based software that gives you a single view of your organisation’s performance. Organisations may elect to select one or all of the components depending on its needs. The Legal Team software product is made up of the following
          interrelated features:
      </p>
      <p>
          1. The Compliance Team<br />
          2. The Regulatory & Compliance Update Team<br />
          3. The Policy Team<br />
          4. The Permit Team<br />
          5. The Report Team<br />
          6. The Audit Team<br />
          7. The Training Team<br />
          8. The Legal Library
      </p>
    </div>
</div>
<!--<div class="row">
  <div class="col-12 mx-auto py-4 text-center">

      <div id="homeCarousel" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <a href="https://www.leescompliance.co.za/training-1" target="_blank">
              <img src="/assets/Banner_Training.png" class="img-fluid" />
            </a>
          </div>
          <div class="carousel-item">
            <a href="https://www.leescompliance.co.za/blog" target="_blank">
              <img src="/assets/Banner_Environment.png" class="img-fluid" />
            </a>
          </div>
          <div class="carousel-item">
            <a href="https://www.leescompliance.co.za/blog" target="_blank">
              <img src="/assets/Banner_POPIA.png" class="img-fluid" />
            </a>
          </div>
          <div class="carousel-item">
            <a href="https://www.leescompliance.co.za/blog" target="_blank">
              <img src="/assets/Banner_BBBEE_CODES.png" class="img-fluid" />
            </a>
          </div>
        </div>
        <a class="carousel-control-prev" href="#homeCarousel" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#homeCarousel" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      
      
  </div>
</div>-->
</div>
