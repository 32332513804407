import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { StatusApiService } from '../../../services/status-api.service';
import { CompanyApiService } from '../../../services/company-api.service';
import { SpinnerService } from '../../../services/spinner.service';
import { RequestCacheService } from '../../../services/request-cache.service';
import { TokenService } from '../../../services/token.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import $ from 'jquery';
//import { ErrorMessage } from 'ng-bootstrap-form-validation';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, map } from 'rxjs/operators'
import { Subject, Observable, of, concat } from 'rxjs';
import { ActApiService } from '../../../services/act-api.service';
import { CompanyDetailStatus } from 'src/app/models/companyDetailStatus';
import { CompanyChecklistItemResult } from 'src/app/models/companyChecklistItemResult';
import { CompanyChecklistItemActResult } from 'src/app/models/companyChecklistItemActResult';
import { CompanyRoleOwner } from 'src/app/models/companyRoleOwner';
import { Act } from 'src/app/models/act';
import { AnswerChecklistItem, AnswerChecklistItemDocument } from 'src/app/models/answerChecklistItem';
import { AssignCompanyChecklistItemUser } from 'src/app/models/assignCompanyChecklistItemUser';
import { CompanyChecklistItem } from 'src/app/models/companyChecklistItem';
import { CompanyChecklistItemAct } from 'src/app/models/companyChecklistItemAct';
import { CompanyChecklistItemDocument } from 'src/app/models/companyChecklistItemDocument';
import { CompanyChecklistUserResult } from 'src/app/models/companyChecklistUserResult';
import { User } from 'src/app/models/user';
import { Period } from 'src/app/models/period';

@Component({
  selector: 'app-self-assessment-checklist',
  templateUrl: './self-assessment-checklist.component.html',
  styleUrls: ['./self-assessment-checklist.component.scss']
})
export class SelfAssessmentChecklistComponent implements OnInit {

  companyId: number;
  apiCompanyId?: number;
  status: CompanyDetailStatus;
  result: CompanyChecklistItemResult;
  actResult: CompanyChecklistItemActResult;
  actResults: boolean = false;
  roleOwners: CompanyRoleOwner[];

  userId: number;
  user: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;

  currentPage: number = 1;
  searchTerm: string = '';

  perPageItems = [5, 10, 20, 50, 100];
  perPage = 10;

  selectedAct: Act;
  //acts$: Observable<Act[]>;
  acts: Act[];
  actsLoading = false;
  actsInput$ = new Subject<string>();
  
  selectedPeriodId?: number = null;
  selectedPeriod: Period;
  periods: Period[];
  periodsLoading = false;

  selectedUser: number;
  users: User[];

  userResults: CompanyChecklistUserResult[];

  answerForm = this.formBuilder.group({
    compliant: [''],
    documents: ['', Validators.required]
  });

  customErrorMessages: any[] = [
    {
      error: 'required',
      format: (label, error) => `This field is required`
    }
  ];

  disableAssignButton = false;
  
  submitted = false;

  constructor(private route: ActivatedRoute,
    private statusApi: StatusApiService,
    private companyApi: CompanyApiService,
    //private lookupApi: LookupApiService,
    private spinner: SpinnerService,
    private cache: RequestCacheService,
    private modalService: NgbModal,
    public token: TokenService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private actApi: ActApiService) { }

  ngOnInit() {

    if (localStorage["PER_PAGE"]) {
      this.perPage = localStorage["PER_PAGE"];
    }

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.orgAdmin || this.superAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.init();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }




  }

  init() {
    this.result = null;
    this.actResult = null;
    this.actResults = false;
    this.selectedAct = null;
    this.selectedUser = null;
    this.selectedPeriodId = null
    this.selectedPeriod = { id: 0, name: 'Current'};

    this.getStatus();
    this.loadActs();
    this.loadPeriods();
    this.getCompanyChecklistActs(null);


  }
  getStatus() {

    this.spinner.startBackground();

    let actId = this.selectedAct ? this.selectedAct.id : null;
    let userId = this.selectedUser ? this.selectedUser : null;
    this.statusApi.getCompanyStatus(this.apiCompanyId, actId, userId, this.selectedPeriodId).subscribe((data) => {
      this.status = data;
      this.spinner.stopBackground();

    });

  }
  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    else {
      this.status = null;
    }
  }

  truncate(str?: string, len: number = 20) {
    if (!str) {
      return str;
    }
    if (str && str.length > len)
      return str.substring(0, len) + '...';
    else
      return str;
  };

  currentCompleted?: boolean;

  getCompanyChecklists(complete: boolean, act: Act = null) {

    if (this.companyAdmin || this.superAdmin) {
      this.loadUsers();
    }

    window.location.hash = 'details';
    this.currentCompleted = complete;
    this.actResult = null;
    this.actResults = false;
    this.selectedAct = act;
    this.getData();
    this.getStatus();
  }

  getCompanyChecklistActs(complete: boolean) {
    window.location.hash = '';
    this.currentCompleted = complete;
    this.result = null;
    this.actResults = true;
    this.getData();
  }

  download(format?: 'xlsx'|'pdf') {
    this.spinner.startBackground();
    this.companyApi.DownloadCompanyChecklistItems(this.currentCompleted, this.apiCompanyId, this.selectedUser, this.selectedAct ? this.selectedAct.id : null, format).subscribe((data) => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }

  periodSelected(event){
    
    this.selectedPeriodId = this.selectedPeriod ? this.selectedPeriod.id : null;

    this.getData();
    this.getStatus();
  }

  actSelected(event) {
    this.currentPage = 1;
    this.selectedAct = null;
    if (event) {
      this.selectedAct = event;
    }
    this.getData();
    this.getStatus();
  }

  resultsActSelected(event) {
    this.currentPage = 1;
    this.selectedAct = null;
    if (event) {
      this.selectedAct = event;
    }

    this.spinner.startBackground();
    this.companyApi.GetCompanyChecklistUserResults(this.apiCompanyId, this.selectedAct ? this.selectedAct.id : null, this.selectedPeriodId).subscribe(data => {
      this.userResults = data;
      this.spinner.stopBackground();
    });

    this.getData();
    this.getStatus();


  }


  userSelected(event) {
    this.currentPage = 1;
    this.selectedUser = null;
    if (event) {
      this.selectedUser = event.id;
    }
    this.getData();
    this.getStatus();
  }

  searchActs(term: string) {
    return this.companyApi.ListCompanyChecklistActs(this.apiCompanyId, 1, 50, term, this.selectedPeriodId).pipe(
      map((r) => {
        return r.items;
      })
    );
  }

  private loadActs() {
    this.actsLoading = true;
    return this.companyApi.ListCompanyChecklistActs(this.apiCompanyId, 1, 50, null, this.selectedPeriodId).subscribe(data => {
      this.acts = data.items;
      this.actsLoading = false;
    });
  }

  private loadPeriods() {
    this.periodsLoading = true;
    return this.companyApi.GetCompanyPeriods(this.apiCompanyId).subscribe(data => {
      let current:Period = { id: 0, name: 'Current'};

      this.periods = [current].concat(data);

      this.periodsLoading = false;
      this.selectedPeriod = current;
    });
  }
  // private loadActs() {
  //   this.acts$ = concat(
  //     of([]), // default items
  //     this.actsInput$.pipe(
  //       debounceTime(200),
  //       distinctUntilChanged(),
  //       tap(() => this.actsLoading = true),
  //       switchMap(term => this.searchActs(term).pipe(
  //         catchError(() => of([])), // empty list on error
  //         tap(() => this.actsLoading = false)
  //       ))
  //     )
  //   );
  // }

  private loadUsers() {
    this.companyApi.GetCompanyActUsers(this.apiCompanyId).subscribe(data => {
      this.users = data;
    });
  }


  getData() {
    this.spinner.startBackground();

    if (this.actResults) {
      this.companyApi.GetCompanyChecklistItemActs(this.apiCompanyId, this.currentCompleted, this.currentPage, this.perPage, this.selectedPeriodId).subscribe((data) => {
        this.actResult = data;

        this.firstEnabled = this.actResult.currentPage > 1;
        this.prevEnabled = this.actResult.currentPage > 1;

        this.nextEnabled = this.actResult.currentPage < data.totalPages;
        this.lastEnabled = this.actResult.currentPage < data.totalPages;

        this.spinner.stopBackground();
      });
    }
    else {
      this.companyApi.GetCompanyChecklistItems(this.apiCompanyId, this.currentCompleted, this.currentPage, this.perPage, this.selectedAct ? this.selectedAct.id : null, this.selectedUser, this.selectedPeriodId).subscribe((data) => {
        this.result = data;

        this.firstEnabled = this.result.currentPage > 1;
        this.prevEnabled = this.result.currentPage > 1;

        this.nextEnabled = this.result.currentPage < data.totalPages;
        this.lastEnabled = this.result.currentPage < data.totalPages;

        this.spinner.stopBackground();
      });

    }
  }

  first() {
    this.currentPage = 1;
    this.getData();
  }

  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.getData();
  }

  next() {
    if (this.actResults) {
      if (this.currentPage < this.actResult.totalPages) {
        this.currentPage++;
      }
    }
    else {
      if (this.currentPage < this.result.totalPages) {
        this.currentPage++;
      }

    }
    this.getData();
  }

  last() {
    if (this.actResults) {
      this.currentPage = this.actResult.totalPages;
    }
    else {
      this.currentPage = this.result.totalPages;

    }
    this.getData();
  }

  private currentRoleOwners: Array<CompanyRoleOwner> = new Array();

  openAssignModal(content, item: CompanyChecklistItemAct) {
    this.currentRoleOwners = new Array();
    this.roleOwners = null;

    this.spinner.startBackground();

    this.companyApi.GetValidCompanyRoleOwners(this.apiCompanyId).subscribe(data => {
      this.roleOwners = data;
      this.spinner.stopBackground();

      this.modalService.open(content).result.then((result) => {


        let userIds: number[] = new Array<number>();

        this.currentRoleOwners.forEach(f => {
          userIds.push(f.user.id);
        });


        let data: AssignCompanyChecklistItemUser = {
          actId: item.act.id,
          userIds: userIds
        };

        this.spinner.startBackground();

        this.disableAssignButton = true;
        this.companyApi.AssignCompanyChecklistItemUser(data, this.apiCompanyId).subscribe(() => {
          this.cache.clear();
          this.getCompanyChecklistActs(this.currentCompleted);
          this.init();
          this.disableAssignButton = false;
        });
      }, (reason) => {
      });
    });
  }

  compliantChange(ev){
    if (this.currentItem.checklistItem.documentUpload)
    {
      if (this.answerForm.controls.compliant.value == "false")
      {
        this.answerForm.controls.documents.setValidators([]);
        this.answerForm.controls.documents.setErrors(null);
      }
      else {
        this.answerForm.controls.documents.setValidators([Validators.required]);
        
      }
    }
  }
  public currentItem: CompanyChecklistItem;

  openAnswerModal(content, item: CompanyChecklistItem) {

    this.currentItem = item;

    this.files = new Array();

    if (item.checklistItem.documentUpload) {
      this.answerForm.controls.documents.setValidators([Validators.required]);
    }
    else {
      this.answerForm.controls.documents.setValidators([]);
    }

    this.answerForm.reset();
    this.modalService.open(content).result.then((result) => {
      // let data: AssignCompanyTaskItemUser = {
      //   taskItemId: item.taskItem.id,
      //   userId: this.currentRoleOwner.user.id
      // };

      // this.spinner.startBackground();

      // this.companyApi.AssignCompanyTaskItemUser(data, this.apiCompanyId).subscribe(() => {
      //   this.cache.clear();
      //   this.getCompanyAudits();
      // });
    }, (reason) => {
    });
  }


  openUserResultsModal(content) {
    this.spinner.startBackground();

    this.companyApi.GetCompanyChecklistUserResults(this.apiCompanyId, this.selectedAct ? this.selectedAct.id : null, this.selectedPeriodId).subscribe(data => {

      this.userResults = data;

      this.spinner.stopBackground();

      this.modalService.open(content).result.then((result) => {

      }, (reason) => {
      });
    });
  }

  submitAnswer() {

    this.submitted = true;

    if (this.answerForm.invalid){
      return;
    }

    this.spinner.startBackground();

    let req: AnswerChecklistItem = {
      compliant: this.answerForm.controls.compliant.value,
      id: this.currentItem.id
    }

    if (this.currentItem.checklistItem.documentUpload) {
      req.documents = this.files;
    }

    this.companyApi.SubmitCompanyChecklistItem(req).subscribe(data => {

      this.cache.clear();

      this.statusApi.getCompanyStatus(this.apiCompanyId, null, null, this.selectedPeriodId).subscribe((data) => {
        this.status = data;
        this.spinner.stopBackground();

        this.getCompanyChecklists(this.currentCompleted, this.selectedAct);
      });

      this.modalService.dismissAll();
      this.spinner.stopBackground();
    });

  }

  error: string;
  files: AnswerChecklistItemDocument[] = new Array();

  fileUpload(event) {
    this.error = null;
    if (event.target.files && event.target.files.length) {
      for (let file of event.target.files) {
        //const [file] = event.target.files;

        // if (file.size > 2147483648) //2MB
        // {
        //   this.answerForm.controls.documents.setValue('');
        //   this.answerForm.controls.documents.setErrors({ 'required': true });
        //   return;
        // }


        // if (file.type != 'application/vnd.ms-excel' && !file.name.toLowerCase().endsWith('.csv')) {
        //   this.answerForm.controls.documents.setValue('');
        //   this.answerForm.controls.documents.setErrors({ 'fileExt': true });

        //   return;
        // }

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          this.files.push({
            data: reader.result,
            filename: file.name
          });
          this.cd.markForCheck();
        };

      }
    }
  }

  removeFile(file) {
    let index = this.files.findIndex(f => f == file);
    if (index >= 0) {
      this.files.splice(index, 1);
      if (this.files.length == 0) {
        this.answerForm.controls.documents.setValue('');
      }
    }
  }

  getDocument(doc: CompanyChecklistItemDocument) {
    this.spinner.startBackground();
    //window.open(this.libraryApi.GetDocumentUrl(id));
    this.companyApi.GetChecklistItemDocument(doc.id).subscribe((data) => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }


  perPageChange(event) {
    localStorage["PER_PAGE"] = this.perPage;
    this.currentPage = 1;
    this.getData();
  }

}
