<div class="container-fluid mt-2" *ngIf="ready">
  <div class="row">
    <div class="col-12 col-sm-10 col-md-8 col-lg-6  offset-sm-1 offset-md-2 offset-lg-3 ">
      <form [formGroup]="mainForm" (ngSubmit)="submit()">
        <h3 class="text-center mb-4"><span class="icon icon-plus-red"></span> Now configure your <span
            class="text-danger">{{companyLabel}}</span></h3>
        <div class="form-group row">
          <label for="name" class="col-sm-4 col-form-label text-start text-sm-right">{{companyLabel}} Name</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" id="name" formControlName="name" [class.is-invalid]="submitted && mainForm.controls.name.invalid">
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.name.errors">
              <div *ngIf="mainForm.controls.name.errors.required">Required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="logo" class="col-sm-4 col-form-label text-start text-sm-right">{{companyLabel}} Logo</label>
          <div class="col-sm-8">
            
              <input type="file" id="addCompanyLogo" class="form-control" formControlName="logo"
                (change)="fileUpload($event)" [class.is-invalid]="submitted && mainForm.controls.logoData.invalid">
                <div class="invalid-feedback text-end" *ngIf="mainForm.controls.logoData.errors">
                  <div *ngIf="mainForm.controls.logoData.errors.required">Required</div>
                </div>
            
            
          </div>
        </div>
        <div class="form-group row">
          <label for="registrationNumber" class="col-sm-4 col-form-label text-start text-sm-right">{{companyLabel}} Registration
            Number</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" id="registrationNumber" formControlName="registrationNumber" [class.is-invalid]="submitted && mainForm.controls.registrationNumber.invalid">
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.registrationNumber.errors">
              <div *ngIf="mainForm.controls.registrationNumber.errors.required">Required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="vatNumber" class="col-sm-4 col-form-label text-start text-sm-right">{{companyLabel}} VAT Number</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" id="vatNumber" formControlName="vatNumber" [class.is-invalid]="submitted && mainForm.controls.vatNumber.invalid">
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.vatNumber.errors">
              <div *ngIf="mainForm.controls.vatNumber.errors.required">Required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="employees" class="col-sm-4 col-form-label text-start text-sm-right">Number of {{companyLabel}}
            Employees</label>
          <div class="col-sm-8">
            <input type="number" min="1" class="form-control" id="employees" formControlName="employees" [class.is-invalid]="submitted && mainForm.controls.employees.invalid">
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.employees.errors">
              <div *ngIf="mainForm.controls.employees.errors.required">Required</div>
              <div *ngIf="mainForm.controls.employees.errors.min">Invalid</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="entityType" class="col-sm-4 col-form-label text-start text-sm-right">Entity Type</label>
          <div class="col-sm-8">
            <select class="form-control" id="entityType" formControlName="entityType" [class.is-invalid]="submitted && mainForm.controls.entityType.invalid">
              <option selected="selected" value="">-- Select an Entity Type --</option>
              <option *ngFor="let entityType of entityTypes" [ngValue]="entityType.id">{{entityType.name}}</option>
            </select>
            
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.entityType.errors">
              <div *ngIf="mainForm.controls.entityType.errors.required">Required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="tradingRegions" class="col-sm-4 col-form-label text-start text-sm-right">{{companyLabel}} Trading
            Regions</label>
          <div class="col-sm-8">
           
            <ng-select formControlName="tradingRegions" [items]="countries" [multiple]="true" [closeOnSelect]="false"
              [searchable]="true" bindLabel="name" bindValue="id" placeholder="No Trading Regions selected" [class.is-invalid]="submitted && mainForm.controls.tradingRegions.invalid">
            </ng-select>
            
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.tradingRegions.errors">
              <div *ngIf="mainForm.controls.tradingRegions.errors.required">Required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="industries" class="col-sm-4 col-form-label text-start text-sm-right"
            aria-describedby="industriesHelp">{{companyLabel}}
            Industries</label>
          <div class="col-sm-8">
           
            <ng-select formControlName="industries" [items]="industries" [multiple]="true" [closeOnSelect]="false"
              [searchable]="true" bindLabel="name" bindValue="id" placeholder="No Industries selected"  [class.is-invalid]="submitted && mainForm.controls.industries.invalid">
            </ng-select>
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.industries.errors">
              <div *ngIf="mainForm.controls.industries.errors.required">Required</div>
            </div>

            <small id="industriesHelp" class="form-text text-muted">Please accurately specify all the industries your
              {{companyLabel.toLowerCase()}} is involved in, as this has a direct bearing on the applicable acts for compliance.<br />
              If no applicable industries present, leave blank.</small>
          </div>
        </div>
        <div class="form-group row">
          <label for="address" class="col-sm-4 col-form-label text-start text-sm-right">{{companyLabel}} Primary Address</label>
          <div class="col-sm-8">
            <textarea class="form-control" id="address" formControlName="address" [class.is-invalid]="submitted && mainForm.controls.address.invalid"></textarea>
            
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.address.errors">
              <div *ngIf="mainForm.controls.address.errors.required">Required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="contactNumber" class="col-sm-4 col-form-label text-start text-sm-right">{{companyLabel}} Primary Contact
            Number</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" id="contactNumber" formControlName="contactNumber" [class.is-invalid]="submitted && mainForm.controls.contactNumber.invalid">
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.contactNumber.errors">
              <div *ngIf="mainForm.controls.contactNumber.errors.required">Required</div>
              <div *ngIf="mainForm.controls.contactNumber.errors.pattern">Invalid</div>
            </div>
          </div>
        </div>
        <div class="text-end"><button type="submit" (click)="setWizard(false)"
            class="btn btn-default">Save</button>&nbsp;<button type="submit" (click)="setWizard(true)"
            class="btn btn-default">Next</button></div>
      </form>
      <hr />
    </div>
  </div>
</div>