<div class="company-list-item d-flex flex-row py-1" [class.alt-row]="_level % 2 == 1">
  <div class="d-flex  justify-content-around align-items-center px-2">
    <div [style]="'padding-left: ' + _level * 4 + 'rem'">
      <a [routerLink]="['/dashboard/configuration/company/edit', _company.id]" title="Edit">
        <span class="icon icon-small icon-edit-red"></span>
      </a><br />
      <a (click)="archive(_company)" title="Archive">
        <span class="icon icon-small icon-hide-red"></span>
      </a>
    </div>
  </div>

  <div class="company-logo d-flex align-items-center justify-content-center">
    <img [src]="_company.logoUrl" [alt]="_company.name" [title]="_company.name" />
  </div>

  <div class="d-flex align-items-center px-2">

    <h3 class="mb-0">{{_company.name}}</h3>

  </div>

  <div class="d-flex align-items-center px-2">
    <div class="icon-link d-flex align-items-center" *ngIf="_company.questionnaire.state == 'Inactive'"><span
        class="icon icon-large icon-questionaire-grey me-2"></span><span>Company
        Questionaire</span></div>
    <a [routerLink]="['/dashboard/configuration/questionnaire', _company.id]"
      *ngIf="_company.questionnaire.state == 'Incomplete'" class="text-danger icon-link d-flex align-items-center">
      <span class="icon icon-large icon-questionaire-red me-2"></span><span class="text-danger">Company
        Questionaire</span>
    </a>
    <div class="text-success icon-link d-flex align-items-center" *ngIf="_company.questionnaire.state == 'Complete'">
      <span class="icon icon-large icon-questionaire-green me-2"></span><span>Company Questionaire Complete</span>
    </div>
  </div>

  <div class="d-flex align-items-center px-2">
    <div class="icon-link d-flex align-items-center" *ngIf="_company.roles.state == 'Inactive'">
      <span class="icon icon-large icon-org-structure-grey me-2"></span><span>Personalise Org Structure</span>
    </div>
    <a [routerLink]="['/dashboard/configuration/org-structure', _company.id]"
      *ngIf="_company.roles.state == 'Incomplete'" class="text-danger icon-link d-flex align-items-center">
      <span class="icon icon-large icon-org-structure-red me-2"></span><span class="text-danger">Personalise Org
        Structure</span>
    </a>
    <a [routerLink]="['/dashboard/configuration/org-structure', _company.id]" *ngIf="_company.roles.state == 'Complete'"
      class="text-success icon-link d-flex align-items-center">
      <span class="icon icon-large icon-org-structure-green me-2"
        *ngIf="_company.roles.state == 'Complete'"></span><span class="text-success">Org Structure Complete</span>
    </a>

  </div>

  <ng-container *ngIf="_company.roles.state == 'Complete'">
    <div class="d-flex align-items-center px-2">

      <div class="icon-link d-flex align-items-center">
        <a [routerLink]="['/dashboard/umbrella-report/', _company.id]" class="icon-link d-flex align-items-center">
          <span class="icon icon-large icon-umbrella-red me-2"></span><span>Review&nbsp;</span><span
            class="text-danger">Company
            Umbrella Report</span>
        </a>

      </div>
    </div>

    <div class="d-flex align-items-center px-2">

      <div class="icon-link d-flex align-items-center">


        <a [routerLink]="['/dashboard/configuration/acts', _company.id]" class="icon-link d-flex align-items-center ">
          <span class="icon icon-large icon-acts-red me-2"></span><span>Review&nbsp;</span><span
            class="text-danger">Company
            Acts</span>
        </a>

      </div>
    </div>
  </ng-container>
</div>
<div>
    <app-company-list-item *ngFor="let company of _company.companies" [company]="company"
      [complete]="_company.roles.state == 'Complete'" [level]="_level + 1"
      (change)="updateCompanyList()"></app-company-list-item>
</div>
<div class="text-center p-2" *ngIf="_company && _company.roles.state == 'Complete' && _level < 4" >
  <a class="btn btn-outline-primary"
    [routerLink]="['/dashboard/configuration/' + route + '/add/', _company.organisationId, _company.id]">Add <b>{{_company.name}}</b>
    {{label}}</a>
</div>