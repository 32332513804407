import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TokenService } from '../../../../services/token.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { SpinnerService } from '../../../../services/spinner.service';
import { ContractsApiService } from '../../../../services/contracts-api.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { RequestCacheService } from '../../../../services/request-cache.service';
import $ from 'jquery';
import { ContractTemplate } from 'src/app/models/contractTemplate';
import { ContractType } from 'src/app/models/contractType';

@Component({
  selector: 'app-contract-templates',
  templateUrl: './contract-templates.component.html',
  styleUrls: ['./contract-templates.component.scss']
})
export class ContractTemplatesComponent implements OnInit {

  add: boolean = false;
  edit: boolean = false;
  currentTemplate: ContractTemplate;
  currentType = 0;
  searchTerm = '';

  companyId: number;
  apiCompanyId?: number;
  contractTemplates: ContractTemplate[];
  filteredContractTemplates: ContractTemplate[];
  contractTypes: ContractType[];

  user: boolean = false;
  contractAdmin: boolean = false;
  superAdmin: boolean = false;

  mainForm = this.formBuilder.group({
    name: ['', Validators.required],
    contractType: ['', Validators.required],
    document: [''],
    documentData: [''],
    companySpecific: false
  });

  constructor(
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private cd: ChangeDetectorRef,
    private contractsApi: ContractsApiService,
    public token: TokenService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private cache: RequestCacheService,
    private cdref: ChangeDetectorRef
  ) { }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {

    this.contractsApi.GetContractTypes().subscribe(data => {
      this.contractTypes = data;
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.currentType = +params.get('id');
    });

    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.contractAdmin || this.user) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.getTemplates();
      }

    }
    else if (this.superAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.getTemplates();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/contracts');
    }
  }

  back() {
    this.router.navigateByUrl('/dashboard/contracts');
  }
  filter() {
    
    switch (+this.currentType) {
      case 1:
        this.filteredContractTemplates = this.contractTemplates.filter(f => f.companyId == 0);
        break;
      case 2:
        this.filteredContractTemplates = this.contractTemplates.filter(f => f.companyId != 0);
        break;
      default:
        this.filteredContractTemplates = this.contractTemplates.filter(f => f.companyId >= 0);
        break;
    }

    if (this.searchTerm != '') {
      this.filteredContractTemplates = this.filteredContractTemplates.filter(f => f.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0);
    }

  }

  clear() {
    this.searchTerm = '';
    this.filter();
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.getTemplates();
    }
    else {
      this.contractTemplates = null;
    }
  }

  getTemplates() {
    this.spinner.startBackground();
    this.contractsApi.ListContractTemplatesFull(this.apiCompanyId).subscribe(data => {
      this.contractTemplates = data;
      this.filter();
      this.spinner.stopBackground();
    });
  }

  addTemplate() {
    this.mainForm.reset();
    this.currentTemplate = <ContractTemplate>{};
    this.mainForm.controls.document.setValidators([Validators.required]);
    this.add = true;
    this.mainForm.controls.companySpecific.enable();
  }

  editTemplate(item: ContractTemplate) {
    this.edit = true;
    this.currentTemplate = item;
    this.mainForm.controls.companySpecific.disable();


    this.mainForm.controls.document.setValidators([]);

    this.mainForm.patchValue({
      name: item.name,
      contractType: item.contractType,
      companySpecific: (item.companyId != null)
    });
  }

  fileUpload(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 21474836480) //20MB
      {
        this.mainForm.controls.document.setValue('');
        this.mainForm.controls.document.setErrors({ 'required': true });
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.mainForm.patchValue({
          documentData: reader.result
        });

        $('.custom-file-label').addClass("selected").html(file.name);

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  cancel() {
    this.add = false;
    this.edit = false;
    this.mainForm.reset();
  }

  submit() {
    this.spinner.startBackground();

    if (this.add) {
      this.currentTemplate = <ContractTemplate>{};
    }

    this.currentTemplate.name = this.mainForm.controls.name.value;
    this.currentTemplate.active = true;
    this.currentTemplate.workDocsId = this.mainForm.controls.documentData.value;
    this.currentTemplate.contractType = this.contractTypes.find(f => f.id == this.mainForm.controls.contractType.value);

    if (this.mainForm.controls.companySpecific.value) {
      this.currentTemplate.companyId = this.apiCompanyId;
    }


    if (this.add) {
      this.contractsApi.AddContractTemplate(this.currentTemplate).subscribe(data => {
        this.add = false;
        this.edit = false
        this.cache.clear();
        this.getTemplates();
      });
    }
    else if (this.edit) {
      this.contractsApi.UpdateContractTemplate(this.currentTemplate.id, this.currentTemplate).subscribe(data => {
        this.add = false;
        this.edit = false
        this.cache.clear();
        this.getTemplates();
      });
    }
    else {

      this.add = false;
      this.edit = false;
      this.mainForm.reset();

    }


  }

  archive(item: ContractTemplate) {

    if (!window.confirm("Are you sure?")) {
      return;
    }

    this.spinner.startBackground();

    item.active = false;

    this.contractsApi.UpdateContractTemplate(item.id, item).subscribe(data => {
      var index = this.contractTemplates.findIndex(f => f.id == item.id);
      this.contractTemplates.splice(index, 1);
      this.spinner.stopBackground();

      this.cache.clear();
      this.getTemplates();
    });

  }

  request(item: ContractTemplate) {

    this.router.navigateByUrl(`/dashboard/contracts-old/request/${item.id}`);
  }
}
