import { Component, OnInit, Input, NgZone } from '@angular/core';
import { TokenService } from '../../services/token.service';
import $ from 'jquery';
import { Router } from '@angular/router';

@Component({
  selector: 'app-puzzle-wheel',
  templateUrl: './puzzle-wheel.component.html',
  styleUrls: ['./puzzle-wheel.component.scss']
})
export class PuzzleWheelComponent implements OnInit {

  umbrellaWheel: boolean = false;

  _percentage: number = 0;
  _section: string = '';
  _showPercentage: boolean = true;

  constructor(private token: TokenService, private router: Router, private ngZone: NgZone) {
    (<any>window).go = this.go.bind(this);
  }

  ngOnInit() {
    this.umbrellaWheel = this.token.isCompanyAdmin() || this.token.isOrganisationAdmin() || this.token.isSuperAdmin();
  }

  @Input()
  set percentage(percentage: number) {
    this._percentage = percentage;
  }

  @Input()
  set section(section: string) {
    this._section = section;
  }

  @Input()
  set showPercentage(showPercentage: boolean) {
    this._showPercentage = showPercentage;
  }

  go(id: string) {
    switch (id) {
      case 'umbrella_report':
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard/umbrella-report')).then();
        break;
      case 'e_learning':
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard/e-learning')).then();
        break;
      case 'contracts':
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard/documents')).then();
        break;
      case 'compliance_program':
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard/compliance-program')).then();
        break;
      case 'self_assessment_checklist':
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard/self-assessment-checklist')).then();
        break;
    }
  }
}
