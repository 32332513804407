import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import $ from 'jquery';
import { RiskRating } from 'src/app/models/riskRating';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-impact-rating',
  templateUrl: './impact-rating.component.html',
  styleUrls: ['./impact-rating.component.scss']
})
export class ImpactRatingComponent implements OnInit {

  impactRating: RiskRating;
  valid: boolean = false;
  isReadOnly = false;
  
  public orgId: number = 0;
  superAdmin: boolean = false;
  orgAdmin: boolean = false;
  @Input()
  set readOnly(value: boolean){
    this.isReadOnly = value;
  }
  

  @Input()
  set value(val: RiskRating) {
    console.log('ImpactRatingComponent value: ', val);
    if (val && val.impact && val.likelihood) {
      this.impactRating = val;
      //let el = $('.cell-content[data-impact="' + this.impactRating.impact.toString() + '"][data-likelihood="' + this.impactRating.likelihood.toString() + '"]')
      //'this.selectItem(el);
    }
  }

  @Output()
  onSelect = new EventEmitter<RiskRating>();

  constructor(private token: TokenService){

  }

  selected(likelihood: number, impact: number){
    if (!this.impactRating) {
      return false;
    }
    else {
      return this.impactRating.impact == impact && this.impactRating.likelihood == likelihood
    } 
  }
  ngOnInit() {

    this.superAdmin = this.token.isSuperAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();

    if (this.superAdmin) {
      this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.orgId = this.token.organisationId();
    }
    

  }

  click(item) {
    if (this.isReadOnly){
      return;
    }
    this.selectItem(item.srcElement);
    this.onSelect.emit(this.impactRating);
  }

  selectItem(element) {
    
    this.valid = true;
    //$('.cell-content').removeClass('selected');
    //$(element).addClass('selected');
    let id = $(element).data('rating');
    let likelihood = $(element).data('likelihood');
    let impact = $(element).data('impact');

    if (!this.impactRating) {
      this.impactRating = <RiskRating>{};
    }

    this.impactRating.id = id;
    this.impactRating.impact = impact;
    this.impactRating.likelihood = likelihood;
  }

}
