<div *ngIf="this.organisation">
    <div class="org-list" >
        <div class="p-1 company-list-title">Organisation</div>
        <app-organisation-list-item [organisation]="organisation"></app-organisation-list-item>
    </div>
    <div class="company-list" *ngIf="organisation.companies">
        <div class="p-1 company-list-title">Companies</div>
        <app-company-list-item *ngFor="let company of organisation.companies" [company]="company" [level]="0" (change)="updateCompanyList()"></app-company-list-item>
    </div>
    <div class="text-center py-2" *ngIf="organisation.companies && organisation.companies.length == 0">No companies defined for this organisation</div>
    <div class="text-center p-2" *ngIf="this.organisation">
        <a class="btn btn-outline-primary" [routerLink]="['/dashboard/configuration/company/add/', organisation.id]">Add Company</a>
    </div>
</div>