<div class="mb-1" *ngIf="superAdmin || orgAdmin">
  <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container-fluid">
  <div class="row mt-4" *ngIf="reportData" [class.alt-color]="orgId == 89">
    <div class="col-12 d-flex pb-2">
      <div class="pe-2">
        Report Type:
        <ng-select style="width: 150px;" [(ngModel)]="reportType" (change)="reportTypeChange($event)"
          [clearable]="false">
          <ng-option [value]="'full'">Full</ng-option>
          <ng-option [value]="'executive'">Executive</ng-option>
          <ng-option [value]="'corrective-action'">Corrective Action</ng-option>
        </ng-select>
      </div>
      <div class="pe-2" *ngIf="reportType != 'corrective-action'">
        Sort By:
        <ng-select style="width: 150px;" [(ngModel)]="sortBy" (change)="sortChange($event)" [clearable]="false">
          <ng-option [value]="'Name'">Applicable Law</ng-option>
          <ng-option [value]="'Status'">Compliance Status</ng-option>
        </ng-select>
      </div>
      <div class="pe-2" *ngIf="reportType != 'corrective-action'">
        Sort Order:
        <ng-select style="width: 150px;" [(ngModel)]="orderDecending" (change)="orderChange($event)"
          [clearable]="false">
          <ng-option [value]="false">Ascending</ng-option>
          <ng-option [value]="true">Descending</ng-option>
        </ng-select>
      </div>

      <div class="pe-2" *ngIf="reportType != 'corrective-action'">
        Filter by Raw Risk:
        <ng-select style="width: 150px;" [(ngModel)]="selectedImpactRating"
          (change)="impactRatingChange($event)" [clearable]="true" 
          placeholder="-- All --">
          <ng-option *ngFor="let item of impactRatings" [value]="item.id">{{item.name}}</ng-option>
        </ng-select>
      </div>
      <!-- <div class="pe-2">
      Filter by Compliance Status:
      <input type="number" class="form-control" [(ngModel)]="selectedComplianceStatus">
    </div>
    <div class="pe-2">
      &nbsp;<br/>
      <button class="btn btn-primary" (click)="complianceStatusChange($event)">SUBMIT</button>
    </div> -->
      <div class="flex-grow-1"></div>
      <div>
        &nbsp;<br />
        <button class="btn btn-primary" (click)="export($event, 'pdf')">EXPORT (PDF)</button>&nbsp;
        <button class="btn btn-primary" (click)="export($event, 'xlsx')">EXPORT (XLSX)</button>
      </div>
    </div>
    <div class="col-12">
      <table class="table table-bordered bg-white table-sm reports-table table-hover"
        *ngIf="reportType != 'corrective-action'">
        <tr>

          <th [style.width]="reportType == 'full' ? '250px' : '20%'">ACT NAME</th>
          <th *ngIf="reportType == 'full'">ABOUT THE LAW</th>
          <th *ngIf="reportType == 'full'">CHANGES</th>
          <th *ngIf="reportType == 'full'">APPLIES TO</th>
          <th *ngIf="reportType == 'full'">REGULATOR</th>
          <th *ngIf="reportType == 'full'">REGULATOR WEBSITE</th>
          <th [style.width]="reportType == 'full' ? 'auto' : '20%'">RAW RISK RATING</th>
          <th [style.width]="reportType == 'full' ? 'auto' : '20%'">RESIDUAL RISK RATING</th>
          <th [style.width]="reportType == 'full' ? 'auto' : '10%'">SCORE</th>
          <th *ngIf="reportType == 'full'">RISKS</th>
          <th *ngIf="reportType == 'full'">CONSEQUENCE OF NON-COMPLIANCE</th>
          <th *ngIf="reportType == 'full'">CONTROLS</th>
          <th *ngIf="reportType == 'full'">PERMITS</th>
          <th *ngIf="reportType == 'full'">POLICY</th>
          <th *ngIf="reportType == 'full'">REPORTING</th>
          <th *ngIf="reportType == 'full'">POPIA</th>
          <th *ngIf="reportType == 'full'">RETENTION (YEARS)</th>
          <th *ngIf="reportType == 'full'">IMPACTED ROLES</th>
          <th *ngIf="reportType == 'full'">IMPACTED DEPARTMENTS</th>
          <th [style.width]="reportType == 'full' ? 'auto' : '30%'">NOTES</th>

          <!-- <th>APPLICABLE<br />LAW</th>
        <th>RISK</th>
        <th *ngIf="reportType == 'full'">PERMIT</th>
        <th *ngIf="reportType == 'full'">STATUTORY<br/>REPORTS</th>
        <th *ngIf="reportType == 'full'">APPLICABLE FUNCTION/<br />RESPONSIBLE PARTY</th>
        <th>COMPLIANCE<br />STATUS</th>
        <th *ngIf="reportType == 'full'">COMPLIANCE TASKS<br/>TO BE COMPLETED</th>
        <th *ngIf="reportType == 'full'">CHANGES</th>
        <th>NOTES</th> -->
        </tr>
        <tr *ngFor="let item of reportData.items">


          <td *ngIf="reportType == 'full'"><span placement="top" [ngbTooltip]="item.name">{{truncate(item.name)}}</span>
          </td>
          <td [style.width]="reportType == 'full' ? '250px' : '30%'" *ngIf="reportType != 'full'">{{item.name}}</td>

          <td *ngIf="reportType == 'full'"><span placement="top"
              [ngbTooltip]="item.about">{{truncate(item.about)}}</span></td>
          <td *ngIf="reportType == 'full'"><span placement="top"
              [ngbTooltip]="item.changes">{{truncate(item.changes)}}</span></td>
          <td *ngIf="reportType == 'full'"><span placement="top"
              [ngbTooltip]="item.appliesTo">{{truncate(item.appliesTo)}}</span></td>
          <td *ngIf="reportType == 'full'"><span placement="top"
              [ngbTooltip]="item.regulator">{{truncate(item.regulator)}}</span></td>
          <td *ngIf="reportType == 'full'"><span placement="top"
              [ngbTooltip]="item.regulatorWebsite">{{truncate(item.regulatorWebsite)}}</span></td>

          <td style="width: 100px">
            <div class="d-flex">
              <div class="flex-grow-1">
                <span class=" badge w-100 p-2"
                  [ngClass]="displayImpactRating(item.impactRating, item.likelihood, item.impact).toLowerCase()">{{displayImpactRating(item.impactRating, item.likelihood, item.impact)}}</span>
              </div>
            </div>
          </td>

          <td style="width: 100px">
            <div class="d-flex">
              <div class="flex-grow-1">
                <span class=" badge w-100 p-2"
                  [ngClass]="displayImpactRating(item.residualRiskRating, item.residualLikelihood, item.residualImpact).toLowerCase()">{{displayImpactRating(item.residualRiskRating, item.residualLikelihood, item.residualImpact)}}</span>
              </div>
            </div>
          </td>


          <td [style.width]="reportType == 'full' ? 'auto' : '10%'" class="text-center">{{item.score}}%</td>

         
          <td *ngIf="reportType == 'full'" [style.width]="reportType == 'full' ? 'auto' : '30%'"><span placement="top"
              [ngbTooltip]="item.riskDescription">{{truncate(item.riskDescription)}}</span></td>

          <td *ngIf="reportType == 'full'"><span placement="top"
              [ngbTooltip]="item.implications">{{truncate(item.implications)}}</span></td>
          <td *ngIf="reportType == 'full'"><span placement="top"
              [ngbTooltip]="item.controls">{{truncate(item.controls)}}</span></td>

          <td *ngIf="reportType == 'full'">
            <div *ngFor="let rr of item.permits">{{rr.name}}</div>
            <em *ngIf="!item.permits || item.permits.length == 0">- NOT REQUIRED -</em>
          </td>

          <td *ngIf="reportType == 'full'">
            <div *ngFor="let rr of item.policies">{{rr.name}}</div>
            <em *ngIf="!item.policies || item.policies.length == 0">- NOT REQUIRED -</em>
          </td>

          <td *ngIf="reportType == 'full'">
            <div *ngFor="let rr of item.reportingRequirements">{{rr.name}}</div>
            <em *ngIf="!item.reportingRequirements || item.reportingRequirements.length == 0">- NOT REQUIRED -</em>
          </td>

          <td *ngIf="reportType == 'full'">{{item.popiaImplication ? 'YES' : 'NO'}}</td>
          <td *ngIf="reportType == 'full'">{{item.documentRetentionPeriod}}</td>
          <td *ngIf="reportType == 'full'"><span placement="top"
              [ngbTooltip]="item.impactedRoles">{{truncate(item.impactedRoles)}}</span></td>
          <td *ngIf="reportType == 'full'"><span placement="top"
              [ngbTooltip]="item.impactedDepartments">{{truncate(item.impactedDepartments)}}</span></td>

          <td [style.width]="reportType == 'full' ? 'auto' : '30%'">
            <div class="d-flex align-items-start">
              <span class=" flex-grow-1" placement="top" [ngbTooltip]="item.notes">{{truncate(item.notes)}}</span>
              <a class="ms-1 icon-link" (click)="openNotesModal(notesModal, item)"><span
                  class="icon icon-small icon-edit-red"></span></a>
            </div>
          </td>
        </tr>
      </table>


      <table class="table table-bordered bg-white table-sm reports-table table-hover"
      *ngIf="reportType == 'corrective-action'">
        <tr>
          <th>#</th>
          <th>ACT</th>
          <th>COMPLIANCE ITEM</th>
          <th>DESCRIPTION</th>
          <th>ASSIGNED TO</th>
          <th style="width: 80px">DUE DATE</th>
          <th>COMPLIANT</th>
          
          <th>CONTROL<br/>TYPE</th>
          <th>CONTROL<br/>FREQUENCY</th>
          <th>CONTROL<br/>AUTOMATION </th>
          <th>CONTROL<br/>CATEGORY</th>
          <th>KEY<br/>CONTROL</th>
          
          <th>COMMENTS</th>
          <th>DOCUMENTS</th>
          
          <th>MARKER<br/>MITIGATES</th>
          <th>MARKER<br/>DESIGN</th>
          <th>MARKER<br/>TIMELY</th>
          <th>MARKER<br/>EVIDENCED</th>
          
          <th>EFFICIENCY RATE</th>
          <th>MARKER COMMENTS</th>
          <th>CORRECTIVE ACTION PLAN</th>
          <th>CORRECTIVE ACTION DUE DATE</th>
        </tr>

        <tr *ngFor="let item of reportData.items">
          <td>{{item.sequence}}</td>
          <td><span placement="top" [ngbTooltip]="item.taskItem.act.name">{{truncate(item.taskItem.act.name,
              40)}}</span></td>
          <td><span placement="top"
              [ngbTooltip]="item.taskItem.complianceQuery">{{truncate(item.taskItem.complianceQuery,
              40)}}</span></td>
          <td><a (click)="openTextModal(textModal, item.taskItem.description)"><span>{{truncate(item.taskItem.description,
                40)}}</span></a></td>
          <td *ngIf="!user">
            <span *ngIf="item.users && item.users.length > 0" class="text-blue">{{getUserString(item.users)}}</span>
            <div class="d-flex" *ngIf="!item.users || item.users.length == 0">
              <span class="text-red">UNASSIGNED</span>
              <a class="ms-1 icon-link" *ngIf="!selectedPeriodId" (click)="openAssignModal(assignModal, item)"><span
                  class="icon icon-small icon-edit-red"></span></a>
            </div>
          </td>
          <td><span [class.text-red]="isOverDue(item)">{{item.dueDate | date:'yyyy-MM-dd'}}</span></td>
          <td>

            <div class="d-flex justify-content-between">
              <span class="text-blue" *ngIf="item.compliant == null && !item.user">UNDETERMINED</span>
              <span class="text-blue" *ngIf="item.compliant == null && item.user">N/A</span>
              <span class="text-red" *ngIf="item.compliant == false">NO</span>
              <span class="text-green" *ngIf="item.compliant == true">YES</span>
            </div>
          </td>

          
            <td>{{item.controlType}}</td>
            <td>{{item.controlFrequency}}</td>
            <td>{{item.controlAutomation}}</td>
            <td>{{item.controlCategory}}</td>
            <td>
              <span class="text-blue" *ngIf="item.controlKeyControl == null">N/A</span>
              <span class="text-red" *ngIf="item.controlKeyControl == false">NO</span>
              <span class="text-green" *ngIf="item.controlKeyControl == true">YES</span>
            </td>
          <td>
            <span placement="top" [ngbTooltip]="item.comment">{{truncate(item.comment, 40)}}</span>
          </td>
          <td>
            <span *ngFor="let doc of item.documents" class="d-block mb-1">{{doc.filename}}</span>
          </td>

            <td>
              <span class="text-blue" *ngIf="item.markerMitigates== null">N/A</span>
              <span class="text-red" *ngIf="item.markerMitigates == false">NO</span>
              <span class="text-green" *ngIf="item.markerMitigates == true">YES</span>
            </td>
            <td>
              <span class="text-blue" *ngIf="item.markerDesign== null">N/A</span>
              <span class="text-red" *ngIf="item.markerDesign == false">NO</span>
              <span class="text-green" *ngIf="item.markerDesign == true">YES</span>
            </td>
            <td>
              <span class="text-blue" *ngIf="item.markerTimely== null">N/A</span>
              <span class="text-red" *ngIf="item.markerTimely == false">NO</span>
              <span class="text-green" *ngIf="item.markerTimely == true">YES</span>
            </td>
            <td>
              <span class="text-blue" *ngIf="item.markerEvidenced== null">N/A</span>
              <span class="text-red" *ngIf="item.markerEvidenced == false">NO</span>
              <span class="text-green" *ngIf="item.markerEvidenced == true">YES</span>
            </td>


          
          <td>{{item.efficiencyRate?.name}}</td>
          <td>
            <span placement="top" [ngbTooltip]="item.markersComment">{{truncate(item.markersComment, 40)}}</span>
          </td>
         
          <td>
            {{item.correctiveActionPlan}}
          </td>
          <td class="d-flex justify-content-between">

            <span>{{item.correctiveActionPlanDueDate | date:'yyyy-MM-dd'}}</span>

          </td>
        </tr>


      </table>

      <div class="pager-bar py-4 mb-1">
        <div class="container text-center d-flex flex-row align-items-center justify-content-center"
          *ngIf="reportData.items.length > 0">
          <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
          <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
          <span class="px-4">Page {{reportData.currentPage}} of {{reportData.totalPages}}</span>
          <div class="px-4 d-flex flex-row align-items-center">
            <div class="pe-2">Items per page:
            </div>
            <div>
              <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
            </div>
          </div>
          <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
          <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
        </div>
      </div>
    </div>

    <ng-template #notesModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Notes</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <textarea class="form-control" id="notes" name="notes" [(ngModel)]="currentNotes"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
      </div>
    </ng-template>

  </div>
</div>