<div class="container-fluid pt-4">
    <div class="d-flex align-items-center justify-content-end pt-4">
        <div class="d-flex align-items-center me-3">
            <label for="filter" class="me-2">Filter:</label><input id="filter" type="text" class="form-control" [(ngModel)]="currentFilter" (keyup)="filter()"  />
        </div>

        <button class="btn btn-default" (click)="back()">Back</button>
    </div>
    <hr>

    <div>

        <div class="mb-1 d-flex  align-items-center" *ngFor="let item of filteredCompanyActAllocations">

            <div class="listing bg-white flex-grow-1 p-2">
                <div class="text-start"><button style="white-space: normal;"
                        class="text-start btn btn-link p-0"><strong>{{item.name}}</strong></button></div>
            </div>
            <div class="px-3 d-flex">
                <button class="btn btn-link p-0 me-3" title="Add" [disabled]="item.allocated"
                    (click)="add(item)"><span class="icon icon-tick-green"></span></button>
                <button class="btn btn-link p-0" title="Remove" [disabled]="!item.allocated"
                    (click)="remove(item)"><span class="icon icon-trash-red"></span></button>
            </div>
        </div>


    </div>

    <hr>
    <div class="text-end pb-4">
        <button class="btn btn-default" (click)="back()">Back</button>
    </div>
</div>