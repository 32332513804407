import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { LibraryApiService } from '../../../services/library-api.service';
import { SpinnerService } from '../../../services/spinner.service';
import { LookupApiService } from '../../../services/lookup-api.service';
import { DocumentApiService } from '../../../services/document-api.service';
import { TokenService } from '../../../services/token.service';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Subject, Observable, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, map } from 'rxjs/operators'
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../../services/alert.service';
import { RequestCacheService } from '../../../services/request-cache.service';
import { CompanyApiService } from '../../../services/company-api.service';
import { EnvironmentService } from '../../../services/environment.service';
import { CompanyBase } from 'src/app/models/company';
import { LibraryPageState } from 'src/app/models/libraryPageState';
import { ListingItem } from 'src/app/models/listingItem';
import { ListingItemFull } from 'src/app/models/listingItemFull';
import { ListingResult } from 'src/app/models/listingResult';
import { ListingType } from 'src/app/models/listingType';
import { Document } from 'src/app/models/document';
import CKEditor from 'src/lib/ckeditor5/build/ckeditor';
//import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';


@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

  //@ViewChild('editor') editorComponent: any;
  //@ViewChild('editorFull') editorFullComponent: any;

  public Editor = CKEditor;

  parentId: number = null;

  result: ListingResult;
  listingTypes: ListingType[];
  breadCrumbs: ListingItem[];
  getBreadCrumbs: boolean = true;
  companies: CompanyBase[];

  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;
  backEnabled: boolean = false;
  editorConfig: any = {
    extraPlugins: 'colorbutton,justify,uploadimage',
    uploadUrl: this.env.getApiUrl() + '/api/library/imagetodataurl',
    filebrowserBrowserUrl: this.env.getApiUrl() + '/api/library/imagetodataurl',
    filebrowserUploadUrl: this.env.getApiUrl() + '/api/library/imagetodataurl',
    filebrowserImageUploadUrl: this.env.getApiUrl() + '/api/library/imagetodataurl',
    removeDialogTabs: 'image:advanced;link:advanced'
  };
  //   imageUploadUrl: this.env.getApiUrl() + '/api/library/imagetodataurl',
  //   highlight: {
  //     options: [
  //       {
  //         model: 'redPen',
  //         class: 'pen-red',
  //         title: 'Red pen',
  //         color: '#851f22',
  //         type: 'pen'
  //       },
  //       {
  //         model: 'greenPen',
  //         class: 'pen-green',
  //         title: 'Green pen',
  //         color: '#2a8d15',
  //         type: 'pen'
  //       }
  //       ,
  //       {
  //         model: 'bluePen',
  //         class: 'pen-blue',
  //         title: 'Blue pen',
  //         color: '#09478a',
  //         type: 'pen'
  //       }
  //       ,
  //       {
  //         model: 'orangePen',
  //         class: 'pen-orange',
  //         title: 'Oraange pen',
  //         color: '#cf5d1f',
  //         type: 'pen'
  //       }
  //       ,
  //       {
  //         model: 'yellowPen',
  //         class: 'pen-yellow',
  //         title: 'Yellow pen',
  //         color: '#baad26',
  //         type: 'pen'
  //       }
  //     ]
  //   },
  // }

  currentState: LibraryPageState = {
    currentPage: 1,
    reverse: false,
    listingTypeId: null,
    perPage: 10,
    searchTerm: ''
  };

  states: LibraryPageState[] = new Array();

  // reverse: boolean = false;
  // currentPage: number = 1;
  // perPage: number = 10;
  // searchTerm: string = '';
  // listingTypeId: number = null;

  superAdmin: boolean = false;
  orgAdmin: boolean = false;
  edit: boolean = false;
  add: boolean = false;
  currentListing: ListingItemFull;


  //public Editor = ClassicEditor;
  public editorData: string = '';
  public editorDataFull: string = '';



  documents$: Observable<Document[]>;

  documentsLoading = false;
  documentsInput$ = new Subject<string>();

  perPageItems = [5, 10, 20, 50, 100];

  mainForm = this.formBuilder.group({
    title: ['', Validators.required],
    description: [''],
    content: [''],
    order: [''],
    //parentId: [''],
    listingTypes: [''],
    documents: [''],
    sharedCompanies: [''],
    exclusiveCompany: ['']
  });

  constructor(
    private route: ActivatedRoute,
    private libraryApi: LibraryApiService,
    private documentApi: DocumentApiService,
    private lookupApi: LookupApiService,
    private spinner: SpinnerService,
    private token: TokenService,
    private formBuilder: UntypedFormBuilder,
    private alert: AlertService,
    private cache: RequestCacheService,
    private router: Router,
    private companyApi: CompanyApiService,
    private env: EnvironmentService,
    private cd: ChangeDetectorRef
  ) {

    //var x = this.Editor;
  }

  ngOnInit() {

    if (localStorage["PER_PAGE"]) {
      this.currentState.perPage = localStorage["PER_PAGE"];
    }

    //this.states = JSON.parse(localStorage.getItem('LIBRARY_STATES'));

    // if (localStorage.getItem('LIBRARY_HISTORY')) {

    //   localStorage.removeItem('LIBRARY_HISTORY');

    //   if (this.states) {
    //     this.currentState = this.states.pop();
    //     localStorage.setItem('LIBRARY_STATES', JSON.stringify(this.states));
    //   }
    // }

    // if (!this.states) {
    //   this.states = new Array();
    // }

    if (window.location.hash) {
      this.parentId = +window.location.hash.replace('#', '');
    }
    // this.route.paramMap.subscribe((params: ParamMap) => {
    //   this.parentId = +params.get('id');
    //   this.getData();
    // });

    this.superAdmin = this.token.isSuperAdmin();
    this.lookupApi.ListListingTypes().subscribe((data) => {
      this.listingTypes = data;

      if (this.parentId) {
        this.getData();
      }
      //
    });


    if (this.superAdmin) {
      this.loadDocuments();


      this.companyApi.ListCompanies().subscribe(data => {
        this.companies = data;
      });
    }

  }

  getData() {
    this.spinner.startBackground();

    let listingTypeId = null;
    if (!this.parentId) {
      listingTypeId = this.currentState.listingTypeId
    }

    this.libraryApi.ListItems(this.parentId, this.currentState.currentPage, this.currentState.perPage, this.currentState.reverse, listingTypeId, this.currentState.searchTerm).subscribe((data) => {
      this.result = data;
      if (!this.currentState.listingTypeId) {
        this.currentState.listingTypeId = this.result.item.listingTypeId;
      }
      this.firstEnabled = this.result.currentPage > 1;
      this.prevEnabled = this.result.currentPage > 1;

      this.nextEnabled = this.result.currentPage < data.totalPages;
      this.lastEnabled = this.result.currentPage < data.totalPages;

      this.backEnabled = (this.result.item != null);

      if (this.parentId) {
        if (this.getBreadCrumbs && this.parentId) {
          this.libraryApi.Breadcrumb(this.parentId).subscribe(data => {
            this.breadCrumbs = data;
            this.getBreadCrumbs = false;
          });
        }
      }
      else {
        this.breadCrumbs = null;
        this.getBreadCrumbs = false;
      }
      this.spinner.stopBackground();

      if (this.parentId) {
        window.location.hash = this.parentId.toString();
      }
      else {
        window.location.hash = '';
      }
    });
  }

  click(evt) {
    const href = evt.target.getAttribute('href');
    if (href) {
      evt.preventDefault();
      window.open(href);
    }
    else {
      const href1 = evt.target.parentNode.getAttribute('href');
      if (href1) {
        evt.preventDefault();
        window.open(href1);
      }
    }
  }

  goto(evt, id: number) {

    evt.preventDefault();

    this.states.push({ ...this.currentState });
    //localStorage.setItem('LIBRARY_STATES', JSON.stringify(this.states));


    this.currentState.currentPage = 1;
    this.currentState.searchTerm = '';
    this.parentId = id;
    this.getBreadCrumbs = true;
    this.getData();

  }
  sort() {
    this.currentState.reverse = !this.currentState.reverse;
    this.getData();
  }

  // details(id: number) {
  //   this.currentPage = 1;
  //   this.parentId = id;
  //   this.searchTerm = '';
  //   this.getData();
  // }

  first() {
    this.currentState.currentPage = 1;
    this.getData();
  }

  prev() {
    if (this.currentState.currentPage > 1) {
      this.currentState.currentPage--;
    }
    this.getData();
  }

  next() {
    if (this.currentState.currentPage < this.result.totalPages) {
      this.currentState.currentPage++;
    }
    this.getData();
  }

  last() {
    this.currentState.currentPage = this.result.totalPages;
    this.getData();
  }

  search() {
    this.currentState.currentPage = 1;
    this.getData();
  }

  listingType(event) {
    this.currentState.currentPage = 1;
    this.getData();
  }

  back(id: number) {
    //localStorage.setItem('LIBRARY_HISTORY', 'true');

    this.currentState = this.states.pop();
    //this.breadCrumbs.pop();
    this.getBreadCrumbs = true;

    if (!this.currentState) {
      this.currentState = {
        currentPage: 1,
        reverse: false,
        listingTypeId: 1,
        perPage: 10,
        searchTerm: ''
      };
    }
    this.parentId = id;


    // if (id != null) {
    //   window.location.hash = id.toString();

    //   //this.router.navigateByUrl('/dashboard/library/' + id.toString());
    // }
    // else {
    //   window.location.hash = '';
    //   //this.router.navigateByUrl('/dashboard/library/');
    // }

    this.getData();
  }


  addItem() {


    this.currentListing = null;
    this.add = true;
    this.edit = false;
    this.editorData = '';
    this.editorDataFull = '';

    this.mainForm.reset();

    if (!this.parentId) {
      this.mainForm.controls.listingTypes.setValidators([Validators.required]);
      this.mainForm.controls.exclusiveCompany.enable();
      this.mainForm.controls.sharedCompanies.disable();
    }
    else {
      this.mainForm.controls.listingTypes.setValidators([]);

      let selectedSharedCompanies: Array<number> = [];
      this.result.item.sharedCompanies.forEach(i => { selectedSharedCompanies.push(i.id) });

      this.mainForm.patchValue({
        sharedCompanies: selectedSharedCompanies,
        exclusiveCompany: this.result.item.exclusiveCompany ? this.result.item.exclusiveCompany.id : null
      });

      this.mainForm.controls.exclusiveCompany.disable();
      this.mainForm.controls.sharedCompanies.disable();
    }







  }

  editItem(listing: ListingItemFull) {

    //this.spinner.startBackground();
    //this.libraryApi.GetById(listing.id).subscribe(data => {

    this.currentListing = listing;


    if (!this.currentListing.parentListingId) {
      this.mainForm.controls.listingTypes.setValidators([Validators.required]);
      this.mainForm.controls.exclusiveCompany.enable();
    }
    else {
      this.mainForm.controls.listingTypes.setValidators([]);
      this.mainForm.controls.exclusiveCompany.disable();
    }

    this.mainForm.controls.sharedCompanies.disable();

    let selectedListingTypeIds: Array<number> = [];
    this.currentListing.listingTypes.forEach(i => { selectedListingTypeIds.push(i.id) });

    let selectedSharedCompanies: Array<number> = [];
    this.currentListing.sharedCompanies.forEach(i => { selectedSharedCompanies.push(i.id) });

    // let selectedDocumentIds: Array<number> = [];
    // this.currentListing.documents.forEach(i => { selectedDocumentIds.push(i.id) });

    this.mainForm.patchValue({
      title: this.currentListing.title,
      description: this.currentListing.description,
      //content: this.currentListing.content,
      order: this.currentListing.order,
      //parentId: this.currentListing.parentListingId,
      listingTypes: selectedListingTypeIds,
      documents: this.currentListing.documents,
      sharedCompanies: selectedSharedCompanies,
      exclusiveCompany: this.currentListing.exclusiveCompany ? this.currentListing.exclusiveCompany.id : null
    });

    if (this.currentListing.content) {
      this.editorData = this.currentListing.content;
      this.editorDataFull = this.currentListing.content;
    }
    else {
      this.editorData = '';
      this.editorDataFull = '';
    }

    this.add = false;
    this.edit = true;

    //this.spinner.stopBackground();
    //});

  }

  cancel() {
    this.add = false;
    this.edit = false;
  }

  submitted = false;

  submit() {

    this.submitted = true;

    if (this.mainForm.invalid) {
      return;
    }

    this.spinner.startBackground();


    if (this.add) {
      this.currentListing = <ListingItemFull>{};
      this.currentListing.parentListingId = this.parentId;
    }

    let l = null;

    if (this.mainForm.controls.listingTypes.value) {
      l = this.listingTypes.filter(c => this.mainForm.controls.listingTypes.value.indexOf(c.id) >= 0);
    }

    let s = null;

    if (this.mainForm.controls.sharedCompanies.value) {
      s = this.companies.filter(c => this.mainForm.controls.sharedCompanies.value.indexOf(c.id) >= 0);
    }

    let e = null;

    if (this.mainForm.controls.exclusiveCompany.value) {
      e = this.companies.find(c => c.id == this.mainForm.controls.exclusiveCompany.value);
    }

    this.currentListing.title = this.mainForm.controls.title.value;
    this.currentListing.description = this.mainForm.controls.description.value;
    //this.currentListing.content = this.mainForm.controls.content.value;
    this.currentListing.content = this.editorData;
    this.currentListing.order = this.mainForm.controls.order.value;
    this.currentListing.listingTypes = l;
    this.currentListing.documents = this.mainForm.controls.documents.value;
    this.currentListing.sharedCompanies = s;
    this.currentListing.exclusiveCompany = e;

    //return;

    if (this.edit) {
      this.libraryApi.Update(this.currentListing.id, this.currentListing).subscribe((r) => {
        this.add = false;
        this.edit = false;
        this.cache.clear();
        this.getData();
      });
    }

    else if (this.add) {
      this.libraryApi.Add(this.currentListing).subscribe((r) => {
        this.add = false;
        this.edit = false;
        this.cache.clear();
        this.getData();
      });
    }
  }



  searchDocuments(term: string) {
    return this.documentApi.ListDocuments(1, 50, term).pipe(
      map((r) => {
        return r.items;
      })
    );
  }

  private loadDocuments() {
    this.documents$ = concat(
      of([]), // default items
      this.documentsInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.documentsLoading = true),
        switchMap(term => this.searchDocuments(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.documentsLoading = false)
        ))
      )
    );
  }

  onEditorReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader);
    };
  }

  lastData = '';

//   onChange({ editor }: ChangeEvent) {
//     //this.editorDataFull = ev.editor.getData();
//     //const domEditableElement = document.querySelector('.editorFull .ck-editor__editable');

//     // Get the editor instance from the editable element.
//     //const editorInstance = domEditableElement['ckeditorInstance'];

//     // Now you can use the editor instance API.
//     //editorInstance.setData(ev.editor.getData());
// debugger;
//     this.editorDataFull = editor.getData();
//     this.editorData = editor.getData();
//   }

//   onChangeFull(ev) {
//     //this.editorData = ev.editor.getData();

//     const domEditableElement = document.querySelector('.editor .ck-editor__editable');

//     // Get the editor instance from the editable element.
//     const editorInstance = domEditableElement['ckeditorInstance'];

//     // Now you can use the editor instance API.
//     editorInstance.setData(ev.editor.getData());

//   }

  //onEditorChange({ editor }: ChangeEvent) {
  //this.editorData = editor.getData();
  //}


  copyToClipboard() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (window.location.href));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.alert.success("Link copied to clipboard");
  }

  archive(id: number) {
    if (!window.confirm("Are you sure you want to archive this item and all its children?")) {
      return;
    }

    this.spinner.startBackground();

    this.libraryApi.Archive(id).subscribe(() => {
      this.cache.clear();
      this.getData();
    });
  }

  perPageChange(event) {
    localStorage["PER_PAGE"] = this.currentState.perPage;
    this.currentState.currentPage = 1;
    this.getData();
  }

  selectListingType(ev: any, id: number) {
    ev.preventDefault();
    this.currentState.listingTypeId = id;
    this.getData();
  }



  public full = false;
  fullscreen() {
    this.full = true;

    this.editorDataFull = this.editorData;
    
    //let data = this.editorComponent.editorInstance.getData();
    //this.editorFullComponent.editorInstance.setData(data);
  }

  closeModal() {
    this.full = false;

    this.editorData = this.editorDataFull;
    //let data = this.editorFullComponent.editorInstance.getData();
    //this.editorComponent.editorInstance.setData(data);
  }
}


export class UploadAdapter {
  private loader;
  constructor(loader: any) {
    this.loader = loader;
  }

  public upload(): Promise<any> {
    //"data:image/png;base64,"+ btoa(binaryString) 
    return this.readThis(this.loader.file);
  }

  readThis(file: any): Promise<any> {
    let imagePromise: Promise<any> = new Promise((resolve, reject) => {
      var myReader: FileReader = new FileReader();
      myReader.onloadend = (e) => {
        let image = myReader.result;
        resolve({ default: image });
      }
      file.then(data => {
        myReader.readAsDataURL(data);
      });

    });
    return imagePromise;
  }

}

// export class UploadAdapter {
//   private loader;
//   constructor(loader: any) {
//     this.loader = loader;
//     // this.loader.file.then(data => {
//     //   return this.readThis(data);
//     // });
//   }

//   public upload() {//: Promise<any> {
//     //"data:image/png;base64,"+ btoa(binaryString)
//     this.loader.file.then(data => {
//       return this.readThis(data);
//     });
//     //return this.readThis(this.loader.file);
//   }

//   readThis(file: File): Promise<any> {
//     let imagePromise: Promise<any> = new Promise((resolve, reject) => {
//       var myReader: FileReader = new FileReader();
//       myReader.onloadend = (e) => {
//         let image = myReader.result;
//         return { default: "data:image/png;base64," + image };
//         resolve();
//       }
//       myReader.readAsDataURL(file);
//     });
//     return imagePromise;
//   }


// }