import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company';
import { CompanyLearningModule } from 'src/app/models/companyLearningModule';
import { CompanyApiService } from '../../../../services/company-api.service';
import { SpinnerService } from '../../../../services/spinner.service';

@Component({
  selector: 'app-elearning-admin',
  templateUrl: './elearning-admin.component.html',
  styleUrls: ['./elearning-admin.component.scss']
})
export class ELearningAdminComponent implements OnInit {

  company: Company;
  companyLearningModules: CompanyLearningModule[]

  constructor(private companyApi: CompanyApiService, private spinner: SpinnerService) { }

  ngOnInit() {
  }

  companySelected(company) {
    this.company = company;
    if (company) {
      this.companyApi.GetCompanyLearningModules(company.id).subscribe(data => {
        this.companyLearningModules = data;
      });
    }
    else {
      this.companyLearningModules = null;
    }
  }

  assign(m: CompanyLearningModule) {
    this.spinner.startBackground();
    this.companyApi.AssignCompanyLearningModule(m).subscribe(() => {
      m.assigned = true;
      this.spinner.stopBackground();
    });
  }

  unassign(m: CompanyLearningModule) {
    this.spinner.startBackground();
    this.companyApi.UnassignCompanyLearningModule(m).subscribe(() => {
      m.assigned = false;
      this.spinner.stopBackground();
    });
  }
}
