<ng-container *ngIf="visible && overlayImage">
  <button class="btn btn-link" id="dropdownNotificationsButton" (click)="openModal(overlayModal)" [disabled]="!overlayImage">
    <span class="icon icon-info-blue"></span>
  </button>


  <ng-template #overlayModal let-modal>
    <div class="modal-header">
      
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body ">
      
      <div>
        <img [src]="overlayImage" class="img-fluid overlay-img">
      </div>
    </div>
  </ng-template>
</ng-container>