import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyApiService } from '../../services/company-api.service';
import { SpinnerService } from '../../services/spinner.service';
import { AlertService } from '../../services/alert.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ContractsApiService } from '../../services/contracts-api.service';
import { LookupApiService } from '../../services/lookup-api.service';
import { Company } from 'src/app/models/company';
import { Contract } from 'src/app/models/contract';
import { ContractDataField } from 'src/app/models/contractDataField';
import { ContractTemplate } from 'src/app/models/contractTemplate';
import { ContractType } from 'src/app/models/contractType';

@Component({
  selector: 'app-request-contract',
  templateUrl: './request-contract.component.html',
  styleUrls: ['./request-contract.component.scss']
})
export class RequestContractComponent implements OnInit {

  company: Company;
  contractTemplates: ContractTemplate[];
  contractTypes: ContractType[];

  requestForm = this.formBuilder.group({
    title: ['', Validators.required],
    name: ['', Validators.required],
    surname: ['', Validators.required],
    email: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ]],
    requiredDocument: [null, Validators.required],
    companyName: ['', Validators.required],
    companyRegistrationNumber: ['', Validators.required],
    companyPhysicalAddress: ['', Validators.required],
    companyPostalAddress: ['', Validators.required],
    representativeName: ['', Validators.required],
    representativeSurname: ['', Validators.required],
    representativeTitle: ['', Validators.required],
    representativeEmail: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ]],
    effectiveDate: ['', Validators.required],
    terminationDate: ['', Validators.required]
  });

  constructor(
    private route: ActivatedRoute,
    private companyApi: CompanyApiService,
    private spinner: SpinnerService,
    private alert: AlertService,
    private formBuilder: UntypedFormBuilder,
    private contractsApi: ContractsApiService,
    private lookupApi: LookupApiService,
  ) { }

  ngOnInit() {
    this.spinner.start();

    this.contractsApi.GetContractTypes().subscribe(data => {
      this.contractTypes = data;
    });


    this.route.paramMap.subscribe(data => {
      var slug = data.get('id');
      this.companyApi.GetBySlug(slug).subscribe(data => {
        this.company = data;
        if (!this.company) {
          this.alert.error('Invalid Url');
          this.spinner.stop();
        }
        else {
          this.contractsApi.ListContractTemplatesFull(this.company.id).subscribe(data => {
            this.contractTemplates = data;
            this.spinner.stop();
          });
        }
      }, error => {
        this.alert.error('Invalid Url');
        this.spinner.stop();
      });
    });
  }

  submit() {

    this.spinner.start();

    let contractTemplate = this.contractTemplates.find(f => f.id == this.requestForm.controls.requiredDocument.value);
    let dataFields: ContractDataField[] = new Array();

    dataFields.push({ name: 'CompanyName', value: this.requestForm.controls.companyName.value });
    dataFields.push({ name: 'CompanyRegistration', value: this.requestForm.controls.companyRegistration.value });
    dataFields.push({ name: 'CompanyVAT', value: this.requestForm.controls.companyVAT.value });
    dataFields.push({ name: 'Name', value: this.requestForm.controls.name.value });
    dataFields.push({ name: 'Surname', value: this.requestForm.controls.surname.value });
    dataFields.push({ name: 'Email', value: this.requestForm.controls.email.value });
    dataFields.push({ name: 'CounterpartyName', value: this.requestForm.controls.counterpartyName.value });
    dataFields.push({ name: 'CounterpartyRegistrationNumber', value: this.requestForm.controls.counterpartyRegistrationNumber.value });
    dataFields.push({ name: 'CounterpartyPhysicalAddress', value: this.requestForm.controls.counterpartyPhysicalAddress.value });
    dataFields.push({ name: 'CounterpartyPostalAddress', value: this.requestForm.controls.counterpartyPostalAddress.value });
    dataFields.push({ name: 'RepresentativeName', value: this.requestForm.controls.representativeName.value });
    dataFields.push({ name: 'RepresentativeSurname', value: this.requestForm.controls.representativeSurname.value });
    dataFields.push({ name: 'RepresentativeTitle', value: this.requestForm.controls.representativeTitle.value });
    dataFields.push({ name: 'RepresentativeEmail', value: this.requestForm.controls.representativeEmail.value });
    dataFields.push({ name: 'EffectiveDate', value: this.requestForm.controls.effectiveDate.value });
    dataFields.push({ name: 'TerminationDate', value: this.requestForm.controls.terminationDate.value });

    let request: Contract = {
      title: this.requestForm.controls.title.value,
      contractTemplate: contractTemplate,
      company: this.company,
      contractDataFields: dataFields,
      contractType: this.contractTypes[0]
    };

    this.contractsApi.AddContractRequest(request).subscribe(data => {
      this.alert.success(`Your request has been submitted. Your reference is: ${data.referenceNumber}`);
      this.requestForm.reset();
      this.spinner.stop();
    }, (error) => {
      this.alert.error('An error occurred while submitting your request, please try again.');
      this.spinner.stop();
    });
  }

}
