import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  superAdmin: boolean = false;
  orgAdmin: boolean = false;
  companyAdmin: boolean = false;
  contractAdmin: boolean = false;
  user: boolean = false;
  libraryUser: boolean = false;
  menuOpen: boolean = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    private token: TokenService
  ) {
    
  }

  ngOnInit() {
    this.superAdmin = this.token.isSuperAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.user = this.token.isUser();
    this.libraryUser = this.token.isLibraryUser();
    this.contractAdmin = this.token.isContractAdmin();
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  logout() {
    this.auth.logout();
    this.router.navigateByUrl('/home');
  }

}
