<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.apiCompanyId">

    <div class="card mb-3" *ngIf="currentDocumentId != 0">
        <div class="card-body">
            <h4 class="card-title">Workflow</h4>
            <div class="mb-3 d-flex justify-content-between align-items-center">
                Correspondence
                <button class="btn btn-primary" (click)="sendCorrespondence(content)">Send</button>
            </div>
            <hr />
            <div class="mb-3 d-flex justify-content-between align-items-center">
                History
                <div>

                    <button class="btn btn-primary me-2" (click)="viewCorrespondence()">View</button>
                    <button class="btn btn-primary" (click)="exportCorrespondence()">Export</button>
                </div>


            </div>
            <div *ngIf="showCorrespondence">

                <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                    <tr>
                        <th>DATE/TIME</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>SENDER</th>
                        <th>TYPE</th>
                        <th>DOCUMENT</th>
                        <th>ATTACHMENTS</th>
                    </tr>
                    <tr *ngFor="let item of correspondence">
                        <td>{{item.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.sender}}</td>
                        <td>{{item.documentCorrespondenceType}}</td>

                        <td class="text-center"><a href="#" (click)="download($event, item.id)"
                                class="d-flex align-items-center"><span class="icon icon-view-red me-2"></span></a></td>
                        <td class="text-center">
                            <div class="d-flex">
                                <a href="#" *ngFor="let attachment of item.attachments"
                                    (click)="downloadAttachment($event, attachment.id, item.id)"
                                    class="d-flex align-items-center">
                                    <span class="icon icon-view-red me-1"></span>
                                </a>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>

    <form [formGroup]="mainForm" (ngSubmit)="submit()">

        <div class="card mb-3" *ngIf="currentDocumentId == 0">
            <div class="card-body">
                <h4 class="card-title">Request to FICA or carry out a Due Diligence</h4>




                <div class="mb-3">
                    <label for="documentFileData" class="form-label"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid">Request
                    </label>
                    <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                        (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                        <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                    </div>


                </div>



                <div class="mb-3">
                    Due diligence for Clients (involves a thorough investigation and analysis to ensure that the
                    institution understands the client’s financial status, regulatory compliance, and potential risks.
                    <br />
                    Please complete the fields below to indicate that a thorough detailed due diligence for FIC has been
                    carried out:

                </div>

            </div>
        </div>

        <div class="card mb-3" *ngIf="currentDocumentId > 0">
            <div class="card-body">
                <h4 class="card-title">Request to FICA or carry out a Due Diligence</h4>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="documentFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid">Update
                            Request</label>
                    </div>

                    <div *ngIf="currentDocument?.documentFilePath" class="mb-2">



                        <div class="d-flex align-items-center">
                            <button *ngIf="currentDocument.documentFilePath" type="button"
                                (click)="downloadDocumentFile($event, 'Document')"
                                class="btn btn-link d-flex justify-content-between align-items-center me-2"><span
                                    class="icon icon-view-red"></span>&nbsp;DOWNLOAD CURRENT</button>
                            <button type="button" class="btn btn-primary me-2"
                                (click)="showHistory = !showHistory; showDocumentUpload = false;">{{showHistory
                                ? 'HIDE' : 'SHOW'}} History</button>
                            <button *ngIf="!showDocumentUpload" type="button" class="btn btn-primary"
                                (click)="showDocumentUpload = true; showHistory = false;">Upload new</button>
                            <button *ngIf="showDocumentUpload" type="button" class=" btn btn-primary"
                                (click)="showDocumentUpload = false;">Cancel</button>

                        </div>
                        <div *ngIf="showHistory" class="mb-2">
                            <button *ngFor="let doc of currentDocument.documentHistory" type="button"
                                (click)="downloadDocumentHistoryFile($event, doc.id)"
                                class="btn btn-link d-flex justify-content-between align-items-center"><span
                                    class="icon icon-view-red"></span>&nbsp;{{doc.createdAt | date:'yyyy-MM-dd HH:mm:ss'
                                }}</button>
                        </div>

                        <div class="mb-2">

                        </div>

                        <div *ngIf="!currentDocument?.documentFilePath || showDocumentUpload" class="mb-2">
                            <input type="file" class="form-control" id="documentFileData"
                                formControlName="documentFileData"
                                [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                                (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                                <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="mb-3">
                    Due diligence for Clients - involves a thorough investigation and analysis to ensure that the
                    institution understands the client’s financial status, regulatory compliance, and potential risks.
                    <br />
                    Please complete the fields below to indicate that a thorough detailed due diligence for FIC has been
                    carried out:

                </div>

            </div>
        </div>



        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Accountable recipient of the request</h4>

                <div class="mb-3">
                    <label for="recipientFirstname" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientFirstname.touched && mainForm.controls.recipientFirstname.invalid">First
                        Name</label>
                    <input type="text" class="form-control" id="recipientFirstname" formControlName="recipientFirstname"
                        [class.is-invalid]="mainForm.controls.recipientFirstname.touched && mainForm.controls.recipientFirstname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientFirstname.errors">
                        <div *ngIf="mainForm.controls.recipientFirstname.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="recipientLastname" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientLastname.touched && mainForm.controls.recipientLastname.invalid">Last
                        Name</label>
                    <input type="text" class="form-control" id="recipientLastname" formControlName="recipientLastname"
                        [class.is-invalid]="mainForm.controls.recipientLastname.touched && mainForm.controls.recipientLastname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientLastname.errors">
                        <div *ngIf="mainForm.controls.recipientLastname.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="recipientContactNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientContactNumber.touched && mainForm.controls.recipientContactNumber.invalid">Contact
                        Number</label>
                    <input type="text" class="form-control" id="recipientContactNumber"
                        formControlName="recipientContactNumber"
                        [class.is-invalid]="mainForm.controls.recipientContactNumber.touched && mainForm.controls.recipientContactNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientContactNumber.errors">
                        <div *ngIf="mainForm.controls.recipientContactNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="recipientEmail" class="form-label"
                        [class.is-invalid]="mainForm.controls.recipientEmail.touched && mainForm.controls.recipientEmail.invalid">Email</label>
                    <input type="text" class="form-control" id="recipientEmail" formControlName="recipientEmail"
                        [class.is-invalid]="mainForm.controls.recipientEmail.touched && mainForm.controls.recipientEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.recipientEmail.errors">
                        <div *ngIf="mainForm.controls.recipientEmail.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.recipientEmail.errors.email">Invalid</div>
                    </div>
                </div>

            </div>
        </div>

        <!-- <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Applicable facility or department</h4>

                <div class="mb-3">
                    <label for="documentRegionId" class="form-label">Region</label>
                    <ng-select [items]="regions" bindLabel="name" bindValue="id" id="documentRegionId"
                        formControlName="documentRegionId" placeholder="Select a region" [clearable]="false"
                        [class.is-invalid]="mainForm.controls.documentRegionId.touched && mainForm.controls.documentRegionId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentRegionId.errors">
                        <div *ngIf="mainForm.controls.documentRegionId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="documentFacilityId" class="form-label">Facility</label>
                    <ng-select [items]="facilities" bindLabel="name" bindValue="id" id="documentFacilityId"
                        formControlName="documentFacilityId" placeholder="Select a facility" [clearable]="false"
                        [readonly]="facilities == undefined"
                        [class.is-invalid]="mainForm.controls.documentFacilityId.touched && mainForm.controls.documentFacilityId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFacilityId.errors">
                        <div *ngIf="mainForm.controls.documentFacilityId.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div> -->

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Request Details</h4>


                <div class="mb-3">
                    <label for="receivedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.receivedDate.touched && mainForm.controls.receivedDate.invalid">Date
                        Request was Received</label>
                    <input type="date" class="form-control" id="receivedDate" formControlName="receivedDate"
                        [class.is-invalid]="mainForm.controls.receivedDate.touched && mainForm.controls.receivedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.receivedDate.errors">
                        <div *ngIf="mainForm.controls.receivedDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="acknowledgedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.acknowledgedDate.touched && mainForm.controls.acknowledgedDate.invalid">Date
                        Request was Acknowledged</label>
                    <input type="date" class="form-control" id="acknowledgedDate" formControlName="acknowledgedDate"
                        [class.is-invalid]="mainForm.controls.acknowledgedDate.touched && mainForm.controls.acknowledgedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.acknowledgedDate.errors">
                        <div *ngIf="mainForm.controls.acknowledgedDate.errors.required">Required</div>
                    </div>
                </div>

                <h5>Requestor</h5>

                <div class="mb-3">
                    <label for="requestorIDNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestorIDNumber.touched && mainForm.controls.requestorIDNumber.invalid">ID
                        Number / Company Registration Number</label>
                    <input type="text" class="form-control" id="requestorIDNumber" formControlName="requestorIDNumber"
                        [class.is-invalid]="mainForm.controls.requestorIDNumber.touched && mainForm.controls.requestorIDNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorIDNumber.errors">
                        <div *ngIf="mainForm.controls.requestorIDNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorName" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestorName.touched && mainForm.controls.requestorName.invalid">Name
                        & Surname / Company Name</label>
                    <input type="text" class="form-control" id="requestorName" formControlName="requestorName"
                        [class.is-invalid]="mainForm.controls.requestorName.touched && mainForm.controls.requestorName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorName.errors">
                        <div *ngIf="mainForm.controls.requestorName.errors.required">Required</div>
                    </div>
                </div>


                <hr />

                <h5>Client Identification and Verification - Know Your Customer (KYC)</h5>

                <p>Please complete the below and confirm that you have collected and verified essential information
                    about the client, including the legal name, business structure, ownership, and control persons and
                    that you have completed the Identity Verification: Use official documents like passports, business
                    licenses, or registration certificates to confirm identities and uploaded those in the last box at
                    the end of this document. </p>
                <div class="mb-3">
                    <label for="clientIDNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientIDNumber.touched && mainForm.controls.clientIDNumber.invalid">ID
                        Number</label>
                    <input type="text" class="form-control" id="clientIDNumber" formControlName="clientIDNumber"
                        [class.is-invalid]="mainForm.controls.clientIDNumber.touched && mainForm.controls.clientIDNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientIDNumber.errors">
                        <div *ngIf="mainForm.controls.clientIDNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="clientFirstname" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientFirstname.touched && mainForm.controls.clientFirstname.invalid">First
                        Name</label>
                    <input type="text" class="form-control" id="clientFirstname" formControlName="clientFirstname"
                        [class.is-invalid]="mainForm.controls.clientFirstname.touched && mainForm.controls.clientFirstname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientFirstname.errors">
                        <div *ngIf="mainForm.controls.clientFirstname.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="clientLastname" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientLastname.touched && mainForm.controls.clientLastname.invalid">Last
                        Name</label>
                    <input type="text" class="form-control" id="clientLastname" formControlName="clientLastname"
                        [class.is-invalid]="mainForm.controls.clientLastname.touched && mainForm.controls.clientLastname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientLastname.errors">
                        <div *ngIf="mainForm.controls.clientLastname.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="clientCompanyRegistrationNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientCompanyRegistrationNumber.touched && mainForm.controls.clientCompanyRegistrationNumber.invalid">Company
                        Registration
                        Number</label>
                    <input type="text" class="form-control" id="clientCompanyRegistrationNumber"
                        formControlName="clientCompanyRegistrationNumber"
                        [class.is-invalid]="mainForm.controls.clientCompanyRegistrationNumber.touched && mainForm.controls.clientCompanyRegistrationNumber.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.clientCompanyRegistrationNumber.errors">
                        <div *ngIf="mainForm.controls.clientCompanyRegistrationNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="clientCompany" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientCompany.touched && mainForm.controls.clientCompany.invalid">Company
                        Name</label>
                    <input type="text" class="form-control" id="clientCompany" formControlName="clientCompany"
                        [class.is-invalid]="mainForm.controls.clientCompany.touched && mainForm.controls.clientCompany.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientCompany.errors">
                        <div *ngIf="mainForm.controls.clientCompany.errors.required">Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="clientAddress" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientAddress.touched && mainForm.controls.clientAddress.invalid">Address</label>
                    <textarea type="text" class="form-control" id="clientAddress" formControlName="clientAddress"
                        [class.is-invalid]="mainForm.controls.clientAddress.touched && mainForm.controls.clientAddress.invalid"></textarea>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientAddress.errors">
                        <div *ngIf="mainForm.controls.clientAddress.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="clientEmail" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientEmail.touched && mainForm.controls.clientEmail.invalid">Email</label>
                    <input type="text" class="form-control" id="clientEmail" formControlName="clientEmail"
                        [class.is-invalid]="mainForm.controls.clientEmail.touched && mainForm.controls.clientEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientEmail.errors">
                        <div *ngIf="mainForm.controls.clientEmail.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="clientContactNumber" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientContactNumber.touched && mainForm.controls.clientContactNumber.invalid">Contact
                        Number</label>
                    <input type="text" class="form-control" id="clientContactNumber"
                        formControlName="clientContactNumber"
                        [class.is-invalid]="mainForm.controls.clientContactNumber.touched && mainForm.controls.clientContactNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientContactNumber.errors">
                        <div *ngIf="mainForm.controls.clientContactNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="clientBusinessStructure" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientBusinessStructure.touched && mainForm.controls.clientBusinessStructure.invalid">Business
                        Structure</label>
                    <input type="text" class="form-control" id="clientBusinessStructure"
                        formControlName="clientBusinessStructure"
                        [class.is-invalid]="mainForm.controls.clientBusinessStructure.touched && mainForm.controls.clientBusinessStructure.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientBusinessStructure.errors">
                        <div *ngIf="mainForm.controls.clientBusinessStructure.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="clientOwnership" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientOwnership.touched && mainForm.controls.clientOwnership.invalid">Ownership</label>
                    <input type="text" class="form-control" id="clientOwnership" formControlName="clientOwnership"
                        [class.is-invalid]="mainForm.controls.clientOwnership.touched && mainForm.controls.clientOwnership.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientOwnership.errors">
                        <div *ngIf="mainForm.controls.clientOwnership.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="clientShareholder" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientShareholder.touched && mainForm.controls.clientShareholder.invalid">Shareholder</label>
                    <input type="text" class="form-control" id="clientShareholder" formControlName="clientShareholder"
                        [class.is-invalid]="mainForm.controls.clientShareholder.touched && mainForm.controls.clientShareholder.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientShareholder.errors">
                        <div *ngIf="mainForm.controls.clientShareholder.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="clientControlPersons" class="form-label"
                        [class.is-invalid]="mainForm.controls.clientControlPersons.touched && mainForm.controls.clientControlPersons.invalid">Control
                        Persons</label>
                    <input type="text" class="form-control" id="clientControlPersons"
                        formControlName="clientControlPersons"
                        [class.is-invalid]="mainForm.controls.clientControlPersons.touched && mainForm.controls.clientControlPersons.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.clientControlPersons.errors">
                        <div *ngIf="mainForm.controls.clientControlPersons.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="clientVerificationFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.clientVerificationFileData.touched && mainForm.controls.clientVerificationFileData.invalid">Confirm
                            Identity Upload (Passport, Business License, Registration Certificate, etc)</label>

                    </div>
                    <div *ngIf="currentDocument?.clientVerificationFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.clientVerificationFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'ClientVerification')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showClientVerificationUpload" type="button" class="btn btn-primary"
                            (click)="showClientVerificationUpload = true;">Upload new</button>
                        <button *ngIf="showClientVerificationUpload" type="button" class=" btn btn-primary"
                            (click)="showClientVerificationUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.clientVerificationFilePath || showClientVerificationUpload">
                        <input type="file" class="form-control" id="clientVerificationFileData"
                            formControlName="clientVerificationFileData"
                            (change)="fileUpload(mainForm.controls.clientVerificationFileData, $event)"
                            [class.is-invalid]="mainForm.controls.clientVerificationFileData.touched && mainForm.controls.clientVerificationFileData.invalid">
                        <div class="invalid-feedback text-end"
                            *ngIf="mainForm.controls.clientVerificationFileData.errors">
                            <div *ngIf="mainForm.controls.clientVerificationFileData.errors.required">Required</div>
                        </div>

                    </div>
                </div>

            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Owners and Directors</h4>

                <ng-container formArrayName="ficaOwnerDirectors">
                    <ng-container
                        *ngFor="let ownerDirectorsForm of mainForm.controls.ficaOwnerDirectors.controls; let i = index">

                        <div [formGroup]="ownerDirectorsForm" class="mb-2">
                            <h5>Party {{i + 1}}</h5>
                            <input type="hidden" formControlName="id" id="id">
                            <div class="mb-3">
                                <label for="name" class="form-label">Name</label>
                                <input type="text" class="form-control" id="name" formControlName="name"
                                    [class.is-invalid]="ownerDirectorsForm.controls.name.touched && ownerDirectorsForm.controls.name.invalid">
                                <div class="invalid-feedback text-end" *ngIf="ownerDirectorsForm.controls.name.errors">
                                    <div *ngIf="ownerDirectorsForm.controls.name.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="surname" class="form-label">Surname</label>
                                <input type="text" class="form-control" id="surname" formControlName="surname"
                                    [class.is-invalid]="ownerDirectorsForm.controls.surname.touched && ownerDirectorsForm.controls.surname.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="ownerDirectorsForm.controls.surname.errors">
                                    <div *ngIf="ownerDirectorsForm.controls.surname.errors.required">Required
                                    </div>
                                </div>
                            </div>


                            <div class="mb-3">
                                <label for="identityNumber" class="form-label">Identity Number</label>
                                <input type="text" class="form-control" id="identityNumber"
                                    formControlName="identityNumber"
                                    [class.is-invalid]="ownerDirectorsForm.controls.identityNumber.touched && ownerDirectorsForm.controls.identityNumber.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="ownerDirectorsForm.controls.identityNumber.errors">
                                    <div *ngIf="ownerDirectorsForm.controls.identityNumber.errors.required">Required
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="address" class="form-label">Address</label>
                                <input type="text" class="form-control" id="address" formControlName="address"
                                    [class.is-invalid]="ownerDirectorsForm.controls.address.touched && ownerDirectorsForm.controls.address.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="ownerDirectorsForm.controls.address.errors">
                                    <div *ngIf="ownerDirectorsForm.controls.address.errors.required">Required</div>
                                </div>
                            </div>



                            <div class="text-end" *ngIf="mainForm.controls.ficaOwnerDirectors.length > 1">
                                <button type="button" class="btn btn-default"
                                    (click)="removeOwnerDirector(i)">Remove</button>&nbsp;

                            </div>

                        </div>
                        <hr />
                    </ng-container>
                </ng-container>
                <div class="text-end">
                    <button type="button" class="btn btn-default" (click)="addOwnerDirector()">Add</button>&nbsp;

                </div>
            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Details of Transaction</h4>



                <div class="mb-3">
                    <label for="requestType" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestType.touched && mainForm.controls.requestType.invalid">Type
                        of request</label>
                    <input type="text" class="form-control" id="requestType" formControlName="requestType"
                        [class.is-invalid]="mainForm.controls.requestType.touched && mainForm.controls.requestType.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestType.errors">
                        <div *ngIf="mainForm.controls.requestType.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestDetails" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestDetails.touched && mainForm.controls.requestDetails.invalid">Describe
                        what information and records are
                        required</label>
                    <textarea type="text" class="form-control" id="requestDetails" formControlName="requestDetails"
                        [class.is-invalid]="mainForm.controls.requestDetails.touched && mainForm.controls.requestDetails.invalid"></textarea>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestDetails.errors">
                        <div *ngIf="mainForm.controls.requestDetails.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestReason" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestReason.touched && mainForm.controls.requestReason.invalid">Describe
                        the reasons why the transaction is taking place</label>
                    <textarea type="text" class="form-control" id="requestReason" formControlName="requestReason"
                        [class.is-invalid]="mainForm.controls.requestReason.touched && mainForm.controls.requestReason.invalid"></textarea>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestReason.errors">
                        <div *ngIf="mainForm.controls.requestReason.errors.required">Required</div>
                    </div>
                </div>

                <hr />

                <div class="mb-3">
                    <label for="requestDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.requestDate.touched && mainForm.controls.requestDate.invalid">Date
                        of Transaction which the request relates </label>
                    <input type="date" class="form-control" id="requestDate" formControlName="requestDate"
                        [class.is-invalid]="mainForm.controls.requestDate.touched && mainForm.controls.requestDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestDate.errors">
                        <div *ngIf="mainForm.controls.requestDate.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Financial Analysis</h4>

                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmFinancialStatements.touched && mainForm.controls.confirmFinancialStatements.invalid"><b>Financial
                            Statements Review:</b> Please confirm that you have Analyzed audited financial statements
                        (balance sheet, income statement, cash flow statement) for at least the last three years of the
                        persons and or entities:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmFinancialStatements"
                                id="confirmFinancialStatementsYes" [value]="true"
                                formControlName="confirmFinancialStatements"
                                [class.is-invalid]="mainForm.controls.confirmFinancialStatements.touched && mainForm.controls.confirmFinancialStatements.invalid">
                            <label class="form-check-label" for="confirmFinancialStatementsYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmFinancialStatements"
                                id="confirmFinancialStatementsNo" [value]="false"
                                formControlName="confirmFinancialStatements"
                                [class.is-invalid]="mainForm.controls.confirmFinancialStatements.touched && mainForm.controls.confirmFinancialStatements.invalid">
                            <label class="form-check-label" for="confirmFinancialStatementsNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmFinancialStatements.errors">
                        <div *ngIf="mainForm.controls.confirmFinancialStatements.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmFinancialStatementsDetails" class="form-label">Results of review of audited financial statements (balance sheet, income statement, cash flow statement) for at least the last three years of the persons and or entities</label>
                    <input type="text" class="form-control" id="confirmFinancialStatementsDetails"
                        formControlName="confirmFinancialStatementsDetails"
                        [class.is-invalid]="mainForm.controls.confirmFinancialStatementsDetails.touched && mainForm.controls.confirmFinancialStatementsDetails.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.confirmFinancialStatementsDetails.errors">
                        <div *ngIf="mainForm.controls.confirmFinancialStatementsDetails.errors.required">
                            Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="confirmFinancialStatementsFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.confirmFinancialStatementsFileData.touched && mainForm.controls.confirmFinancialStatementsFileData.invalid">Financial statements Upload</label>

                    </div>
                    <div *ngIf="currentDocument?.confirmFinancialStatementsFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.confirmFinancialStatementsFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'FinancialStatements')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showClientVerificationUpload" type="button" class="btn btn-primary"
                            (click)="showClientVerificationUpload = true;">Upload new</button>
                        <button *ngIf="showClientVerificationUpload" type="button" class=" btn btn-primary"
                            (click)="showClientVerificationUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.confirmFinancialStatementsFilePath || showClientVerificationUpload">
                        <input type="file" class="form-control" id="confirmFinancialStatementsFileData"
                            formControlName="confirmFinancialStatementsFileData"
                            (change)="fileUpload(mainForm.controls.confirmFinancialStatementsFileData, $event)"
                            [class.is-invalid]="mainForm.controls.confirmFinancialStatementsFileData.touched && mainForm.controls.confirmFinancialStatementsFileData.invalid">
                        <div class="invalid-feedback text-end"
                            *ngIf="mainForm.controls.confirmFinancialStatementsFileData.errors">
                            <div *ngIf="mainForm.controls.confirmFinancialStatementsFileData.errors.required">Required</div>
                        </div>

                    </div>
                </div>


            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Credit History</h4>

                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmCreditHistory.touched && mainForm.controls.confirmCreditHistory.invalid"><b>Credit
                            History:</b> Please confirm that you have analyzed and checked client's credit reports and
                        history with credit bureaus and other financial institutions:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmCreditHistory"
                                id="confirmCreditHistoryYes" [value]="true" formControlName="confirmCreditHistory"
                                [class.is-invalid]="mainForm.controls.confirmCreditHistory.touched && mainForm.controls.confirmCreditHistory.invalid">
                            <label class="form-check-label" for="confirmCreditHistoryYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmCreditHistory"
                                id="confirmCreditHistoryNo" [value]="false" formControlName="confirmCreditHistory"
                                [class.is-invalid]="mainForm.controls.confirmCreditHistory.touched && mainForm.controls.confirmCreditHistory.invalid">
                            <label class="form-check-label" for="confirmCreditHistoryNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmCreditHistory.errors">
                        <div *ngIf="mainForm.controls.confirmCreditHistory.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmCreditHistoryDetails" class="form-label">Results of client's credit reports and
                        history with credit bureaus and other financial institutions:</label>
                    <input type="text" class="form-control" id="confirmCreditHistoryDetails"
                        formControlName="confirmCreditHistoryDetails"
                        [class.is-invalid]="mainForm.controls.confirmCreditHistoryDetails.touched && mainForm.controls.confirmCreditHistoryDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmCreditHistoryDetails.errors">
                        <div *ngIf="mainForm.controls.confirmCreditHistoryDetails.errors.required">
                            Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="confirmCreditHistoryFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.confirmCreditHistoryFileData.touched && mainForm.controls.confirmCreditHistoryFileData.invalid">Credit
                            Reports Upload</label>

                    </div>
                    <div *ngIf="currentDocument?.confirmCreditHistoryFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.confirmCreditHistoryFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'CreditHistory')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showClientVerificationUpload" type="button" class="btn btn-primary"
                            (click)="showClientVerificationUpload = true;">Upload new</button>
                        <button *ngIf="showClientVerificationUpload" type="button" class=" btn btn-primary"
                            (click)="showClientVerificationUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.confirmCreditHistoryFilePath || showClientVerificationUpload">
                        <input type="file" class="form-control" id="confirmCreditHistoryFileData"
                            formControlName="confirmCreditHistoryFileData"
                            (change)="fileUpload(mainForm.controls.confirmCreditHistoryFileData, $event)"
                            [class.is-invalid]="mainForm.controls.confirmCreditHistoryFileData.touched && mainForm.controls.confirmCreditHistoryFileData.invalid">
                        <div class="invalid-feedback text-end"
                            *ngIf="mainForm.controls.confirmCreditHistoryFileData.errors">
                            <div *ngIf="mainForm.controls.confirmCreditHistoryFileData.errors.required">Required</div>
                        </div>

                    </div>
                </div>


            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Profitability and Stability</h4>

                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmProfitabilityStability.touched && mainForm.controls.confirmProfitabilityStability.invalid"><b>Profitability
                            and Stability:</b> Please confirm that you have assessed the client's profitability,
                        liquidity, and financial stability through key financial ratios:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmProfitabilityStability"
                                id="confirmProfitabilityStabilityYes" [value]="true"
                                formControlName="confirmProfitabilityStability"
                                [class.is-invalid]="mainForm.controls.confirmProfitabilityStability.touched && mainForm.controls.confirmProfitabilityStability.invalid">
                            <label class="form-check-label" for="confirmProfitabilityStabilityYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmProfitabilityStability"
                                id="confirmProfitabilityStabilityNo" [value]="false"
                                formControlName="confirmProfitabilityStability"
                                [class.is-invalid]="mainForm.controls.confirmProfitabilityStability.touched && mainForm.controls.confirmProfitabilityStability.invalid">
                            <label class="form-check-label" for="confirmProfitabilityStabilityNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.confirmProfitabilityStability.errors">
                        <div *ngIf="mainForm.controls.confirmProfitabilityStability.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmProfitabilityStabilityDetails" class="form-label">Comments, status of client's
                        profitability, liquidity, and financial stability through key financial ratios:</label>
                    <input type="text" class="form-control" id="confirmProfitabilityStabilityDetails"
                        formControlName="confirmProfitabilityStabilityDetails"
                        [class.is-invalid]="mainForm.controls.confirmProfitabilityStabilityDetails.touched && mainForm.controls.confirmProfitabilityStabilityDetails.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.confirmProfitabilityStabilityDetails.errors">
                        <div *ngIf="mainForm.controls.confirmProfitabilityStabilityDetails.errors.required">
                            Required</div>
                    </div>
                </div>


            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Business Operations Review</h4>

                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmBusinessModel.touched && mainForm.controls.confirmBusinessModel.invalid"><b>Business
                            Model:</b> Please confirm that you have analyzed and checked client's business model,
                        revenue streams, and market position:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmBusinessModel"
                                id="confirmBusinessModelYes" [value]="true" formControlName="confirmBusinessModel"
                                [class.is-invalid]="mainForm.controls.confirmBusinessModel.touched && mainForm.controls.confirmBusinessModel.invalid">
                            <label class="form-check-label" for="confirmBusinessModelYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmBusinessModel"
                                id="confirmBusinessModelNo" [value]="false" formControlName="confirmBusinessModel"
                                [class.is-invalid]="mainForm.controls.confirmBusinessModel.touched && mainForm.controls.confirmBusinessModel.invalid">
                            <label class="form-check-label" for="confirmBusinessModelNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmBusinessModel.errors">
                        <div *ngIf="mainForm.controls.confirmBusinessModel.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmBusinessModelDetails" class="form-label">Business Model Comments:</label>
                    <input type="text" class="form-control" id="confirmBusinessModelDetails"
                        formControlName="confirmBusinessModelDetails"
                        [class.is-invalid]="mainForm.controls.confirmBusinessModelDetails.touched && mainForm.controls.confirmBusinessModelDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmBusinessModelDetails.errors">
                        <div *ngIf="mainForm.controls.confirmBusinessModelDetails.errors.required">
                            Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmOperationalEfficiency.touched && mainForm.controls.confirmOperationalEfficiency.invalid"><b>Operational
                            Efficiency:</b> Please confirm that you have evaluated the client’s operational processes,
                        supply chain, and management efficiency:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmOperationalEfficiency"
                                id="confirmOperationalEfficiencyYes" [value]="true"
                                formControlName="confirmOperationalEfficiency"
                                [class.is-invalid]="mainForm.controls.confirmOperationalEfficiency.touched && mainForm.controls.confirmOperationalEfficiency.invalid">
                            <label class="form-check-label" for="confirmOperationalEfficiencyYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmOperationalEfficiency"
                                id="confirmOperationalEfficiencyNo" [value]="false"
                                formControlName="confirmOperationalEfficiency"
                                [class.is-invalid]="mainForm.controls.confirmOperationalEfficiency.touched && mainForm.controls.confirmOperationalEfficiency.invalid">
                            <label class="form-check-label" for="confirmOperationalEfficiencyNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.confirmOperationalEfficiency.errors">
                        <div *ngIf="mainForm.controls.confirmOperationalEfficiency.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmOperationalEfficiencyDetails" class="form-label">Operational Efficiency
                        Comments:</label>
                    <input type="text" class="form-control" id="confirmOperationalEfficiencyDetails"
                        formControlName="confirmOperationalEfficiencyDetails"
                        [class.is-invalid]="mainForm.controls.confirmOperationalEfficiencyDetails.touched && mainForm.controls.confirmOperationalEfficiencyDetails.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.confirmOperationalEfficiencyDetails.errors">
                        <div *ngIf="mainForm.controls.confirmOperationalEfficiencyDetails.errors.required">
                            Required</div>
                    </div>
                </div>




                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmIndustryAnalysis.touched && mainForm.controls.confirmIndustryAnalysis.invalid"><b>Industry
                            Analysis:</b> Please confirm that you have analyzed the client's industry, including market
                        trends, competition, and regulatory environment:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmIndustryAnalysis"
                                id="confirmIndustryAnalysisYes" [value]="true" formControlName="confirmIndustryAnalysis"
                                [class.is-invalid]="mainForm.controls.confirmIndustryAnalysis.touched && mainForm.controls.confirmIndustryAnalysis.invalid">
                            <label class="form-check-label" for="confirmIndustryAnalysisYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmIndustryAnalysis"
                                id="confirmIndustryAnalysisNo" [value]="false" formControlName="confirmIndustryAnalysis"
                                [class.is-invalid]="mainForm.controls.confirmIndustryAnalysis.touched && mainForm.controls.confirmIndustryAnalysis.invalid">
                            <label class="form-check-label" for="confirmIndustryAnalysisNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmIndustryAnalysis.errors">
                        <div *ngIf="mainForm.controls.confirmIndustryAnalysis.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmIndustryAnalysisDetails" class="form-label">Industry Analysis
                        Comments:</label>
                    <input type="text" class="form-control" id="confirmIndustryAnalysisDetails"
                        formControlName="confirmIndustryAnalysisDetails"
                        [class.is-invalid]="mainForm.controls.confirmIndustryAnalysisDetails.touched && mainForm.controls.confirmIndustryAnalysisDetails.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.confirmIndustryAnalysisDetails.errors">
                        <div *ngIf="mainForm.controls.confirmIndustryAnalysisDetails.errors.required">
                            Required</div>
                    </div>
                </div>




            </div>
        </div>



        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Risk Assessment</h4>

                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmCreditRisk.touched && mainForm.controls.confirmCreditRisk.invalid"><b>Credit
                            Risk:</b> Please confirm that you have assessed , analyzed and checked the likelihood of the
                        client defaulting on their obligations:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmCreditRisk"
                                id="confirmCreditRiskYes" [value]="true" formControlName="confirmCreditRisk"
                                [class.is-invalid]="mainForm.controls.confirmCreditRisk.touched && mainForm.controls.confirmCreditRisk.invalid">
                            <label class="form-check-label" for="confirmCreditRiskYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmCreditRisk"
                                id="confirmCreditRiskNo" [value]="false" formControlName="confirmCreditRisk"
                                [class.is-invalid]="mainForm.controls.confirmCreditRisk.touched && mainForm.controls.confirmCreditRisk.invalid">
                            <label class="form-check-label" for="confirmCreditRiskNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmCreditRisk.errors">
                        <div *ngIf="mainForm.controls.confirmCreditRisk.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmCreditRiskDetails" class="form-label">Credit Risk Comments:</label>
                    <input type="text" class="form-control" id="confirmCreditRiskDetails"
                        formControlName="confirmCreditRiskDetails"
                        [class.is-invalid]="mainForm.controls.confirmCreditRiskDetails.touched && mainForm.controls.confirmCreditRiskDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmCreditRiskDetails.errors">
                        <div *ngIf="mainForm.controls.confirmCreditRiskDetails.errors.required">
                            Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmMarketRisk.touched && mainForm.controls.confirmMarketRisk.invalid"><b>Market
                            Risk:</b> Please confirm that you have assessed , analyzed and evaluated the client’s
                        exposure to market fluctuations, including interest rates, foreign exchange rates, and commodity
                        prices:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmMarketRisk"
                                id="confirmMarketRiskYes" [value]="true" formControlName="confirmMarketRisk"
                                [class.is-invalid]="mainForm.controls.confirmMarketRisk.touched && mainForm.controls.confirmMarketRisk.invalid">
                            <label class="form-check-label" for="confirmMarketRiskYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmMarketRisk"
                                id="confirmMarketRiskNo" [value]="false" formControlName="confirmMarketRisk"
                                [class.is-invalid]="mainForm.controls.confirmMarketRisk.touched && mainForm.controls.confirmMarketRisk.invalid">
                            <label class="form-check-label" for="confirmMarketRiskNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmMarketRisk.errors">
                        <div *ngIf="mainForm.controls.confirmMarketRisk.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmMarketRiskDetails" class="form-label">Market Risk Comments:</label>
                    <input type="text" class="form-control" id="confirmMarketRiskDetails"
                        formControlName="confirmMarketRiskDetails"
                        [class.is-invalid]="mainForm.controls.confirmMarketRiskDetails.touched && mainForm.controls.confirmMarketRiskDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmMarketRiskDetails.errors">
                        <div *ngIf="mainForm.controls.confirmMarketRiskDetails.errors.required">
                            Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmOperationalRisk.touched && mainForm.controls.confirmOperationalRisk.invalid"><b>Operational
                            Risk:</b> Please confirm that you have assessed and whether you have identified potential
                        operational risks, such as system failures, fraud, and business continuity issues:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmOperationalRisk"
                                id="confirmOperationalRiskYes" [value]="true" formControlName="confirmOperationalRisk"
                                [class.is-invalid]="mainForm.controls.confirmOperationalRisk.touched && mainForm.controls.confirmOperationalRisk.invalid">
                            <label class="form-check-label" for="confirmOperationalRiskYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmOperationalRisk"
                                id="confirmOperationalRiskNo" [value]="false" formControlName="confirmOperationalRisk"
                                [class.is-invalid]="mainForm.controls.confirmOperationalRisk.touched && mainForm.controls.confirmOperationalRisk.invalid">
                            <label class="form-check-label" for="confirmOperationalRiskNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmOperationalRisk.errors">
                        <div *ngIf="mainForm.controls.confirmOperationalRisk.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmOperationalRiskDetails" class="form-label">Operational Risk Comments:</label>
                    <input type="text" class="form-control" id="confirmOperationalRiskDetails"
                        formControlName="confirmOperationalRiskDetails"
                        [class.is-invalid]="mainForm.controls.confirmOperationalRiskDetails.touched && mainForm.controls.confirmOperationalRiskDetails.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.confirmOperationalRiskDetails.errors">
                        <div *ngIf="mainForm.controls.confirmOperationalRiskDetails.errors.required">
                            Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmReputationRisk.touched && mainForm.controls.confirmReputationRisk.invalid"><b>Reputation
                            Risk:</b> Please confirm that you have assessed any reputational risks associated with the
                        client, including past controversies or legal issues:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmReputationRisk"
                                id="confirmReputationRiskYes" [value]="true" formControlName="confirmReputationRisk"
                                [class.is-invalid]="mainForm.controls.confirmReputationRisk.touched && mainForm.controls.confirmReputationRisk.invalid">
                            <label class="form-check-label" for="confirmReputationRiskYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmReputationRisk"
                                id="confirmReputationRiskNo" [value]="false" formControlName="confirmReputationRisk"
                                [class.is-invalid]="mainForm.controls.confirmReputationRisk.touched && mainForm.controls.confirmReputationRisk.invalid">
                            <label class="form-check-label" for="confirmReputationRiskNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmReputationRisk.errors">
                        <div *ngIf="mainForm.controls.confirmReputationRisk.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmReputationRiskDetails" class="form-label">Reputation Risk Comments</label>
                    <input type="text" class="form-control" id="confirmReputationRiskDetails"
                        formControlName="confirmReputationRiskDetails"
                        [class.is-invalid]="mainForm.controls.confirmReputationRiskDetails.touched && mainForm.controls.confirmReputationRiskDetails.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.confirmReputationRiskDetails.errors">
                        <div *ngIf="mainForm.controls.confirmReputationRiskDetails.errors.required">
                            Required</div>
                    </div>
                </div>


            </div>
        </div>




        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Regulatory Compliance</h4>

                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmAMLRisk.touched && mainForm.controls.confirmAMLRisk.invalid"><b>Anti-Money
                            Laundering (AML):</b> Please confirm that you have assessed and conducted an AML check to
                        ensure the client is not involved in money laundering activities including monitoring
                        transactions and verifying sources of funds:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmAMLRisk" id="confirmAMLRiskYes"
                                [value]="true" formControlName="confirmAMLRisk"
                                [class.is-invalid]="mainForm.controls.confirmAMLRisk.touched && mainForm.controls.confirmAMLRisk.invalid">
                            <label class="form-check-label" for="confirmAMLRiskYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmAMLRisk" id="confirmAMLRiskNo"
                                [value]="false" formControlName="confirmAMLRisk"
                                [class.is-invalid]="mainForm.controls.confirmAMLRisk.touched && mainForm.controls.confirmAMLRisk.invalid">
                            <label class="form-check-label" for="confirmAMLRiskNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmAMLRisk.errors">
                        <div *ngIf="mainForm.controls.confirmAMLRisk.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmAMLRiskDetails" class="form-label">Anti-Money Laundering (AML) Comments:</label>
                    <input type="text" class="form-control" id="confirmAMLRiskDetails"
                        formControlName="confirmAMLRiskDetails"
                        [class.is-invalid]="mainForm.controls.confirmAMLRiskDetails.touched && mainForm.controls.confirmAMLRiskDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmAMLRiskDetails.errors">
                        <div *ngIf="mainForm.controls.confirmAMLRiskDetails.errors.required">
                            Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmSanctionsPEPs.touched && mainForm.controls.confirmSanctionsPEPs.invalid"><b>Sanctions
                            and PEPs:</b> Please confirm that you have assessed if the client or associated parties are
                        on international sanctions lists or if they are Politically Exposed Persons (PEPs):</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmSanctionsPEPs"
                                id="confirmSanctionsPEPsYes" [value]="true" formControlName="confirmSanctionsPEPs"
                                [class.is-invalid]="mainForm.controls.confirmSanctionsPEPs.touched && mainForm.controls.confirmSanctionsPEPs.invalid">
                            <label class="form-check-label" for="confirmSanctionsPEPsYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmSanctionsPEPs"
                                id="confirmSanctionsPEPsNo" [value]="false" formControlName="confirmSanctionsPEPs"
                                [class.is-invalid]="mainForm.controls.confirmSanctionsPEPs.touched && mainForm.controls.confirmSanctionsPEPs.invalid">
                            <label class="form-check-label" for="confirmSanctionsPEPsNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmSanctionsPEPs.errors">
                        <div *ngIf="mainForm.controls.confirmSanctionsPEPs.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmSanctionsPEPsDetails" class="form-label">Sanctions and PEPs Comments:</label>
                    <input type="text" class="form-control" id="confirmSanctionsPEPsDetails"
                        formControlName="confirmSanctionsPEPsDetails"
                        [class.is-invalid]="mainForm.controls.confirmSanctionsPEPsDetails.touched && mainForm.controls.confirmSanctionsPEPsDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmSanctionsPEPsDetails.errors">
                        <div *ngIf="mainForm.controls.confirmSanctionsPEPsDetails.errors.required">
                            Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmLegalCompliance.touched && mainForm.controls.confirmLegalCompliance.invalid"><b>Legal
                            Compliance:</b> Please confirm that you have assessed and ensured the client complies with
                        all relevant local and international laws and regulations:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmLegalCompliance"
                                id="confirmLegalComplianceYes" [value]="true" formControlName="confirmLegalCompliance"
                                [class.is-invalid]="mainForm.controls.confirmLegalCompliance.touched && mainForm.controls.confirmLegalCompliance.invalid">
                            <label class="form-check-label" for="confirmLegalComplianceYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmLegalCompliance"
                                id="confirmLegalComplianceNo" [value]="false" formControlName="confirmLegalCompliance"
                                [class.is-invalid]="mainForm.controls.confirmLegalCompliance.touched && mainForm.controls.confirmLegalCompliance.invalid">
                            <label class="form-check-label" for="confirmLegalComplianceNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmLegalCompliance.errors">
                        <div *ngIf="mainForm.controls.confirmLegalCompliance.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmLegalComplianceDetails" class="form-label">Legal Compliance Comments:</label>
                    <input type="text" class="form-control" id="confirmLegalComplianceDetails"
                        formControlName="confirmLegalComplianceDetails"
                        [class.is-invalid]="mainForm.controls.confirmLegalComplianceDetails.touched && mainForm.controls.confirmLegalComplianceDetails.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.confirmLegalComplianceDetails.errors">
                        <div *ngIf="mainForm.controls.confirmLegalComplianceDetails.errors.required">
                            Required</div>
                    </div>
                </div>




            </div>
        </div>



        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Background Checks</h4>

                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmCorporateHistory.touched && mainForm.controls.confirmCorporateHistory.invalid"><b>Corporate History:</b> Please confirm that you have assessed   and Investigated the client’s corporate history, including mergers, acquisitions, and significant changes in business structure:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmCorporateHistory" id="confirmCorporateHistoryYes"
                                [value]="true" formControlName="confirmCorporateHistory"
                                [class.is-invalid]="mainForm.controls.confirmCorporateHistory.touched && mainForm.controls.confirmCorporateHistory.invalid">
                            <label class="form-check-label" for="confirmCorporateHistoryYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmCorporateHistory" id="confirmCorporateHistoryNo"
                                [value]="false" formControlName="confirmCorporateHistory"
                                [class.is-invalid]="mainForm.controls.confirmCorporateHistory.touched && mainForm.controls.confirmCorporateHistory.invalid">
                            <label class="form-check-label" for="confirmCorporateHistoryNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmCorporateHistory.errors">
                        <div *ngIf="mainForm.controls.confirmCorporateHistory.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmCorporateHistoryDetails" class="form-label">Corporate History Comments:</label>
                    <input type="text" class="form-control" id="confirmCorporateHistoryDetails"
                        formControlName="confirmCorporateHistoryDetails"
                        [class.is-invalid]="mainForm.controls.confirmCorporateHistoryDetails.touched && mainForm.controls.confirmCorporateHistoryDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmCorporateHistoryDetails.errors">
                        <div *ngIf="mainForm.controls.confirmCorporateHistoryDetails.errors.required">
                            Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmLitigationLegal.touched && mainForm.controls.confirmLitigationLegal.invalid"><b>Litigation and Legal Issues:</b> Please confirm that you have assessed  and reviewed any past or ongoing litigation, legal disputes, or regulatory actions involving the client:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmLitigationLegal"
                                id="confirmLitigationLegalYes" [value]="true" formControlName="confirmLitigationLegal"
                                [class.is-invalid]="mainForm.controls.confirmLitigationLegal.touched && mainForm.controls.confirmLitigationLegal.invalid">
                            <label class="form-check-label" for="confirmLitigationLegalYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmLitigationLegal"
                                id="confirmLitigationLegalNo" [value]="false" formControlName="confirmLitigationLegal"
                                [class.is-invalid]="mainForm.controls.confirmLitigationLegal.touched && mainForm.controls.confirmLitigationLegal.invalid">
                            <label class="form-check-label" for="confirmLitigationLegalNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmLitigationLegal.errors">
                        <div *ngIf="mainForm.controls.confirmLitigationLegal.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmLitigationLegalDetails" class="form-label">Litigation and Legal Issues Comments:</label>
                    <input type="text" class="form-control" id="confirmLitigationLegalDetails"
                        formControlName="confirmLitigationLegalDetails"
                        [class.is-invalid]="mainForm.controls.confirmLitigationLegalDetails.touched && mainForm.controls.confirmLitigationLegalDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmLitigationLegalDetails.errors">
                        <div *ngIf="mainForm.controls.confirmLitigationLegalDetails.errors.required">
                            Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmManagementTeam.touched && mainForm.controls.confirmManagementTeam.invalid"><b>Management Team:</b> Please confirm that you have assessed  and conducted background checks on key executives and board members, including their professional history and any past legal issues:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmManagementTeam"
                                id="confirmManagementTeamYes" [value]="true" formControlName="confirmManagementTeam"
                                [class.is-invalid]="mainForm.controls.confirmManagementTeam.touched && mainForm.controls.confirmManagementTeam.invalid">
                            <label class="form-check-label" for="confirmManagementTeamYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmManagementTeam"
                                id="confirmManagementTeamNo" [value]="false" formControlName="confirmManagementTeam"
                                [class.is-invalid]="mainForm.controls.confirmManagementTeam.touched && mainForm.controls.confirmManagementTeam.invalid">
                            <label class="form-check-label" for="confirmManagementTeamNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmManagementTeam.errors">
                        <div *ngIf="mainForm.controls.confirmManagementTeam.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmManagementTeamDetails" class="form-label">Management Team Comments:</label>
                    <input type="text" class="form-control" id="confirmManagementTeamDetails"
                        formControlName="confirmManagementTeamDetails"
                        [class.is-invalid]="mainForm.controls.confirmManagementTeamDetails.touched && mainForm.controls.confirmManagementTeamDetails.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.confirmManagementTeamDetails.errors">
                        <div *ngIf="mainForm.controls.confirmManagementTeamDetails.errors.required">
                            Required</div>
                    </div>
                </div>




            </div>
        </div>




        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Documentation and Reporting</h4>

                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmDueDiligenceReport.touched && mainForm.controls.confirmDueDiligenceReport.invalid"><b>Due Diligence Report:</b> Please confirm that you have placed all findings into a comprehensive due diligence report, highlighting the client’s financial health, compliance status, risks, and recommendations:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmDueDiligenceReport" id="confirmDueDiligenceReportYes"
                                [value]="true" formControlName="confirmDueDiligenceReport"
                                [class.is-invalid]="mainForm.controls.confirmDueDiligenceReport.touched && mainForm.controls.confirmDueDiligenceReport.invalid">
                            <label class="form-check-label" for="confirmDueDiligenceReportYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmDueDiligenceReport" id="confirmDueDiligenceReportNo"
                                [value]="false" formControlName="confirmDueDiligenceReport"
                                [class.is-invalid]="mainForm.controls.confirmDueDiligenceReport.touched && mainForm.controls.confirmDueDiligenceReport.invalid">
                            <label class="form-check-label" for="confirmDueDiligenceReportNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmDueDiligenceReport.errors">
                        <div *ngIf="mainForm.controls.confirmDueDiligenceReport.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmDueDiligenceReportDetails" class="form-label">Due Diligence Report Comments:</label>
                    <input type="text" class="form-control" id="confirmDueDiligenceReportDetails"
                        formControlName="confirmDueDiligenceReportDetails"
                        [class.is-invalid]="mainForm.controls.confirmDueDiligenceReportDetails.touched && mainForm.controls.confirmDueDiligenceReportDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmDueDiligenceReportDetails.errors">
                        <div *ngIf="mainForm.controls.confirmDueDiligenceReportDetails.errors.required">
                            Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmRiskRating.touched && mainForm.controls.confirmRiskRating.invalid"><b>Risk Rating:</b> Please confirm that you have Assigned a risk rating to the client based on the due diligence findings, which will guide the institution’s decision-making process:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmRiskRating" id="confirmRiskRatingYes"
                                [value]="true" formControlName="confirmRiskRating"
                                [class.is-invalid]="mainForm.controls.confirmRiskRating.touched && mainForm.controls.confirmRiskRating.invalid">
                            <label class="form-check-label" for="confirmRiskRatingYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmRiskRating" id="confirmRiskRatingNo"
                                [value]="false" formControlName="confirmRiskRating"
                                [class.is-invalid]="mainForm.controls.confirmRiskRating.touched && mainForm.controls.confirmRiskRating.invalid">
                            <label class="form-check-label" for="confirmRiskRatingNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmRiskRating.errors">
                        <div *ngIf="mainForm.controls.confirmRiskRating.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmRiskRatingDetails" class="form-label">Risk Rating Comments:</label>
                    <input type="text" class="form-control" id="confirmRiskRatingDetails"
                        formControlName="confirmRiskRatingDetails"
                        [class.is-invalid]="mainForm.controls.confirmRiskRatingDetails.touched && mainForm.controls.confirmRiskRatingDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmRiskRatingDetails.errors">
                        <div *ngIf="mainForm.controls.confirmRiskRatingDetails.errors.required">
                            Required</div>
                    </div>
                </div>

    


            </div>
        </div>



        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Ongoing Monitoring</h4>

                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmPeriodicReviews.touched && mainForm.controls.confirmPeriodicReviews.invalid"><b>Periodic Reviews:</b> Please confirm that you Conduct periodic reviews of the client’s financial and operational status to ensure ongoing compliance and risk management:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmPeriodicReviews" id="confirmPeriodicReviewsYes"
                                [value]="true" formControlName="confirmPeriodicReviews"
                                [class.is-invalid]="mainForm.controls.confirmPeriodicReviews.touched && mainForm.controls.confirmPeriodicReviews.invalid">
                            <label class="form-check-label" for="confirmPeriodicReviewsYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmPeriodicReviews" id="confirmPeriodicReviewsNo"
                                [value]="false" formControlName="confirmPeriodicReviews"
                                [class.is-invalid]="mainForm.controls.confirmPeriodicReviews.touched && mainForm.controls.confirmPeriodicReviews.invalid">
                            <label class="form-check-label" for="confirmPeriodicReviewsNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmPeriodicReviews.errors">
                        <div *ngIf="mainForm.controls.confirmPeriodicReviews.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmPeriodicReviewsDetails" class="form-label">Periodic Reviews Comments:</label>
                    <input type="text" class="form-control" id="confirmPeriodicReviewsDetails"
                        formControlName="confirmPeriodicReviewsDetails"
                        [class.is-invalid]="mainForm.controls.confirmPeriodicReviewsDetails.touched && mainForm.controls.confirmPeriodicReviewsDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmPeriodicReviewsDetails.errors">
                        <div *ngIf="mainForm.controls.confirmPeriodicReviewsDetails.errors.required">
                            Required</div>
                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.confirmTransactionMonitoring.touched && mainForm.controls.confirmTransactionMonitoring.invalid"><b>Transaction Monitoring:</b> Please confirm that you Continuously monitor the client’s transactions for unusual or suspicious activities:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmTransactionMonitoring" id="confirmTransactionMonitoringYes"
                                [value]="true" formControlName="confirmTransactionMonitoring"
                                [class.is-invalid]="mainForm.controls.confirmTransactionMonitoring.touched && mainForm.controls.confirmTransactionMonitoring.invalid">
                            <label class="form-check-label" for="confirmTransactionMonitoringYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="confirmTransactionMonitoring" id="confirmTransactionMonitoringNo"
                                [value]="false" formControlName="confirmTransactionMonitoring"
                                [class.is-invalid]="mainForm.controls.confirmTransactionMonitoring.touched && mainForm.controls.confirmTransactionMonitoring.invalid">
                            <label class="form-check-label" for="confirmTransactionMonitoringNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmTransactionMonitoring.errors">
                        <div *ngIf="mainForm.controls.confirmTransactionMonitoring.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="confirmTransactionMonitoringDetails" class="form-label">Transaction Monitoring Comments:</label>
                    <input type="text" class="form-control" id="confirmTransactionMonitoringDetails"
                        formControlName="confirmTransactionMonitoringDetails"
                        [class.is-invalid]="mainForm.controls.confirmTransactionMonitoringDetails.touched && mainForm.controls.confirmTransactionMonitoringDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmTransactionMonitoringDetails.errors">
                        <div *ngIf="mainForm.controls.confirmTransactionMonitoringDetails.errors.required">
                            Required</div>
                    </div>
                </div>

    


            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Status of request</h4>

                <div class="mb-3">
                    <label for="ficaDocumentStatusId" class="form-label"
                        [class.is-invalid]="mainForm.controls.ficaDocumentStatusId.touched && mainForm.controls.ficaDocumentStatusId.invalid">What
                        is the current status of the
                        request</label>
                    <ng-select [items]="statuses" bindLabel="name" bindValue="id" id="ficaDocumentStatusId"
                        formControlName="ficaDocumentStatusId" placeholder="Select a request status" [clearable]="false"
                        [class.is-invalid]="mainForm.controls.ficaDocumentStatusId.touched && mainForm.controls.ficaDocumentStatusId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.ficaDocumentStatusId.errors">
                        <div *ngIf="mainForm.controls.ficaDocumentStatusId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="dueDilligenceFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.dueDilligenceFileData.touched && mainForm.controls.dueDilligenceFileData.invalid">KYC</label>

                    </div>
                    <div *ngIf="currentDocument?.dueDilligenceFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.dueDilligenceFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'DueDilligence')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showDueDilligenceUpload" type="button" class="btn btn-primary"
                            (click)="showDueDilligenceUpload = true;">Upload new</button>
                        <button *ngIf="showDueDilligenceUpload" type="button" class=" btn btn-primary"
                            (click)="showDueDilligenceUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.dueDilligenceFilePath || showDueDilligenceUpload">
                        <input type="file" class="form-control" id="dueDilligenceFileData"
                            formControlName="dueDilligenceFileData"
                            (change)="fileUpload(mainForm.controls.dueDilligenceFileData, $event)"
                            [class.is-invalid]="mainForm.controls.dueDilligenceFileData.touched && mainForm.controls.dueDilligenceFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.dueDilligenceFileData.errors">
                            <div *ngIf="mainForm.controls.dueDilligenceFileData.errors.required">Required</div>
                        </div>

                    </div>
                </div>



                <div class="mb-3">
                    <label for="extension" class="form-label"
                        [class.is-invalid]="mainForm.controls.sanctionsTest.touched && mainForm.controls.sanctionsTest.invalid">Sanctions
                        Test</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="sanctionsTest" id="sanctionsTestYes"
                                [value]="true" formControlName="sanctionsTest"
                                [class.is-invalid]="mainForm.controls.sanctionsTest.touched && mainForm.controls.sanctionsTest.invalid">
                            <label class="form-check-label" for="sanctionsTestYes">Accepted</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="sanctionsTest" id="sanctionsTestNo"
                                [value]="false" formControlName="sanctionsTest"
                                [class.is-invalid]="mainForm.controls.sanctionsTest.touched && mainForm.controls.sanctionsTest.invalid">
                            <label class="form-check-label" for="sanctionsTestNo">Not Accepted</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.sanctionsTest.errors">
                        <div *ngIf="mainForm.controls.sanctionsTest.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="sanctionsTestFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.sanctionsTestFileData.touched && mainForm.controls.sanctionsTestFileData.invalid">Sanctions Test Upload</label>

                    </div>
                    <div *ngIf="currentDocument?.sanctionsTestFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.sanctionsTestFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'SanctionsTest')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showSanctionsTestUpload" type="button" class="btn btn-primary"
                            (click)="showSanctionsTestUpload = true;">Upload new</button>
                        <button *ngIf="showSanctionsTestUpload" type="button" class=" btn btn-primary"
                            (click)="showSanctionsTestUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.sanctionsTestFilePath || showSanctionsTestUpload">
                        <input type="file" class="form-control" id="sanctionsTestFileData"
                            formControlName="sanctionsTestFileData"
                            (change)="fileUpload(mainForm.controls.sanctionsTestFileData, $event)"
                            [class.is-invalid]="mainForm.controls.sanctionsTestFileData.touched && mainForm.controls.sanctionsTestFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.sanctionsTestFileData.errors">
                            <div *ngIf="mainForm.controls.sanctionsTestFileData.errors.required">Required</div>
                        </div>

                    </div>
                </div>



            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Charges/ Fees</h4>

                <div class="mb-3">
                    <label for="creditBureuxFee" class="form-label"
                        [class.is-invalid]="mainForm.controls.creditBureuxFee.touched && mainForm.controls.creditBureuxFee.invalid">Credit Bureux
                        Fee</label>
                    <input type="text" class="form-control" id="creditBureuxFee" formControlName="creditBureuxFee"
                        [class.is-invalid]="mainForm.controls.creditBureuxFee.touched && mainForm.controls.creditBureuxFee.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.creditBureuxFee.errors">
                        <div *ngIf="mainForm.controls.creditBureuxFee.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="sanctionsFee" class="form-label"
                        [class.is-invalid]="mainForm.controls.sanctionsFee.touched && mainForm.controls.sanctionsFee.invalid">Sanctions Fee</label>
                    <input type="text" class="form-control" id="sanctionsFee" formControlName="sanctionsFee"
                        [class.is-invalid]="mainForm.controls.sanctionsFee.touched && mainForm.controls.sanctionsFee.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.sanctionsFee.errors">
                        <div *ngIf="mainForm.controls.sanctionsFee.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="otherFee" class="form-label"
                        [class.is-invalid]="mainForm.controls.otherFee.touched && mainForm.controls.otherFee.invalid">Other</label>
                    <input type="text" class="form-control" id="otherFee" formControlName="otherFee"
                        [class.is-invalid]="mainForm.controls.otherFee.touched && mainForm.controls.otherFee.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.otherFee.errors">
                        <div *ngIf="mainForm.controls.otherFee.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="totalFee" class="form-label"
                        [class.is-invalid]="mainForm.controls.totalFee.touched && mainForm.controls.totalFee.invalid">Deposit</label>
                    <input type="text" class="form-control" id="totalFee" formControlName="totalFee"
                        [class.is-invalid]="mainForm.controls.totalFee.touched && mainForm.controls.totalFee.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.totalFee.errors">
                        <div *ngIf="mainForm.controls.totalFee.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="departmentToBeCharged" class="form-label"
                        [class.is-invalid]="mainForm.controls.departmentToBeCharged.touched && mainForm.controls.departmentToBeCharged.invalid">Amount
                        to be banked</label>
                    <input type="text" class="form-control" id="departmentToBeCharged" formControlName="departmentToBeCharged"
                        [class.is-invalid]="mainForm.controls.departmentToBeCharged.touched && mainForm.controls.departmentToBeCharged.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.departmentToBeCharged.errors">
                        <div *ngIf="mainForm.controls.departmentToBeCharged.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Response Outcome/ Decision</h4>

                <div class="mb-3">
                    <label for="decision" class="form-label"
                        [class.is-invalid]="mainForm.controls.decision.touched && mainForm.controls.decision.invalid">Outcome
                        of decision</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="decision" id="decisionApproved"
                                [value]="1" formControlName="decision"
                                [class.is-invalid]="mainForm.controls.decision.touched && mainForm.controls.decision.invalid">
                            <label class="form-check-label" for="decisionApproved">Approved</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="decision" id="decisionDeclined"
                                [value]="0" formControlName="decision"
                                [class.is-invalid]="mainForm.controls.decision.touched && mainForm.controls.decision.invalid">
                            <label class="form-check-label" for="decisionDeclined">Declined</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="decision" id="decisionPartially"
                                [value]="-1" formControlName="decision"
                                [class.is-invalid]="mainForm.controls.decision.touched && mainForm.controls.decision.invalid">
                            <label class="form-check-label" for="decisionPartially">Partially</label>
                        </div>

                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decision.errors">
                        <div *ngIf="mainForm.controls.decision.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="decisionReason" class="form-label"
                        [class.is-invalid]="mainForm.controls.decisionReason.touched && mainForm.controls.decisionReason.invalid">Reason
                        for outcome</label>
                    <input type="text" class="form-control" id="decisionReason" formControlName="decisionReason"
                        [class.is-invalid]="mainForm.controls.decisionReason.touched && mainForm.controls.decisionReason.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decisionReason.errors">
                        <div *ngIf="mainForm.controls.decisionReason.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="decisionDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.decisionDate.touched && mainForm.controls.decisionDate.invalid">Date
                        of decision</label>
                    <input type="date" class="form-control" id="decisionDate" formControlName="decisionDate"
                        [class.is-invalid]="mainForm.controls.decisionDate.touched && mainForm.controls.decisionDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.decisionDate.errors">
                        <div *ngIf="mainForm.controls.decisionDate.errors.required">Required</div>
                    </div>
                </div>




            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Important Dates</h4>


                <div class="mb-3">
                    <label for="transactionOpenedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.transactionOpenedDate.touched && mainForm.controls.transactionOpenedDate.invalid">Date
                        transaction was opened</label>
                    <input type="date" class="form-control" id="transactionOpenedDate"
                        formControlName="transactionOpenedDate"
                        [class.is-invalid]="mainForm.controls.transactionOpenedDate.touched && mainForm.controls.transactionOpenedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.transactionOpenedDate.errors">
                        <div *ngIf="mainForm.controls.transactionOpenedDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="transactionApprovedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.transactionApprovedDate.touched && mainForm.controls.transactionApprovedDate.invalid">Date
                        transaction was approved</label>
                    <input type="date" class="form-control" id="transactionApprovedDate"
                        formControlName="transactionApprovedDate">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.transactionApprovedDate.errors"
                        [class.is-invalid]="mainForm.controls.transactionApprovedDate.touched && mainForm.controls.transactionApprovedDate.invalid">
                        <div *ngIf="mainForm.controls.transactionApprovedDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="transactionClosedDate" class="form-label"
                        [class.is-invalid]="mainForm.controls.transactionClosedDate.touched && mainForm.controls.transactionClosedDate.invalid">Date
                        transaction was closed
                    </label>
                    <input type="date" class="form-control" id="transactionClosedDate"
                        formControlName="transactionClosedDate"
                        [class.is-invalid]="mainForm.controls.transactionClosedDate.touched && mainForm.controls.transactionClosedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.transactionClosedDate.errors">
                        <div *ngIf="mainForm.controls.transactionClosedDate.errors.required">Required</div>
                    </div>
                </div>

                <!-- <div class="mb-3">
                    <label for="renewalDate" class="form-label">Renewal Date</label>
                    <input type="date" class="form-control" id="renewalDate" formControlName="renewalDate"
                        [class.is-invalid]="mainForm.controls.renewalDate.touched && mainForm.controls.renewalDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.renewalDate.errors">
                        <div *ngIf="mainForm.controls.renewalDate.errors.required">Required</div>
                    </div>
                </div> -->



            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Uploads</h4>


                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="kycFormFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.kycFormFileData.touched && mainForm.controls.kycFormFileData.invalid">KYC Forms</label>

                    </div>
                    <div *ngIf="currentDocument?.kycFormFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.kycFormFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'KYCForm')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showkycFormUpload" type="button" class="btn btn-primary"
                            (click)="showkycFormUpload = true;">Upload new</button>
                        <button *ngIf="showkycFormUpload" type="button" class=" btn btn-primary"
                            (click)="showkycFormUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.kycFormFilePath || showkycFormUpload">
                        <input type="file" class="form-control" id="kycFormFileData" formControlName="kycFormFileData"
                            (change)="fileUpload(mainForm.controls.kycFormFileData, $event)"
                            [class.is-invalid]="mainForm.controls.kycFormFileData.touched && mainForm.controls.kycFormFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.kycFormFileData.errors">
                            <div *ngIf="mainForm.controls.kycFormFileData.errors.required">Required</div>
                        </div>

                    </div>
                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="companyDocumentsFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.companyDocumentsFileData.touched && mainForm.controls.companyDocumentsFileData.invalid">Company Documents </label>

                    </div>

                    <div *ngIf="currentDocument?.companyDocumentsFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.companyDocumentsFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'CompanyDocuments')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showcompanyDocumentsUpload" type="button" class="btn btn-primary"
                            (click)="showcompanyDocumentsUpload = true;">Upload new</button>
                        <button *ngIf="showcompanyDocumentsUpload" type="button" class=" btn btn-primary"
                            (click)="showcompanyDocumentsUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.companyDocumentsFilePath || showcompanyDocumentsUpload">
                        <input type="file" class="form-control" id="companyDocumentsFileData"
                            formControlName="companyDocumentsFileData"
                            (change)="fileUpload(mainForm.controls.companyDocumentsFileData, $event)"
                            [class.is-invalid]="mainForm.controls.companyDocumentsFileData.touched && mainForm.controls.companyDocumentsFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.companyDocumentsFileData.errors">
                            <div *ngIf="mainForm.controls.companyDocumentsFileData.errors.required">Required</div>
                        </div>

                    </div>



                </div>

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="identityDocumentsFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.identityDocumentsFileData.touched && mainForm.controls.identityDocumentsFileData.invalid">Identity Documents</label>

                    </div>

                    <div *ngIf="currentDocument?.identityDocumentsFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.identityDocumentsFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'IdentityDocuments')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showConsentUpload" type="button" class="btn btn-primary"
                            (click)="showConsentUpload = true;">Upload new</button>
                        <button *ngIf="showConsentUpload" type="button" class=" btn btn-primary"
                            (click)="showConsentUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.identityDocumentsFilePath || showConsentUpload">
                        <input type="file" class="form-control" id="identityDocumentsFileData" formControlName="identityDocumentsFileData"
                            (change)="fileUpload(mainForm.controls.identityDocumentsFileData, $event)"
                            [class.is-invalid]="mainForm.controls.identityDocumentsFileData.touched && mainForm.controls.identityDocumentsFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.identityDocumentsFileData.errors">
                            <div *ngIf="mainForm.controls.identityDocumentsFileData.errors.required">Required</div>
                        </div>
                    </div>


                </div>

                

                <div class="mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="additionalTransactionFileData" class="form-label"
                            [class.is-invalid]="mainForm.controls.additionalTransactionFileData.touched && mainForm.controls.additionalTransactionFileData.invalid">Additional Transaction Documentation</label>

                    </div>

                    <div *ngIf="currentDocument?.additionalTransactionFilePath" class="d-flex align-items-center mb-2">
                        <button *ngIf="currentDocument?.additionalTransactionFilePath" type="button"
                            (click)="downloadDocumentFile($event, 'AdditionalTransaction')"
                            class="btn btn-link d-flex justify-content-between align-items-center"><span
                                class="icon icon-view-red"></span>&nbsp;Download</button>
                        <button *ngIf="!showConsentUpload" type="button" class="btn btn-primary"
                            (click)="showConsentUpload = true;">Upload new</button>
                        <button *ngIf="showConsentUpload" type="button" class=" btn btn-primary"
                            (click)="showConsentUpload = false;">Cancel</button>
                    </div>
                    <div *ngIf="!currentDocument?.additionalTransactionFilePath || showConsentUpload">
                        <input type="file" class="form-control" id="additionalTransactionFileData" formControlName="additionalTransactionFileData"
                            (change)="fileUpload(mainForm.controls.additionalTransactionFileData, $event)"
                            [class.is-invalid]="mainForm.controls.additionalTransactionFileData.touched && mainForm.controls.additionalTransactionFileData.invalid">
                        <div class="invalid-feedback text-end" *ngIf="mainForm.controls.additionalTransactionFileData.errors">
                            <div *ngIf="mainForm.controls.additionalTransactionFileData.errors.required">Required</div>
                        </div>
                    </div>


                </div>







            </div>
        </div>

        <div class="text-end">
            <button type="button" class="btn btn-default" routerLink="/dashboard/documents/fica">Cancel</button>&nbsp;
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
    </form>




</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Send Correspondence</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="correspondenceForm" (ngSubmit)="submitCorrespondence()">

            <div class="mb-3">
                <label for="documentCorrespondenceTypeId" class="form-label"
                    [class.is-invalid]="correspondenceForm.controls.documentCorrespondenceTypeId.touched && correspondenceForm.controls.documentCorrespondenceTypeId.invalid">Type</label>
                <ng-select [items]="documentCorrespondenceTypes" bindLabel="name" bindValue="id"
                    id="documentCorrespondenceTypeId"
                    [class.is-invalid]="correspondenceForm.controls.documentCorrespondenceTypeId.touched && correspondenceForm.controls.documentCorrespondenceTypeId.invalid"
                    formControlName="documentCorrespondenceTypeId" placeholder="Select a template"></ng-select>
                <div class="invalid-feedback text-end"
                    *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors">
                    <div *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors.required">Required</div>
                </div>

            </div>

            <div class="mb-3">
                <label for="sender" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.sender.touched) && correspondenceForm.controls.sender.invalid">Sender</label>
                <input type="text" class="form-control" id="sender" formControlName="sender"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.sender.touched) && correspondenceForm.controls.sender.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.sender.errors">
                    <div *ngIf="correspondenceForm.controls.sender.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.name.touched) && correspondenceForm.controls.name.invalid">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.name.touched) && correspondenceForm.controls.name.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.name.errors">
                    <div *ngIf="correspondenceForm.controls.name.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="email" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.email.touched) && correspondenceForm.controls.email.invalid">Email</label>
                <input type="text" class="form-control" id="email" formControlName="email"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.email.touched) && correspondenceForm.controls.email.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.email.errors">
                    <div *ngIf="correspondenceForm.controls.email.errors.required">Required</div>
                    <div *ngIf="correspondenceForm.controls.email.errors.email">Invalid</div>
                </div>
            </div>

            <div class="mb-3">
                <label for="documentFileData" class="form-label"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.documentFileData.touched) && correspondenceForm.controls.documentFileData.invalid">Document</label>
                <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                    (change)="fileUpload(correspondenceForm.controls.documentFileData, $event)"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.documentFileData.touched) && correspondenceForm.controls.documentFileData.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.documentFileData.errors">
                    <div *ngIf="correspondenceForm.controls.documentFileData.errors.required">Required</div>
                </div>
            </div>

            <div *ngFor="let attachment of correspondenceForm.controls.attachments.controls; let i = index"
                formArrayName="attachments">
                <div class="mb-3">
                    <label for="attachmentFileData" class="form-label"
                        [class.is-invalid]="(correspondenceFormSubmitted || attachment.touched) && attachment.invalid">Attachment
                        {{i+1}}</label>
                    <div class="input-group">
                        <input type="file" class="form-control" id="attachmentFileData" [formControl]="attachment"
                            (change)="fileUpload(attachment, $event)"
                            [class.is-invalid]="(correspondenceFormSubmitted || attachment.touched) && attachment.invalid">
                        <button type="button" class="btn btn-danger" (click)="removeAttachement(i)">X</button>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="attachment.errors">
                        <div *ngIf="attachment.errors.required">Required</div>
                    </div>

                </div>

            </div>
            <div class="text-end">
                <button type="button" class="btn btn-link" (click)="addAttachement()">+ Add Attachment</button>
            </div>


            <div class="text-end">
                <button type="button" class="btn btn-default" (click)="modal.dismiss()">Cancel</button>&nbsp;
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>

</ng-template>