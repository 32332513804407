import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { Country } from '../models/country';
import { Department } from '../models/department';
import { EntityType } from '../models/entityType';
import { ImpactRating } from '../models/impactRating';
import { Industry } from '../models/industry';
import { LearningModule } from '../models/learningModule';
import { ListingType } from '../models/listingType';
import { Question } from '../models/question';
import { EfficiencyRate } from '../models/efficiencyRate';

@Injectable({
  providedIn: 'root'
})
export class LookupApiService extends ApiBaseService {

  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super(http, env);
  }

  ListCountries(): Observable<Array<Country>> {
    return this.get<Array<Country>>(`api/Lookup/Countries`);
  }

  ListEntityTypes(): Observable<Array<EntityType>> {
    return this.get<Array<EntityType>>(`api/Lookup/EntityTypes`);
  }

  ListImpactRatings(organisationId?:number): Observable<Array<ImpactRating>> {
    
    let url = `api/Lookup/ImpactRatings`;

    if (organisationId){
      url = `${url}?organisationId=${organisationId}`
    }

    return this.get<Array<ImpactRating>>(url);
  }

  ListIndustries(): Observable<Array<Industry>> {
    return this.get<Array<Industry>>(`api/Lookup/Industries`);
  }

  ListListingTypes(): Observable<Array<ListingType>> {
    return this.get<Array<ListingType>>(`api/Lookup/ListingTypes`);
  }

  ListDepartments(): Observable<Array<Department>> {
    return this.get<Array<Department>>(`api/Lookup/Departments`);
  }

  ListQuestions(): Observable<Array<Question>> {
    return this.get<Array<Question>>(`api/Lookup/Questions`);
  }

  ListLearningModules(): Observable<Array<LearningModule>> {
    return this.get<Array<LearningModule>>(`api/Lookup/LearningModules`);
  }

  ListEfficiencyRatings(): Observable<Array<EfficiencyRate>> {
    return this.get<Array<EfficiencyRate>>(`api/Lookup/EfficiencyRatings`);
  }

  GetImpactRating(organisationId: number, currentLabel: string, likelihood?: number, impact?: number) {
    if (organisationId == 89 && currentLabel) {
      if (currentLabel.toUpperCase() == "EXTREME" || currentLabel.toUpperCase() == "CATASTROPHIC") {
        if (likelihood == 5 && impact == 5){
          return 'CATASTROPHIC';
        }
        return "CRITICAL";
      }
      if (currentLabel.toUpperCase() == "MAJOR") {
        return "HIGH";
      }
      if (currentLabel.toUpperCase() == "MEDIUM") {
        return "MEDIUM";
      }
      if (currentLabel.toUpperCase() == "MINOR") {
        return "LOW";
      }
    }
    else {
      return currentLabel;
    }
  }

  GetImpactRatings(organisationId: number, current: Array<ImpactRating>) {

    if (current && current.length > 0) {
      current.forEach(f => {
        f.name = this.GetImpactRating(organisationId, f.name);
      });
    }
    return current;
  }

}
