import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../../../services/token.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SpinnerService } from '../../../../services/spinner.service';
import { ContractsApiService } from '../../../../services/contracts-api.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../../../services/alert.service';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { AccountApiService } from 'src/app/services/account-api.service';
import { Company } from 'src/app/models/company';
import { Contract } from 'src/app/models/contract';
import { ContractDataField } from 'src/app/models/contractDataField';
import { ContractTemplate } from 'src/app/models/contractTemplate';
import { ContractType } from 'src/app/models/contractType';

@Component({
  selector: 'app-contract-requests',
  templateUrl: './contract-requests.component.html',
  styleUrls: ['./contract-requests.component.scss']
})
export class ContractRequestsComponent implements OnInit {

  user: boolean = false;
  contractAdmin: boolean = false;
  superAdmin: boolean = false;

  companyId: number;
  apiCompanyId?: number;
  contractTemplates: ContractTemplate[];
  filteredContractTemplates: ContractTemplate[]
  company: Company;
  contractTypes: ContractType[];
  selectedTemplate: number;

  typeSelected: boolean = false;

  requestForm = this.formBuilder.group({
    companyName: ['', Validators.required],
    companyRegistration: ['', Validators.required],
    companyVAT: [''],
    title: ['', Validators.required],
    name: ['', Validators.required],
    surname: ['', Validators.required],
    email: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ]],
    contractType: ['', Validators.required],
    requiredDocument: [null, Validators.required],
    counterpartyName: ['', Validators.required],
    counterpartyRegistrationNumber: ['', Validators.required],
    counterpartyPhysicalAddress: ['', Validators.required],
    counterpartyPostalAddress: ['', Validators.required],
    representativeName: ['', Validators.required],
    representativeSurname: ['', Validators.required],
    representativeTitle: ['', Validators.required],
    representativeEmail: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ]],
    effectiveDate: ['', Validators.required],
    terminationDate: ['', Validators.required]
  });

  constructor(
    public token: TokenService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private router: Router,
    private contractsApi: ContractsApiService,
    private formBuilder: UntypedFormBuilder,
    private alert: AlertService,
    private cache: RequestCacheService,
    private accountApi: AccountApiService
  ) { }

  ngOnInit() {

    this.requestForm.controls.requiredDocument.disable();

    this.contractsApi.GetContractTypes().subscribe(data => {
      this.contractTypes = data;
    })

    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.selectedTemplate = +params.get('id');

      if (this.selectedTemplate) {
        this.requestForm.patchValue({ requiredDocument: this.selectedTemplate });
      }
    });

    if (this.contractAdmin || this.user) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.getCompany();
        this.getTemplates();
      }

    }
    else if (this.superAdmin) {

    }
    else {
      this.router.navigateByUrl('/dashboard/contracts');
    }

  }

  companySelected(company) {

    if (company) {
      this.company = company;
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;

      this.requestForm.patchValue({ 
        companyName: this.company.name,
        companyRegistration: this.company.registrationNumber,
        companyVAT: this.company.vatNumber 
      });
      

      this.getTemplates();
    }
    else {
      this.contractTemplates = null;
    }
  }

  getCompany() {
    this.accountApi.getProfile().subscribe(data => {
      this.requestForm.patchValue({ 
        name: data.firstname,
        surname: data.lastname,
        email: data.email,
        companyName: data.company,
        companyRegistration: data.companyRegistration,
        companyVAT: data.companyVAT 
      });
    });
  }
  getTemplates() {
    this.spinner.startBackground();
    this.contractsApi.ListContractTemplatesFull(this.apiCompanyId).subscribe(data => {
      this.contractTemplates = data;
      this.spinner.stopBackground();
    });
  }


  selectType(ev: Event) {

    if (ev) {
      this.filteredContractTemplates = this.contractTemplates.filter(f => f.contractType.id == this.requestForm.controls.contractType.value);
      this.requestForm.controls.requiredDocument.enable();
    }
    else {
      this.filteredContractTemplates = null;
      this.requestForm.controls.requiredDocument.disable();

    }
    this.requestForm.controls.requiredDocument.patchValue(null);
  }

  submit() {

    this.spinner.start();

    let contractTemplate = this.contractTemplates.find(f => f.id == this.requestForm.controls.requiredDocument.value);
    let contractType = this.contractTypes.find(f => f.id == this.requestForm.controls.contractType.value);
    let dataFields: ContractDataField[] = new Array();

    dataFields.push({ name: 'CompanyName', value: this.requestForm.controls.companyName.value });
    dataFields.push({ name: 'CompanyRegistration', value: this.requestForm.controls.companyRegistration.value });
    dataFields.push({ name: 'CompanyVAT', value: this.requestForm.controls.companyVAT.value });
    dataFields.push({ name: 'Name', value: this.requestForm.controls.name.value });
    dataFields.push({ name: 'Surname', value: this.requestForm.controls.surname.value });
    dataFields.push({ name: 'Email', value: this.requestForm.controls.email.value });
    dataFields.push({ name: 'CounterpartyName', value: this.requestForm.controls.counterpartyName.value });
    dataFields.push({ name: 'CounterpartyRegistrationNumber', value: this.requestForm.controls.counterpartyRegistrationNumber.value });
    dataFields.push({ name: 'CounterpartyPhysicalAddress', value: this.requestForm.controls.counterpartyPhysicalAddress.value });
    dataFields.push({ name: 'CounterpartyPostalAddress', value: this.requestForm.controls.counterpartyPostalAddress.value });
    dataFields.push({ name: 'RepresentativeName', value: this.requestForm.controls.representativeName.value });
    dataFields.push({ name: 'RepresentativeSurname', value: this.requestForm.controls.representativeSurname.value });
    dataFields.push({ name: 'RepresentativeTitle', value: this.requestForm.controls.representativeTitle.value });
    dataFields.push({ name: 'RepresentativeEmail', value: this.requestForm.controls.representativeEmail.value });
    dataFields.push({ name: 'EffectiveDate', value: this.requestForm.controls.effectiveDate.value });
    dataFields.push({ name: 'TerminationDate', value: this.requestForm.controls.terminationDate.value });


    let request: Contract = {
      title: this.requestForm.controls.title.value,
      contractTemplate: contractTemplate,
      company: this.company,
      contractDataFields: dataFields,
      contractType: contractType
    };

    this.contractsApi.AddContractRequest(request).subscribe(data => {
      this.alert.success(`Your request has been submitted. Your reference is: ${data.referenceNumber}`);
      this.requestForm.reset();
      this.spinner.stop();
      this.cache.clear();
    }, (error) => {
      this.alert.error('An error occurred while submitting your request, please try again.');
      this.spinner.stop();
    });
  }

  back() {
    this.router.navigateByUrl('/dashboard/contracts');
  }
}
