<div class="get h-100 pt-4 d-flex align-items-center text-center">
    <div class="container flex-grow-1 d-flex flex-column">
      <div class="d-flex align-items-center justify-content-center">
        <h1 class="display-4 text-uppercase">The future of legal compliance is now!</h1>
      </div>
      <div class="content-block flex-column mb-4">
        <div class="row">
          <div class="col-12">
              <h3 class="text-start">Let us get in touch with you to discuss your requirements</h3>
              <form [formGroup]="contactForm" (ngSubmit)="submit()">
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="First Name *" formControlName="firstname"
                        [class.is-invalid]="submitted && contactForm.controls.firstname.invalid">
                      <div class="invalid-feedback text-end" *ngIf="contactForm.controls.firstname.errors">
                        <div *ngIf="contactForm.controls.firstname.errors.required">Required</div>
                      </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Last Name" formControlName="lastname">
                        
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Email *" formControlName="email"
                        [class.is-invalid]="submitted && contactForm.controls.firstname.invalid">
                      <div class="invalid-feedback text-end" *ngIf="contactForm.controls.email.errors">
                        <div *ngIf="contactForm.controls.email.errors.required">Required</div>
                        <div *ngIf="contactForm.controls.email.errors.email">Invalid</div>
                      </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Phone" formControlName="phoneNumber">
                        
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-start">
                      <button type="submit" class="btn btn-danger">Send</button>
                    </div>
                  </div>
                </form>

          </div>
          
        </div>
      </div>
    </div>
  </div>