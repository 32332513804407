<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.apiCompanyId">
    <h2>Permit Requests</h2>

    <div class="d-flex align-items-end   mb-2">
        <button class="btn btn-primary me-2" routerLink="/dashboard/documents/home">&lt; BACK</button>
        <div class="me-2">
            <label for="filterStatusId">Status:</label>
            <ng-select [(ngModel)]="filterStatusId" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 250px;" placeholder="All">
                <ng-option *ngFor="let status of statuses" [value]="status.id">{{status.name}}</ng-option>
            </ng-select>
        </div>
        <div class="me-2">
            <label for="filterGranted">Granted:</label>
            <ng-select [(ngModel)]="filterGranted" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 100px;" placeholder="All">
                <ng-option [value]="true">YES</ng-option>
                <ng-option [value]="false">NO</ng-option>
            </ng-select>
        </div>
        
        <div class="flex-fill">
        </div>

        <button class="btn btn-primary" routerLink="/dashboard/documents/permits/0">NEW REQUEST</button>


    </div>

    <div class="row">



        <div class="col">

            <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                <tr>
                    <th class="text-center">REF NO</th>
                    <th class="text-center">DATE</th>
                    <th class="text-center">COMPANY AND<br/>REQUESTOR</th>
                    <th class="text-center">REGULATOR</th>
                    <th class="text-center">TYPE</th>
                    <th class="text-center">STATUS</th>
                    <th class="text-center">DURATION/DATES</th>
                    <th class="text-center">FEES/COSTS</th>
                    <th class="text-center">CONDITION</th>
                    <th class="text-center">GANTED AND IN FILE</th>
                    <th class="text-center" style="width: 150px;">FILES</th>
                </tr>
                <tr *ngFor="let item of result?.items">
                    <td class="p-2"><a class="p-0"
                            [routerLink]="'/dashboard/documents/permits/' + item.id">{{item.referenceNumber}}</a></td>
                    <td>
                        {{item.requestedDate | date:'yyyy-MM-dd'}}
                    </td>
                    <td>
                        <strong>Required by:</strong><br />
                        {{item.requiredByName}}<br />
                        {{item.requiredByIDNumber}}<br />
                        {{item.requiredByDepartment}}<br />
                        {{item.requiredByAddress}}<br />
                        {{item.requiredByContactPerson}}<br />
                        {{item.requiredByEmail}}<br />
                        {{item.requiredByContactNumber}}<br />
                        <strong>Requested by:</strong><br />
                        {{item.requestorCompany}}<br />
                        {{item.requestorDepartment}}<br />
                        {{item.requestorName}}<br />
                        {{item.requestorEmail}}<br />
                        {{item.requestorContactNumber}}
                    </td>
                    <td>
                        {{item.regulatorName}}<br />
                        {{item.regulatorCapacity}}<br />
                        {{item.regulatorDepartment}}<br />
                        {{item.regulatorEmail}}<br />
                        {{item.regulatorContactNumber}}<br />
                        {{item.requiredByEmail}}<br />
                        <strong>SANS approved or Accredited </strong><br />
                        {{item.certificateFilePath ? 'YES': 'NO'}}
                    </td>
                    
                    <td>
                        {{item.permitType}}<br />
                        <strong>Law:</strong><br />
                        {{item.law}}<br />
                        <strong>Applicable Area:</strong><br />
                        {{item.applicableArea}}<br />
                        
                    </td>
                    <td>
                        {{item.permitDocumentStatus}}<br />
                    </td>
                    <td>
                        <strong>Start</strong><br />
                        {{item.permitStartDate | date:'yyyy-MM-dd'}}<br />
                        <strong>End</strong><br />
                        {{item.permitEndDate | date:'yyyy-MM-dd'}}<br />
                        <strong>Term</strong><br />
                        {{item.permitTerm}}<br />
                        <strong>Renewal</strong><br />
                        {{item.permitRenewalDate | date:'yyyy-MM-dd'}}<br />
                        <strong>Termination</strong><br />
                        {{item.permitTerminationDate | date:'yyyy-MM-dd'}}<br />
                    </td>
                    <td>
                        <strong>Cost:</strong><br />
                        {{item.permitCost}}<br />
                        <strong>Cost per month:</strong><br />
                        {{item.permitCostPerMonth}}<br />
                        <strong>Cost per annum:</strong><br />
                        {{item.permitCostPerAnnum}}<br />
                        <strong>Increase per annum:</strong><br />
                        {{item.permitIncreasePerAnnum}}<br />
                    </td>
                    
                    <td>
                        {{item.permitConditions}}
                    </td>
                    <td>
                        {{item.granted ? 'YES': 'NO'}}
                    </td>
                    <td>
                        <a href="#" (click)="download($event, item.id, 'Document')"
                            class="d-flex align-items-center"><span class="icon icon-view-red me-2"></span>Permit</a><br />
                        <a *ngIf="item.certificateFilePath" href="#" (click)="download($event, item.id, 'Certificate')" class="d-flex align-items-center"><span
                                class="icon icon-view-red me-2"></span>SANS Cerificate</a><br />
                        
                    </td>
                </tr>

            </table>

            <div class="pager-bar py-4 mb-1" *ngIf="result && result.items">
                <div class="container text-center d-flex flex-row align-items-center justify-content-center"
                    *ngIf="result.items.length > 0">
                    <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt;
                        First</button>&nbsp;
                    <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
                    <span class="px-4">Page {{result.currentPage}} of {{result.totalPages}}</span>
                    <div class="px-4 d-flex flex-row align-items-center">
                        <div class="pe-2">Items per page:
                        </div>
                        <div>
                            <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
                        </div>
                    </div>
                    <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
                    <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last
                        &gt;&gt;</button>&nbsp;
                </div>
            </div>

        </div>

    </div>
</div>