import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ContractsApiService } from '../../../../services/contracts-api.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { Location } from '@angular/common';
import { ContractMessage } from 'src/app/models/contractMessage';

@Component({
  selector: 'app-contract-messages',
  templateUrl: './contract-messages.component.html',
  styleUrls: ['./contract-messages.component.scss']
})
export class ContractMessagesComponent implements OnInit {

  contractId: number;
  contractMessages: ContractMessage[];

  constructor(
    private route: ActivatedRoute,
    private contractApi: ContractsApiService,
    private spinner: SpinnerService,
    private location: Location
    ) { }

  ngOnInit() {

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.contractId = +params.get('id');
      this.getDocumentMessages();
    });

  }

  getDocumentMessages(){
    this.spinner.startBackground();
    this.contractApi.GetContractMessages(this.contractId).subscribe(data => {
      this.contractMessages = data;
      this.spinner.stopBackground();
    });
  }
  back(){
    this.location.back();
  }


  filterMessages(parentId: number){
    return this.contractMessages.filter(f => f.parentId == parentId);
  }
}
