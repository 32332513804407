import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { OrganisationApiService } from '../../services/organisation-api.service';
import { EventsService } from '../../services/events.service';
import { Organisation } from 'src/app/models/organisation';

@Component({
  selector: 'app-organisation-list-item',
  templateUrl: './organisation-list-item.component.html',
  styleUrls: ['./organisation-list-item.component.scss']
})
export class OrganisationListItemComponent implements OnInit {

  _organisation: Organisation;

  constructor(private spinner: SpinnerService, private organisationApi: OrganisationApiService, private events: EventsService) { }

  @Input()
  set organisation(org: Organisation){
    this._organisation = org;
  }
  
  @Output()
  change = new EventEmitter();
  
  ngOnInit() {
  }

  archive(organisation: Organisation){

    if (!window.confirm("Are you sure?")){
      return;
    }
    
    this.spinner.startBackground();
    organisation.active = false;
    this.organisationApi.Archive(organisation.id).subscribe(() => {
      //this._company = null;
      this.change.emit();
      this.spinner.stopBackground();
      this.events.fireEvent('configuration-submenu-update');
      this.events.fireEvent('update-org-list');
    });
  }
  
}
