import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TokenService } from '../../../../services/token.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { SpinnerService } from '../../../../services/spinner.service';
import { ContractsApiService } from '../../../../services/contracts-api.service';
import { AlertService } from '../../../../services/alert.service';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { ContractDataField } from 'src/app/models/contractDataField';
import { Contract } from 'src/app/models/contract';
import { ContractTemplate } from 'src/app/models/contractTemplate';
import { ContractType } from 'src/app/models/contractType';

@Component({
  selector: 'app-contract-open',
  templateUrl: './contract-open.component.html',
  styleUrls: ['./contract-open.component.scss']
})
export class ContractOpenComponent implements OnInit {

  user: boolean = false;
  contractAdmin: boolean = false;
  superAdmin: boolean = false;
  contractId: number;
  contract: Contract;
  contractTemplates: ContractTemplate[];
  filteredContractTemplates: ContractTemplate[];
  contractTypes: ContractType[];

  requestForm = this.formBuilder.group({
    companyName: ['', Validators.required],
    companyRegistration: ['', Validators.required],
    companyVAT: [''],
    title: ['', Validators.required],
    name: ['', Validators.required],
    surname: ['', Validators.required],
    email: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ]],
    contractType: ['', Validators.required],
    requiredDocument: [null],
    counterpartyName: ['', Validators.required],
    counterpartyRegistrationNumber: ['', Validators.required],
    counterpartyPhysicalAddress: ['', Validators.required],
    counterpartyPostalAddress: ['', Validators.required],
    representativeName: ['', Validators.required],
    representativeSurname: ['', Validators.required],
    representativeTitle: ['', Validators.required],
    representativeEmail: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ]],
    effectiveDate: ['', Validators.required],
    terminationDate: ['', Validators.required]
  });

  constructor(
    public token: TokenService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private router: Router,
    private contractsApi: ContractsApiService,
    private formBuilder: UntypedFormBuilder,
    private alert: AlertService,
    private cache: RequestCacheService
  ) { }

  ngOnInit() {
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    this.contractsApi.GetContractTypes().subscribe(data => {
      this.contractTypes = data;
    })

    if (this.user && !this.contractAdmin && !this.superAdmin)
    {
      this.alert.error('You are not authorized to view this page.', true);
      this.router.navigateByUrl('/');
      return;
    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.contractId = +params.get('id');
      this.getContract();
    });
  }

  getContract(){
    this.spinner.startBackground();

    this.contractsApi.GetContract(this.contractId).subscribe(c => {

      if (c.contractStatus.id != 1){
        this.alert.error('This contract cannot be opened');
        this.spinner.stopBackground();
        this.back();
      }

      let companyId = null;

      if (this.superAdmin) {
        companyId = c.company.id;
      }
      
      this.contractsApi.ListContractTemplatesFull(companyId).subscribe(data => {
        this.contractTemplates = data;

        this.contract = c;
        
        if (this.contract.contractType)
        {
          this.filteredContractTemplates = this.contractTemplates.filter(f => f.contractType.id == this.contract.contractType.id);
          this.requestForm.controls.requiredDocument.enable();
        }
        else{
          this.filteredContractTemplates = null;
      this.requestForm.controls.requiredDocument.disable();
        }
        

        

        this.requestForm.patchValue({
          companyName: this.contract.contractDataFields.find(f => f.name == "CompanyName").value,
          companyRegistration: this.contract.contractDataFields.find(f => f.name == "CompanyRegistration").value,
          companyVAT: this.contract.contractDataFields.find(f => f.name == "CompanyVAT").value,
          title: this.contract.title,
          name: this.contract.contractDataFields.find(f => f.name == "Name").value,
          surname: this.contract.contractDataFields.find(f => f.name == "Surname").value,
          email: this.contract.contractDataFields.find(f => f.name == "Email").value,
          contractType: this.contract.contractType ? this.contract.contractType.id : null,
          requiredDocument: this.contract.contractTemplate ? this.contract.contractTemplate.id : null,
          counterpartyName: this.contract.contractDataFields.find(f => f.name == "CounterpartyName").value,
          counterpartyRegistrationNumber: this.contract.contractDataFields.find(f => f.name == "CounterpartyRegistrationNumber").value,
          counterpartyPhysicalAddress: this.contract.contractDataFields.find(f => f.name == "CounterpartyPhysicalAddress").value,
          counterpartyPostalAddress: this.contract.contractDataFields.find(f => f.name == "CounterpartyPostalAddress").value,
          representativeName: this.contract.contractDataFields.find(f => f.name == "RepresentativeName").value,
          representativeSurname: this.contract.contractDataFields.find(f => f.name == "RepresentativeSurname").value,
          representativeTitle: this.contract.contractDataFields.find(f => f.name == "RepresentativeTitle").value,
          representativeEmail: this.contract.contractDataFields.find(f => f.name == "RepresentativeEmail").value,
          effectiveDate: this.contract.contractDataFields.find(f => f.name == "EffectiveDate").value,
          terminationDate: this.contract.contractDataFields.find(f => f.name == "TerminationDate").value,
        });


        this.spinner.stopBackground();
      });


      
    });
  }

  submit(){

    this.spinner.startBackground();


    let contractTemplate = this.contractTemplates.find(f => f.id == this.requestForm.controls.requiredDocument.value);
    let contractType = this.contractTypes.find(f => f.id == this.requestForm.controls.contractType.value);
    let dataFields: ContractDataField[] = new Array();

    dataFields.push({ name: 'CompanyName', value: this.requestForm.controls.companyName.value });
    dataFields.push({ name: 'CompanyRegistration', value: this.requestForm.controls.companyRegistration.value });
    dataFields.push({ name: 'CompanyVAT', value: this.requestForm.controls.companyVAT.value });
    dataFields.push({ name: 'Name', value: this.requestForm.controls.name.value });
    dataFields.push({ name: 'Surname', value: this.requestForm.controls.surname.value });
    dataFields.push({ name: 'Email', value: this.requestForm.controls.email.value });
    dataFields.push({ name: 'CounterpartyName', value: this.requestForm.controls.counterpartyName.value });
    dataFields.push({ name: 'CounterpartyRegistrationNumber', value: this.requestForm.controls.counterpartyRegistrationNumber.value });
    dataFields.push({ name: 'CounterpartyPhysicalAddress', value: this.requestForm.controls.counterpartyPhysicalAddress.value });
    dataFields.push({ name: 'CounterpartyPostalAddress', value: this.requestForm.controls.counterpartyPostalAddress.value });
    dataFields.push({ name: 'RepresentativeName', value: this.requestForm.controls.representativeName.value });
    dataFields.push({ name: 'RepresentativeSurname', value: this.requestForm.controls.representativeSurname.value });
    dataFields.push({ name: 'RepresentativeTitle', value: this.requestForm.controls.representativeTitle.value });
    dataFields.push({ name: 'RepresentativeEmail', value: this.requestForm.controls.representativeEmail.value });
    dataFields.push({ name: 'EffectiveDate', value: this.requestForm.controls.effectiveDate.value });
    dataFields.push({ name: 'TerminationDate', value: this.requestForm.controls.terminationDate.value });

    this.contract.title = this.requestForm.controls.title.value;
    this.contract.contractDataFields = dataFields;
    this.contract.contractTemplate = contractTemplate;
    this.contract.contractType = contractType;

    this.contractsApi.OpenContractRequest(this.contract).subscribe(data => {
      this.spinner.stop();
      this.cache.clear();
      this.router.navigateByUrl('/dashboard/contracts-old/home');
    }, (error) => {
      this.alert.error('An error occurred while submitting your request, please try again.');
      this.spinner.stop();
    });
  }
  back(){
    this.router.navigateByUrl('/dashboard/contracts-old/home');
  }
  selectType(ev: Event) {

    if (ev) {
      this.filteredContractTemplates = this.contractTemplates.filter(f => f.contractType.id == this.requestForm.controls.contractType.value);
      this.requestForm.controls.requiredDocument.enable();
    }
    else {
      this.filteredContractTemplates = null;
      this.requestForm.controls.requiredDocument.disable();

    }
    this.requestForm.controls.requiredDocument.patchValue(null);
  }
}
