import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
//import { ErrorMessage } from 'ng-bootstrap-form-validation';
import { AccountApiService } from '../../services/account-api.service';
import { SpinnerService } from '../../services/spinner.service';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { forkJoin } from 'rxjs';
import { ChoosePassword } from 'src/app/models/choosePassword';

@Component({
  selector: 'app-choose-password',
  templateUrl: './choose-password.component.html',
  styleUrls: ['./choose-password.component.scss']
})
export class ChoosePasswordComponent implements OnInit {

  submitted = false;

  customErrorMessages: any[] = [
    {
      error: 'matchOther',
      format: (label, error) => `Passwords do not match`
    },
    {
      error: 'pattern',
      format: (label, error) => `Minimum 5 characters and must contain at least 1 capital letter, number and special character`
    }
  ];

  mainForm = this.formBuilder.group({
    choosePassword: ['', [Validators.required, Validators.pattern('^([a-zA-Z0-9!@*#$%^&*]{5,20})$')]],
    confirmPassword: ['', [Validators.required, this.matchOtherValidator('choosePassword')]]
  });

  token: string;
  userId: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private accountApi: AccountApiService,
    private spinner: SpinnerService,
    private router: Router,
    private alert: AlertService,
    private auth: AuthService
  ) { }

  ngOnInit() {

    // forkJoin(
    //   this.route.queryParamMap,
    //   this.route.paramMap
    // ).subscribe((r) => {
    //   this.userId = +r[0].get('id');
    //   this.token = r[1].get('token');

    // });

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.token = params.get('token');
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = +params.get('id');
    });

    this.auth.logout();
  }

  submit() {

    this.submitted = true;

    if (!this.mainForm.valid){
      return;
    }
    
    this.spinner.startBackground();

    let data: ChoosePassword = {
      password: this.mainForm.controls.choosePassword.value,
      token: this.token,
      userId: this.userId
    };
    this.accountApi.choosePassword(data).subscribe(() => {
      this.alert.success('Password successfully chosen, you may now log in', true);
      this.router.navigateByUrl('/login');
      this.spinner.stopBackground();
    }, (error) => {
      console.log(error);
      this.alert.error('Could not update your password. please ensure your password is complex enough (Minimum 5 characters and must contain at least 1 capital letter, number and special character)', true);
      this.spinner.stopBackground();
    });
  }

  matchOtherValidator(otherControlName: string) {

    let thisControl: UntypedFormControl;
    let otherControl: UntypedFormControl;

    return function matchOtherValidate(control: UntypedFormControl) {

      if (!control.parent) {
        return null;
      }

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as UntypedFormControl;
        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) {
        return null;
      }

      if (otherControl.value !== thisControl.value) {
        return {
          matchOther: true
        };
      }

      return null;

    }

  }
}
