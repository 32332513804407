import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TokenService } from '../../../../services/token.service';

@Component({
  selector: 'app-umbrella-report-compare',
  templateUrl: './umbrella-report-compare.component.html',
  styleUrls: ['./umbrella-report-compare.component.scss']
})
export class UmbrellaReportCompareComponent implements OnInit {
  
  companyId: number;
  apiCompanyId?: number;

  constructor(
    private route: ActivatedRoute,
    public token: TokenService,
    private router: Router) { }

  ngOnInit() {
    
    if (this.token.isCompanyAdmin()) {
      this.companyId = this.token.companyId();
    }
    else if (this.token.isSuperAdmin()) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
    }
  }


}
