import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { OrganisationApiService } from '../../../../../services/organisation-api.service';
import { SpinnerService } from '../../../../../services/spinner.service';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { EventsService } from '../../../../../services/events.service';
import $ from 'jquery';
import { Organisation } from 'src/app/models/organisation';

@Component({
  selector: 'app-edit-organisation',
  templateUrl: './edit-organisation.component.html',
  styleUrls: ['./edit-organisation.component.scss']
})
export class EditOrganisationComponent implements OnInit {

  organisation: Organisation;

  mainForm = this.formBuilder.group({
    name: ['', Validators.required],
    logo: [''],
    logoData: ['', Validators.required]
  });

  submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private organisationApi: OrganisationApiService,
    private router: Router,
    private spinner: SpinnerService,
    private events: EventsService
  ) {
    
  }

  ngOnInit() {

    // $(document).ready(function () {
    //   $('.custom-file-input').on('change', function () {
    //     let fileName = $(this).val().split('\\').pop();
    //     $(this).next('.custom-file-label').addClass("selected").html(fileName);
    //   });
    // });

    this.spinner.startBackground();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.organisationApi.GetById(+params.get('id')).subscribe((org) => {
        this.organisation = org;

        this.mainForm.patchValue({
          name: this.organisation.name,
          logoData: this.organisation.logoUrl
        });

        this.spinner.stopBackground();
      });
    });
  }

  fileUpload(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) //2MB
      {
        this.mainForm.controls.logo.setValue('');
        this.mainForm.controls.logo.setErrors({ 'required': true });
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.mainForm.patchValue({
          logoData: reader.result
        });

        this.organisation.logoUrl = this.mainForm.controls.logoData.value;

        $('.custom-file-label').addClass("selected").html(file.name);

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }
  submit() {

    this.submitted = true;

    if (this.mainForm.invalid){
      return;
    }
    
    this.spinner.start();

    this.organisation.logoUrl = this.mainForm.controls.logoData.value;
    this.organisation.name = this.mainForm.controls.name.value;

    this.organisationApi.Update(this.organisation.id, this.organisation).subscribe((r) => {
      this.events.fireEvent('configuration-submenu-update');
      this.router.navigateByUrl('/dashboard/configuration/organisation/list');
    });
  }

  
}
