<div class="container-fluid" *ngIf="this.questions">
  <div class="question-header my-2 p-2">
    <h3 class="m-0 text-center">QUESTION {{currentQuestion + 1}} / {{this.questions.length}}</h3>
  </div>
</div>
<div class="container">
  <div class="questions">
    <ng-container *ngFor="let question of questions">
      <div class="form-group" *ngIf="question.question.id == questions[currentQuestion].question.id">
        <h5 class="text-uppercase">{{question.question.questionText}}</h5>
        <div class="form-check ms-2">
          <input class="form-check-input" [(ngModel)]="question.answer" id="question_yes_{{question.question.id}}" type="radio" [value]="true">
          <label class="form-check-label" for="question_yes_{{question.question.id}}">
            Yes
          </label>
        </div>
        <div class="form-check ms-2">
          <input class="form-check-input" [(ngModel)]="question.answer" id="question_no_{{question.question.id}}" type="radio" [value]="false">
          <label class="form-check-label" for="question_no_{{question.question.id}}">
            No
          </label>
        </div>
      </div>
    </ng-container>
    <hr>
    <button class="btn btn-primary btn-outline" (click)="prev()" [disabled]="currentQuestion == 0">Previous</button>
    &nbsp;
    <button class="btn btn-primary btn-outline" (click)="next()" [disabled]="questions[currentQuestion].answer == null" [hidden]="currentQuestion >= this.questions.length - 1">Next</button>
    <button class="btn btn-primary btn-outline" (click)="submit()" [disabled]="questions[currentQuestion].answer == null" [hidden]="currentQuestion < this.questions.length - 1">Submit</button>
  </div>
</div>