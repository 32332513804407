import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usage-policy',
  templateUrl: './usage-policy.component.html',
  styleUrls: ['./usage-policy.component.scss']
})
export class UsagePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
