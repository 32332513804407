<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-12 col-sm-10 col-md-8 col-lg-6  offset-sm-1 offset-md-2 offset-lg-3 ">

      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
          <a ngbNavLink>Profile</a>
          <ng-template ngbNavContent>
            <ng-container *ngIf="profile">
              <form [formGroup]="profileForm" (ngSubmit)="updateProfile()">
                <div class="form-group row">
                  <label for="organisation" class="col-form-label">Organisation</label>
                  <input type="text" class="form-control" disabled="disabled" [value]="profile.organisation">
                </div>
                <div class="form-group row">
                  <label for="company" class="col-form-label">Company</label>
                  <input type="text" class="form-control" disabled="disabled" [value]="profile.company">
                </div>
                <div class="form-group row">
                  <label for="company" class="col-form-label">Company Registration Number</label>
                  <input type="text" class="form-control" disabled="disabled" [value]="profile.companyRegistration">
                </div>
                <div class="form-group row">
                  <label for="company" class="col-form-label">Company VAT Number</label>
                  <input type="text" class="form-control" disabled="disabled" [value]="profile.companyVAT">
                </div>
                <div class="form-group row">
                  <label for="roles" class="col-form-label">Roles</label>
                  <input type="text" class="form-control" disabled="disabled" [value]="roles">
                </div>
                <div class="form-group row">
                  <label for="firstname" class="col-form-label">Firstname</label>
                  <input type="text" class="form-control" formControlName="firstname" [class.is-invalid]="profileSubmitted && profileForm.controls.firstname.invalid">
                  <div class="invalid-feedback text-end" *ngIf="profileForm.controls.firstname.errors">
                    <div *ngIf="profileForm.controls.firstname.errors.required">Required</div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="lastname" class="col-form-label">Lastname</label>
                  <input type="text" class="form-control" formControlName="lastname" [class.is-invalid]="profileSubmitted && profileForm.controls.lastname.invalid">
                  <div class="invalid-feedback text-end" *ngIf="profileForm.controls.lastname.errors">
                    <div *ngIf="profileForm.controls.lastname.errors.required">Required</div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="email" class="col-form-label">Email</label>
                  <input type="email" class="form-control" disabled="disabled" [value]="profile.email">
                </div>
                <div class="form-group row">
                  <label for="phoneNumber" class="col-form-label">Phone Number</label>
                  <input type="tel" class="form-control" formControlName="phoneNumber">
                </div>
                <div class="text-end">
                  <button type="submit" class="btn btn-default">Update</button>
                </div>
              </form>
            </ng-container>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink>Change Password</a>
          <ng-template ngbNavContent>
            <form [formGroup]="passwordForm" (ngSubmit)="changePassword()">
              <div class="form-group row">
                <label for="oldPassword" class="col-form-label">Old Password</label>
                <input type="password" class="form-control" formControlName="oldPassword" [class.is-invalid]="passwordSubmitted && passwordForm.controls.oldPassword.invalid">
                <div class="invalid-feedback text-end" *ngIf="passwordForm.controls.oldPassword.errors">
                  <div *ngIf="passwordForm.controls.oldPassword.errors.required">Required</div>
                </div>
              </div>
              <div class="form-group row">
                <label for="newPassword" class="col-form-label">New Password</label>
                <input type="password" class="form-control" formControlName="newPassword" [class.is-invalid]="passwordSubmitted && passwordForm.controls.newPassword.invalid">
                <div class="invalid-feedback text-end" *ngIf="passwordForm.controls.newPassword.errors">
                  <div *ngIf="passwordForm.controls.newPassword.errors.required">Required</div>
                </div>
              </div>
              <div class="form-group row">
                <label for="newPasswordConfirm" class="col-form-label">Confirm New Password</label>
                <input type="password" class="form-control" formControlName="newPasswordConfirm" [class.is-invalid]="passwordSubmitted && passwordForm.controls.newPasswordConfirm.invalid">
                <div class="invalid-feedback text-end" *ngIf="passwordForm.controls.newPasswordConfirm.errors">
                  <div *ngIf="passwordForm.controls.newPasswordConfirm.errors.required">Required</div>
                </div>
              </div>
              <div class="text-end">
                <button type="submit" class="btn btn-default">Update</button>
              </div>
            </form>

          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>


      <!-- <ngb-tabset (tabChange)="tabChange($event)">
        <ngb-tab title="Profile">
          <ng-template ngbTabContent>
            
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Change Password">
          <ng-template ngbTabContent>
            
          </ng-template>
        </ngb-tab>
      </ngb-tabset> -->

    </div>
  </div>
</div>