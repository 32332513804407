<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.apiCompanyId">

    <div class="card mb-3" *ngIf="currentDocumentId != 0">
        <div class="card-body">
            <h4 class="card-title">Workflow</h4>
            <div class="mb-3 d-flex justify-content-between align-items-center">
                Correspondence
                <button class="btn btn-primary" (click)="sendCorrespondence(content)">Send</button>
            </div>
            <hr />
            <div class="mb-3 d-flex justify-content-between align-items-center">
                History
                <button class="btn btn-primary" (click)="viewCorrespondence()">View</button>

            </div>
            <div *ngIf="showCorrespondence">

                <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                    <tr>
                        <th>DATE/TIME</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>SENDER</th>
                        <th>TYPE</th>
                        <th>DOCUMENT</th>
                        <th>ATTACHMENTS</th>
                    </tr>
                    <tr *ngFor="let item of correspondence">
                        <td>{{item.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.sender}}</td>
                        <td>{{item.documentCorrespondenceType}}</td>

                        <td class="text-center">
                            <a href="#" (click)="download($event, item.id)" class="d-flex align-items-center">
                                <span class="icon icon-view-red me-2"></span>
                            </a>
                        </td>
                        <td class="text-center">
                            <div class="d-flex">
                                <a href="#" *ngFor="let attachment of item.attachments"
                                    (click)="downloadAttachment($event, attachment.id, item.id)"
                                    class="d-flex align-items-center">
                                    <span class="icon icon-view-red me-1"></span>
                                </a>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>

    <form [formGroup]="mainForm" (ngSubmit)="submit()">

        <div class="card mb-3" *ngIf="currentDocumentId == 0">
            <div class="card-body">
                <h4 class="card-title">Document Template</h4>

                <div class="mb-3">
                    <label for="standardTemplate" class="form-label">Template Type</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="standardTemplate"
                                id="standardTemplateYes"
                                [class.is-invalid]="mainForm.controls.standardTemplate.touched && mainForm.controls.standardTemplate.invalid"
                                [value]="true" formControlName="standardTemplate">
                            <label class="form-check-label" for="standardTemplateYes">Standard</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="standardTemplate" id="standardTemplateNo"
                                [class.is-invalid]="mainForm.controls.standardTemplate.touched && mainForm.controls.standardTemplate.invalid"
                                [value]="false" formControlName="standardTemplate">
                            <label class="form-check-label" for="standardTemplateNo">Own provided</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.standardTemplate.errors">
                        <div *ngIf="mainForm.controls.standardTemplate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.standardTemplate.value != true">
                    <label for="documentTemplateId" class="form-label">Template</label>
                    <ng-select [items]="templates" bindLabel="name" bindValue="id" id="documentTemplateId"
                        [class.is-invalid]="mainForm.controls.documentTemplateId.touched && mainForm.controls.documentTemplateId.invalid"
                        formControlName="documentTemplateId" placeholder="Select a standard template"
                        [clearable]="true"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentTemplateId.errors">
                        <div *ngIf="mainForm.controls.documentTemplateId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.standardTemplate.value != false">
                    <label for="documentFileData" class="form-label">Template</label>
                    <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                        (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                        <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.standardTemplate.value != false">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="useAsTemplate"
                            formControlName="useAsTemplate">
                        <label class="form-check-label" for="useAsTemplate">Load document as a Standard Template</label>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.useAsTemplate.errors">
                        <div *ngIf="mainForm.controls.useAsTemplate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" *ngIf="mainForm.controls.useAsTemplate.value"
                    [class.d-none]="mainForm.controls.standardTemplate.value != false">
                    <label for="templateName" class="form-label">Template Name</label>
                    <input type="text" class="form-control" id="templateName" formControlName="templateName"
                        [class.is-invalid]="mainForm.controls.templateName.touched && mainForm.controls.templateName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.templateName.errors">
                        <div *ngIf="mainForm.controls.templateName.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card mb-3" *ngIf="currentDocumentId > 0">
            <div class="card-body">
                <h4 class="card-title">Document</h4>

                <div class="mb-3">
                    <label for="documentFileData" class="form-label">Update Document</label>
                    <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                        (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                        <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">


                <div class="mb-3">
                    <label for="requestedDate" class="form-label">Date of Instruction/Request</label>
                    <input type="date" class="form-control" id="requestedDate" formControlName="requestedDate"
                        [class.is-invalid]="mainForm.controls.requestedDate.touched && mainForm.controls.requestedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestedDate.errors">
                        <div *ngIf="mainForm.controls.requestedDate.errors.required">Required</div>
                    </div>
                </div>


            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Required By</h4>

                <div class="mb-3">
                    <label for="requiredByName" class="form-label">Name</label>
                    <input type="text" class="form-control" id="requiredByName" formControlName="requiredByName"
                        [class.is-invalid]="mainForm.controls.requiredByName.touched && mainForm.controls.requiredByName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByName.errors">
                        <div *ngIf="mainForm.controls.requiredByName.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requiredByIDNumber" class="form-label">ID Number</label>
                    <input type="text" class="form-control" id="requiredByIDNumber" formControlName="requiredByIDNumber"
                        [class.is-invalid]="mainForm.controls.requiredByIDNumber.touched && mainForm.controls.requiredByIDNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByIDNumber.errors">
                        <div *ngIf="mainForm.controls.requiredByIDNumber.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requiredByDepartment" class="form-label">Department</label>
                    <input type="text" class="form-control" id="requiredByDepartment"
                        formControlName="requiredByDepartment"
                        [class.is-invalid]="mainForm.controls.requiredByDepartment.touched && mainForm.controls.requiredByDepartment.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByDepartment.errors">
                        <div *ngIf="mainForm.controls.requiredByDepartment.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requiredByAddress" class="form-label">Address</label>
                    <textarea type="text" class="form-control" id="requiredByAddress"
                        formControlName="requiredByAddress"></textarea>
                </div>

                <div class="mb-3">
                    <label for="requiredByContactPerson" class="form-label">Contact Person</label>
                    <input type="text" class="form-control" id="requiredByContactPerson"
                        formControlName="requiredByContactPerson"
                        [class.is-invalid]="mainForm.controls.requiredByContactPerson.touched && mainForm.controls.requiredByContactPerson.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByContactPerson.errors">
                        <div *ngIf="mainForm.controls.requiredByContactPerson.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requiredByEmail" class="form-label">Email</label>
                    <input type="text" class="form-control" id="requiredByEmail" formControlName="requiredByEmail"
                        [class.is-invalid]="mainForm.controls.requiredByEmail.touched && mainForm.controls.requiredByEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByEmail.errors">
                        <div *ngIf="mainForm.controls.requiredByEmail.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.requiredByEmail.errors.email">Invalid</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requiredByContactNumber" class="form-label">Contact Number</label>
                    <input type="text" class="form-control" id="requiredByContactNumber"
                        formControlName="requiredByContactNumber"
                        [class.is-invalid]="mainForm.controls.requiredByContactNumber.touched && mainForm.controls.requiredByContactNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requiredByContactNumber.errors">
                        <div *ngIf="mainForm.controls.requiredByContactNumber.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Requested By</h4>

                <div class="mb-3">
                    <label for="requestorCompany" class="form-label">Company Name</label>
                    <input type="text" class="form-control" id="requestorCompany" formControlName="requestorCompany"
                        [class.is-invalid]="mainForm.controls.requestorCompany.touched && mainForm.controls.requestorCompany.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorCompany.errors">
                        <div *ngIf="mainForm.controls.requestorCompany.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorDepartment" class="form-label">Department</label>
                    <input type="text" class="form-control" id="requestorDepartment"
                        formControlName="requestorDepartment"
                        [class.is-invalid]="mainForm.controls.requestorDepartment.touched && mainForm.controls.requestorDepartment.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorDepartment.errors">
                        <div *ngIf="mainForm.controls.requestorDepartment.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorName" class="form-label">Name</label>
                    <input type="text" class="form-control" id="requestorName" formControlName="requestorName"
                        [class.is-invalid]="mainForm.controls.requestorName.touched && mainForm.controls.requestorName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorName.errors">
                        <div *ngIf="mainForm.controls.requestorName.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorEmail" class="form-label">Email</label>
                    <input type="text" class="form-control" id="requestorEmail" formControlName="requestorEmail"
                        [class.is-invalid]="mainForm.controls.requestorEmail.touched && mainForm.controls.requestorEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorEmail.errors">
                        <div *ngIf="mainForm.controls.requestorEmail.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.requestorEmail.errors.email">Invalid</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorContactNumber" class="form-label">Contact Number</label>
                    <input type="text" class="form-control" id="requestorContactNumber"
                        formControlName="requestorContactNumber"
                        [class.is-invalid]="mainForm.controls.requestorContactNumber.touched && mainForm.controls.requestorContactNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorContactNumber.errors">
                        <div *ngIf="mainForm.controls.requestorContactNumber.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>






        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Request Details</h4>


                <div class="mb-3">
                    <label for="permitType" class="form-label">Permit Type</label>
                    <input type="text" class="form-control" id="permitType" formControlName="permitType"
                        [class.is-invalid]="mainForm.controls.permitType.touched && mainForm.controls.permitType.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitType.errors">
                        <div *ngIf="mainForm.controls.permitType.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="law" class="form-label">Law</label>
                    <input type="text" class="form-control" id="law" formControlName="law"
                        [class.is-invalid]="mainForm.controls.law.touched && mainForm.controls.law.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.law.errors">
                        <div *ngIf="mainForm.controls.law.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="applicableArea" class="form-label">Applicable Area</label>
                    <input type="text" class="form-control" id="applicableArea" formControlName="applicableArea"
                        [class.is-invalid]="mainForm.controls.applicableArea.touched && mainForm.controls.applicableArea.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.applicableArea.errors">
                        <div *ngIf="mainForm.controls.applicableArea.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>






        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Fees &amp; Costs</h4>


                <div class="mb-3">
                    <label for="permitCost" class="form-label">Permit Cost</label>
                    <input type="text" class="form-control" id="permitCost" formControlName="permitCost"
                        [class.is-invalid]="mainForm.controls.permitCost.touched && mainForm.controls.permitCost.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitCost.errors">
                        <div *ngIf="mainForm.controls.permitCost.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="permitCostPerMonth" class="form-label">Cost per Month</label>
                    <input type="text" class="form-control" id="permitCostPerMonth" formControlName="permitCostPerMonth"
                        [class.is-invalid]="mainForm.controls.permitCostPerMonth.touched && mainForm.controls.permitCostPerMonth.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitCostPerMonth.errors">
                        <div *ngIf="mainForm.controls.permitCostPerMonth.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="permitCostPerAnnum" class="form-label">Cost per Annum</label>
                    <input type="text" class="form-control" id="permitCostPerAnnum" formControlName="permitCostPerAnnum"
                        [class.is-invalid]="mainForm.controls.permitCostPerAnnum.touched && mainForm.controls.permitCostPerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitCostPerAnnum.errors">
                        <div *ngIf="mainForm.controls.permitCostPerAnnum.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="permitIncreasePerAnnum" class="form-label">Increase per Annum</label>
                    <input type="text" class="form-control" id="permitIncreasePerAnnum"
                        formControlName="permitIncreasePerAnnum"
                        [class.is-invalid]="mainForm.controls.permitIncreasePerAnnum.touched && mainForm.controls.permitIncreasePerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitIncreasePerAnnum.errors">
                        <div *ngIf="mainForm.controls.permitIncreasePerAnnum.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>






        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Duration &amp; Dates</h4>




                <div class="mb-3">
                    <label for="permitStartDate" class="form-label">Start Date</label>
                    <input type="date" class="form-control" id="permitStartDate" formControlName="permitStartDate"
                        [class.is-invalid]="mainForm.controls.permitStartDate.touched && mainForm.controls.permitStartDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitStartDate.errors">
                        <div *ngIf="mainForm.controls.permitStartDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="permitEndDate" class="form-label">End Date</label>
                    <input type="date" class="form-control" id="permitEndDate" formControlName="permitEndDate"
                        [class.is-invalid]="mainForm.controls.permitEndDate.touched && mainForm.controls.permitEndDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitEndDate.errors">
                        <div *ngIf="mainForm.controls.permitEndDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="permitTerm" class="form-label">Term</label>
                    <input type="text" class="form-control" id="permitTerm" formControlName="permitTerm"
                        [class.is-invalid]="mainForm.controls.permitTerm.touched && mainForm.controls.permitTerm.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitTerm.errors">
                        <div *ngIf="mainForm.controls.permitTerm.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="permitRenewalDate" class="form-label">Renewal Date</label>
                    <input type="date" class="form-control" id="permitRenewalDate" formControlName="permitRenewalDate"
                        [class.is-invalid]="mainForm.controls.permitRenewalDate.touched && mainForm.controls.permitRenewalDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitRenewalDate.errors">
                        <div *ngIf="mainForm.controls.permitRenewalDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="permitTerminationDate" class="form-label">Termination Date</label>
                    <input type="date" class="form-control" id="permitTerminationDate"
                        formControlName="permitTerminationDate"
                        [class.is-invalid]="mainForm.controls.permitTerminationDate.touched && mainForm.controls.permitTerminationDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitTerminationDate.errors">
                        <div *ngIf="mainForm.controls.permitTerminationDate.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>






        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Regulator Or Person Granting</h4>


                <div class="mb-3">
                    <label for="regulatorName" class="form-label">Name</label>
                    <input type="text" class="form-control" id="regulatorName" formControlName="regulatorName"
                        [class.is-invalid]="mainForm.controls.regulatorName.touched && mainForm.controls.regulatorName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.regulatorName.errors">
                        <div *ngIf="mainForm.controls.regulatorName.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="regulatorCapacity" class="form-label">Capacity</label>
                    <input type="text" class="form-control" id="regulatorCapacity" formControlName="regulatorCapacity"
                        [class.is-invalid]="mainForm.controls.regulatorCapacity.touched && mainForm.controls.regulatorCapacity.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.regulatorCapacity.errors">
                        <div *ngIf="mainForm.controls.regulatorCapacity.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="regulatorDepartment" class="form-label">Department</label>
                    <input type="text" class="form-control" id="regulatorDepartment"
                        formControlName="regulatorDepartment"
                        [class.is-invalid]="mainForm.controls.regulatorDepartment.touched && mainForm.controls.regulatorDepartment.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.regulatorDepartment.errors">
                        <div *ngIf="mainForm.controls.regulatorDepartment.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="regulatorEmail" class="form-label">Email</label>
                    <input type="text" class="form-control" id="regulatorEmail" formControlName="regulatorEmail"
                        [class.is-invalid]="mainForm.controls.regulatorEmail.touched && mainForm.controls.regulatorEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.regulatorEmail.errors">
                        <div *ngIf="mainForm.controls.regulatorEmail.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.regulatorEmail.errors.email">Invalid</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="regulatorContactNumber" class="form-label">Contact Number</label>
                    <input type="text" class="form-control" id="regulatorContactNumber"
                        formControlName="regulatorContactNumber"
                        [class.is-invalid]="mainForm.controls.regulatorContactNumber.touched && mainForm.controls.regulatorContactNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.regulatorContactNumber.errors">
                        <div *ngIf="mainForm.controls.regulatorContactNumber.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="certificateFileData" class="form-label">SANAS approved or Accredited -
                        Certificate</label>
                    <input type="file" class="form-control" id="certificateFileData"
                        formControlName="certificateFileData"
                        (change)="fileUpload(mainForm.controls.certificateFileData, $event)">
                </div>


            </div>
        </div>






        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Permit Status</h4>

                <div class="mb-3">
                    <label for="permitDocumentStatusId" class="form-label">Current Status</label>
                    <ng-select [items]="statuses" bindLabel="name" bindValue="id" id="permitDocumentStatusId"
                        formControlName="permitDocumentStatusId" placeholder="Select a request status"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.permitDocumentStatusId.touched && mainForm.controls.permitDocumentStatusId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitDocumentStatusId.errors">
                        <div *ngIf="mainForm.controls.permitDocumentStatusId.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="permitConditions" class="form-label">Conditions</label>
                    <input type="text" class="form-control" id="permitConditions" formControlName="permitConditions"
                        [class.is-invalid]="mainForm.controls.permitConditions.touched && mainForm.controls.permitConditions.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitConditions.errors">
                        <div *ngIf="mainForm.controls.permitConditions.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="permitGranted" class="form-label">Permit Granted</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="permitGranted" id="permitGrantedYes"
                                [value]="true" formControlName="permitGranted"
                                [class.is-invalid]="mainForm.controls.permitGranted.touched && mainForm.controls.permitGranted.invalid">
                            <label class="form-check-label" for="permitGrantedYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="permitGranted" id="permitGrantedNo"
                                [value]="false" formControlName="permitGranted"
                                [class.is-invalid]="mainForm.controls.permitGranted.touched && mainForm.controls.permitGranted.invalid">
                            <label class="form-check-label" for="permitGrantedNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.permitGranted.errors">
                        <div *ngIf="mainForm.controls.permitGranted.errors.required">Required</div>
                    </div>
                </div>


            </div>
        </div>



        <div class="text-end">
            <button type="button" class="btn btn-default" routerLink="/dashboard/documents/permits">Cancel</button>&nbsp;
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
    </form>




</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Send Correspondence</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="correspondenceForm" (ngSubmit)="submitCorrespondence()">

            <div class="mb-3">
                <label for="documentCorrespondenceTypeId" class="form-label">Type</label>
                <ng-select [items]="documentCorrespondenceTypes" bindLabel="name" bindValue="id"
                    id="documentCorrespondenceTypeId"
                    [class.is-invalid]="correspondenceForm.controls.documentCorrespondenceTypeId.touched && correspondenceForm.controls.documentCorrespondenceTypeId.invalid"
                    formControlName="documentCorrespondenceTypeId" placeholder="Select a template"></ng-select>
                <div class="invalid-feedback text-end"
                    *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors">
                    <div *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors.required">Required</div>
                </div>

            </div>

            <div class="mb-3">
                <label for="sender" class="form-label">Sender</label>
                <input type="text" class="form-control" id="sender" formControlName="sender"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.sender.touched) && correspondenceForm.controls.sender.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.sender.errors">
                    <div *ngIf="correspondenceForm.controls.sender.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.name.touched) && correspondenceForm.controls.name.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.name.errors">
                    <div *ngIf="correspondenceForm.controls.name.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input type="text" class="form-control" id="email" formControlName="email"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.email.touched) && correspondenceForm.controls.email.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.email.errors">
                    <div *ngIf="correspondenceForm.controls.email.errors.required">Required</div>
                    <div *ngIf="correspondenceForm.controls.email.errors.email">Invalid</div>
                </div>
            </div>

            <div class="mb-3">
                <label for="documentFileData" class="form-label">Document</label>
                <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                    (change)="fileUpload(correspondenceForm.controls.documentFileData, $event)"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.documentFileData.touched) && correspondenceForm.controls.documentFileData.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.documentFileData.errors">
                    <div *ngIf="correspondenceForm.controls.documentFileData.errors.required">Required</div>
                </div>
            </div>


            <div *ngFor="let attachment of correspondenceForm.controls.attachments.controls; let i = index"
                formArrayName="attachments">
                <div class="mb-3">
                    <label for="attachmentFileData" class="form-label">Attachment {{i+1}}</label>
                    <div class="input-group">
                        <input type="file" class="form-control" id="attachmentFileData" [formControl]="attachment"
                            (change)="fileUpload(attachment, $event)"
                            [class.is-invalid]="(correspondenceFormSubmitted || attachment.touched) && attachment.invalid">
                        <button type="button" class="btn btn-danger" (click)="removeAttachement(i)">X</button>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="attachment.errors">
                        <div *ngIf="attachment.errors.required">Required</div>
                    </div>

                </div>

            </div>
            <div class="text-end">
                <button type="button" class="btn btn-link" (click)="addAttachement()">+ Add Attachment</button>
            </div>

            <div class="text-end">
                <button type="button" class="btn btn-default" (click)="modal.dismiss()">Cancel</button>&nbsp;
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>

</ng-template>