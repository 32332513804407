<div class="mb-1" *ngIf="superAdmin">
  <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>

<div class="container d-flex pt-2">
  <div>
    
  </div>
  <table class="contract-table w-100 flex-grow-1">
    <tr>
      <th><button class="btn btn-default" (click)="back()">&lt; Back</button></th>
      <th class="text-center"><span class="icon icon-stamp-grey"></span>
        <div><small>Branded</small></div>
      </th>
      <th class="text-center"><span class="icon icon-merge-grey"></span>
        <div><small>Merged</small></div>
      </th>
      <th class="text-center"><span class="icon icon-download-grey"></span>
        <div><small>Downloaded</small></div>
      </th>
      <th class="text-center"><span class="icon icon-edit-grey"></span>
        <div><small>Last Modified</small></div>
      </th>
      <th class="text-center"><span class="icon icon-upload-grey"></span>
        <div><small>Uploaded</small></div>
      </th>
      <th class="text-center"><span class="icon icon-version-grey"></span>
        <div><small>Version</small></div>
      </th>
      <th class="text-center"><span class="icon icon-status-grey"></span>
        <div><small>Status</small></div>
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    <tr *ngFor="let contract of contracts">
      <td><span class="icon icon-documents-grey me-2"></span>{{contract.title}}</td>
      <td class="text-center">
        <span *ngIf="!contract.contractTemplate || !contract.contractTemplate.companyId">&nbsp;</span>
        <span *ngIf="contract.contractTemplate && contract.contractTemplate.companyId" class="icon icon-tick-green"></span>
      </td>
      <td class="text-center">
          <span *ngIf="contract.contractStatus.id != 1" class="icon icon-tick-green"></span>
          <a *ngIf="contract.contractStatus.id == 1" (click)="openContract(contract)"><span class="icon icon-plus-red"></span></a>
        </td>
      <td class="text-center">
        {{contract.downloadedAt | date:'yyyy-MM-dd HH:mm:ss' }}
      </td>
      <td class="text-center">
        {{contract.lastModified | date:'yyyy-MM-dd HH:mm:ss' }}
      </td>
      <td class="text-center">
        {{contract.createdAt | date:'yyyy-MM-dd HH:mm:ss' }}
      </td>
      <td class="text-center"><span *ngIf="contract.version">{{contract.version}}</span></td>
      <td class="text-center">
        <span *ngIf="contract.contractStatus">{{contract.contractStatus.description}}</span>
        <a *ngIf="contract.contractStatus && contract.contractStatus.id == 2" (click)="closeContract(contract)" title="Close case">&nbsp;<span class="icon icon-tick-red"></span></a>
      </td>
      <td class="text-center no-white-bg">
        <a *ngIf="contract.folderId" [href]="'https://legalteam.awsapps.com/workdocs/index.html#/folder/' + contract.folderId" target="_blank" title="View document in WorkDocs"><span class="icon icon-view-red"></span></a>
      </td>
      <td class="text-center no-white-bg">
        <a *ngIf="contract.documentId" [routerLink]="'/dashboard/contracts-old/messages/' + contract.id" title="View messages"><span class="icon icon-email-red"></span></a>
      </td>
      <td class="text-center no-white-bg">
        <a href="#" (click)="openReminderModal($event, reminderModal, contract)" title="Reminder">
          <span *ngIf="!contract.reminderDateTime" class="icon icon-notification-grey"></span>
          <span *ngIf="contract.reminderDateTime" class="icon icon-notification-red"></span>
        </a>
      </td>
    </tr>
  </table>

</div>

<ng-template #reminderModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Reminder</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form>
      <div class="modal-body">
        
          
          <div class="form-group">
            <input class="form-control" [ngModel]="currentReminder.reminderDateTime | date:'yyyy-MM-dd'" (ngModelChange)="currentReminder.reminderDateTime = $event" type="date" id="reminderDateTime" name="reminderDateTime"  placeholder="Reminder Date">
          </div>
          <div class="form-group">
            <input class="form-control" [(ngModel)]="currentReminder.reminderText" type="text" id="reminderText" name="reminderText" placeholder="Reminder Text">
          </div>
        
      </div>
      <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-primary" [disabled]="!currentReminder.reminderDateTime || !currentReminder.reminderText"
          (click)="modal.close('Save click')">Save</button>
      </div>
    </form>
  </ng-template>