import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver, Inject, Output, EventEmitter } from '@angular/core';
import { LibraryTreeItem } from '../../models/libraryTreeItem';
import { SpinnerService } from '../../services/spinner.service';
import { LibraryApiService } from '../../services/library-api.service';
import { ListingItemAdmin } from 'src/app/models/listing-item-admin';
import { ChangedItem } from 'src/app/models/listingsBulkUpdate';

@Component({
  selector: 'app-library-tree-list',
  templateUrl: './library-tree-list.component.html',
  styleUrls: ['./library-tree-list.component.scss']
})
export class LibraryTreeListComponent implements OnInit {

  @ViewChild('children', {
    read: ViewContainerRef
}) viewContainerRef: ViewContainerRef

@ViewChild('childList',  { static: true }) childList: LibraryTreeListComponent

  public items: LibraryTreeItem[];
  public selectedItem: LibraryTreeItem;

  //checkedChildren: any[];
  //checkedItems: number[];

  _parentId: number;
  _typeId: number;
  _companyId: number;
  factoryResolver: any;

  @Input()
  set parentId(parentId: number) {
    this._parentId = parentId;
    this.getData();
  }

  @Input()
  set typeId(id: number) {
    this._typeId = id;
    this.getData();
  }

  @Input()
  set companyId(id: number) {
    this._companyId = id;
    this.getData();
  }

  @Output()
  selected: EventEmitter<LibraryTreeItem> = new EventEmitter();
  // @Output()
  // checked: EventEmitter<number[]> = new EventEmitter();
  @Output()
  changed: EventEmitter<ChangedItem> = new EventEmitter();
  
  constructor(
    private spinner: SpinnerService,
    private libraryApi: LibraryApiService
  ) {
  }

  ngOnInit() {
  }

  public reload(){
    //this.checkedChildren = null;
    //this.checkedItems = null;
    this.getData();
  }
  getData() {
    if (this._typeId && this._companyId)
    {
      this.spinner.startBackground();

      this.libraryApi.ListItemsAdmin(this._companyId, this._parentId, this._typeId).subscribe(data => {
        this.items = this.convertItems(data);
        //this.checkedItems = this.getCheckedItems(); 
        //this.items.filter(f => f.checked).map(m => m.item.id);
        //this.emitChecked();
        this.spinner.stopBackground();
      });
    }
  }

  
  itemChanged(item, event){
    
    this.changed.emit({id: item.item.id, checked: item.checked});
    //this.emitChanged();
  }

  childrenChanged(parentId, item)
  {
    this.changed.emit(item);
  }

  // check(id, items){

  //   if (!this.checkedChildren){
  //     this.checkedChildren = new Array();
  //   }

  //   let item = this.checkedChildren.find(f => f.id == id);

  //   if (!item){
  //     item = {
  //       id: id,
  //       items: items
  //     };

  //     this.checkedChildren.push(item);
  //   }
  //   else{
  //     item.items = items;
  //   }

  //   //this.checkedChildren = items;
  //   this.emitChecked();
  // }
  // emitChecked(){

  //   let ids = new Array<number>();

  //   if (this.checkedItems){
  //     ids = ids.concat(this.checkedItems);
  //   }

  //   if (this.checkedChildren){
  //     for (let c of this.checkedChildren)
  //     {
  //       if (c.items)
  //       {
  //         ids = ids.concat(c.items);
  //       }
  //     }
  //   }

  //   this.checked.emit(ids);
  // }

 
  select(item: LibraryTreeItem) {
    this.selected.emit(item);
  }
  
  expand(item: LibraryTreeItem) {
    if (!item) {
      return;
    }

    if (item.expanded) {
      item.expanded = false;
    }
    else {
      item.expanded = true;
    }



    // this.spinner.startBackground();
    // this.libraryApi.ListItems(item.item.id, 1, 1000).subscribe(data => {
    //   item.expanded = true;
    //   item.children = this.convertItems(data.items);
    //   this.spinner.stopBackground();
    // });
  }

  convertItems(items: ListingItemAdmin[]) {
    let newItems: LibraryTreeItem[] = new Array;

    items.forEach(f => {
      //if (f.exclusive) return;
      newItems.push({
        children: null,
        expanded: false,
        item: f,
        checked: f.access
      });
    });

    return newItems;
  }

}
