import { Component, OnInit } from '@angular/core';
import { State } from 'src/app/enums/state';
import { SubMenuItem } from 'src/app/models/sub-menu-item';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public items: Array<SubMenuItem>;

  constructor() {

    this.items = [
      { text: "Users", state: State.Incomplete, icon: 'icon-user-management-red', link: '/dashboard/admin/users' },
      { text: "Library", state: State.Incomplete, icon: 'icon-library-red', link: '/dashboard/admin/library' },
      { text: "Documents", state: State.Incomplete, icon: 'icon-document-management-red', link: '/dashboard/admin/documents' },
      { text: "Acts", state: State.Incomplete, icon: 'icon-acts-red', link: '/dashboard/admin/acts' },
      { text: "E-Learning", state: State.Incomplete, icon: 'icon-elearning-red', link: '/dashboard/admin/e-learning' },
      { text: "Compliance Program", state: State.Incomplete, icon: 'icon-compliance-program-red', link: '/dashboard/admin/compliance-program' },
      { text: "Self Assessment", state: State.Incomplete, icon: 'icon-self-assessment-red', link: '/dashboard/admin/self-assessment' },
      { text: "Periods", state: State.Incomplete, icon: 'icon-period-red', link: '/dashboard/admin/periods' },

    ];

  }

  ngOnInit() {
  }


}
