import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyAct } from 'src/app/models/companyAct';
import { CompanyDetailStatus } from 'src/app/models/companyDetailStatus';
import { RiskData } from 'src/app/models/riskData';
import { RiskRating } from 'src/app/models/riskRating';

@Component({
  selector: 'app-risk-modal',
  templateUrl: './risk-modal.component.html',
  styleUrls: ['./risk-modal.component.scss']
})
export class RiskModalComponent implements OnInit {

  public _rating: RiskRating = null;
  public _residualModal: boolean = false;
  public _status: CompanyDetailStatus = null;
  public _act: CompanyAct = null;
  public _riskData: RiskData = null;
  public _readOnly: boolean = false;
  constructor() { }
  
  @Input()
  set rating(rating: RiskRating){
    this._rating = rating;
  }
 
  @Input()
  set residualModal(residualModal: boolean){
    this._residualModal = residualModal;
  }
  @Input()
  set status(status: CompanyDetailStatus){
    this._status = status;
  }
  @Input()
  set act(act: CompanyAct){
    this._act = act;
  }
  @Input()
  set riskData(riskData: RiskData){
    this._riskData = riskData;
  }
  @Input()
  set readOnly(readOnly: boolean){
    this._readOnly = readOnly;
  }

  @Output()
  onSelect = new EventEmitter<RiskRating>();
  
  ngOnInit(): void {
  }

  riskUpdated(ev) {
    this._rating = ev;
    this.onSelect.emit(ev);
  }
}
