<div ngbDropdown placement="left-top" class="d-inline-block" (openChange)="dropdownToggled($event)">
  <button class="btn btn-link" id="dropdownNotificationsButton" ngbDropdownToggle>
    <span *ngIf="!count" class="icon icon-notification-grey"></span>
    <span *ngIf="count" class="icon icon-notification-red"></span>
    <span *ngIf="count" class="badge rounded-pill  badge-primary">{{count}}</span>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownNotificationsButton">
    <div class="p-2 px-4 text-center" *ngIf="!notifications || notifications.length == 0">No notifications</div>
    <div class="notification-list p-2 px-4" *ngIf="notifications && notifications.length > 0">
      <ng-container *ngFor="let notification of notifications; let i = index">
        <hr *ngIf="i > 0" />
        <div class="notification-item d-flex align-items-center">
          <div class="flex-grow-1">
            <strong>{{notification.title}}</strong><br />
            <span>{{notification.body}}</span>
          </div>
          <div class="ps-4">
            <button class="btn btn-link" title="Archive" (click)="archive($event, notification)">
              <span class="icon icon-small icon-hide-red"></span>

            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>