<div class="container-fluid pt-4" *ngIf="roleOwners">
  <div class="text-center pb-2">
    To add a default owner to all the roles enter the details in this top row and click on ADD DEFAULT OWNER. You may
    then still change individual rows before clicking SAVE at the bottom
  </div>
  <div class="role-owner-row py-1 text-center form-inline">
    <div class="mx-auto d-flex justify-content-center">
      <input type="text" disabled class="form-control mx-2" value="DEFAULT">-
      <input type="text" disabled class="form-control mx-2" value="OWNER">&nbsp;
      <input type="text" class="form-control mx-2" [(ngModel)]="defaultName" placeholder="Name">&nbsp;
      <input type="text" class="form-control mx-2" [(ngModel)]="defaultSurname" placeholder="Surname">&nbsp;
      <input type="text" class="form-control mx-2" [(ngModel)]="defaultEmail" placeholder="Email">
    </div>
  </div>
  <div class="text-center pt-2">
    <button type="button" (click)="addDefaultOwner()" class="btn btn-default">Add Default Owner</button>
  </div>
  <hr />
  <form [formGroup]="mainForm" (ngSubmit)="submit()">
    <div formArrayName="items" *ngFor="let ro of mainForm.get('items').controls; let i = index">
      <div [formGroupName]="i" class="role-owner-row py-1 text-center form-inline">
        <div class="mx-auto d-flex justify-content-center">
          <input type="hidden" formControlName="departmentId">
          <input type="text" formControlName="department" class="form-control mx-2">-
          <input type="text" formControlName="customTitle" class="form-control mx-2" placeholder="Custom Title">&nbsp;
          <input type="text" formControlName="firstname" class="form-control mx-2" placeholder="Name">&nbsp;
          <input type="text" formControlName="lastname" class="form-control mx-2" placeholder="Surname">&nbsp;
          <input type="text" formControlName="email" class="form-control mx-2" placeholder="Email">
        </div>
      </div>
    </div>
    <hr>
    <div class="text-center pb-4">
      <button type="submit" class="btn btn-default">Save</button>&nbsp;<button class="btn btn-default"
        (click)="back()">Back</button>
    </div>
  </form>
</div>