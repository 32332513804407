import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyAct } from 'src/app/models/companyAct';
import { CompanyDetailStatus } from 'src/app/models/companyDetailStatus';
import { RiskData } from 'src/app/models/riskData';
import { RiskRating } from 'src/app/models/riskRating';
import { UpdateCompanyActImpactRating } from 'src/app/models/updateCompanyActImpactRating';
import { CompanyApiService } from 'src/app/services/company-api.service';
import { LookupApiService } from 'src/app/services/lookup-api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StatusApiService } from 'src/app/services/status-api.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-compliance-status',
  templateUrl: './compliance-status.component.html',
  styleUrls: ['./compliance-status.component.scss']
})
export class ComplianceStatusComponent implements OnInit {

  @Input()
  public status: CompanyDetailStatus

  @Input()
  public act: CompanyAct

  @Input()
  public modal: boolean = false;

  @Output() onFilter = new EventEmitter<number>();
  @Output() onExport = new EventEmitter();
  @Output() onUpdate = new EventEmitter();

  orgId: number = 0;

  superAdmin = false;
  orgAdmin = false;

  residualModal = false;

  constructor(
    private token: TokenService,
     private lookupApi: LookupApiService, 
     private modalService: NgbModal, 
     private spinner: SpinnerService,
     private companyApi: CompanyApiService,
     private statusApi: StatusApiService) { }

  ngOnInit(): void {

    this.superAdmin = this.token.isSuperAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();

    if (this.superAdmin) {
      this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.orgId = this.token.organisationId();
    }

  }

  getCompanyAudits(id: number){
    this.onFilter.emit(id);
  }

  export(){
    this.onExport.emit();
  }

  getRiskLabel(risk: string, likelihood?: number, impact?: number){
    
    return this.lookupApi.GetImpactRating(this.orgId, risk, likelihood, impact);

  }

  showRiskModal(residualModal: boolean){
    this.residualModal = residualModal;
    
  }

  riskModalTitle = '';
  newRating: RiskRating = null;
  riskData: RiskData = null;
  risidualRiskStatus: CompanyDetailStatus = null;
  readOnly = false;

  openRiskModal(content) {
    this.riskModalTitle = "Update Impact Risk";
    this.residualModal = false;
    this.risidualRiskStatus = null;
    this.readOnly = true;

    this.newRating = {
      impact: this.act.impact,
      likelihood: this.act.likelihood
    };

    this.riskData = {
      controls: this.act.controls,
      implications: this.act.implications,
      riskDescription: this.act.riskDescription
    };

    //this.currentImpactRating = act.impactRating.id;
    this.modalService.open(content, { size: "lg" }).result.then((result) => {
      
    }, (reason) => {
    });
  }

  

  openResidualRiskModal(content) {
    this.readOnly = false;
    this.riskModalTitle = this.act.name + " - Residual Risk";
    this.risidualRiskStatus = null;

    this.residualModal = true;
    this.spinner.start();

    let companyId = null;

    if (this.superAdmin || this.orgAdmin){
      companyId = this.act.companyId;
    }
    this.statusApi.getCompanyStatus(companyId, this.act.actId).subscribe(data => {
      
      this.spinner.stop();
      this.risidualRiskStatus = data;

      this.newRating = {
        impact: this.act.residualImpact,
        likelihood: this.act.residualLikelihood
      };

      this.riskData = {
        controls: this.act.controls,
        implications: this.act.implications,
        riskDescription: this.act.riskDescription
      };

      
      this.modalService.open(content, { size: "lg" }).result.then((result) => {
        let newId = this.newRating.id;
        let likelihood = this.newRating.likelihood;
        let impact = this.newRating.impact;
        if (newId) {
          let data: UpdateCompanyActImpactRating = {
            actId: this.act.actId,
            companyId: this.act.companyId,
            impactRatingId: newId,
            likelihood: likelihood,
            impact: impact
          };

          this.spinner.startBackground();

          this.companyApi.UpdateResidualRiskRating(data).subscribe(() => {
            // this.cache.clear();
            // this.getData();
            // this.getStatus();
            this.onUpdate.emit();
            this.spinner.stop();
          });
        }
      }, (reason) => {
      });


    });


  }

  riskUpdated(ev) {
    this.newRating = ev;
  }


  // openRiskModal(content) {
  //   this.residualModal = false;
  //   this.riskModalTitle = "Impact Risk";
  //   this.residualModal = false;

  //   this.modalService.open(content, { size: "lg" }).result.then((result) => {
      
  //   });

  //   setTimeout(() => {
  //     document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
  //   }, 500);
  // }

  // openResidualRiskModal(content) {
  //   this.residualModal = true;
  //   this.riskModalTitle = "Residual Risk";
  //   this.residualModal = false;

  //   this.modalService.open(content, { size: "lg" }).result.then((result) => {

      
  //   });

  //   setTimeout(() => {
  //     document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
  //   }, 500);
    
  // }

}
