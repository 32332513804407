<div class="mb-1" *ngIf="superAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div *ngIf="contractTemplates">
    <ng-container *ngIf="!add && !edit">
        <div class="search-bar py-4">
            <div class="container d-flex">
                <div class="d-flex align-items-center">
                    <div class="px-1"><button class="btn btn-primary btn-small" type="button"
                            (click)="back()">&lt;&nbsp;BACK</button></div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="px-1">Type:</div>
                    <div class="px-1">
                        <select class="form-control" [(ngModel)]="currentType" (change)="filter()" style="width:200px;">
                            <option [selected]="currentType == 0" value="0">All</option>
                            <option [selected]="currentType == 1" value="1">Generic</option>
                            <option [selected]="currentType == 2" value="2">Branded</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end flex-grow-1">
                    <div class="px-1"><span class="icon icon-search-red"></span></div>
                    <div class="px-1">Search:</div>
                    <div class="px-1"><input class="form-control" name="search" type="text" [(ngModel)]="searchTerm" />
                    </div>
                    <div class="px-1"><button class="btn btn-primary btn-small" type="button"
                            [disabled]="searchTerm == ''" (click)="filter()">SEARCH</button></div>
                    <div class="px-1"><button class="btn btn-primary btn-small" type="button" (click)="clear()"
                            [disabled]="searchTerm == ''">CLEAR</button></div>
                    <div *ngIf="superAdmin || contractAdmin" class="px-1"><button class="btn btn-primary btn-small"
                            type="button" (click)="addTemplate()">ADD NEW
                            TEMPLATE</button></div>
                </div>
            </div>
        </div>
        <div class="container">

            <div *ngIf="contractTemplates.length == 0" class="p-2 text-center">
                No templates loaded yet.
            </div>
            <ng-container *ngFor="let template of filteredContractTemplates">
                <div class="mb-1 d-flex  align-items-center">
                    <div class="listing flex-grow-1 p-2 d-flex flex-row">
                        <div class="text-start flex-grow-1"><button class="btn btn-link p-0"
                                (click)="request(template)"><strong>{{template.name}}</strong></button></div>
                        <div *ngIf="template.companyId"><span class="icon icon-branded-red"></span></div>
                    </div>
                    <div class="px-3 d-flex">
                        <button class="btn btn-link p-0" (click)="request(template)" title="Request Edit"><span
                                class="icon icon-tick-red"></span></button>
                        <ng-container
                            *ngIf="this.superAdmin || (this.contractAdmin && template.companyId == this.companyId)">
                            <button class="btn btn-link ms-2 p-0" (click)="editTemplate(template)" title="Edit"><span
                                    class="icon icon-edit-red"></span></button>
                            <button class="btn btn-link ms-2 p-0" (click)="archive(template)" title="Archive"><span
                                    class="icon icon-trash-red"></span></button>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="currentTemplate && (add || edit)">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-10 col-md-8 col-lg-6  offset-sm-1 offset-md-2 offset-lg-3 ">
                    <form [formGroup]="mainForm" (ngSubmit)="submit()">
                        <div class="form-group row">
                            <label for="complianceQuery" class="col-12 col-form-label text-startcol-1">Name</label>
                            <div class="col-12">
                                <input type="text" class="form-control" id="name" formControlName="name">
                                <!-- <bfv-messages></bfv-messages> -->
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="type" class="col-12 col-form-label text-startcol-1">Document Type</label>
                            <div class="col-12">
                                <ng-select formControlName="contractType" [items]="contractTypes" bindLabel="name" bindValue="id" placeholder="No Document Type selected">
                                </ng-select>
                                <!-- <bfv-messages></bfv-messages> -->
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="document" class="col-12 col-form-label text-startcol-1">Template Document</label>
                            <div class="col-12">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" formControlName="document"
                                        (change)="fileUpload($event)">
                                    <label class="custom-file-label" for="logo">
                                        Select file...
                                    </label>
                                </div>
                                <!-- <bfv-messages></bfv-messages> -->
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="this.superAdmin">
                            <div class="form-check d-flex align-items-center ms-3">
                                <input class="form-check-input" type="checkbox" id="companySpecific"
                                    formControlName="companySpecific">
                                <label class="form-check-label" for="companySpecific">
                                    Company Specific (Branded)?
                                </label>
                            </div>
                        </div>
                        <div class="text-end">
                            <button type="button" class="btn btn-default me-2" (click)="cancel()">Cancel</button>
                            <button type="submit" class="btn btn-default">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </ng-container>
</div>