  <div class="container-fluid" *ngIf="contract">
    <div class="row">
      <div class="col-12">
        <button class="my-2 btn btn-default" (click)="back()">&lt; Back</button>
      </div>
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 mx-auto">
        <form [formGroup]="requestForm" (ngSubmit)="submit()">
          <div class="form-group row">
            <label for="title" class="col-12 col-form-label text-startcol-1">Request Title <span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="title" formControlName="title">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <h4 class="pt-2">Your Company Information:</h4>
            </div>
          </div>
          <div class="form-group row">
            <label for="name" class="col-12 col-form-label text-startcol-1">Company Name<span
              class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="companyName" formControlName="companyName">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="surname" class="col-12 col-form-label text-startcol-1">Company Registration Number<span
              class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="companyRegistration" formControlName="companyRegistration">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="email" class="col-12 col-form-label text-startcol-1">Company VAT Number</label>
            <div class="col-12">
              <input type="email" class="form-control" id="companyVAT" formControlName="companyVAT">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <h4 class="pt-2">Your information:</h4>
            </div>
          </div>
          <div class="form-group row">
            <label for="name" class="col-12 col-form-label text-startcol-1">Name <span class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="name" formControlName="name">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="surname" class="col-12 col-form-label text-startcol-1">Surname <span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="surname" formControlName="surname">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="email" class="col-12 col-form-label text-startcol-1">Email <span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="email" class="form-control" id="email" formControlName="email">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="type" class="col-12 col-form-label text-startcol-1">Document Type<span
              class="text-danger">*</span></label>
            <div class="col-12">
                <ng-select formControlName="contractType" [items]="contractTypes" bindLabel="name" bindValue="id" placeholder="No Document Type selected" (change)="selectType($event)">
                </ng-select>
                <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="requiredDocument" class="col-12 col-form-label text-startcol-1">Document Template</label>
            <div class="col-12">
              <ng-select [items]="filteredContractTemplates" bindLabel="name" bindValue="id" placeholder="No Template"
                formControlName="requiredDocument">
                <ng-template ng-label-tmp let-item="item">
                    <span *ngIf="item.companyId">(B)</span>
                    <span *ngIf="!item.companyId">(G)</span>
                  <span> {{item.name}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <span *ngIf="item.companyId">(B)</span>
                  <span *ngIf="!item.companyId">(G)</span>
                  <span> {{item.name}}</span>
                </ng-template>
              </ng-select>
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <h4 class="pt-4">Who must the agreement be with?</h4>
            </div>
          </div>
          <div class="form-group row">
            <label for="counterpartyName" class="col-12 col-form-label text-startcol-1">Name<span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="counterpartyName" formControlName="counterpartyName">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="counterpartyRegistrationNumber" class="col-12 col-form-label text-startcol-1">Registration
              Number / Identification Number<span class="text-danger">*</span>
            </label>
            <div class="col-12">
              <input type="text" class="form-control" id="counterpartyRegistrationNumber"
                formControlName="counterpartyRegistrationNumber">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="counterpartyPhysicalAddress" class="col-12 col-form-label text-startcol-1">Physical Address<span class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="counterpartyPhysicalAddress"
                formControlName="counterpartyPhysicalAddress">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="counterpartyPostalAddress" class="col-12 col-form-label text-startcol-1">Postal Address<span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="counterpartyPostalAddress" formControlName="counterpartyPostalAddress">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="representativeName" class="col-12 col-form-label text-startcol-1">Representative Name<span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="representativeName" formControlName="representativeName">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="representativeSurname" class="col-12 col-form-label text-startcol-1">Representative Surname<span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="representativeSurname" formControlName="representativeSurname">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="representativeTitle" class="col-12 col-form-label text-startcol-1">Representative Title <span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="text" class="form-control" id="representativeTitle" formControlName="representativeTitle">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="representativeEmail" class="col-12 col-form-label text-startcol-1">Representative Email <span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="email" class="form-control" id="representativeEmail" formControlName="representativeEmail">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="effectiveDate" class="col-12 col-form-label text-startcol-1">Effective Date <span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="date" class="form-control" id="effectiveDate" formControlName="effectiveDate">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <label for="terminationDate" class="col-12 col-form-label text-startcol-1">Termination Date <span
                class="text-danger">*</span></label>
            <div class="col-12">
              <input type="date" class="form-control" id="terminationDate" formControlName="terminationDate">
              <!-- <bfv-messages></bfv-messages> -->
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 text-center">
              <button type="submit" class="mt-4 mb-4 btn btn-default">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
