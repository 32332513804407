<div class="menu py-1 h-100" [class.open]="this.menuOpen">
  <ul class="menu-items h-100 d-flex flex-column">
    <li>
      <button class="btn btn-link icon-link" (click)="toggleMenu()">
        <img *ngIf="!this.menuOpen" class="icon" src="/assets/expand_left_menu.png" />
        <img *ngIf="this.menuOpen" class="icon" src="/assets/collapse_left_menu.png" />
      </button>
    </li>
    <li *ngIf="superAdmin">
      <a routerLink="/dashboard/configuration/organisation/list" class="btn btn-link icon-link">
        <img class="icon" src="/assets/configuration_b_white.png" /><span *ngIf="this.menuOpen" class="px-1">Configuration</span>
      </a>
    </li>
    <li *ngIf="superAdmin || orgAdmin || companyAdmin">
      <a routerLink="/dashboard/umbrella-report" class="btn btn-link icon-link">
        <img class="icon" src="/assets/umbrella_report_b_white.png" /><span *ngIf="this.menuOpen" class="px-1">Umbrella
          Report</span>
      </a>
    </li>
    <li *ngIf="superAdmin || orgAdmin || companyAdmin || user">
      <a routerLink="/dashboard/self-assessment-checklist" class="btn btn-link icon-link">
        <img class="icon" src="/assets/action_plan_b_white.png" /><span *ngIf="this.menuOpen" class="px-1">Self
          Assessment Checklist</span>
      </a>
    </li>
    <li *ngIf="superAdmin || orgAdmin || companyAdmin || user">
      <a routerLink="/dashboard/e-learning" class="btn btn-link icon-link">
        <img class="icon" src="/assets/elearning_b_white.png" /><span *ngIf="this.menuOpen" class="px-1">E-Learning</span>
      </a>
    </li>
    <li *ngIf="superAdmin  || orgAdmin || companyAdmin || contractAdmin">
      <a routerLink="/dashboard/documents" class="btn btn-link icon-link">
        <img class="icon" src="/assets/contracts_b_white.png" /><span *ngIf="this.menuOpen" class="px-1">Documents</span>
      </a>
    </li>
    <li *ngIf="superAdmin || orgAdmin || companyAdmin || user">
      <a routerLink="/dashboard/compliance-program" class="btn btn-link icon-link">
        <img class="icon" src="/assets/internal_audit_b_white.png" /><span *ngIf="this.menuOpen" class="px-1">Compliance
          Program</span>
      </a>
    </li>
    <li *ngIf="superAdmin || orgAdmin || companyAdmin || user || libraryUser">
      <a routerLink="/dashboard/library" class="btn btn-link icon-link">
        <img class="icon" src="/assets/legal_Library.png" /><span *ngIf="this.menuOpen" class="px-1">Legal Library</span>
      </a>
    </li>
    <li *ngIf="superAdmin || orgAdmin || companyAdmin || user">
      <a routerLink="/dashboard/reports" class="btn btn-link icon-link">
        <img class="icon" src="/assets/reports_b_white.png" /><span *ngIf="this.menuOpen" class="px-1">Reports</span>
      </a>
    </li>
    <li *ngIf="superAdmin || orgAdmin || companyAdmin || user || libraryUser">
      <a routerLink="/dashboard/profile" class="btn btn-link icon-link">
        <img class="icon" src="/assets/my_profile.png" /><span *ngIf="this.menuOpen" class="px-1">My Profile</span>
      </a>
    </li>
    <li *ngIf="superAdmin || orgAdmin || companyAdmin || user">
      <a routerLink="/dashboard/contact" class="btn btn-link icon-link">
        <img class="icon" src="/assets/contact_us.png" /><span *ngIf="this.menuOpen" class="px-1">Contact Us</span>
      </a>
    </li>
    <li *ngIf="superAdmin">
      <a routerLink="/dashboard/admin" class="btn btn-link icon-link">
        <img class="icon" src="/assets/admin.png" /><span *ngIf="this.menuOpen" class="px-1">Admin</span>
      </a>
    </li>

    <li class="mt-auto">
      <button class="btn btn-link icon-link" (click)="logout()">
        <img class="icon" src="/assets/logout.png" /><span *ngIf="this.menuOpen" class="px-1">Log Out</span>
      </button>
    </li>
  </ul>

</div>