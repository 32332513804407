<div class="mb-1">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<ng-container *ngIf="company">
    <div class="company-info mb-1 p-2 ">
        <div class="container d-flex  align-items-center">
            <div class="company-logo flex-grow-1">
                <img [src]="company.logoUrl">
            </div>
            <div class="d-flex  align-items-center">
                <div class="px-1"><button class="btn btn-primary btn-small" type="button" (click)="add()">ADD
                        PERIOD</button></div>

            </div>
        </div>
    </div>
    <ng-container *ngIf="periods">
        <div class="container">
            <div *ngIf="!periods || periods.length == 0" class="text-center">No periods</div>
            <ng-container *ngIf="addPeriod" >
            <div class="py-2 d-flex  align-items-center justify-content-center text-danger">
                NOTE: Adding a new period will archive all the current Self Assessment Checklists and Compliance Program
                items and start a new period. This cannot be undone!
            </div>
            <div class="py-2 d-flex  align-items-center">
                <span class="icon icon-period-red user-icon pe-1"></span>
                <div class="py-2 d-flex flex-grow-1">
                    <input type="text" class="form-control mx-2 pe-1  flex-fill w-25" placeholder="Name"
                        [(ngModel)]="newPeriod.name" />
                </div>
                <button class="btn btn-link pe-1" (click)="updatePeriod(newPeriod)"><span class="icon icon-tick-red"
                        title="Add"></span></button>
            </div>
            <div class="py-2">
                <hr />
            </div>
            </ng-container>
            <div *ngFor="let period of periods" class="py-2 d-flex  align-items-center">
                <span class="icon icon-period-red user-icon pe-1"></span>
                <div class="py-2 d-flex flex-grow-1">
                    <input type="text" class="form-control mx-2 pe-1  flex-fill w-25" placeholder="Name"
                        [(ngModel)]="period.name" />
                </div>
                <button class="btn btn-link pe-1" (click)="update(period)"><span class="icon icon-tick-red"
                        title="Update"></span></button>
            </div>
        </div>
    </ng-container>
</ng-container>