import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contracts-puzzle-wheel',
  templateUrl: './contracts-puzzle-wheel.component.html',
  styleUrl: './contracts-puzzle-wheel.component.scss'
})
export class ContractsPuzzleWheelComponent {

  constructor(private ngZone: NgZone, private router: Router){
    (<any>window).go = this.go.bind(this);
  }

  go(id: string) {
    switch (id) {
      case 'contracts':
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard/documents/contracts')).then();
        break;
      case 'paia':
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard/documents/paia')).then();
        break;
      case 'permits':
        this.ngZone.run(() => this.router.navigateByUrl('/dashboard/documents/permits')).then();
        break;
        case 'fica':
          this.ngZone.run(() => this.router.navigateByUrl('/dashboard/documents/fica')).then();
          break;
     
    }
  }
}
