import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { EnvironmentService } from './environment.service';
import { Industry } from '../models/industry';

@Injectable({
  providedIn: 'root'
})
export class IndustryApiService extends ApiService<Industry> {

  _controller: string = 'industries';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('industries', http, env);
  }
}
