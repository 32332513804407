import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TokenService } from '../../../../services/token.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { SpinnerService } from '../../../../services/spinner.service';
import { ContractsApiService } from '../../../../services/contracts-api.service';
import { AlertService } from '../../../../services/alert.service';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Company } from 'src/app/models/company';
import { Contract } from 'src/app/models/contract';
import { ContractReminder } from 'src/app/models/contractReminder';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit {

  user: boolean = false;
  contractAdmin: boolean = false;
  superAdmin: boolean = false;

  companyId: number;
  apiCompanyId?: number;
  contracts: Contract[];
  company: Company;

  currentType?: number;

  reminderForm = this.formBuilder.group({
    reminderDateTime: ['', [Validators.required]],
    reminderText: ['', [Validators.required]]
  });

  constructor(
    public token: TokenService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private router: Router,
    private contractsApi: ContractsApiService,
    private alert: AlertService,
    private cache: RequestCacheService,
    private cdref: ChangeDetectorRef,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngOnInit() {

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.currentType = +params.get('id');
      this.getContracts();
    });

    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.contractAdmin || this.user) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.getContracts();
      }

    }
    else if (this.superAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.getContracts();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/contracts');
    }
  }

  companySelected(company) {

    if (company) {
      this.company = company;
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.getContracts();
    }
    else {
      this.contracts = null;
    }
  }

  getContracts() {
    this.spinner.startBackground();
    this.contractsApi.ListContracts(this.apiCompanyId, this.currentType).subscribe(data => {
      this.contracts = data;
      this.spinner.stopBackground();
    });
  }

  back() {
    this.router.navigateByUrl('/dashboard/contracts');
  }

  openContract(contract: Contract){
    this.router.navigateByUrl('/dashboard/contracts-old/open/' + contract.id);
  }

  closeContract(contract: Contract){
    this.spinner.startBackground();
    this.contractsApi.CloseContract(contract).subscribe(data => {
      this.spinner.stopBackground();
      this.cache.clear();
      this.router.navigateByUrl('/dashboard/contracts');
    })
  }

  public currentReminders: ContractReminder[];

  openReminderModal(ev: Event, content: any, contract: Contract){

    ev.preventDefault();
    this.spinner.startBackground();

    this.contractsApi.GetContractReminders(contract.id).subscribe((data => {

      this.spinner.stopBackground();
      this.modalService.open(content).result.then((result) => {     

        this.spinner.startBackground();
        
      }, (reason) => {
      });

    }));

    
  }

 
}
