<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 offset-sm-1 offset-md-2 offset-lg-3 offset-xl-4">
        <h3 class="text-uppercase text-center">Welcome - please choose a password</h3>
        <form [formGroup]="mainForm" (ngSubmit)="submit()">
          
          <!-- <div class="form-group row" [customErrorMessages]="customErrorMessages"> -->
            <div class="form-group row">
            <label for="choosePassword" class="col-sm-3 col-lg-4 col-form-label">Choose Password</label>
            <div class="col-sm-9 col-lg-8">
              <input type="password" class="form-control" formControlName="choosePassword" [class.is-invalid]="submitted && mainForm.controls.choosePassword.invalid">
              <div class="invalid-feedback text-end" *ngIf="mainForm.controls.choosePassword.errors">
                <div *ngIf="mainForm.controls.choosePassword.errors.required">Required</div>
                <div *ngIf="mainForm.controls.choosePassword.errors.pattern">Minimum 5 characters and must contain at least 1 capital letter, number and special character</div>
              </div>
            </div>
          </div>
          <!-- <div class="form-group row" [customErrorMessages]="customErrorMessages"> -->
          <div class="form-group row">
            <label for="confirmPassword" class="col-sm-3 col-lg-4 col-form-label">Confirm Password</label>
            <div class="col-sm-9 col-lg-8">
              <input type="password" class="form-control" formControlName="confirmPassword" [class.is-invalid]="submitted && mainForm.controls.confirmPassword.invalid">
              <div class="invalid-feedback text-end" *ngIf="mainForm.controls.confirmPassword.errors">
                <div *ngIf="mainForm.controls.confirmPassword.errors.required">Required</div>
                <div *ngIf="mainForm.controls.confirmPassword.errors.matchOther">Passwords do not match</div>
              </div>
            </div>
          </div>
          <div class="text-end">
            <button type="submit" class="btn btn-default">Submit</button>
          </div>
        </form>
    </div>
  </div>
</div>
