import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompanyApiService } from '../../services/company-api.service';
import { SpinnerService } from '../../services/spinner.service';
import { EventsService } from '../../services/events.service';
import { CompanyStatus } from 'src/app/models/status';

@Component({
  selector: 'app-business-unit-list-item',
  templateUrl: './business-unit-list-item.component.html',
  styleUrls: ['./business-unit-list-item.component.scss']
})
export class BusinessUnitListItemComponent implements OnInit {

  _company: CompanyStatus;
  _complete: boolean;

  constructor(private companyApi: CompanyApiService, private spinner: SpinnerService, private events: EventsService) { }

  @Input()
  set company(company: CompanyStatus){
    this._company = company;
  }

  @Input()
  set complete(complete: boolean){
    this._complete = complete;
  }

  @Output()
  change = new EventEmitter();
  
  ngOnInit() {
  }

  archive(company: CompanyStatus){
    if (!window.confirm("Are you sure?")){
      return;
    }
    
    this.spinner.startBackground();

    company.active = false;
    this.companyApi.Archive(company.id).subscribe(() => {
      //this._company = null;
      this.change.emit();
      this.spinner.stopBackground();

      this.events.fireEvent('configuration-submenu-update');
      this.events.fireEvent('update-company-list');

    });
  }

}
