import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company';
import { Period } from 'src/app/models/period';
import { CompanyApiService } from 'src/app/services/company-api.service';
import { RequestCacheService } from 'src/app/services/request-cache.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-period-admin',
  templateUrl: './period-admin.component.html',
  styleUrls: ['./period-admin.component.scss']
})
export class PeriodAdminComponent implements OnInit {

  company: Company;
  periods: Period[];

  newPeriod: Period;
  addPeriod: boolean = false;

  constructor(
    private companyApi: CompanyApiService, 
    private spinnner: SpinnerService,
    private cache: RequestCacheService) { }

  ngOnInit(): void {
  }

  companySelected(company) {
    this.company = company;
    this.getPeriods();
  }

  getPeriods(){
    this.spinnner.startBackground();
    if (this.company) {
      this.companyApi.GetCompanyPeriods(this.company.id).subscribe(data => {
        this.periods = data;
        this.spinnner.stopBackground();
      });
    }
    else {
      this.periods = null;
      this.spinnner.stopBackground();
    }
  }

  update(period: Period) {
    this.updatePeriod(period);
  }

  updatePeriod(period: Period) {
    this.spinnner.startBackground();

    if (this.addPeriod)
    {
      this.companyApi.AddCompanyPeriod(this.company.id, period).subscribe(data => {
        this.newPeriod = <Period>{};
        this.addPeriod = false;
        this.cache.clear();
        this.spinnner.stopBackground();
        this.getPeriods();
        
      });
    }
    else{
      this.companyApi.UpdateCompanyPeriod(this.company.id, period).subscribe(data => {
        this.cache.clear();
        this.spinnner.stopBackground();
        this.getPeriods();
      });
    }
    
  }

  add(){
    this.addPeriod = true;
    this.newPeriod = <Period>{};
  }
  cancel(){
    this.addPeriod = false;
  }

}
