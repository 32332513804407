import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../../../services/spinner.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { StatusApiService } from '../../../../../services/status-api.service';
import { EventsService } from '../../../../../services/events.service';
import { OrganisationStatus } from 'src/app/models/status';

@Component({
  selector: 'app-list-company',
  templateUrl: './list-company.component.html',
  styleUrls: ['./list-company.component.scss']
})
export class ListCompanyComponent implements OnInit {

  orgId: number
  organisation: OrganisationStatus;
  //companies: Array<CompanyStatus>;
  ready: boolean = false;

  constructor(
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private statusApi: StatusApiService,
    private events: EventsService
  ) { }

  ngOnInit() {
    this.spinner.startBackground();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.orgId = +params.get('id');
      localStorage.setItem('CURRENT_ORG_ID', this.orgId.toString());
      this.getData();
    });

    this.events.genericEvent$.subscribe(
      command => {
        if (command == 'update-company-list'){
          this.getData();
        }
      });
  }


  updateCompanyList(){
    this.getData();
  }


  
  getData(){
    this.spinner.startBackground();
    this.statusApi.getOrganisationStatus(this.orgId).subscribe(s => {
      this.organisation = s;
      this.ready = true;
      this.spinner.stopBackground();
    });
  }

}
