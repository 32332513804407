import { Component, OnInit, ViewChild } from '@angular/core';
import { LibraryApiService } from '../../../../services/library-api.service';
import { CompanyApiService } from '../../../../services/company-api.service';
import { SpinnerService } from '../../../../services/spinner.service';

import { forkJoin } from 'rxjs';
import { LibraryTreeItem } from '../../../../models/libraryTreeItem';
import { LookupApiService } from '../../../../services/lookup-api.service';
import { AlertService } from '../../../../services/alert.service';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { LibraryTreeListComponent } from '../../../../components/library-tree-list/library-tree-list.component';
import { CompanySelectorComponent } from 'src/app/components/company-selector/company-selector.component';
import { Company } from 'src/app/models/company';
import { ChangedItem, ListingsBulkUpdate } from 'src/app/models/listingsBulkUpdate';
import { ListingType } from 'src/app/models/listingType';

@Component({
  selector: 'app-library-admin',
  templateUrl: './library-admin.component.html',
  styleUrls: ['./library-admin.component.scss']
})
export class LibraryAdminComponent implements OnInit {
  @ViewChild('libraryTreeList', { static: true }) libraryTreeList: LibraryTreeListComponent;
  @ViewChild('companySelector', { static: true }) companySelector: CompanySelectorComponent;

  public listingTypes: ListingType[];
  public currentListingTypeId: number;
  public currentCompanyId: number;
  public busy: boolean = false;

  company: Company;
  changedItems: ChangedItem[] = [];

  constructor(
      private libraryApi: LibraryApiService,
      private companyApi: CompanyApiService,
      private spinner: SpinnerService,
      private lookupApi: LookupApiService,
      private alertService: AlertService,
      private cache: RequestCacheService
    ) { }

  ngOnInit() {
    this.lookupApi.ListListingTypes().subscribe(data => {
      this.listingTypes = data;
      this.currentListingTypeId = this.listingTypes[0].id;
    });
  }

  companySelected(company: Company) {
    this.company = company;
    if (company) {
      //this.libraryApi.ListItemsAdmin(this.company.id, null, this.currentListingTypeId);
      this.currentCompanyId = this.company.id;
    }
    else {
      this.currentCompanyId = null;
    }
  }

  listingTypeChanged(evt){
     this.changedItems = [];
  }

  itemSelected(item){
    //this.spinner.startBackground();
    // this.libraryApi.GetById(item.item.id).subscribe(data => {
    //   this.selectedItem = data;
    //   this.spinner.stopBackground();
    // });
  }
  
  itemsChanged(item){
    let i = this.changedItems.findIndex(f => f.id == item.id);

    if (i >= 0)
    {
      this.changedItems.splice(i, 1);
    }

    this.changedItems.push(item);
  }


   submit(){
    
    let data: ListingsBulkUpdate = {
      changedItems: this.changedItems,
      companyId: this.company.id,
    };


    this.spinner.startBackground();
    this.busy = true;


    this.libraryApi.BulkUpdate(data).subscribe(() => {
      this.spinner.stopBackground();  
      this.busy = false;
      this.cache.clear();
      this.alertService.success("Permissions scheduled to run");
      //this.libraryTreeList.reload();
      this.companySelector.clear();
     
    }, (error) => {
      this.busy = false;
      this.alertService.error("An error occurred. " + error);
    });
  }

  

  // public companies: SelectableCompanyBase[];
  // public filteredCompanies: SelectableCompanyBase[];
  // public library: LibraryTreeItem[];
  // public selectedItem: ListingItemFull;
  // public listingTypes: ListingType[];
  // public currentListingTypeId: number;
  // checkedItems: number[];
  // checkedCompanies: number[];

  // companyFilter: string = '';

  // removeChecked: boolean = false;
  // keepOriginalChecked: boolean = false;
  // childItemsChecked: boolean = false;

  // busy: boolean = false;
  // flag: boolean = true;

  
  
  // constructor(
  //   private libraryApi: LibraryApiService,
  //   private companyApi: CompanyApiService,
  //   private spinner: SpinnerService,
  //   private lookupApi: LookupApiService,
  //   private alertService: AlertService,
  //   private cache: RequestCacheService
  // ) { }

  // ngOnInit() {

  //   this.spinner.startBackground();

  //   forkJoin(
  //     //this.libraryApi.ListItems(null, 1, 1000),
  //     this.companyApi.ListCompanies(),
  //     this.lookupApi.ListListingTypes()
  //   ).subscribe((r) => {
  //     //this.library = this.convertItems(r[0].items.filter(f => !f.exclusive));
  //     this.companies = r[0] as SelectableCompanyBase[];
  //     this.applyCompanyFilter();
  //     this.listingTypes = r[1];
  //     this.spinner.stopBackground();

  //     this.currentListingTypeId = this.listingTypes[0].id;
  //   });
  // }

  // listingTypeChanged(evt){
  //   this.checkedItems = null;
  // }

  // filterCompanies(evt){
  //   this.applyCompanyFilter();
  // }

  // applyCompanyFilter(){
  //   this.filteredCompanies = this.companies.filter(f => f.name.toLowerCase().indexOf(this.companyFilter.toLowerCase()) > -1 || f.selected);
  // }

  // itemSelected(item){
  //   this.spinner.startBackground();
  //   this.libraryApi.GetById(item.item.id).subscribe(data => {
  //     this.selectedItem = data;
  //     this.spinner.stopBackground();
  //   });
  // }

  // companyChanged(item){
  //   this.checkedCompanies = this.filteredCompanies.filter(f => f.selected).map(m => m.id);
  // }
  
  // itemsChecked(items){
  //   this.checkedItems = items;
  // }

  // submit(){
    
  //   let data: ListingsBulkUpdate = {
  //     childItems: this.childItemsChecked,
  //     companyIds: this.checkedCompanies,
  //     keepOriginal: this.keepOriginalChecked,
  //     listingIds: this.checkedItems,
  //     remove: this.removeChecked
  //   };


  //   this.spinner.startBackground();
  //   this.busy = true;


  //   this.libraryApi.BulkUpdate(data).subscribe(() => {
  //     this.spinner.stopBackground();  
  //     this.busy = false;
  //     this.cache.clear();
  //     this.alertService.success("Permissions scheduled to run");
  //     this.libraryTreeList.reload();
  //     this.filteredCompanies.forEach(element => {
  //       element.selected = false;
  //     });
  //     this.applyCompanyFilter();
  //   }, (error) => {
  //     this.busy = false;
  //     this.alertService.error("An error occurred. " + error);
  //   });
  // }

}
