<div class="toolkit h-100 pt-4 d-flex align-items-center text-center">
  <div class="container flex-grow-1 d-flex flex-column">
    <div class="d-flex align-items-center justify-content-center">
      <h1 class="display-4 text-uppercase">The future of legal compliance is now!</h1>
    </div>
    <div class="content-block flex-column mb-4">
      <div class="row">
        <div class="col-12 text-start">
            <h2>SME/SMME LEGAL BUSINESSES ACROSS AFRICA</h2>
          <h5>ABOUT</h5>
          <p><strong>THE LEGAL TEAM SMME/SME LEGALTOOLKIT</strong> is an innovative online portal that houses the information which any person would need when setting up a business.</p>
          <p>The SMMELegalToolKit, in particular, is targeted at small businesses and starts-ups.</p>

          <h5 class="purple">CONTENTS OF THE LEGALTOOLKIT</h5>
          <p>The SMMELegalToolKit, contains the following information, set out in a sequential format: </p>
          <p class="font-weight-bold">
            Step 001- Develop a business plan<br/>
            Step 002- Decide on a name for your business and have it registered<br/>
            Step 003- Decide on the type of business entity<br/>
            Step 004- Registering a Company (Pty) Limited and operating it in accordance with the Companies Act, 2008<br/>
            Step 005- Register a Domain Name<br/>
            Step 006- Decide on the place where the business will be operated from<br/>
            Step 007- Ascertain which Laws apply to your business<br/>
            Step 007- Register for Tax as per the Tax Requirements and open a bank account<br/>
            Step 008- Find out how the Labour and Employment Laws will apply and hire Employees if needed<br/>
            Step 008- Register your Employees with the Unemployment Insurance Fund (UIF) and understand the UIF requirements<br/>
            Step 009- Ensure that your offices and work environment is safe and complies with the Health and Safety and Environmental Laws<br/>
            Step 011- Ascertain if you have to pay Skills Development Levies (SDL) and if so comply with the SDL requirements<br/>
            Step 012- Register with the Compensation Fund in respect of the Compensation For Injuries And Diseases (COIDA) Act and note requirements<br/>
            Step 013- Ascertain if the Employment Equity Act and requirements to submit EEE reports and plans <br/>
            Step 014- Ascertain if you are a VAT Vendor and if so register and take note of the VAT requirements<br/>
            Step 015- If you are to run a Canteen, Spaza or Food Facility or Restaurant - register such - Food Facilities<br/>
            Step 016- Register all vehicles and ensure that all drivers are licensed and comply with the rules of the roads<br/>
            Step 017- Attend to financial affairs- accounting records, books of account, financial statements and audited financials<br/>
            Step 018- Take note of the Broad-Based Black Economic Empowerment (B-BBEE) laws<br/>
            Step 019- Ensure that you always have a Tax Clearance Certificate and positive Tax status<br/>
            Step 020- Develop all required contracts and trading documents<br/>
            Step 021- Develop and implement company policies<br/>
            Step 022- Take note of the Promotion Of Access To Information Act and develop a PAIA manual<br/>
            Step 023- Take note of the Anti-Bribery and Corruption Laws and establish ABC procedures<br/>
            Step 024- Implement sound Corporate Governance procedures<br/>
            Step 025- Develop a Webpage and other useful Social Media Sites<br/>
            Step 026- Ensure all Trademarks and other Intellectual Property rights are protected<br/>
            Step 027- Educate staff about the perils and uses of Social Media and develop a Social Media Policy<br/>
            Step 028- Comply with all Company Secretarial matters<br/>
            Step 029- Register for needle time if playing music in your office or factory<br/>
            Step 030- Take note of the Laws pertaining to Consumer Rights<br/>
            Step 031- Understand how to submit a tender and prepare a standard vendor/ tender pack<br/>
            Step 032- Collect money due to you<br/>
            Step 033- Managing Employees and holding Disciplinary Enquiries and Hearings<br/>
            Step 034- Various licenses


          </p>
<h5>FOR MORE INFORMATION</h5>
<p>Send an email to </p>
<p><a href="mailto:info@thelegalteam.co">info&#64;thelegalteam.co</a></p>
<p><strong>EMPOWERING AND DRIVING SMME/SMME LEGAL BUSINESSES ACROSS AFRICA</strong></p>




        </div>        
      </div>
    </div>
  </div>
</div>