import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { ChangePassword } from '../models/changePassword';
import { ChoosePassword } from '../models/choosePassword';
import { Profile } from '../models/profile';
import { ResetPassword } from '../models/resetPassword';
import { Lookup } from '../models/lookup';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService extends ApiService<{}> {

  private controllerName: string = 'account';

  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('account', http, env);
  }

  choosePassword(data: ChoosePassword): Observable<{}> {
    return this.post<ChoosePassword, {}>(`api/${this.controllerName}/choose-password`, data);
  }

  resetPassword(email: string): Observable<{}> {
    return this.post<ResetPassword, {}>(`api/${this.controllerName}/reset-password`, { email: email });
  }

  getProfile(): Observable<Profile> {
    return this.get<Profile>(`api/${this.controllerName}/profile`);
  }

  geCompanies(): Observable<Lookup[]> {
    return this.get<Lookup[]>(`api/${this.controllerName}/companies`);
  }

  updateProfile(data: Profile): Observable<Profile> {
    return this.post<Profile, Profile>(`api/${this.controllerName}/profile`, data);
  }

  changePassword(data: ChangePassword): Observable<boolean> {
    return this.post<ChangePassword, boolean>(`api/${this.controllerName}/change-password`, data);
  }
}
