<div class="container text-center">
  <h2>Admin</h2>
  <hr />
  <div class="row">
    <div class="col-12 col-sm-8 col-md-6 col-lg-4 col-xl-2 offset-sm-2 offset-md-3 offset-lg-4 offset-xl-5">
      <a routerLink="/dashboard/admin/documents" class="btn btn-outline-primary btn-lg btn-block mb-2">DOCUMENTS</a><br />
      <a routerLink="/dashboard/admin/acts" class="btn btn-outline-primary btn-lg btn-block mb-2">ACTS</a><br />
      <a routerLink="/dashboard/admin/users" class="btn btn-outline-primary btn-lg btn-block mb-2">USERS</a><br />
    </div>
  </div>
</div>