<div class="top-bar p-4">
  <div class="container">
    <ng-select [items]="acts$ | async" bindLabel="name" [multiple]="false" [hideSelected]="true" [loading]="actsLoading"
      [typeahead]="actsInput$" placeholder="No Act selected" (change)="actSelected($event)">
    </ng-select>
  </div>
</div>

<ng-container *ngIf="currentAct">
  <div class="container">
    <div *ngIf="taskItems">

      <ng-container *ngIf="!add && !edit">
        <div class="pt-2 text-end">
          <button class="btn btn-primary btn-small" type="button" (click)="addItem()">ADD NEW</button>
        </div>
        <div *ngIf="taskItems.length == 0" class="p-2 text-center">
          No items loaded yet.
        </div>
        <div *ngFor="let item of taskItems" class="item p-2 my-2 d-flex flex-row align-items-center">
          <div class="flex-grow-1 px-2">
            <span>{{item.complianceQuery}}</span>
          </div>
          <div class="text-center px-2">
            <strong>Document Upload</strong><br />
            <span>{{item.documentUpload ? 'Yes' : 'No'}}</span>
          </div>
          <div class="px-2">
            <a (click)="editItem(item)" title="Edit">
              <span class="icon icon-small icon-edit-red"></span>
            </a><br />
            <a (click)="archive(item)" title="Archive">
              <span class="icon icon-small icon-hide-red"></span>
            </a>
          </div>
        </div>
        <!-- <div class="pager-bar py-4 mb-1">
          <div class="container text-center" *ngIf="result.items.length > 0">
            <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
            <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
            <span class="px-4">Page {{result.currentPage}} of {{result.totalPages}}</span>
            <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
            <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
          </div>
        </div> -->
      </ng-container>

      <ng-container *ngIf="add || edit">
        <form [formGroup]="mainForm" (ngSubmit)="submit()">

          <div class="form-group row">
            <label for="complianceQuery" class="col-12 col-form-label text-startcol-1">Compliance Query</label>
            <div class="col-12">
              <input type="text" class="form-control" id="complianceQuery" formControlName="complianceQuery" [class.is-invalid]="submitted && mainForm.controls.complianceQuery.invalid">
              <div class="invalid-feedback text-end" *ngIf="mainForm.controls.complianceQuery.errors">
                <div *ngIf="mainForm.controls.complianceQuery.errors.required">Required</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="description" class="col-12 col-form-label text-startcol-1">Description</label>
            <div class="col-12">
              <input type="text" class="form-control" id="description" formControlName="description">
              
            </div>
          </div>
          <div class="form-group row">
            <div class="form-check ms-3">
              <input class="form-check-input" type="checkbox" id="documentUpload" formControlName="documentUpload">
              <label class="form-check-label" for="documentUpload">
                Document Upload?
              </label>
            </div>
          </div>
          <div class="text-end">
            <button type="button" class="btn btn-default me-2" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-default">Save</button>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</ng-container>