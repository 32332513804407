import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SubMenuItem } from 'src/app/models/sub-menu-item';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {

  public _items: Array<SubMenuItem>;

  @Input()
  set items(i: Array<SubMenuItem>) {
    this._items = i;
  }

  get items() {
    return this._items;
  }

  constructor(private router: Router) { }

  ngOnInit() {
  }

  itemClicked(item: SubMenuItem) {
    if (item && item.link) {
      this.router.navigateByUrl(item.link);
    }
  }

}
