<footer>
  <div class="container-fluid">
    <div class="row">
      
      <div class="col-12 d-flex align-items-center justify-content-center">
    
          <p class="text-center mb-0">
            Copyright &copy; {{ date | date: 'yyyy' }} All Rights Reserved<span class="d-none d-sm-inline"> | </span><br class="d-inline d-sm-none" /><a routerLink="/usage-policy" routerLinkActive="active">Usage Policy</a> | <a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a>
          </p>
        </div>
    </div>
    
  </div>
</footer>
