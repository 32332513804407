<ng-container *ngIf="!isContractRequest(); else contractRequest">

<div class="header-container">
  <app-header></app-header>
</div>

<div class="main-container">

  <div class="main-content-container d-flex align-items-stretch">
    <div class="sidebar-container" *ngIf="this.globals.loggedIn">
      <app-side-bar></app-side-bar>
    </div>
    <div class="content-container flex-grow-1">
      <div>
        <div class="content d-flex">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="footer-container">
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>

</ng-container>

<ng-template #contractRequest>
  <div class="flex-grow-1 d-flex contract-request">
  <router-outlet></router-outlet>
  </div>
</ng-template>

<app-alert></app-alert>
<ngx-ui-loader></ngx-ui-loader>