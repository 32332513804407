import { Component, OnInit } from '@angular/core';
import { Subject, Observable, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, map } from 'rxjs/operators'
import { ActApiService } from '../../../../services/act-api.service';
import { TaskItemApiService } from '../../../../services/taskitem-api.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { TaskItem } from 'src/app/models/taskItem';
import { Act } from 'src/app/models/act';

@Component({
  selector: 'app-compliance-program-admin',
  templateUrl: './compliance-program-admin.component.html',
  styleUrls: ['./compliance-program-admin.component.scss']
})
export class ComplianceProgramAdminComponent implements OnInit {

  add: boolean = false;
  edit: boolean = false;

  acts$: Observable<Act[]>;
  actsLoading = false;
  actsInput$ = new Subject<string>();
  taskItems: Array<TaskItem>;

  currentAct: Act;
  currentItem: TaskItem;

  submitted = false;
  mainForm = this.formBuilder.group({
    description: [''],
    complianceQuery: ['', Validators.required],
    documentUpload: ['']
  });
  constructor(
    private actApi: ActApiService,
    private taskItemApi: TaskItemApiService,
    private spinner: SpinnerService,
    private formBuilder: UntypedFormBuilder,
    private cache: RequestCacheService
  ) { }

  ngOnInit() {
    this.loadActs();
  }

  searchActs(term: string) {
    return this.actApi.ListActs(1, 50, term).pipe(
      map((r) => {
        return r.items;
      })
    );
  }

  private loadActs() {
    this.acts$ = concat(
      of([]), // default items
      this.actsInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.actsLoading = true),
        switchMap(term => this.searchActs(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.actsLoading = false)
        ))
      )
    );
  }

  getData() {
    this.spinner.startBackground();

    this.taskItemApi.ListByActId(this.currentAct.id).subscribe(data => {
      this.taskItems = data;
      this.spinner.stopBackground();
    });
  }
  actSelected(event) {
    this.currentAct = event;
    this.getData();
  }

  addItem() {
    this.add = true;
  }


  editItem(item: TaskItem) {
    this.edit = true;
    this.currentItem = item;

    this.mainForm.patchValue({
      description: item.description,
      complianceQuery: item.complianceQuery,
      documentUpload: item.documentUpload
    });
  }

  cancel() {
    this.add = false;
    this.edit = false;
    this.mainForm.reset();
  }

  submit() {

    this.submitted = true;

    if (this.mainForm.invalid){
      return;
    }
    
    this.spinner.startBackground();

    if (this.add) {
      this.currentItem = <TaskItem>{};
      this.currentItem.act = this.currentAct;
    }

    this.currentItem.description = this.mainForm.controls.description.value;
    this.currentItem.complianceQuery = this.mainForm.controls.complianceQuery.value;
    this.currentItem.documentUpload = this.mainForm.controls.documentUpload.value == true;

    if (this.add) {
      this.taskItemApi.Add(this.currentItem).subscribe(data => {
        this.add = false;
        this.edit = false
        this.cache.clear();
        this.getData();
      });
    }
    else if (this.edit) {
      this.taskItemApi.Update(this.currentItem.id, this.currentItem).subscribe(data => {
        this.add = false;
        this.edit = false
        this.cache.clear();
        this.getData();
      });
    }

    this.add = false;
    this.edit = false;
    this.mainForm.reset();
  }

  archive(item: TaskItem) {

    if (!window.confirm("Are you sure?")) {
      return;
    }

    this.spinner.startBackground();

    item.active = false;

    this.taskItemApi.Update(item.id, item).subscribe(data => {
      var index = this.taskItems.findIndex(f => f.id == item.id);
      this.taskItems.splice(index, 1);
      this.spinner.stopBackground();

      this.taskItemApi.ListByActId(this.currentAct.id).subscribe(data => {
        this.taskItems = data;
      });
    });

  }


}
