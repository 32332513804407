<div class="d-flex flex-row align-items-center sub-menu">

  <div *ngFor="let item of _items" [ngClass]="['d-flex', 'flex-row', 'align-items-center','sub-menu-item', item.state.toLowerCase()]"
    [class.link]="item.link" (click)="itemClicked(item)">
    <span *ngIf="item.icon" [ngClass]="['icon', item.icon]"></span>
    <span *ngIf="item.number" class="number">{{item.number}}</span>&nbsp;{{item.text}}&nbsp;
    <span *ngIf="item.state == 'Complete'"><span class="icon icon-tiny icon-tick-green"></span>&nbsp;</span>
  </div>


</div>