import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiReadOnlyService } from './api-read-only.service';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { CompanyDetailStatus } from '../models/companyDetailStatus';
import { OrganisationStatus, Status } from '../models/status';

@Injectable({
  providedIn: 'root'
})
export class StatusApiService extends ApiReadOnlyService<Status> {

  private controllerName: string = 'status';

  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('status', http, env);
  }

  getStatus(): Observable<Status> {
    return this.get<Status>(`api/${this.controllerName}/`);
  }

  getOrganisationStatus(orgId: number): Observable<OrganisationStatus> {
    return this.get<OrganisationStatus>(`api/${this.controllerName}/${orgId}`);
  }

  getCompanyStatus(companyId?: number, actId?: number, userId?: number, periodId?: number): Observable<CompanyDetailStatus> {

    let url = '';

    if (companyId) {
      url = `api/${this.controllerName}/company/${companyId}?`;
    }
    else {
      url = `api/${this.controllerName}/company?`;
    }

    if (actId) {
      url = `${url}actId=${actId.toString()}&`;
    }

    if (userId) {
      url = `${url}userId=${userId.toString()}`;
    }

    if (periodId) {
      url = `${url}periodId=${periodId.toString()}`;
    }

    return this.get<CompanyDetailStatus>(url);
  }
}
