import { Injectable } from '@angular/core';
import $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class FormSubmitService {

  constructor() { }

  disableForm(){
    $('form, form input[type="submit"], form button[type="submit"]').attr('disabled', 'disabled');
  }

  enableForm(){
    $('form, form input[type="submit"], form button[type="submit"]').removeAttr('disabled');
  }
}
