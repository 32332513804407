import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  getEnv(){

    switch (window.location.hostname.toLowerCase()){
      case 'localhost':
        return 'dev';
      case 'mylegalteam.dnsalias.net':
      case 'test.thelegalteam.co':
        return 'test';
      case 'thelegalteam.co':
      case 'www.thelegalteam.co':
      default:
        return 'prod';
    }

  }
  getApiUrl()
  {
    switch (this.getEnv()){
      case 'dev':
        return environment.devApiUrl;
      case 'test':
        return environment.testApiUrl;
      case 'prod':
      default:
        return environment.prodApiUrl;
    }

  }
}
