import { Component, OnInit } from '@angular/core';
import { CompanyApiService } from '../../../../services/company-api.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { Company } from 'src/app/models/company';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss']
})
export class UserAdminComponent implements OnInit {

  users: User[];
  finalUsers: User[];
  company: Company;
  searchTerm: string;
  roles: string[];

  newUser: User;
  addUser: boolean = false;

  constructor(
    private companyApi: CompanyApiService, 
    private spinnner: SpinnerService,
    private cache: RequestCacheService) { }

  ngOnInit() {
    this.companyApi.GetAllRoles().subscribe(data => this.roles = data);
  }

  companySelected(company) {
    this.company = company;
    this.getUsers();
  }

  getUsers(){
    this.spinnner.startBackground();
    if (this.company) {
      this.companyApi.GetCompanyUsers(this.company.id).subscribe(data => {
        this.users = data;
        this.finalUsers = data;
        this.spinnner.stopBackground();
      });
    }
    else {
      this.users = null;
      this.finalUsers = null;
      this.spinnner.stopBackground();
    }
  }

  update(user: User) {
    this.updateUser(user);
  }

  archive(user: User) {

    if (!window.confirm("Are you sure?")) {
      return;
    }

    user.active = false;
    this.updateUser(user);
  }

  activate(user: User) {
    user.active = true;
    this.updateUser(user);
  }

  updateUser(user: User) {
    this.spinnner.startBackground();

    if (this.addUser)
    {
      this.newUser.companyId = this.company.id;
      this.companyApi.AddCompanyUser(user).subscribe(data => {
        this.newUser = <User>{};
        this.addUser = false;
        this.cache.clear();
        this.spinnner.stopBackground();
        this.getUsers();
        
      });
    }
    else{
      this.companyApi.UpdateCompanyUser(user).subscribe(data => this.spinnner.stopBackground());
    }
    
  }

  search() {
    if (this.searchTerm) {
      let s = this.searchTerm.toLowerCase();
      this.finalUsers = this.users.filter(f => {
        return f.firstname.toLowerCase().indexOf(s) > -1 ||
          f.lastname.toLowerCase().indexOf(s) > -1 ||
          f.email.toLowerCase().indexOf(s) > -1
      });
    }
    else {
      this.finalUsers = this.users;
    }
  }

  add(){
    this.addUser = true;
    this.newUser = <User>{};
  }
  cancel(){
    this.addUser = false;
  }

  
}
