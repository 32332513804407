<div class="detail-block-items px-4 mx-4" *ngIf="status"    [class.alt-color]="orgId == 89">

  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>RISK RATE (RAW)</strong></div>
    <div class="text-end flex-grow-1">
      <a class="flex-grow-1" *ngIf="act" (click)="openRiskModal(riskModal)">
        <span class="badge rounded-pill {{status.compliance.overallRisk?.toLowerCase()}} text-uppercase">{{getRiskLabel(status.compliance.overallRisk, status.compliance.likelihood, status.compliance.impact)}}</span>
      </a>
      <span *ngIf="!act" class="badge rounded-pill {{status.compliance.overallRisk?.toLowerCase()}} text-uppercase">{{getRiskLabel(status.umbrellaReport.overallRisk, status.umbrellaReport.likelihood, status.umbrellaReport.impact)}}</span>
    </div>
    <div class="text-center px-2" *ngIf="!modal">
      <div style="width:50px"></div>
      <!-- <button class="btn btn-link icon-link" (click)="getCompanyActs()"><span
            class="icon icon-view-red"></span></button> -->
    </div>
  </div>

  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>REQUIRED</strong></div>
    <div class="text-end flex-grow-1"><span class="badge rounded-pill text-bg-light">{{status.compliance.required}}</span>
    </div>
    <div class="text-center px-2" *ngIf="!modal">
      <button *ngIf="this.status.compliance.required == 0" class="btn btn-link icon-link"><span
          class="icon icon-view-grey"></span></button>
      <button *ngIf="this.status.compliance.required > 0" class="btn btn-link icon-link"
        (click)="getCompanyAudits(0)"><span class="icon icon-view-red"></span></button>
    </div>
  </div>

  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>COMPLIANT</strong></div>
    <div class="text-end flex-grow-1"><span
        class="badge rounded-pill text-bg-light">{{status.compliance.compliant}}</span></div>
    <div class="text-center px-2" *ngIf="!modal">
      <button *ngIf="this.status.compliance.compliant == 0" class="btn btn-link icon-link"><span
          class="icon icon-view-grey"></span></button>
      <button *ngIf="this.status.compliance.compliant > 0" class="btn btn-link icon-link"
        (click)="getCompanyAudits(1)"><span class="icon icon-view-red"></span></button>
    </div>
  </div>
  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>NOT COMPLIANT</strong></div>
    <div class="text-end flex-grow-1"><span
        class="badge rounded-pill text-bg-light">{{status.compliance.notCompliant}}</span></div>
    <div class="text-center px-2" *ngIf="!modal">
      <button *ngIf="this.status.compliance.notCompliant == 0" class="btn btn-link icon-link"><span
          class="icon icon-view-grey"></span></button>
      <button *ngIf="this.status.compliance.notCompliant > 0" class="btn btn-link icon-link"
        (click)="getCompanyAudits(2)"><span class="icon icon-view-red"></span></button>
    </div>
  </div>
  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>UNDETERMINED</strong></div>
    <div class="text-end flex-grow-1"><span
        class="badge rounded-pill text-bg-light">{{status.compliance.undetermined}}</span></div>
    <div class="text-center px-2" *ngIf="!modal">
      <button *ngIf="this.status.compliance.undetermined == 0" class="btn btn-link icon-link"><span
          class="icon icon-view-grey"></span></button>
      <button *ngIf="this.status.compliance.undetermined > 0" class="btn btn-link icon-link"
        (click)="getCompanyAudits(3)"><span class="icon icon-view-red"></span></button>
    </div>
  </div>
  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>NOT APPLICABLE</strong></div>
    <div class="text-end flex-grow-1"><span
        class="badge rounded-pill text-bg-light">{{status.compliance.notApplicable}}</span></div>
    <div class="text-center px-2" *ngIf="!modal">
      <button *ngIf="this.status.compliance.notApplicable == 0" class="btn btn-link icon-link"><span
          class="icon icon-view-grey"></span></button>
      <button *ngIf="this.status.compliance.notApplicable > 0" class="btn btn-link icon-link"
        (click)="getCompanyAudits(7)"><span class="icon icon-view-red"></span></button>
    </div>
  </div>

  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>ALLOCATED</strong></div>
    <div class="text-end flex-grow-1"><span
        class="badge rounded-pill text-bg-light">{{status.compliance.allocated}}</span></div>
    <div class="text-center px-2" *ngIf="!modal">
      <button *ngIf="this.status.compliance.allocated == 0" class="btn btn-link icon-link"><span
          class="icon icon-view-grey"></span></button>
      <button *ngIf="this.status.compliance.allocated > 0" class="btn btn-link icon-link"
        (click)="getCompanyAudits(4)"><span class="icon icon-view-red"></span></button>
    </div>
  </div>

  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>COMPLETED</strong></div>
    <div class="text-end flex-grow-1"><span
        class="badge rounded-pill text-bg-light">{{status.compliance.completed}}</span></div>
    <div class="text-center px-2" *ngIf="!modal">
      <button *ngIf="this.status.compliance.completed == 0" class="btn btn-link icon-link"><span
          class="icon icon-view-grey"></span></button>
      <button *ngIf="this.status.compliance.completed > 0" class="btn btn-link icon-link"
        (click)="getCompanyAudits(5)"><span class="icon icon-view-red"></span></button>
    </div>
  </div>

  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>REALLOCATED</strong></div>
    <div class="text-end flex-grow-1"><span
        class="badge rounded-pill text-bg-light">{{status.compliance.reallocated}}</span></div>
    <div class="text-center px-2" *ngIf="!modal">
      <button *ngIf="this.status.compliance.reallocated == 0" class="btn btn-link icon-link"><span
          class="icon icon-view-grey"></span></button>
      <button *ngIf="this.status.compliance.reallocated > 0" class="btn btn-link icon-link"
        (click)="getCompanyAudits(6)"><span class="icon icon-view-red"></span></button>
    </div>
  </div>


  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>SCORE</strong></div>
    <div class="text-end flex-grow-1"><span
        class="badge rounded-pill text-bg-light">{{status.compliance.percentage | percent}}</span></div>
        <div class="text-center px-2" *ngIf="!modal">
          <div style="width:50px"></div>
        </div>
  </div>


  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>RESIDUAL RISK RATE</strong></div>
    <div class="text-end flex-grow-1">
      <a  *ngIf="act" class="flex-grow-1" (click)="openResidualRiskModal(riskModal)">
        <span class="badge rounded-pill {{status.compliance.residualRisk?.toLowerCase()}} text-uppercase">{{getRiskLabel(status.compliance.residualRisk, status.compliance.residualLikelihood, status.compliance.residualImpact)}}</span>
      </a>
      <span *ngIf="!act" class="badge rounded-pill {{status.compliance.residualRisk?.toLowerCase()}} text-uppercase">{{getRiskLabel(status.umbrellaReport.residualRisk, status.umbrellaReport.residualLikelihood, status.umbrellaReport.residualImpact)}}</span>
    </div>
    <div class="text-center px-2" *ngIf="!modal">
      <div style="width:50px"></div>
    </div>
  </div>

  <div class="detail-block-item d-flex align-items-center my-4">
    <div class="text-uppercase text-danger"><strong>CONTROL EFFICIENCY RATE</strong></div>
    <div class="text-end flex-grow-1"><span
        class="badge rounded-pill {{status.compliance.efficiencyRate?.toLowerCase()}}">{{status.compliance.efficiencyRate}}</span>
    </div>
    <div class="text-center px-2" *ngIf="!modal">
      <div style="width:50px"></div>
    </div>
  </div>


  <div class="detail-block-item d-flex align-items-center my-4" *ngIf="!modal">
    <div class="text-uppercase text-danger"><strong>DOWNLOAD MARKED TASK EXERCISE</strong></div>
    <div class="text-end flex-grow-1">&nbsp;
        
    </div>
    <div class="text-center px-2">
      <div style="width:50px">
        <button class="btn btn-link icon-link"
          (click)="export()"><span class="icon icon-view-red"></span></button>
      </div>
    </div>
  </div>

  <!-- <div class="detail-block-item d-flex align-items-center my-4">
      <div class="text-uppercase text-danger"><strong>CONTROL EFFICIENCY RATE</strong></div>
      <div class="text-end flex-grow-1"><span
          class="badge rounded-pill text-uppercase">0</span>
      </div>
      <div class="text-center px-2">
        <div style="width:50px"></div>
      </div>
    </div> -->

</div>


<ng-template #riskModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{riskModalTitle}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <app-risk-modal (onSelect)="riskUpdated($event)" [rating]="newRating" [riskData]="riskData" [residualModal]="residualModal" [status]="risidualRiskStatus" [act]="act" [readOnly]="readOnly"></app-risk-modal>
    
    <!-- <div class="row">
      <div class="col-12">
        <app-impact-rating [readOnly]="true" [value]="newRating"></app-impact-rating>
      </div>
      
    </div> -->

   
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" 
      (click)="modal.close('Save click')">{{readOnly ? 'Close' : 'Save'}}</button>
  </div>
</ng-template>