import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PermitDocumentResult, PermitDocumentStatus } from 'src/app/models/documents';
import { DocumentsApiService } from 'src/app/services/documents-api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-permits',
  templateUrl: './permits.component.html',
  styleUrl: './permits.component.scss'
})
export class PermitsComponent {

  companyId: number;
  orgId: number;
  apiCompanyId?: number;

  userId: number;
  user: boolean = false;
  contractAdmin: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  result: PermitDocumentResult;

  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;

  currentPage: number = 1;

  perPageItems = [5, 10, 20, 50, 100];
  perPage = 10;

  filterGranted: boolean = undefined;
  filterStatusId: number = undefined;
  filterRegionId: number = undefined;
  filterFacilityId: number = undefined;

  statuses: PermitDocumentStatus[];

  constructor(private token: TokenService, private router: Router, private ngZone: NgZone, private route: ActivatedRoute, private documentsApi: DocumentsApiService, private spinner: SpinnerService) {

  }

  ngOnInit() {

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user || this.contractAdmin) {
      this.companyId = this.token.companyId();
      this.apiCompanyId = this.companyId;
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.init();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }
  }

  init() {

    if (this.superAdmin) {
      this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.orgId = this.token.organisationId();
    }

    this.documentsApi.GetPermitStatuses().subscribe(data => {
      this.statuses = data;
    });

    this.getData();


  }

  
  getData() {
    console.log(this.filterStatusId, this.filterGranted)
    this.documentsApi.GetPermitDocuments(this.apiCompanyId, this.filterStatusId, this.filterGranted, this.perPage, this.currentPage).subscribe(data => {
      this.result = data;
    })
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    else {

    }
  }

  download(ev: Event, id: number, file: string) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetPermitDocumentFile(id, this.apiCompanyId, file).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  first() {
    this.currentPage = 1;
    this.getData();
  }

  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.getData();
  }

  next() {
    if (this.currentPage < this.result.totalPages) {
      this.currentPage++;
    }

    this.getData();
  }

  last() {
    this.currentPage = this.result.totalPages;
    this.getData();
  }

  perPageChange(e: Event) {
    localStorage["PER_PAGE"] = this.perPage;
    this.currentPage = 1;
    this.getData();
  }


}
