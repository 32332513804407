import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../../services/token.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { StatusApiService } from '../../../services/status-api.service';
import { SpinnerService } from '../../../services/spinner.service';
import { CompanyDetailStatus } from 'src/app/models/companyDetailStatus';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsOldComponent implements OnInit {

  companyId: number;
  apiCompanyId?: number;
  status: CompanyDetailStatus;

  user: boolean = false;
  companyAdmin: boolean = false;
  superAdmin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private statusApi: StatusApiService,
    private spinner: SpinnerService,
    public token: TokenService,
    private router: Router
  ) { }

  ngOnInit() {

    this.user = this.token.isUser();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.getStatus();
      }

    }
    else if (this.superAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.getStatus();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }

  }

  getStatus() {

    this.spinner.startBackground();
    this.statusApi.getCompanyStatus(this.apiCompanyId).subscribe((data) => {
      this.status = data;
      this.spinner.stopBackground();
    });

  }
  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.getStatus();
    }
    else {
      this.status = null;
    }
  }
}
