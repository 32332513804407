import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { ContactRequest } from '../models/contactRequest';

@Injectable({
  providedIn: 'root'
})
export class ContactApiService extends ApiBaseService {

  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super(http, env);
  }

  public submit(request: ContactRequest): Observable<{}> {
    return this.post<ContactRequest, {}>('api/contact', request);
  }
}
