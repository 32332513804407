import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DocumentApiService } from '../../../../services/document-api.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import $ from 'jquery';

import { Document } from 'src/app/models/document';
import { DocumentResult } from 'src/app/models/documentResult';
@Component({
  selector: 'app-document-admin',
  templateUrl: './document-admin.component.html',
  styleUrls: ['./document-admin.component.scss']
})
export class DocumentAdminComponent implements OnInit {

  result: DocumentResult

  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;

  currentPage: number = 1;
  perPage: number = 10;
  searchTerm: string = '';

  edit: boolean = false;
  add: boolean = false;

  currentDocument: Document;

  submitted = false;
  
  mainForm = this.formBuilder.group({
    title: ['', Validators.required],
    description: [''],
    document: [''],
    documentData: ['', Validators.required]
  });
  
  constructor(
    private documentApi: DocumentApiService, 
    private spinner: SpinnerService, 
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    
  }

  getData(){
    this.spinner.startBackground();
    this.documentApi.ListDocuments(this.currentPage, this.perPage, this.searchTerm).subscribe((data) => {
      this.result = data;

      this.firstEnabled = this.result.currentPage > 1;
      this.prevEnabled = this.result.currentPage > 1;

      this.nextEnabled = this.result.currentPage < data.totalPages;
      this.lastEnabled = this.result.currentPage < data.totalPages;

      this.spinner.stopBackground();
    });
  }

  addDocument(){
    this.currentDocument = null;
    this.add = true;
    this.edit = false;
    this.mainForm.controls.documentData.setValidators([Validators.required]);
    this.mainForm.reset();
    
  }
  editDocument(doc:Document){
    this.currentDocument = doc;

    this.mainForm.controls.documentData.setValidators([]);

    this.mainForm.patchValue({
      title: this.currentDocument.title,
      description: this.currentDocument.description
    });

    this.add = false;
    this.edit = true;
  }
  search(){
    
    this.currentPage = 1;
    this.getData();
  }

  cancel(){
    this.add = false;
    this.edit = false;
  }

  fileUpload(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) //2MB
      {
        this.mainForm.controls.document.setValue('');
        this.mainForm.controls.document.setErrors({ 'required': true });
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.mainForm.patchValue({
          documentData: reader.result,
          document: file.name
        });

        //this.currentDocument.url = this.mainForm.controls.documentData.value;

        $('.custom-file-label').addClass("selected").html(file.name);

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  submit(){

    this.submitted = true;

    if (this.mainForm.invalid){
      return;
    }

    this.spinner.startBackground();

    
    if (this.add){
      this.currentDocument = <Document>{};
    }

    this.currentDocument.title = this.mainForm.controls.title.value;
    this.currentDocument.description = this.mainForm.controls.description.value;
    this.currentDocument.url = this.mainForm.controls.documentData.value;
    this.currentDocument.fileName = this.mainForm.controls.document.value;

    if (this.edit)
    {
      this.documentApi.Update(this.currentDocument.id, this.currentDocument).subscribe((r) => {
        this.add = false;
        this.edit = false
        this.getData();
      });
    }

    else if (this.add)
    {
      this.documentApi.Add(this.currentDocument).subscribe((r) => {
        this.add = false;
        this.edit = false
        this.getData();
      });
    }
  }
}
