import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { EnvironmentService } from '../../services/environment.service';
import { Lookup } from 'src/app/models/lookup';
import { AccountApiService } from 'src/app/services/account-api.service';
import { EventsService } from 'src/app/services/events.service';
import { TokenService } from 'src/app/services/token.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  companies: Lookup[] = [];
  selectedCompany: number;

  constructor(
    public globals: Globals,
    private router: Router,
    private auth: AuthService,
    private env: EnvironmentService,
    private accountApi: AccountApiService,
    private events: EventsService,
    private token: TokenService,
    private spinner: SpinnerService
  ) { }

  production = true;


  ngOnInit() {
    this.production = this.env.getEnv() == 'prod';

    if (this.globals.loggedIn){
      this.init();
    }

    this.events.genericEvent$.subscribe(data => {
      if (data == 'LOGIN'){
        this.init();
      }
      else if (data == 'LOGOUT'){
        this.companies = [];
      }
    });
    
    
  }
  init(){
    
    this.accountApi.geCompanies().subscribe(data => {
      this.companies = data;
      this.selectedCompany = +this.token.companyId();
    });
  }

  logout() {
    this.auth.logout();
    this.router.navigateByUrl('/home');
  }

  selectCompany(e: any){
    this.spinner.start();
    this.auth.switchCompany(this.selectedCompany).subscribe(data => {
      this.token.reload();
      window.location.href = '/';
    }, error => { this.spinner.stop() });
    
  }

}
