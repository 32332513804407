<div class="mb-1" *ngIf="superAdmin">
  <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>

<div class="container-fluid d-flex flex-column" *ngIf="contracts">
  <div class="mt-4">
    <button class="btn btn-default" (click)="back()">&lt; Back</button>
  </div>

  <div class="row mt-2">
    <div class="col-12 d-flex">
      <div class="pe-2">
        Search:
        <input type="text" style="width: 250px;" [(ngModel)]="search" class="form-control" (keydown)="searchInputChanged($event)" />
      </div>
      <div class="pe-2">
        Document Type:
        <ng-select style="width: 150px;" [items]="documentTypes" [(ngModel)]="selectedDocumentType"
          (change)="documentTypeChange($event)" [clearable]="true" bindLabel="name" bindValue="id"
          placeholder="-- All --"></ng-select>
      </div>
      <div class="pe-2">
        Sort Order:
        <ng-select style="width: 200px;" [(ngModel)]="order" (change)="orderChange($event)"  [clearable]="true">
          <ng-option value="effectiveAsc">Effective Date Ascending</ng-option>
          <ng-option value="effectiveDesc">Effective Date Descending</ng-option>
          <ng-option value="terminationAsc">Termination Date Ascending</ng-option>
          <ng-option value="terminationDesc">Termination Date Descending</ng-option>
        </ng-select>
      </div>
      
    </div>
  </div>
  <table class="mt-2 table table-bordered bg-white table-sm table-hover">
    <tr>
      <th>CONTRACT<br />PARTIES</th>
      <th>DOCUMENT<br />TITLE</th>
      <th>DOCUMENT<br />TYPE</th>
      <th>EFFECTIVE<br />DATE</th>
      <th>TERMINATION<br />DATE</th>
      <th>REQUESTOR</th>
      <th>RESPONSIBLE<br />PARTY</th>
      <th class="text-center">STATUS</th>
      <th class="text-center">VIEW</th>
      <th>NOTES</th>
      <th class="text-center">REMINDERS<br />AND TASKS</th>
    </tr>
    <tr *ngFor="let contract of contracts">
      <td>{{getDataField(contract, 'CounterpartyName')}}</td>      
      <td>{{contract.title}}</td>
      <td><span *ngIf="contract.contractType">{{contract.contractType.name}}</span></td>
      <td>{{getDataField(contract, 'EffectiveDate')}}</td>
      <td>{{getDataField(contract, 'TerminationDate')}}</td>
      <td>{{getDataField(contract, 'Name')}} {{getDataField(contract, 'Surame')}}</td>
      <td>{{contract.reminderEmail}}</td>
      <td class="text-center"><span>{{contract.contractStatus.description}}</span></td>
      <td class="text-center">
          <a *ngIf="contract.folderId" [href]="'https://legalteam.awsapps.com/workdocs/index.html#/folder/' + contract.folderId" target="_blank" title="View document in WorkDocs"><span class="icon icon-view-red"></span></a>
      </td>
      <td>
          <div class="d-flex align-items-start">
              <span class=" flex-grow-1" placement="top" [ngbTooltip]="contract.notes">{{truncate(contract.notes)}}</span>
            <a class="ms-1 icon-link" (click)="openNotesModal(notesModal, contract)"><span
                class="icon icon-small icon-edit-red"></span></a>
          </div>
        </td>
      <td class="text-center">
        <a href="#" (click)="openReminderModal($event, reminderModal, contract)" title="Reminders and Tasks">
          <span class="icon icon-notification-red"></span>
        </a>
      </td>
    </tr>
  </table>

  <!-- <table class="contract-table w-100 flex-grow-1">
    <tr>
      <th></th>
      <th class="text-center"><span class="icon icon-stamp-grey"></span>
        <div><small>Branded</small></div>
      </th>
      <th class="text-center"><span class="icon icon-merge-grey"></span>
        <div><small>Merged</small></div>
      </th>
      <th class="text-center"><span class="icon icon-download-grey"></span>
        <div><small>Downloaded</small></div>
      </th>
      <th class="text-center"><span class="icon icon-edit-grey"></span>
        <div><small>Last Modified</small></div>
      </th>
      <th class="text-center"><span class="icon icon-upload-grey"></span>
        <div><small>Uploaded</small></div>
      </th>
      <th class="text-center"><span class="icon icon-version-grey"></span>
        <div><small>Version</small></div>
      </th>
      <th class="text-center"><span class="icon icon-status-grey"></span>
        <div><small>Status</small></div>
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    <tr *ngFor="let contract of contracts">
      <td><span class="icon icon-documents-grey me-2"></span>{{contract.title}}</td>
      <td class="text-center">
        <span *ngIf="!contract.contractTemplate || !contract.contractTemplate.companyId">&nbsp;</span>
        <span *ngIf="contract.contractTemplate && contract.contractTemplate.companyId" class="icon icon-tick-green"></span>
      </td>
      <td class="text-center">
          <span *ngIf="contract.contractStatus.id != 1" class="icon icon-tick-green"></span>
          <a *ngIf="contract.contractStatus.id == 1" (click)="openContract(contract)"><span class="icon icon-plus-red"></span></a>
        </td>
      <td class="text-center">
        {{contract.downloadedAt | date:'yyyy-MM-dd HH:mm:ss' }}
      </td>
      <td class="text-center">
        {{contract.lastModified | date:'yyyy-MM-dd HH:mm:ss' }}
      </td>
      <td class="text-center">
        {{contract.createdAt | date:'yyyy-MM-dd HH:mm:ss' }}
      </td>
      <td class="text-center"><span *ngIf="contract.version">{{contract.version}}</span></td>
      <td class="text-center">
        <span *ngIf="contract.contractStatus">{{contract.contractStatus.description}}</span>
        <a *ngIf="contract.contractStatus && contract.contractStatus.id == 2" (click)="closeContract(contract)" title="Close case">&nbsp;<span class="icon icon-tick-red"></span></a>
      </td>
      <td class="text-center no-white-bg">
        <a *ngIf="contract.folderId" [href]="'https://legalteam.awsapps.com/workdocs/index.html#/folder/' + contract.folderId" target="_blank" title="View document in WorkDocs"><span class="icon icon-view-red"></span></a>
      </td>
      <td class="text-center no-white-bg">
        <a *ngIf="contract.documentId" [routerLink]="'/dashboard/contracts-old/messages/' + contract.id" title="View messages"><span class="icon icon-email-red"></span></a>
      </td>
      <td class="text-center no-white-bg">
        <a href="#" (click)="openReminderModal($event, reminderModal, contract)" title="Reminder">
          <span *ngIf="!contract.reminderDateTime" class="icon icon-notification-grey"></span>
          <span *ngIf="contract.reminderDateTime" class="icon icon-notification-red"></span>
        </a>
      </td>
    </tr>
  </table> -->

</div>

<ng-template #reminderModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Reminders and Tasks</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form>
      <div class="modal-body">
        
          <div class="form-group">
              <label>Responsible Person Email</label>
              <input class="form-control" [ngModel]="currentReminder.reminderEmail" (ngModelChange)="currentReminder.reminderEmail = $event" type="email" id="reminderEmail" name="reminderEmail"  placeholder="Responsible Person Email">
            </div>
          <div class="form-group">
              <label>Reminder Date</label>
            <input class="form-control" [ngModel]="currentReminder.reminderDateTime | date:'yyyy-MM-dd'" (ngModelChange)="currentReminder.reminderDateTime = $event" type="date" id="reminderDateTime" name="reminderDateTime"  placeholder="Reminder Date">
          </div>
          <div class="form-group">
              <label>Reminder Text</label>
            <input class="form-control" [(ngModel)]="currentReminder.reminderText" type="text" id="reminderText" name="reminderText" placeholder="Reminder Text">
          </div>
          <div class="form-group">
              <button type="button" ngbAutofocus class="btn btn-primary" [disabled]="!currentReminder.reminderDateTime || !currentReminder.reminderText || !currentReminder.reminderEmail"
              (click)="addReminder()">Save</button>
          </div>
          
          <h5>Current Reminders</h5>
          <div *ngFor="let reminder of currentReminders">
              <hr/>
            {{currentReminder.reminderDateTime | date:'yyyy-MM-dd'}}<br/>
            {{reminder.reminderText}}
            
          </div>

      </div>
      <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-primary" [disabled]="!currentReminder.reminderDateTime || !currentReminder.reminderText"
          (click)="modal.close('Close click')">Close</button>
      </div>
    </form>
  </ng-template>


  <ng-template #notesModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Notes</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <textarea class="form-control" id="notes" name="notes" [(ngModel)]="currentNotes">
                </textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
      </div>
    </ng-template>
