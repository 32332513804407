import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
 
@Injectable()
export class EventsService {
 
  // Observable string sources
  private genricEventSource = new Subject<string>();

  // Observable string streams
  genericEvent$ = this.genricEventSource.asObservable();
 
  // Service message commands
  fireEvent(command: string) {
    this.genricEventSource.next(command);
  }
}

