import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { EnvironmentService } from './environment.service';
import { DocumentResult } from '../models/documentResult';
import { Document } from '../models/document';

@Injectable({
  providedIn: 'root'
})
export class DocumentApiService extends ApiService<Document> {

  _controller: string = 'document';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('document', http, env);
  }

  public ListDocuments(page: number = 1, perPage: number = 20, search: string = null): Observable<DocumentResult> {
    let queryString = '';

    queryString = `${queryString}page=${page}&perPage=${perPage}`;

    if (search) {
      queryString = `${queryString}&search=${search}`;
    }

    return this.get<DocumentResult>(`api/${this._controller}/?${queryString}`);
  }

  public GetDocument(id: number): Observable<{}> {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/${id}`;
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }


}
