<div class="mb-1" *ngIf="superAdmin || orgAdmin">
  <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="status"  [class.alt-color]="orgId == 89">
  <div class="row">
    <div class="col-6 px-4">
      <app-puzzle-wheel [percentage]="this.status.umbrellaReport.percentage" [section]="'umbrella_report'">
      </app-puzzle-wheel>
    </div>
    <div class="col-6">
      <div class="detail-block p-4 m-4">
        <div class="logo text-center">
          <img [src]="status.logoUrl" class="img-fluid company-logo" />
        </div>
        <hr />
        <div class="text-center d-flex align-items-center justify-content-around">
          <div class="d-flex align-items-center">
            <span class="me-3">Period:</span>
            <ng-select [items]="periods" bindLabel="name" [multiple]="false" [loading]="periodsLoading"
              (change)="periodSelected($event)" [(ngModel)]="selectedPeriod" [clearable]="false">
            </ng-select>
          </div>
          <div>
            <a routerLink="/dashboard/umbrella-report-compare">Compare</a>
          </div>
        </div>
        <div class="detail-block-items px-4 mx-4">
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>APPLICABLE ACTS</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.umbrellaReport.applicableActs}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="status.umbrellaReport.applicableActs == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="status.umbrellaReport.applicableActs > 0" class="btn btn-link icon-link"
                (click)="getCompanyActs()"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>RAW RISK</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill {{status.umbrellaReport.overallRisk?.toLowerCase()}} text-uppercase">{{getRiskLabel(status.umbrellaReport.overallRisk, status.umbrellaReport.impact, status.umbrellaReport.likelihood)}}</span>
            </div>
            <div class="text-center px-2">
              <div style="width:50px"></div>
              <!-- <button class="btn btn-link icon-link" (click)="getCompanyActs()"><span
                  class="icon icon-view-red"></span></button> -->
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>RESIDUAL RISK</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill {{status.umbrellaReport.residualRisk?.toLowerCase()}} text-uppercase">{{getRiskLabel(status.umbrellaReport.residualRisk, status.umbrellaReport.residualImpact, status.umbrellaReport.residualLikelihood)}}</span>
            </div>
            <div class="text-center px-2">
              <div style="width:50px"></div>
              <!-- <button class="btn btn-link icon-link" (click)="getCompanyActs()"><span
                  class="icon icon-view-red"></span></button> -->
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>IMPACTED DEPARTMENTS</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.umbrellaReport.impactedDepartments}}</span></div>
            <div class="text-center px-2">
              <div style="width:50px"></div>
              <!-- <button *ngIf="status.umbrellaReport.impactedDepartments == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="status.umbrellaReport.impactedDepartments > 0" class="btn btn-link icon-link"
                (click)="getCompanyActs()"><span class="icon icon-view-red"></span></button> -->
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>IMPACTED ROLES</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.umbrellaReport.applicableRoles}}</span></div>
            <div class="text-center px-2">
              <div style="width:50px"></div>
              <!-- <button *ngIf="status.umbrellaReport.applicableRoles == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="status.umbrellaReport.applicableRoles > 0" class="btn btn-link icon-link"
                (click)="getCompanyActs()"><span class="icon icon-view-red"></span></button> -->
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>REQUIRED PERMITS</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.umbrellaReport.requiredPermits}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="status.umbrellaReport.requiredPermits == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="status.umbrellaReport.requiredPermits > 0" class="btn btn-link icon-link"
                (click)="getCompanyPermits()"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>REQUIRED POLICIES</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.umbrellaReport.requiredPolicies}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="status.umbrellaReport.requiredPolicies == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="status.umbrellaReport.requiredPolicies > 0" class="btn btn-link icon-link"
                (click)="getCompanyPolicies()"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>REQUIRED REPORTS</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.umbrellaReport.requiredReports}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="status.umbrellaReport.requiredReports == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="status.umbrellaReport.requiredReports > 0" class="btn btn-link icon-link"
                (click)="getCompanyReportingRequirement()"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" *ngIf="status"   [class.alt-color]="orgId == 89">
  <div class="row mt-4" *ngIf="companyActs">
    <div class="col-12 d-flex pb-2">
      <div class="pe-2">
        Sort Order:
        <ng-select style="width: 150px;" [(ngModel)]="orderDecending" (change)="actOrderChange($event)"
          [clearable]="false">
          <ng-option [value]="false">Ascending</ng-option>
          <ng-option [value]="true">Descending</ng-option>
        </ng-select>
      </div>
      <div class="pe-2">
        Filter by Department:
        <ng-select style="width: 150px;" [items]="actDepartments" [(ngModel)]="selectedActDepartment"
          (change)="actDepartmentChange($event)" [clearable]="true" bindLabel="name" bindValue="id"
          placeholder="-- All --"></ng-select>
      </div>
      <div class="pe-2">
        Filter by Role:
        <ng-select style="width: 150px;" [items]="actUsers" [(ngModel)]="selectedActUser"
          (change)="actUserChange($event)" [clearable]="true" bindLabel="email" bindValue="id" placeholder="-- All --">
        </ng-select>
      </div>
      <div>
        Filter by Raw Risk:
        <!-- <ng-select style="width: 150px;" [items]="impactRatings" [(ngModel)]="selectedImpactRating"
          (change)="actImpactRatingChange($event)" [clearable]="true" bindLabel="name" bindValue="id"
          placeholder="-- All --">
          
        </ng-select> -->
        <ng-select style="width: 150px;" [(ngModel)]="selectedImpactRating"
          (change)="actImpactRatingChange($event)" [clearable]="true" 
          placeholder="-- All --">
          <ng-option *ngFor="let item of impactRatings" [value]="item.id">{{item.name}}</ng-option>
        </ng-select>
      </div>
    </div>
    <div class="col-12">
      <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
        <tr>
          <th style="width: 300px">ACT<br />NAME</th>
          <th>ABOUT<br />THE LAW</th>
          <th>CHANGES</th>
          <th>APPLIES<br />TO</th>
          <th>REGULATOR</th>
          <th style="width: 80px">REGULATOR<br />WEBSITE</th>
          <th>RAW RISK RATING</th>
          <th>SCORE</th>
          <th>RESIDUAL RISK RATING</th>
          <th>RISKS</th>
          <th>CONSEQUENCE OF<br />NON-COMPLIANCE</th>
          <th>CONTROLS</th>
          <th>PERMITS</th>
          <th>CRMP/POLICIES</th>
          <th>REPORTING</th>
          <th>POPIA</th>
          <th>RETENTION<br />(YEARS)</th>
          <th style="width: 80px">IMPACTED<br />ROLES</th>
          <th style="width: 80px">IMPACTED<br />DEPARTMENTS</th>
          <th>DOWNLOAD COMPLIANCE MARKERS<br />TASK EXCERCISE</th>
        </tr>
        <tr *ngFor="let item of companyActs.items">
          <td style="width: 300px">
            <a *ngIf="item.listingId" href="#" (click)="library($event, item.listingId)"><span placement="top"
                [ngbTooltip]="item.name">{{truncate(item.name, 1000)}}</span></a>
            <span *ngIf="!item.listingId" placement="top" [ngbTooltip]="item.name">{{truncate(item.name, 1000)}}</span>
          </td>
          <!-- <td><span placement="top" [ngbTooltip]="item.about">{{truncate(item.about)}}</span></td> -->

          <td><a (click)="openTextModal(textModal, item.about)"><span>{{truncate(item.about)}}</span></a></td>
          <td><a (click)="openTextModal(textModal, item.changes)"><span>{{truncate(item.changes)}}</span></a></td>

          <td><span placement="top" [ngbTooltip]="item.appliesTo">{{truncate(item.appliesTo)}}</span></td>
          <td><span placement="top" [ngbTooltip]="item.regulator">{{truncate(item.regulator)}}</span></td>
          <td style="width: 80px"><span placement="top" [ngbTooltip]="item.regulatorWebsite"><a [href]="item.regulatorWebsite"
                _target="_blank">{{truncate(item.regulatorWebsite)}}</a></span></td>
          <td>
            <div class="d-flex">
              <a class="flex-grow-1" (click)="openRiskModal(riskModal, item)">
                <span class=" badge w-100 p-2"
                  [ngClass]="displayImpactRating(item.impactRating, item.likelihood, item.impact)?.toLowerCase()">{{displayImpactRating(item.impactRating, item.likelihood, item.impact)}}</span>
              </a>
            </div>
          </td>
          <td class="text-center">
            {{item.score}}%
          </td>
          <td>
            <div class="d-flex">
              <a class="flex-grow-1" (click)="openResidualRiskModal(riskModal, item)">
                <span class=" badge w-100 p-2"
                  [ngClass]="displayImpactRating(item.residualRiskRating, item.likelihood, item.impact)?.toLowerCase()">{{displayImpactRating(item.residualRiskRating, item.likelihood, item.impact)}}</span>
              </a>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-start">
              <span class=" flex-grow-1" placement="top"
                [ngbTooltip]="item.riskDescription">{{truncate(item.riskDescription)}}</span>
              <a class="ms-1 icon-link" (click)="openRiskDescriptionModal(riskDescriptionModal, item)"><span
                  class="icon icon-small icon-edit-red"></span></a>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-start">
              <span class=" flex-grow-1" placement="top"
                [ngbTooltip]="item.implications">{{truncate(item.implications)}}</span>
              <a class="ms-1 icon-link" (click)="openImplicationsModal(implicationsModal, item)"><span
                  class="icon icon-small icon-edit-red"></span></a>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-start">
              <span class=" flex-grow-1" placement="top" [ngbTooltip]="item.controls">{{truncate(item.controls)}}</span>
              <a class="ms-1 icon-link" (click)="openControlsModal(controlsModal, item)"><span
                  class="icon icon-small icon-edit-red"></span></a>
            </div>
          </td>

          <td>

            <div *ngFor="let permit of item.permits">{{permit.title}}</div>

            <em *ngIf="!item.permits || item.permits.length == 0">- NOT REQUIRED -</em>
          </td>
          <td>
            <div *ngFor="let policy of item.policies">{{policy.title}}</div>
            <em *ngIf="!item.policies || item.policies.length == 0">- NOT REQUIRED -</em>
          </td>
          <td>
            <div *ngFor="let rr of item.reportingRequirements">{{rr.name}}</div>
            <em *ngIf="!item.reportingRequirements || item.reportingRequirements.length == 0">- NOT REQUIRED -</em>
          </td>
          <td class="text-center">{{item.popiaImplication ? 'YES' : 'NO'}}</td>
          <td class="">
            <div class="d-flex align-items-start">
            <span class=" flex-grow-1" placement="top"
              [ngbTooltip]="item.documentRetentionPeriod">{{item.documentRetentionPeriod}}</span>
            <a class="ms-1 icon-link"
              (click)="openDocumentRetentionPeriodModal(documentRetentionPeriodModal, item)"><span
                class="icon icon-small icon-edit-red"></span></a>
              </div>
          </td>
          <td style="width: 80px"><span placement="top" [ngbTooltip]="item.impactedRoles">{{truncate(item.impactedRoles)}}</span></td>
          <td style="width: 80px"><span placement="top"
              [ngbTooltip]="item.impactedDepartments">{{truncate(item.impactedDepartments)}}</span></td>

          <td class="text-center"><a href="'#" (click)="downloadTasks($event, item.actId)"><span class="icon icon-small icon-download-red"></span></a></td>
        </tr>
      </table>
      <div class="pager-bar py-4 mb-1">
        <div class="container text-center d-flex flex-row align-items-center justify-content-center"
          *ngIf="companyActs.items.length > 0">
          <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
          <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
          <span class="px-4">Page {{companyActs.currentPage}} of {{companyActs.totalPages}}</span>
          <div class="px-4 d-flex flex-row align-items-center">
            <div class="pe-2">Items per page:
            </div>
            <div>
              <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
            </div>
          </div>
          <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
          <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row mt-4" *ngIf="companyPermits">
    <div class="col">
      <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
        <tr>
          <th style="width: 50%;">PERMIT</th>
          <th style="width: 50%;">ACT</th>
        </tr>
        <tr *ngFor="let item of companyPermits.items">
          <td>{{item.name}}
            <div *ngIf="item.description">{{item.description}}</div>
          </td>
          <td>
            {{item.act.name}}
          </td>
        </tr>
      </table>
      <div class="pager-bar py-4 mb-1">
        <div class="container text-center d-flex flex-row align-items-center justify-content-center"
          *ngIf="companyPermits.items.length > 0">
          <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
          <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
          <span class="px-4">Page {{companyPermits.currentPage}} of {{companyPermits.totalPages}}</span>
          <div class="px-4 d-flex flex-row align-items-center">
            <div class="pe-2">Items per page:
            </div>
            <div>
              <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
            </div>
          </div>
          <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
          <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
        </div>
      </div>
    </div>
  </div> -->
  <div class="row mt-4" *ngIf="actListings">
    <div class="col">
      <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
        <tr>
          <th style="width: 48%;">ACT</th>
          <th style="width: 48%;">TITLE</th>
          <th style="width: 4%;">&nbsp;</th>
        </tr>
        <tr *ngFor="let item of actListings.items">

          <td>{{item.act.name}}</td>
          <td>{{item.title}}
            <div *ngIf="item.description">{{item.description}}</div>
          </td>
          <td>
            <a target="_blank" [href]="'/dashboard/library#' + item.id.toString()"><span
                class="icon icon-view-red"></span></a>
          </td>
        </tr>
      </table>
      <div class="pager-bar py-4 mb-1">
        <div class="container text-center d-flex flex-row align-items-center justify-content-center"
          *ngIf="actListings.items.length > 0">
          <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
          <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
          <span class="px-4">Page {{actListings.currentPage}} of {{actListings.totalPages}}</span>
          <div class="px-4 d-flex flex-row align-items-center">
            <div class="pe-2">Items per page:
            </div>
            <div>
              <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
            </div>
          </div>
          <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
          <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
        </div>
      </div>
    </div>
  </div>


  <div class="row mt-4" *ngIf="companyReportingRequirements">
    <div class="col">
      <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
        <tr>
          <th style="width: 50%;">REPORTING REQUIREMENT</th>
          <th style="width: 50%;">ACT</th>
        </tr>
        <tr *ngFor="let item of companyReportingRequirements.items">
          <td>{{item.name}}
            <div *ngIf="item.description">{{item.description}}</div>
          </td>
          <td>
            {{item.act.name}}
          </td>
        </tr>
      </table>
      <div class="pager-bar py-4 mb-1">
        <div class="container text-center d-flex flex-row align-items-center justify-content-center"
          *ngIf="companyReportingRequirements.items.length > 0">
          <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
          <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
          <span class="px-4">Page {{companyReportingRequirements.currentPage}} of
            {{companyReportingRequirements.totalPages}}</span>
          <div class="px-4 d-flex flex-row align-items-center">
            <div class="pe-2">Items per page:
            </div>
            <div>
              <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
            </div>
          </div>
          <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
          <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
        </div>
      </div>
    </div>
  </div>

</div>
<ng-template #riskModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{riskModalTitle}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <app-risk-modal (onSelect)="riskUpdated($event)" [rating]="newRating" [riskData]="riskData" [residualModal]="residualModal" [status]="risidualRiskStatus" [act]="currentAct" [readOnly]="false"></app-risk-modal>
    <!-- <div class="row">
      <div class="col-lg-6">
        <app-impact-rating (onSelect)="riskUpdated($event)" [value]="newRating"></app-impact-rating>
      </div>
      <div class="col-lg-6" *ngIf="residualModal">
        <div class="py-3">
          <app-compliance-status [status]="risidualRiskStatus"></app-compliance-status>
        </div>
      </div>
      <div class="col-lg-6" *ngIf="!residualModal">
        <div class="mb-2">
          <strong>Risks</strong>
          <p>{{riskData.riskDescription}}</p>
        </div>
        <div class="mb-2">
          <strong>Consequence of Non-compliance</strong>
          <p>{{riskData.implications}}</p>
        </div>
        <div class="mb-2">
          <strong>Controls</strong>
          <p>{{riskData.controls}}</p>
        </div>
      </div>
    </div> -->

    <!-- <form>
      <div class="form-group">
        <select class="form-control" id="impactRating" name="impactRating" [(ngModel)]="currentImpactRating">
          <option *ngFor="let ir of impactRatings" [value]="ir.id">{{ir.name}}</option>
        </select>
      </div>
    </form> -->
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" [disabled]="!newRating || !newRating.id"
      (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<ng-template #controlsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Control</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <textarea class="form-control" id="controls" name="controls" [(ngModel)]="currentControls">
            </textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>

<ng-template #implicationsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Implications</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <textarea class="form-control" id="implications" name="implications" [(ngModel)]="currentImplications">
              </textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>

<ng-template #riskDescriptionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Risk Description</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <textarea class="form-control" id="riskDescription" name="riskDescription" [(ngModel)]="currentRiskDescription">
                </textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>

<ng-template #textModal let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div [innerHtml]="textModalBody"></div>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.dismiss('Close click')">Close</button>
  </div>
</ng-template>

<ng-template #documentRetentionPeriodModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Document Retention Period</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <input type="number" min="0" class="form-control" id="documentRetentionPerdiod" name="documentRetentionPerdiod"
          [(ngModel)]="currentDocumentRetentionPeriod" />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>