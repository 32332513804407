<ng-container *ngIf="!this.edit && !this.add">


<ng-container *ngIf="currentState.listingTypeId == null && !parentId">
  
  <div class="container my-3">
    <h2>Library</h2>
    <div *ngFor="let listingType of listingTypes">
      <div class="listing flex-grow-1 p-2 mb-1"><a href="#" (click)="selectListingType($event, listingType.id)"><h4>{{listingType.description}}</h4></a></div>
    </div>
  </div>
</ng-container>

  <div *ngIf="result" class="pb-4">
    <div class="search-bar py-4">
      <div class="container d-flex  justify-content-between">
        <div class="d-flex  align-items-center">

          <div class="px-1"><span class="icon icon-documents-red"></span></div>
          <div class="px-1">View documents by type:</div>
          <div class="px-1">
            <select class="form-control" [(ngModel)]="currentState.listingTypeId" (change)="listingType()"
              [disabled]="result.item" name="listingTypes">
              <option *ngFor="let listingType of listingTypes" [selected]="listingType.id == currentState.listingTypeId"
                [value]="listingType.id">
                {{listingType.description}}
              </option>
            </select>
          </div>

        </div>
        <div class="d-flex  align-items-center">
          <div class="px-1"><span class="icon icon-search-red"></span></div>
          <div class="px-1">Search all documents:</div>
          <div class="px-1"><input class="form-control" name="search" type="text"
              [(ngModel)]="currentState.searchTerm" /></div>
          <div class="px-1"><button class="btn btn-primary btn-small" type="button"
              [disabled]="currentState.searchTerm == ''" (click)="search()">SEARCH</button></div>
          <div *ngIf="superAdmin" class="px-1"><button class="btn btn-primary btn-small" type="button"
              (click)="addItem()">ADD
              NEW</button></div>
          <div *ngIf="superAdmin && result.item"><button class="btn btn-primary btn-small" type="button"
              (click)="editItem(result.item)">EDIT</button></div>
        </div>
      </div>
    </div>
    <div class="breadcrumb" *ngIf="result.item">
      <span *ngIf="result.item"><a (click)="goto($event, null)">Home</a>&nbsp;&gt;&nbsp;</span>
      <span *ngFor="let bc of breadCrumbs">
        <ng-container *ngIf="bc.id != result.item.id">
          <a (click)="goto($event, bc.id)">{{bc.title}}</a>&nbsp;&gt;&nbsp;
        </ng-container>
        <ng-container *ngIf="bc.id == result.item.id">{{bc.title}}
        </ng-container>
      </span>
    </div>
    <div class="container mt-4">
      <div class="d-flex  align-items-start">
        <div>

          <div class="p-2" *ngIf="result.items.length > 0">
            <button class="btn btn-link" (click)="sort()">
              <span class="icon icon-large icon-sort-grey"></span>
            </button>
          </div>
        </div>
        <div class="flex-grow-1">
          <div class="d-flex" *ngIf="result.item">
            <h3 class="flex-grow-1">{{result.item.title}}
            </h3>
            <a class="me-auto mb-2 btn btn-light btn-small" (click)="copyToClipboard()">copy link</a>
          </div>
          <div *ngIf="result.items.length == 0" class="mb-1 d-flex  align-items-center">
            <div class="listing flex-grow-1 p-2">No sub-items in this listing</div>
          </div>
          <div *ngIf="result.items.length > 0" class="mb-1 d-flex  align-items-center">
            <p>Items in <span class="text-green">green</span> are exclusive</p>
          </div>
          <div *ngFor="let li of result.items" class="mb-1 d-flex  align-items-center">
            <div class="listing flex-grow-1 p-2" [class.exclusive]="li.exclusive">
              <div><a [href]="'/dashboard/library#' + li.id" class="p-0 text-uppercase"
                  (click)="goto($event, li.id)"><strong>{{li.title}}</strong></a>
              </div>
              <div>{{li.description}}&nbsp;</div>
            </div>
            <div class="px-3 d-flex flex-column">
              <a (click)="goto(li.id)" title="View"><span class="icon icon-view-red"></span></a>
              <a *ngIf="superAdmin" (click)="archive(li.id)" title="Archive"><span
                  class="icon icon-hide-red"></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pager-bar py-4 mb-1">
      <div class="container text-center d-flex flex-row align-items-center justify-content-center"
        *ngIf="result.items.length > 0">
        <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
        <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
        <span class="px-4">Page {{result.currentPage}} of {{result.totalPages}}</span>
        <div class="px-4 d-flex flex-row align-items-center">
          <div class="pe-2">Items per page:
          </div>
          <div>
            <ng-select [items]="perPageItems" [(ngModel)]="currentState.perPage" (change)="perPageChange($event)"
              [clearable]="false" [dropdownPosition]="'top'"></ng-select>
          </div>
        </div>
        <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
        <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
      </div>
    </div>
    <div class="container content-item" *ngIf="result.item">
      <h3 class="text-center">{{result.item.title}}</h3>
      <div (click)="click($event)" [innerHTML]="result.item.content | safeHtml"></div>
      <hr />
      <h4>Documents</h4>
      <div *ngIf="!result.item.documents || result.item.documents.length == 0">
        No documents
      </div>
      <app-document-list-item *ngFor="let doc of result.item.documents" [document]="doc"></app-document-list-item>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="this.edit || this.add">
  <div class="container mt-2">
    <div class="row">
      <div class="col-12 col-sm-10 col-md-8   offset-sm-1 offset-md-2  ">
        <h3 *ngIf="this.edit">Edit Item</h3>
        <h3 *ngIf="this.add">Add Item</h3>
        <form [formGroup]="mainForm" (ngSubmit)="submit()">
          <div class="form-group row">
            <label for="name" class="col-12 col-form-label text-start">Title</label>
            <div class="col-12">
              <input type="text" class="form-control" id="title" formControlName="title" [class.is-invalid]="submitted && mainForm.controls.title.invalid">
              <div class="invalid-feedback text-end" *ngIf="mainForm.controls.title.errors">
                <div *ngIf="mainForm.controls.title.errors.required">Required</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="description" class="col-12 col-form-label text-start">Description</label>
            <div class="col-12">
              <input type="text" class="form-control" id="description" formControlName="description">
            </div>
          </div>
          <div class="form-group row">
            <label for="order" class="col-12 col-form-label text-start">Order</label>
            <div class="col-12">
              <input type="text" class="form-control" id="order" formControlName="order">
              
            </div>
          </div>
          <div class="form-group row">
            <label for="listingTypes" class="col-12 col-form-label text-start">Types</label>
            <div class="col-12">
              <ng-select formControlName="listingTypes" [items]="listingTypes" [multiple]="true" [closeOnSelect]="false"
                [searchable]="true" bindLabel="description" bindValue="id" placeholder="No Types selected">
              </ng-select>
              
            </div>
          </div>
          <div class="form-group row">
            <label for="listingTypes" class="col-12 col-form-label text-start">Documents</label>
            <div class="col-12">
              <ng-select [items]="documents$ | async" bindLabel="title" [multiple]="true" [hideSelected]="true"
                [loading]="documentsLoading" [typeahead]="documentsInput$" formControlName="documents"
                placeholder="No Documents selected">
              </ng-select>
              
            </div>
          </div>

          <div class="form-group row">
            <label for="listingTypes" class="col-12 col-form-label text-start">Shared Companies</label>
            <div class="col-12">
              <ng-select formControlName="sharedCompanies" [items]="companies" [multiple]="true" [closeOnSelect]="false"
                [searchable]="true" bindLabel="name" bindValue="id" placeholder="No Companies selected">
              </ng-select>
              
              <small id="sharedCompaniesHelp" class="form-text text-muted">Companies listed here will have access to
                this item. These companies are automatically derived from the parent item. Please use the Library section under Admin to modify this.</small>
            </div>
          </div>


          <div class="form-group row">
            <label for="listingTypes" class="col-12 col-form-label text-start">Exclusive Company</label>
            <div class="col-12">
              <ng-select formControlName="exclusiveCompany" [items]="companies" [multiple]="false"
                [closeOnSelect]="true" [searchable]="true" bindLabel="name" bindValue="id"
                placeholder="No Company selected">
              </ng-select>
              
              <small id="exclusiveCompanyHelp" class="form-text text-red">If a company is selected here this item will
                be exclusively visible on their library and no other company library. It will also ignore companies
                selected in the Shared Companies field above. You can only edit this on the root item.</small>
            </div>
          </div>

          <div class="form-group row">

              <label for="content" class="col-12 col-form-label text-start">Content &nbsp; &nbsp; <button type="button" class="btn btn-outline-primary" (click)="fullscreen()">Fullscreen</button> </label>
              
            

            <div class="col-12 position-relative editor">
              <ckeditor #editor [editor]="Editor" [(ngModel)]="editorData"  [ngModelOptions]="{standalone: true}"  (ready)="onEditorReady($event)">
              </ckeditor>
            </div>
          </div>

          <div class="text-end pb-4">
            <button type="button" class="btn btn-default me-2" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-default">Save</button>
          </div>
        </form>

      </div>
    </div>
  </div>

  <div class="content-modal p-4 d-flex flex-column editorFull" [class.d-none]="!full" >
    
    <div class="text-end mb-2">
      <button type="button" ngbAutofocus class="btn btn-primary" 
      (click)="closeModal()">Close</button>
    </div>

    <ckeditor #editorFull [editor]="Editor" [(ngModel)]="editorDataFull" (ready)="onEditorReady($event)" class="mb-3 flex-fill">
    </ckeditor>
  
  
    
  </div>
  

</ng-container>