import { formatDate } from '@angular/common';
import { Component, TemplateRef, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ContractDocumentCorrespondence, DocumentCorrespondenceAttachment, DocumentCorrespondenceType, DocumentTemplate, DocumentTemplateType, PermitDocument, PermitDocumentCorrespondence, PermitDocumentStatus } from 'src/app/models/documents';
import { AlertService } from 'src/app/services/alert.service';
import { DocumentsApiService } from 'src/app/services/documents-api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-permits-add-edit',
  templateUrl: './permits-add-edit.component.html',
  styleUrl: './permits-add-edit.component.scss'
})
export class PermitsAddEditComponent {

  private modalService = inject(NgbModal);

  companyId: number;
  orgId: number;
  apiCompanyId?: number;

  userId: number;
  user: boolean = false;
  contractAdmin: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  templateTypes: DocumentTemplateType[];
  templates: DocumentTemplate[];
  documentCorrespondenceTypes: DocumentCorrespondenceType[];

  statuses: PermitDocumentStatus[];

  submitted = false;

  mainForm = new FormGroup({
    id: new FormControl(),
    createdAt: new FormControl(),
    lastModified: new FormControl(),
    referenceNumber: new FormControl(),

    documentFilePath: new FormControl(),
    documentFileData: new FormControl(),

    standardTemplate: new FormControl(undefined, Validators.required),
    useAsTemplate: new FormControl(false),
    templateName: new FormControl(undefined),

    documentTemplateId: new FormControl(),

    requestedDate: new FormControl(),

    requiredByName: new FormControl(),
    requiredByIDNumber: new FormControl(),
    requiredByDepartment: new FormControl(),
    requiredByAddress: new FormControl(),
    requiredByContactPerson: new FormControl(),
    requiredByEmail: new FormControl(),
    requiredByContactNumber: new FormControl(),

    requestorCompany: new FormControl(),
    requestorDepartment: new FormControl(),
    requestorName: new FormControl(),
    requestorEmail: new FormControl(),
    requestorContactNumber: new FormControl(),

    permitType: new FormControl(),
    law: new FormControl(),
    applicableArea: new FormControl(),

    permitCost: new FormControl(),
    permitCostPerMonth: new FormControl(),
    permitCostPerAnnum: new FormControl(),
    permitIncreasePerAnnum: new FormControl(),

    permitStartDate: new FormControl(),
    permitEndDate: new FormControl(),
    permitTerm: new FormControl(),
    permitRenewalDate: new FormControl(),
    permitTerminationDate: new FormControl(),

    regulatorName: new FormControl(),
    regulatorCapacity: new FormControl(),
    regulatorDepartment: new FormControl(),
    regulatorEmail: new FormControl(),
    regulatorContactNumber: new FormControl(),
    certificateFileData: new FormControl(),
    certificateFilePath: new FormControl(),

    permitConditions: new FormControl(),
    permitGranted: new FormControl(),
    companyId: new FormControl(),
    company: new FormControl(),
    permitDocumentStatusId: new FormControl(undefined, Validators.required),
    permitDocumentStatus: new FormControl(),
    userId: new FormControl(0),
    user: new FormControl(),
  });

  currentDocumentId = 0;
  //currentDocument: PermitDocument;


  constructor(
    private documentsApi: DocumentsApiService,
    private route: ActivatedRoute,
    private token: TokenService,
    private router: Router,
    private spinner: SpinnerService,
    private alertService: AlertService) { }

  ngOnInit() {

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      // this.route.paramMap.subscribe((params: ParamMap) => {
      //   this.companyId = +params.get('companyId');
      //   this.apiCompanyId = this.companyId;
      //   if (this.companyId) {
      //     this.init();
      //   }
      // });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }


    this.route.paramMap.subscribe((params: ParamMap) => {
      this.currentDocumentId = +params.get('id');

      this.init();
    });

    this.documentsApi.GetPermitStatuses().subscribe(data => {
      this.statuses = data;
    });

    this.mainForm.controls.standardTemplate.valueChanges.subscribe(data => {
      this.mainForm.controls.documentTemplateId.clearValidators();
      this.mainForm.controls.documentFileData.clearValidators();

      if (this.currentDocumentId == 0) {
        if (data == true) {
          this.mainForm.controls.documentTemplateId.addValidators(Validators.required);
        }
        else {
          this.mainForm.controls.documentFileData.addValidators(Validators.required);
        }
      }

      this.mainForm.controls.documentTemplateId.updateValueAndValidity();
      this.mainForm.controls.documentFileData.updateValueAndValidity();
    });

    this.mainForm.controls.useAsTemplate.valueChanges.subscribe(data => {
      if (this.mainForm.controls.useAsTemplate.value) {
        this.mainForm.controls.templateName.addValidators(Validators.required);
      } else {
        this.mainForm.controls.templateName.clearValidators();
      }
      this.mainForm.controls.templateName.updateValueAndValidity();
    });
  }

  init() {
    if (this.apiCompanyId) {
      this.documentsApi.GetTemplates(this.apiCompanyId, 3).subscribe(data => this.templates = data);
    }
    if (this.currentDocumentId > 0 && this.apiCompanyId) {
      this.documentsApi.GetDocumentCorrespondenceTypes('Permit').subscribe(data => {
        this.documentCorrespondenceTypes = data;
      });
      this.documentsApi.GetPermitDocument(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
        if (!data){
          this.router.navigateByUrl('/dashboard/documents/contracts');
          return;
        }

        this.mainForm.patchValue(data);

        this.mainForm.patchValue({
          requestedDate: data.requestedDate ? formatDate(data.requestedDate, 'yyyy-MM-dd', 'en') : undefined,
          permitStartDate: data.permitStartDate ? formatDate(data.permitStartDate, 'yyyy-MM-dd', 'en') : undefined,
          permitEndDate: data.permitEndDate ? formatDate(data.permitEndDate, 'yyyy-MM-dd', 'en') : undefined,
          permitRenewalDate: data.permitRenewalDate ? formatDate(data.permitRenewalDate, 'yyyy-MM-dd', 'en') : undefined,
          permitTerminationDate: data.permitTerminationDate ? formatDate(data.permitTerminationDate, 'yyyy-MM-dd', 'en') : undefined,
        });
      })
    }
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    
  }

  fileUpload(control: FormControl, event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) { //2MB
        control.setValue(undefined);
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        control.patchValue(reader.result);
      };
    }
  }


  submit() {

    console.log(this.apiCompanyId);
    this.mainForm.markAllAsTouched();

    if (!this.mainForm.valid) {
      this.alertService.error('Not all required fields have been completed please check the form.');
      return;
    }

    let document = this.mainForm.value as PermitDocument;
    document.id = this.currentDocumentId;
    document.companyId = this.apiCompanyId;

    this.spinner.startBackground();
    let func: Observable<{}>;

    if (this.currentDocumentId == 0) {
      func = this.documentsApi.AddPermitDocument(document);

    } else {
      func = this.documentsApi.UpdatePermitDocument(document);
    }

    func.subscribe(() => {
      this.spinner.stopBackground();
      this.alertService.success('Record succesfully added');
      this.router.navigateByUrl('/dashboard/documents/permits');
    }, error => {
      this.spinner.stopBackground();
      this.alertService.error(JSON.stringify(error));
    });

  }

  correspondenceForm: FormGroup;
  correspondenceFormSubmitted = false;

  sendCorrespondence(content: TemplateRef<any>) {
    this.correspondenceForm = new FormGroup({
      name: new FormControl(undefined, Validators.required),
      email: new FormControl(undefined, [Validators.required, Validators.email]),
      sender: new FormControl(undefined, Validators.required),
      documentFileData: new FormControl(undefined, Validators.required),
      documentCorrespondenceTypeId: new FormControl(undefined, Validators.required),
      attachments: new FormArray([])
    });

    this.correspondenceFormSubmitted = false;
    
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				
			},
			(reason) => {
				
			},
		);
  }

  addAttachement() {
    (this.correspondenceForm.controls.attachments as FormArray).push(new FormControl(undefined, Validators.required));
      
    this.correspondenceForm.updateValueAndValidity();
  }

  removeAttachement(index: number) {
    (this.correspondenceForm.controls.attachments as FormArray).removeAt(index);
    this.correspondenceForm.updateValueAndValidity();
  }

  submitCorrespondence(){
    this.correspondenceFormSubmitted = true;
    if (!this.correspondenceForm.valid){
      return;
    }

    this.showCorrespondence = false;

    let attachments: DocumentCorrespondenceAttachment[] = [];

    (this.correspondenceForm.controls.attachments as FormArray).controls.forEach(f => {
        let a: DocumentCorrespondenceAttachment = {
          documentFileData: f.value
        };
        attachments.push(a);
    });

    
    let model: PermitDocumentCorrespondence = {
      documentCorrespondenceTypeId: this.correspondenceForm.controls.documentCorrespondenceTypeId.value,
      permitDocumentId: this.currentDocumentId,
      email: this.correspondenceForm.controls.email.value,
      name: this.correspondenceForm.controls.name.value,
      sender: this.correspondenceForm.controls.sender.value,
      documentFileData: this.correspondenceForm.controls.documentFileData.value,
      attachments: attachments
    };

    this.spinner.start();

    this.documentsApi.addPermitDocumentCorrespondence(model, this.apiCompanyId).subscribe(data => {
      this.spinner.stop();
      this.alertService.success('Correspondence succesfully submitted');
      this.modalService.dismissAll();
    }, error => {
      this.spinner.stop();
      this.alertService.error(JSON.stringify(error));
    })

  }

  correspondence: PermitDocumentCorrespondence[];
  showCorrespondence = false;
  viewCorrespondence(){
    
    this.spinner.startBackground();
    this.documentsApi.getPermitDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      this.showCorrespondence = true;
      this.correspondence = data;
      this.spinner.stopBackground();
    });
  }

  hideCorrespondence(){
    this.showCorrespondence = false;
  }

  download(ev: Event, id: number) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getPermitDocumentCorrespondenceFile(id, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadAttachment(ev: Event, attachmentId: number, correspondenceId: number){
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getPermitDocumentCorrespondenceAttachmentFile(this.currentDocumentId, attachmentId, correspondenceId, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    });
  }

}
