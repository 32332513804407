<div class="company-selector py-3">
  <div class="container d-flex">
    <div class=" me-2" *ngIf="superAdmin">
      <ng-select [(ngModel)]="selectedOrganisation" [items]="organisations" bindLabel="name" bindValue="id" placeholder="Select Organisation"
        (change)="selectOrganisation($event)" (clear)="clearOrganisation()">
      </ng-select>
    </div>
    <div class=" me-2">
      <ng-select [(ngModel)]="selectedCompany" [items]="companies" bindLabel="name" bindValue="id" [disabled]="!selectedOrganisation || !companies"
        placeholder="Select Company" (change)="selectCompany($event)" (clear)="clearCompany()">
      </ng-select>
    </div>
    <div class=" me-2">
      <ng-select [(ngModel)]="selectedSubCompany" [items]="subCompanies" bindLabel="name" bindValue="id" [disabled]="!selectedOrganisation || !companies"
        placeholder="Select Sub-Company" (change)="selectSubCompany($event)" (clear)="clearSubCompany()">
      </ng-select>
    </div>
    <div class=" me-2">
      <ng-select [(ngModel)]="selectedBusinessUnit" [items]="businessUnits" bindLabel="name" bindValue="id" [disabled]="!selectedOrganisation || !companies"
        placeholder="Select Business Unit" (change)="selectBusinessUnit($event)" (clear)="clearBusinessUnit()">
      </ng-select>
    </div>
    <div class=" me-2">
      <ng-select [(ngModel)]="selectedArea" [items]="areas" bindLabel="name" bindValue="id" [disabled]="!selectedOrganisation || !companies"
        placeholder="Select Area" (change)="selectArea($event)" (clear)="clearArea()">
      </ng-select>
    </div>
    <div>
      <ng-select [(ngModel)]="selectedCategory" [items]="categories" bindLabel="name" bindValue="id" [disabled]="!selectedOrganisation || !companies"
        placeholder="Select Legal Category" (change)="selectCategory($event)" (clear)="clearCategory()">
      </ng-select>
    </div>
  </div>
</div>