import { Component, OnInit, ViewChild } from '@angular/core';
import { ActApiService } from '../../../../services/act-api.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LookupApiService } from '../../../../services/lookup-api.service';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LibraryApiService } from '../../../../services/library-api.service';

import { Subject, Observable, of, concat, forkJoin } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, map } from 'rxjs/operators'
import { ReportingRequirementsApiService } from '../../../../services/reporting-requirements-api.service';
import { TaskItemApiService } from '../../../../services/taskitem-api.service';
import { IndustryApiService } from '../../../../services/industry-api.service';
import { QuestionApiService } from '../../../../services/question-api.service';
import { FormSubmitService } from '../../../../services/form-submit.service';
import { Act } from 'src/app/models/act';
import { ActFull } from 'src/app/models/actFull';
import { ActResult } from 'src/app/models/actResult';
import { Department } from 'src/app/models/department';
import { EntityType } from 'src/app/models/entityType';
import { ImpactRating } from 'src/app/models/impactRating';
import { Industry } from 'src/app/models/industry';
import { ListingItem } from 'src/app/models/listingItem';
import { Question } from 'src/app/models/question';
import { ReportingRequirement } from 'src/app/models/reportingRequirement';
import { RiskRating } from 'src/app/models/riskRating';
import { TaskItem } from 'src/app/models/taskItem';

@Component({
  selector: 'app-act-admin',
  templateUrl: './act-admin.component.html',
  styleUrls: ['./act-admin.component.scss']
})
export class ActAdminComponent implements OnInit {

  @ViewChild('impactRating', { static: true }) impactRatingSelect: NgSelectComponent;
  @ViewChild('riskModal', { static: true }) riskModal: any;
  @ViewChild('industryModal', { static: true }) industryModal: any;
  @ViewChild('questionModal', { static: true }) questionModal: any;
  @ViewChild('permitModal', { static: true }) permitModal: any;
  @ViewChild('reportingRequirementModal', { static: true }) reportingRequirementModal: any;
  @ViewChild('taskItemModal', { static: true }) taskItemModal: any;
  result: ActResult

  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;

  currentPage: number = 1;
  perPage: number = 10;
  searchTerm: string = '';

  edit: boolean = false;
  add: boolean = false;

  currentAct: ActFull;

  industries: Array<Industry>;
  impactRatings: Array<ImpactRating>;
  departments: Array<Department>;
  questions: Array<Question>;
  entityTypes: Array<EntityType>;

  submitted = false;
  industrySubmitted = false;

  mainForm = this.formBuilder.group({
    details: this.formBuilder.group({
      name: ['', Validators.required],
      industry: [''],
      entityTypes: ['', Validators.required],
      departments: ['', Validators.required],
      about: [''],
      purpose: [''],
      regulator: [''],
      regulatorWebsite: [''],
      exclusions: [''],
      appliesTo: [''],
      mandatoryTraining: [''],
      directorLiability: [''],
      popiaImplication: [''],
      questions: [''],
      documentRetentionPeriod: ['', Validators.min(0)],
      changes: [''],
      listingId: ['']
    }),
    riskControls: this.formBuilder.group({
      impactRating: ['', Validators.required],
      impact: [''],
      likelihood: [''],

      riskDescription: [''],
      implications: [''],
      controls: [''],

    }),
    requirements: this.formBuilder.group({
      policies: [''],
      //policy: [''],
      permits: [''],
      reportingRequirements: [''],
      prescribedForms: [''],
      //reportingRequirementName: [''],
      //reportingDescription: [''],
      frequency: [''],
    })//,
    //actionsTasks: this.formBuilder.group({
    //  taskItems: [''],
    //}),
  });

  industryForm = this.formBuilder.group({
    name: ['', Validators.required],
  });

  questionForm = this.formBuilder.group({
    question: ['', Validators.required],
    industry: [''],
  });

  permitForm = this.formBuilder.group({
    name: ['', Validators.required],
    description: [''],
  });

  reportingRequirementForm = this.formBuilder.group({
    name: ['', Validators.required],
    description: [''],
  });

  taskItemForm = this.formBuilder.group({
    title: ['', Validators.required],
    description: [''],
    documentUpload: [''],
  });


  policies$: Observable<ListingItem[]>;
  permits$: Observable<ListingItem[]>;
  listings$: Observable<ListingItem[]>;

  policiesLoading = false;
  policiesInput$ = new Subject<string>();

  permitsLoading = false;
  permitsInput$ = new Subject<string>();

  listingsLoading = false;
  listingsInput$ = new Subject<string>();

  //permits: Array<Permit>;
  reportingRequirements: Array<ReportingRequirement>;
  taskItems: Array<TaskItem>;

  constructor(
    private lookupApi: LookupApiService,
    private actApi: ActApiService,
    private libraryApi: LibraryApiService,
    private reportingRequirementsApi: ReportingRequirementsApiService,
    private taskItemApi: TaskItemApiService,
    private spinner: SpinnerService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private industryApi: IndustryApiService,
    private questionApi: QuestionApiService,
    private cache: RequestCacheService,
    private formSubmit: FormSubmitService) {

    forkJoin(
      this.lookupApi.ListIndustries(),
      this.lookupApi.ListImpactRatings(),
      this.lookupApi.ListDepartments(),
      this.lookupApi.ListQuestions(),
      this.lookupApi.ListEntityTypes(),
      //this.permitsApi.List(),
      this.reportingRequirementsApi.List()
    ).subscribe((r) => {
      this.industries = r[0];
      this.impactRatings = r[1];
      this.departments = r[2];
      this.questions = r[3];
      this.entityTypes = r[4];
      //this.permits = r[5];
      this.reportingRequirements = r[5];
    });

  }

  ngOnInit() {

    this.loadPermits();
    this.loadPolicies();
    this.loadListings();
  }


  searchPolicies(term: string) {
    return this.libraryApi.ListItems(null, 1, 50, false, 7, term).pipe(
      map((r) => {
        return r.items;
      })
    );
  }

  searchPermits(term: string) {
    return this.libraryApi.ListItems(null, 1, 50, false, 8, term).pipe(
      map((r) => {
        return r.items;
      })
    );
  }

  searchListings(term: string) {
    return this.libraryApi.ListItems(null, 1, 50, false, null, term).pipe(
      map((r) => {
        return r.items;
      })
    );
  }

  private loadPolicies() {
    this.policies$ = concat(
      of([]), // default items
      this.policiesInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.policiesLoading = true),
        switchMap(term => this.searchPolicies(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.policiesLoading = false)
        ))
      )
    );
  }

  private loadPermits() {
    this.permits$ = concat(
      of([]), // default items
      this.permitsInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.permitsLoading = true),
        switchMap(term => this.searchPermits(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.permitsLoading = false)
        ))
      )
    );
  }

  private loadListings() {
    this.listings$ = concat(
      of([]), // default items
      this.listingsInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.listingsLoading = true),
        switchMap(term => this.searchListings(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.listingsLoading = false)
        ))
      )
    );
  }

  getData() {
    this.spinner.startBackground();
    this.actApi.ListActs(this.currentPage, this.perPage, this.searchTerm).subscribe((data) => {
      this.result = data;

      this.firstEnabled = this.result.currentPage > 1;
      this.prevEnabled = this.result.currentPage > 1;

      this.nextEnabled = this.result.currentPage < data.totalPages;
      this.lastEnabled = this.result.currentPage < data.totalPages;

      this.spinner.stopBackground();
    });
  }

  addItem() {
    this.currentAct = null;
    this.taskItems = new Array();
    this.add = true;
    this.edit = false;
    this.mainForm.reset();
  }
  editItem(act: Act) {

    this.spinner.startBackground();
    this.actApi.GetById(act.id).subscribe(data => {

      this.currentAct = data;

      let industryId = null;
      let impactRatingId = null;

      if (this.currentAct.industry && this.currentAct.industry.id) {
        industryId = this.currentAct.industry.id;
      }

      if (this.currentAct.impactRating && this.currentAct.impactRating.id) {
        impactRatingId = this.currentAct.impactRating.id;
      }

      let selectedDepartmentIds: Array<number> = [];
      let selectedQuestionIds: Array<number> = [];
      let selectedEntityTypeIds: Array<number> = [];
      //let selectedListingIds: Array<number> = [];
      let selectedPermitIds: Array<number> = [];
      let selectedReportingRequirementIds: Array<number> = [];
      //let selectedTaskItemIds: Array<number> = [];

      this.currentAct.departments.forEach(i => { selectedDepartmentIds.push(i.id) });
      this.currentAct.questions.forEach(i => { selectedQuestionIds.push(i.id) });
      this.currentAct.entityTypes.forEach(i => { selectedEntityTypeIds.push(i.id) });
      //this.currentAct.listings.forEach(i => { selectedListingIds.push(i.id) });
      this.currentAct.permits.forEach(i => { selectedPermitIds.push(i.id) });
      this.currentAct.reportingRequirements.forEach(i => { selectedReportingRequirementIds.push(i.id) });
      //this.currentAct.taskItems.forEach(i => { selectedTaskItemIds.push(i.id) });


      this.mainForm.controls.details.patchValue(
        {
          name: this.currentAct.name,
          industry: industryId,
          entityTypes: selectedEntityTypeIds,
          departments: selectedDepartmentIds,
          about: this.currentAct.about,
          purpose: this.currentAct.purpose,
          regulator: this.currentAct.regulator,
          regulatorWebsite: this.currentAct.regulatorWebsite,
          exclusions: this.currentAct.exclusions,
          appliesTo: this.currentAct.appliesTo,
          mandatoryTraining: this.currentAct.mandatoryTraining,
          directorLiability: this.currentAct.directorLiability,
          popiaImplication: this.currentAct.popiaImplication,
          questions: selectedQuestionIds,
          documentRetentionPeriod: this.currentAct.documentRetentionPeriod,
          changes: this.currentAct.changes
        }
      );

      this.mainForm.controls.riskControls.patchValue({
        impactRating: impactRatingId,
        implications: this.currentAct.implications,
        impact: this.currentAct.impact,
        likelihood: this.currentAct.likelihood,
        controls: this.currentAct.controls,
        riskDescription: this.currentAct.riskDescription
      });

      this.mainForm.controls.requirements.patchValue({
        permits: this.currentAct.permits,
        policies: this.currentAct.policies,
        //policy: this.currentAct.policy,
        //permits: selectedPermitIds,
        prescribedForms: this.currentAct.prescribedForms,
        reportingRequirements: selectedReportingRequirementIds,
        //reportingRequirementName: this.currentAct.reportingRequirementName,
        //reportingDescription: this.currentAct.reportingDescription,
        frequency: this.currentAct.frequency,
      });
      // this.mainForm.controls.actionsTasks.patchValue({
      //   taskItems: selectedTaskItemIds
      // });

      this.taskItems = this.currentAct.taskItems;

      this.add = false;
      this.edit = true;

      this.spinner.stopBackground();
    });

  }
  search() {

    this.currentPage = 1;
    this.getData();
  }

  cancel() {
    this.add = false;
    this.edit = false;
  }

  impactRatingOpen() {

    if (!this.newRating) {
      this.newRating = <RiskRating>{};
    }

    let riskControls = this.mainForm.controls.riskControls as UntypedFormGroup;
    this.newRating.id = riskControls.controls.impactRating.value;
    this.newRating.impact = riskControls.controls.impact.value;
    this.newRating.likelihood = riskControls.controls.likelihood.value;

    this.impactRatingSelect.close();
    this.openRiskModal();
  }

  newRating: RiskRating = null;

  openRiskModal() {

    this.modalService.open(this.riskModal).result.then((result) => {

      if (this.newRating && this.newRating.id) {
        this.mainForm.controls.riskControls.patchValue({
          impactRating: this.newRating.id,
          impact: this.newRating.impact,
          likelihood: this.newRating.likelihood,
        });
      }

    }, (reason) => {
    });
  }

  riskUpdated(ev) {
    this.newRating = ev;
  }


  openIndustryModal() {
    this.modalService.open(this.industryModal).result.then((result) => {
    }, (reason) => {
    });
  }

  submitIndustry() {

    this.industrySubmitted = true;

    if (this.industryForm.invalid){
      return;
    }

    this.modalService.dismissAll();
    this.spinner.startBackground();

    let newIndustry = {
      name: this.industryForm.controls.name.value
    }

    this.industryApi.Add(newIndustry).subscribe(data => {
      this.cache.clear();
      this.lookupApi.ListIndustries().subscribe(i => {
        this.industries = i;
        this.mainForm.controls.details.patchValue({
          industry: data.id
        });
        this.spinner.stopBackground();
        this.industryForm.reset();
      });
    });
  }

  openQuestionModal(evt) {

    evt.stopPropagation();
    evt.preventDefault();

    this.modalService.open(this.questionModal).result.then((result) => {
    }, (reason) => {
    });
  }

  submitQuestion() {

    this.modalService.dismissAll();
    this.spinner.startBackground();

    let i = null;

    if (this.questionForm.controls.industry.value) {
      i = this.industries.find(c => this.questionForm.controls.industry.value == c.id);
    }

    let newQuestion: Question = {
      questionText: this.questionForm.controls.question.value,
      industry: i,
      order: 0
    }

    let details = this.mainForm.controls.details as UntypedFormGroup;
    let questionIds = details.controls.questions.value as Array<number>;

    this.questionApi.Add(newQuestion).subscribe(data => {
      this.cache.clear();
      this.lookupApi.ListQuestions().subscribe(i => {
        this.questions = i;

        if (!questionIds) {
          questionIds = new Array<number>();
        }

        questionIds.push(data.id);
        this.mainForm.controls.details.patchValue({
          questions: questionIds
        });
        this.spinner.stopBackground();
        this.questionForm.reset();
      });
    });
  }

  // currentPermit: Permit;
  // openPermitModal(evt, item: Permit = null) {
  //   evt.stopPropagation();
  //   evt.preventDefault();
  //   this.currentPermit = item;

  //   if (this.currentPermit) {
  //     this.permitForm.patchValue({
  //       name: this.currentPermit.name,
  //       description: this.currentPermit.description
  //     });
  //   }
  //   this.modalService.open(this.permitModal).result.then((result) => {
  //   }, (reason) => {
  //   });
  // }

  // submitPermit() {

  //   this.modalService.dismissAll();
  //   this.spinner.startBackground();

  //   let requirements = this.mainForm.controls.requirements as FormGroup;
  //   let permitIds = requirements.controls.permits.value as Array<number>;

  //   if (this.currentPermit) {
  //     this.currentPermit.name = this.permitForm.controls.name.value;
  //     this.currentPermit.description = this.permitForm.controls.description.value;

  //     this.permitsApi.Update(this.currentPermit.id, this.currentPermit).subscribe(data => {
  //       this.cache.clear();
  //       this.permitsApi.List().subscribe(i => {
  //         this.permits = i;

  //         // if (!permitIds) {
  //         //   permitIds = new Array<number>();
  //         // }

  //         //permitIds.push(data.id);

  //         // this.mainForm.controls.requirements.patchValue({
  //         //   permits: permitIds
  //         // });
  //         this.spinner.stopBackground();
  //         this.permitForm.reset();
  //       });
  //     });

  //   }
  //   else {
  //     let newPermit: Permit = {
  //       name: this.permitForm.controls.name.value,
  //       description: this.permitForm.controls.description.value,
  //     }



  //     this.permitsApi.Add(newPermit).subscribe(data => {
  //       this.cache.clear();
  //       this.permitsApi.List().subscribe(i => {
  //         this.permits = i;

  //         if (!permitIds) {
  //           permitIds = new Array<number>();
  //         }

  //         permitIds.push(data.id);

  //         this.mainForm.controls.requirements.patchValue({
  //           permits: permitIds
  //         });
  //         this.spinner.stopBackground();
  //         this.permitForm.reset();
  //       });
  //     });

  //   }
  // }

  currentReportingRequirement: ReportingRequirement;
  openReportingRequirementModal(evt, item: ReportingRequirement = null) {
    evt.stopPropagation();
    evt.preventDefault();
    this.currentReportingRequirement = item;

    if (this.currentReportingRequirement) {
      this.reportingRequirementForm.patchValue({
        name: this.currentReportingRequirement.name,
        description: this.currentReportingRequirement.description
      });
    }
    this.modalService.open(this.reportingRequirementModal).result.then((result) => {
    }, (reason) => {
    });
  }

  submitReportingRequirement() {

    this.modalService.dismissAll();
    this.spinner.startBackground();

    let requirements = this.mainForm.controls.requirements as UntypedFormGroup;
    let reportingRequirementIds = requirements.controls.reportingRequirements.value as Array<number>;

    if (this.currentReportingRequirement) {
      this.currentReportingRequirement.name = this.reportingRequirementForm.controls.name.value;
      this.currentReportingRequirement.description = this.reportingRequirementForm.controls.description.value;

      this.reportingRequirementsApi.Update(this.currentReportingRequirement.id, this.currentReportingRequirement).subscribe(data => {
        this.cache.clear();
        this.reportingRequirementsApi.List().subscribe(i => {
          this.reportingRequirements = i;

          // if (!reportingRequirementIds) {
          //   reportingRequirementIds = new Array<number>();
          // }

          //reportingRequirementIds.push(data.id);

          // this.mainForm.controls.requirements.patchValue({
          //   reportingRequirements: reportingRequirementIds
          // });
          this.spinner.stopBackground();
          this.reportingRequirementForm.reset();
        });
      });

    }
    else {
      let newReportingRequirement: ReportingRequirement = {
        name: this.reportingRequirementForm.controls.name.value,
        description: this.reportingRequirementForm.controls.description.value,
      }



      this.reportingRequirementsApi.Add(newReportingRequirement).subscribe(data => {
        this.cache.clear();
        this.reportingRequirementsApi.List().subscribe(i => {
          this.reportingRequirements = i;

          if (!reportingRequirementIds) {
            reportingRequirementIds = new Array<number>();
          }

          reportingRequirementIds.push(data.id);

          this.mainForm.controls.requirements.patchValue({
            reportingRequirements: reportingRequirementIds
          });
          this.spinner.stopBackground();
          this.reportingRequirementForm.reset();
        });
      });

    }
  }

  currentTaskItem: TaskItem;

  addTask() {

    this.currentTaskItem = null;
    this.openTaskItemModal();
  }

  editTask(item: TaskItem) {
    this.currentTaskItem = item;
    this.openTaskItemModal();
  }

  removeTask(item: TaskItem) {
    //this.currentAct.taskItems

    let i = this.taskItems.findIndex(f => f == item);

    this.taskItems.splice(i, 1);
  }

  openTaskItemModal() {

    if (this.currentTaskItem) {
      this.taskItemForm.patchValue({
        complianceQuery: this.currentTaskItem.complianceQuery,
        description: this.currentTaskItem.description,
        documentUpload: this.currentTaskItem.documentUpload
      });
    }
    else {
      this.taskItemForm.reset();
    }

    this.modalService.open(this.taskItemModal).result.then((result) => {
    }, (reason) => {
    });
  }

  submitTaskItem() {

    if (this.currentTaskItem) {
      this.currentTaskItem.complianceQuery = this.taskItemForm.controls.title.value;
      this.currentTaskItem.description = this.taskItemForm.controls.description.value;
    }
    else {
      this.currentTaskItem = {
        complianceQuery: this.taskItemForm.controls.complianceQuery.value,
        description: this.taskItemForm.controls.description.value,
        documentUpload: this.taskItemForm.controls.documentUpload.value,
      }

      this.taskItems.push(this.currentTaskItem);
    }

    this.modalService.dismissAll();

  }

  submit() {

    this.submitted = true;

    if (this.mainForm.invalid){
      return;
    }

    //this.formSubmit.disableForm();
    this.spinner.startBackground();

    if (this.add) {
      this.currentAct = <ActFull>{};
    }

    let details = this.mainForm.controls.details as UntypedFormGroup;
    let riskControls = this.mainForm.controls.riskControls as UntypedFormGroup;
    let requirements = this.mainForm.controls.requirements as UntypedFormGroup;
    //let actionsTasks = this.mainForm.controls.actionsTasks as FormGroup;



    let i = this.industries.find(c => details.controls.industry.value == c.id);
    let ir = this.impactRatings.find(c => riskControls.controls.impactRating.value == c.id);
    let d = this.departments.filter(c => details.controls.departments.value.indexOf(c.id) >= 0);

    let q: Array<Question> = [];
    if (details.controls.questions.value) {
      q = this.questions.filter(c => details.controls.questions.value.indexOf(c.id) >= 0);
    }

    let e: Array<EntityType> = [];
    if (details.controls.entityTypes.value) {
      e = this.entityTypes.filter(c => details.controls.entityTypes.value.indexOf(c.id) >= 0);
    }

    // let p: Array<Permit> = [];
    // if (requirements.controls.permits.value) {
    //   p = this.permits.filter(c => requirements.controls.permits.value.indexOf(c.id) >= 0);
    // }

    let r: Array<ReportingRequirement> = [];
    if (requirements.controls.reportingRequirements.value) {
      r = this.reportingRequirements.filter(c => requirements.controls.reportingRequirements.value.indexOf(c.id) >= 0);
    }


    //let t: Array<TaskItem> = [];
    //if (actionsTasks.controls.taskItems.value) {
    //  t = this.taskItems.filter(c => actionsTasks.controls.taskItems.value.indexOf(c.id) >= 0);
    //}

    let po: Array<ListingItem> = new Array<ListingItem>();

    if (requirements.controls.policies.value) {
      for (let li of requirements.controls.policies.value) {
        po.push(li);
      }
    }

    let pe: Array<ListingItem> = new Array<ListingItem>();

    if (requirements.controls.permits.value) {
      for (let li of requirements.controls.permits.value) {
        pe.push(li);
      }
    }


    this.currentAct.name = details.controls.name.value;
    this.currentAct.industry = i;
    this.currentAct.controls = riskControls.controls.controls.value;
    this.currentAct.impactRating = ir;
    this.currentAct.implications = riskControls.controls.implications.value;
    this.currentAct.purpose = details.controls.purpose.value;
    this.currentAct.departments = d;
    this.currentAct.questions = q;
    this.currentAct.entityTypes = e;

    this.currentAct.about = details.controls.about.value;
    this.currentAct.regulator = details.controls.regulator.value;
    this.currentAct.regulatorWebsite = details.controls.regulatorWebsite.value;
    this.currentAct.exclusions = details.controls.exclusions.value;
    this.currentAct.appliesTo = details.controls.appliesTo.value;
    this.currentAct.mandatoryTraining = details.controls.mandatoryTraining.value == true;
    this.currentAct.directorLiability = details.controls.directorLiability.value == true;
    this.currentAct.popiaImplication = details.controls.popiaImplication.value == true;
    this.currentAct.documentRetentionPeriod = details.controls.documentRetentionPeriod.value;
    this.currentAct.impact = riskControls.controls.impact.value;
    this.currentAct.likelihood = riskControls.controls.likelihood.value;
    this.currentAct.riskDescription = riskControls.controls.riskDescription.value;

    this.currentAct.changes = details.controls.changes.value;
    this.currentAct.listingId = details.controls.listingId.value?.id;

    //this.currentAct.policy = requirements.controls.policy.value;
    this.currentAct.prescribedForms = requirements.controls.prescribedForms.value;
    //this.currentAct.reportingRequirementName = requirements.controls.prescribedForms.value;
    //this.currentAct.reportingDescription = requirements.controls.prescribedForms.value;
    this.currentAct.frequency = requirements.controls.frequency.value;

    this.currentAct.permits = pe;
    this.currentAct.policies = po;
    this.currentAct.reportingRequirements = r;
    this.currentAct.taskItems = this.taskItems;

    if (this.edit) {
      this.actApi.Update(this.currentAct.id, this.currentAct).subscribe((r) => {
        this.add = false;
        this.edit = false
        this.cache.clear();
        this.formSubmit.enableForm();
        this.spinner.stopBackground();
        //this.getData();
      });
    }

    else if (this.add) {
      this.actApi.Add(this.currentAct).subscribe((r) => {
        this.add = false;
        this.edit = false;
        this.cache.clear();
        this.formSubmit.enableForm();
        this.spinner.stopBackground();
        //this.getData();
      });
    }
  }
}
