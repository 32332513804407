import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { ActSection } from '../models/actSection';

@Injectable({
  providedIn: 'root'
})
export class ActSectionApiService extends ApiService<ActSection> {

  _controller: string = 'actsections';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('actsections', http, env);
  }

  public ListByActId(actId: number): Observable<Array<ActSection>> {
    return this.get<Array<ActSection>>(`api/${this._controller}/?actid=${actId}`);
  }
}
