<nav class="navbar navbar-expand-lg navbar-light bg-white">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/home">
      <img src="/assets/the-legal-team-logo.png" alt="">
    </a>
    <!-- div *ngIf="!production">
      <h1 style="color:red;"><strong>TEST SERVER</strong></h1>
    <div -->

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
      *ngIf="!this.globals.loggedIn">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="!this.globals.loggedIn">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" routerLink="/about">About The Legal Team</a>
        </li>
        <li class="nav-item"[routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" routerLink="/get">Get The Legal Team</a>
        </li>
        <li class="nav-item"[routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" routerLink="/toolkit">SMME Toolkit</a>
        </li>
        <li class="nav-item"[routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" routerLink="/enterprise-supplier-development">Enterprise Development</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" routerLink="/contact">Contact</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" routerLink="/login">Login</a>
        </li>

        <!-- <li *ngIf="this.globals.loggedIn" class="nav-item">
          <a class="nav-link" (click)="logout()">Logout</a>
        </li> -->
      </ul>
    </div>
    <ul class="navbar-nav">
      <li *ngIf="companies && companies.length > 0">
        <ng-select [(ngModel)]="selectedCompany" [items]="companies" bindLabel="name" bindValue="id" [clearable]="false"
        placeholder="Select Company" (change)="selectCompany($event)" style="width: 180px;">
      </ng-select>
      </li>
      <li *ngIf="this.globals.loggedIn">
        <app-notifications></app-notifications>
      </li>
      <li>
        <app-overlay></app-overlay>
      </li>
    </ul>

  </div>
</nav>
