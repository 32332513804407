import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { StatusApiService } from '../../../services/status-api.service';
import { CompanyApiService } from '../../../services/company-api.service';
import { SpinnerService } from '../../../services/spinner.service';
import { RequestCacheService } from '../../../services/request-cache.service';
import { TokenService } from '../../../services/token.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import $ from 'jquery';
//import { ErrorMessage } from 'ng-bootstrap-form-validation';
import { Subject, Observable, of, concat } from 'rxjs';
import {  map } from 'rxjs/operators'
import { ActApiService } from '../../../services/act-api.service';
import { CompanyRoleOwner } from 'src/app/models/companyRoleOwner';
import { CompanyTaskItem } from 'src/app/models/companyTaskItem';
import { AssignCompanyTaskItemUser } from 'src/app/models/assignCompanyTaskItemUser';
import { AnswerTaskItem, AnswerTaskItemDocument } from 'src/app/models/answerTaskItem';
import { CompanyTaskItemDocument } from 'src/app/models/companyTaskItemDocument';
import { CompanyTaskItemSummaryItem } from 'src/app/models/companyTaskItemSummaryItem';
import { Act } from 'src/app/models/act';
import { CompanyTaskItemResult } from 'src/app/models/companyTaskItemResult';
import { CompanyTaskItemSummaryResult } from 'src/app/models/companyTaskItemSummaryResult';
import { CompanyDetailStatus } from 'src/app/models/companyDetailStatus';
import { User } from 'src/app/models/user';
import { Period } from 'src/app/models/period';
import { CompanyTaskItemMark } from 'src/app/models/companyTaskItemMark';
import { LookupApiService } from 'src/app/services/lookup-api.service';
import { EfficiencyRate } from 'src/app/models/efficiencyRate';
import { CompanyAct } from 'src/app/models/companyAct';

@Component({
  selector: 'app-compliance-program',
  templateUrl: './compliance-program.component.html',
  styleUrls: ['./compliance-program.component.scss']
})
export class ComplianceProgramComponent implements OnInit {

  companyId: number;
  orgId: number;
  apiCompanyId?: number;
  status: CompanyDetailStatus;
  summaryResult: CompanyTaskItemSummaryResult;
  result: CompanyTaskItemResult;
  roleOwners: CompanyRoleOwner[];

  userId: number;
  user: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;

  currentPage: number = 1;
  searchTerm: string = '';

  perPageItems = [5, 10, 20, 50, 100];
  perPage = 10;
  summary = true;

  selectedAct: number;
  //acts$: Observable<Act[]>;
  acts: Act[];
  actsLoading = false;
  actsInput$ = new Subject<string>();

  selectedPeriodId?: number = null;
  selectedPeriod: Period;
  periods: Period[];
  periodsLoading = false;
  
  efficiencyRatings: EfficiencyRate[] = [];

  currentAct: CompanyAct;

  answerForm = this.formBuilder.group({
    compliant: [''],
    documents: [''],
    comments: [''],
    controlType: [''],
    controlFrequency: [''],
    controlAutomation: [''],
    controlCategory: [''],
    controlKeyControl: [''],
  });

  markerForm = this.formBuilder.group({
    compliant: [''],
    efficiencyRateId: [''],
    comments: [''],
    correctiveActionPlan: [''],
    correctiveActionPlanDueDate: [''],
    markerMitigates: [''],
    markerDesign: [''],
    markerTimely: [''],
    markerEvidenced: [''],
  });


  customErrorMessages: any[] = [
    {
      error: 'required',
      format: (label, error) => `This field is required`
    }
  ];

  assignAll: boolean = false;
  dueDate: Date;

  textModalBody: string;

  disableAssignButton = false;

  constructor(private route: ActivatedRoute,
    private statusApi: StatusApiService,
    private companyApi: CompanyApiService,
    private actApi: ActApiService,
    private spinner: SpinnerService,
    private cache: RequestCacheService,
    private modalService: NgbModal,
    public token: TokenService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private lookup: LookupApiService) { }

  ngOnInit() {

    this.lookup.ListEfficiencyRatings().subscribe(data => {
      this.efficiencyRatings = data;
    });

    if (localStorage["PER_PAGE"]) {
      this.perPage = localStorage["PER_PAGE"];
    }

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.init();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }



  }

  init() {

    if (this.superAdmin) {
      this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.orgId = this.token.organisationId();
    }

    this.result = null;
    this.currentCompliant = null
    this.selectedAct = null;
    this.selectedPeriodId = null
    this.selectedPeriod = { id: 0, name: 'Current'};

    this.getStatus();
    this.loadActs();
    this.loadPeriods();
    this.getData();
    //this.getCompanyAudits(null);


  }

  getStatus() {
    this.spinner.startBackground();
    this.statusApi.getCompanyStatus(this.apiCompanyId, this.selectedAct, null, this.selectedPeriodId).subscribe((data) => {
      this.status = data;
      this.spinner.stopBackground();
    });

  }
  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    else {
      this.status = null;
    }
  }

  periodSelected(event){
    
    this.selectedPeriodId = this.selectedPeriod ? this.selectedPeriod.id : null;

    this.getData();
    this.getStatus();
  }

  backToSummary(){
    this.summary = true;
    this.getData();
    this.getStatus();
  }
  truncate(str?: string, len: number = 20) {
    if (!str) {
      return str;
    }
    if (str && str.length > len)
      return str.substring(0, len) + '...';
    else
      return str;
  };

  currentCompliant?: number;

  getCompanyAudits(compliant: number) {
    window.location.hash = 'details';
    this.currentCompliant = compliant;
    this.summary = false;
    this.getData();
  }

  actSelected(event) {
    this.currentPage = 1;
    this.selectedAct = null;
    if (event) {
      this.selectedAct = event;
    }
    this.getData();
    this.getStatus();
  }

  summaryItemSelected(ev: Event, item: CompanyTaskItemSummaryItem){
    ev.preventDefault();
    this.result = null;
    this.selectedAct = item.act.id;
    this.currentPage = 1;
    this.getStatus();
    this.getCompanyAudits(0);
  }

  resultsActSelected(event) {
    this.currentPage = 1;
    // this.selectedAct = null;
    // if (event) {
    //   this.selectedAct = event;
    // }

    // this.spinner.startBackground();
    // this.companyApi.GetCompanyTas(this.apiCompanyId, this.selectedAct ? this.selectedAct.id : null).subscribe(data => {
    //   this.userResults = data;
    //   this.spinner.stopBackground();
    // });

    this.getData();
    this.getStatus();

    if (this.selectedAct){
      this.companyApi.GetCompanyAct(this.selectedAct, this.apiCompanyId).subscribe(data => {
        this.currentAct = data;
      });
    }
    

  }


  searchActs(term: string) {
    return this.companyApi.ListCompanyTaskItemActs(this.apiCompanyId, 1, 5000, term).pipe(
      map((r) => {
        return r.items;
      })
    );
    // return this.actApi.ListActs(1, 50, term).pipe(
    //   map((r) => {
    //     return r.items;
    //   })
    // );
  }


  private loadActs() {


    this.actsLoading = true;
    return this.companyApi.ListCompanyTaskItemActs(this.apiCompanyId, 1, 5000).subscribe(data => {
      this.acts = data.items;
      this.actsLoading = false;
    });


    // this.acts$ = concat(
    //   of([]), // default items
    //   this.actsInput$.pipe(
    //     debounceTime(200),
    //     distinctUntilChanged(),
    //     tap(() => this.actsLoading = true),
    //     switchMap(term => this.searchActs(term).pipe(
    //       catchError(() => of([])), // empty list on error
    //       tap(() => this.actsLoading = false)
    //     ))
    //   )
    // );
  }
  
  private loadPeriods() {
    this.periodsLoading = true;
    return this.companyApi.GetCompanyPeriods(this.apiCompanyId).subscribe(data => {
      let current:Period = { id: 0, name: 'Current'};

      this.periods = [current].concat(data);

      this.periodsLoading = false;
      this.selectedPeriod = current;
    });
  }

  getData() {
    this.spinner.startBackground();

    if (this.summary)
    {
      this.companyApi.GetCompanyTaskItemSummary(this.apiCompanyId, this.currentPage, this.perPage, this.selectedAct, this.selectedPeriodId).subscribe((data) => {
        this.summaryResult = data;
  
        this.firstEnabled = this.summaryResult.currentPage > 1;
        this.prevEnabled = this.summaryResult.currentPage > 1;
  
        this.nextEnabled = this.summaryResult.currentPage < data.totalPages;
        this.lastEnabled = this.summaryResult.currentPage < data.totalPages;
  
        this.spinner.stopBackground();
      });
    }
    else {
      
      this.companyApi.GetCompanyTaskItems(this.apiCompanyId, this.currentCompliant, this.currentPage, this.perPage, this.selectedAct, this.selectedPeriodId).subscribe((data) => {
        this.result = data;

        this.firstEnabled = this.result.currentPage > 1;
        this.prevEnabled = this.result.currentPage > 1;

        this.nextEnabled = this.result.currentPage < data.totalPages;
        this.lastEnabled = this.result.currentPage < data.totalPages;

        this.spinner.stopBackground();
      });
    }
  }

  export(format: 'xlsx' | 'pdf'){
    this.spinner.startBackground();
    this.companyApi.ExportCompanyTaskItems(this.apiCompanyId, this.selectedAct, this.selectedPeriodId, this.currentCompliant, format).subscribe((data) => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }

  first() {
    this.currentPage = 1;
    this.getData();
  }

  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.getData();
  }

  next() {
    if (this.summary)
    {
      if (this.currentPage < this.summaryResult.totalPages) {
        this.currentPage++;
      }
    }
    else{
      if (this.currentPage < this.result.totalPages) {
        this.currentPage++;
      }
    }
    
    this.getData();
  }

  last() {
    if (this.summary)
    {
      this.currentPage = this.summaryResult.totalPages;
    }
    else{
      this.currentPage = this.result.totalPages;
    }

    
    this.getData();
  }

  getUserString(user: User[]){
    return user.map(m => `${m.firstname} ${m.lastname}`).join(', ');
  }

  canShowAnswerModal(item: CompanyTaskItem){
    return (item.users && item.users.length > 0 && ((item.users.findIndex(f => f.id == this.userId) > -1) || this.superAdmin || this.companyAdmin) && !this.selectedPeriodId);
  }
  

  currentRoleOwners: CompanyRoleOwner[];

  currentAssignItem: CompanyTaskItem;

  openAssignModal(content, item: CompanyTaskItem) {
    this.assignAll = false;
    this.dueDate = item.dueDate;
    this.currentRoleOwners = null;
    this.roleOwners = null;
    this.currentAssignItem = item;

    this.spinner.startBackground();

    this.companyApi.GetValidCompanyRoleOwners(this.apiCompanyId).subscribe(data => {
      this.roleOwners = data;
      this.currentRoleOwners = item.users.map(m => { return this.roleOwners.find(f => f.user.id == m.id) });
      this.spinner.stopBackground();

      this.modalService.open(content).result.then((result) => {
        let data: AssignCompanyTaskItemUser = {
          taskItemId: item.taskItem.id,
          userIds: this.currentRoleOwners.map(m => m.user.id),
          dueDate: this.dueDate,
          wholeAct: this.assignAll
        };

        this.spinner.startBackground();
        this.disableAssignButton = true;
        this.companyApi.AssignCompanyTaskItemUser(data, this.apiCompanyId).subscribe(() => {
          this.cache.clear();
          this.getCompanyAudits(0);
          this.disableAssignButton = false;
        });
      }, (reason) => {
      });
    });
  }

  
  

  compliantChange(ev){
    if (this.currentItem.taskItem.documentUpload && (!this.currentItem.documents || this.currentItem.documents.length == 0))
    {
      if (this.answerForm.controls.compliant.value != true)
      {
        this.answerForm.controls.documents.setValidators([]);
        this.answerForm.controls.documents.setErrors(null);
      }
      else {
          this.answerForm.controls.documents.setValidators([Validators.required]);
      }
    }
  }

  public currentItem: CompanyTaskItem;

  openAnswerModal(content, item: CompanyTaskItem) {
    console.log('documentUpload', item.taskItem.documentUpload);
    this.currentItem = item;

    this.files = new Array();

    if (item.taskItem.documentUpload && (!item.documents || item.documents.length == 0)) {
      this.answerForm.controls.documents.setValidators([Validators.required]);
    }
    else {
      this.answerForm.controls.documents.setValidators([]);
    }

    this.answerForm.reset();

    if (this.currentItem.completedDateTime){
      this.answerForm.patchValue({compliant: this.currentItem.compliant});
    }
    else{
      this.answerForm.patchValue({compliant: ''});      
    }

    this.answerForm.patchValue({comments: this.currentItem.comment}); 

    this.answerForm.patchValue({
      controlType: this.currentItem.controlType,
      controlFrequency: this.currentItem.controlFrequency, 
      controlAutomation: this.currentItem.controlAutomation,
      controlCategory: this.currentItem.controlCategory,
      controlKeyControl: this.currentItem.controlKeyControl,
    }); 

    this.modalService.open(content).result.then((result) => {
      // let data: AssignCompanyTaskItemUser = {
      //   taskItemId: item.taskItem.id,
      //   userId: this.currentRoleOwner.user.id
      // };

      // this.spinner.startBackground();

      // this.companyApi.AssignCompanyTaskItemUser(data, this.apiCompanyId).subscribe(() => {
      //   this.cache.clear();
      //   this.getCompanyAudits();
      // });
    }, (reason) => {
    });
  }


  

  submitted = false;
  submitAnswer() {

    this.submitted = true;
    if (!this.answerForm.valid){
      return;
    }
    this.spinner.startBackground();


    let req: AnswerTaskItem = {
      compliant: this.answerForm.controls.compliant.value,
      comments: this.answerForm.controls.comments.value,
      id: this.currentItem.id,
      controlType: this.answerForm.controls.controlType.value,
      controlFrequency: this.answerForm.controls.controlFrequency.value,
      controlAutomation: this.answerForm.controls.controlAutomation.value,
      controlCategory: this.answerForm.controls.controlCategory.value,
      controlKeyControl: this.answerForm.controls.controlKeyControl.value,
    }

    if (this.currentItem.taskItem.documentUpload) {
      req.documents = this.files;
    }

    this.modalService.dismissAll();
    
    this.companyApi.SubmitCompanyTaskItem(req).subscribe(data => {

      

      this.cache.clear();

      this.statusApi.getCompanyStatus(this.apiCompanyId, this.selectedAct, null, this.selectedPeriodId).subscribe((data) => {
        this.status = data;
        this.spinner.stopBackground();

        this.getCompanyAudits(this.currentCompliant);
      });

      
      this.spinner.stopBackground();
    });

  }

  openMarkerModal(content, item: CompanyTaskItem) {
    this.currentItem = item;

    this.markerForm.reset();

    this.markerForm.patchValue({
      compliant: item.adjustedCompliant,
      efficiencyRateId: item.efficiencyRate?.id,
      comments: item.markersComment,
      correctiveActionPlan: item.correctiveActionPlan,
      correctiveActionPlanDueDate: item.correctiveActionPlanDueDate,
      markerMitigates: item.markerMitigates,
      markerDesign: item.markerDesign,
      markerTimely: item.markerTimely,
      markerEvidenced: item.markerEvidenced,
    });
    this.modalService.open(content).result.then((result) => {
    }, (reason) => {
    });
  }

  submitMark() {

    if (!this.markerForm.valid){
      return;
    }
    this.spinner.startBackground();


    let req: CompanyTaskItemMark = {
      id: this.currentItem.id,
      compliant: this.markerForm.controls.compliant.value,
      comments: this.markerForm.controls.comments.value,
      efficiencyRateId: +this.markerForm.controls.efficiencyRateId.value,
      correctiveActionPlan: this.markerForm.controls.correctiveActionPlan.value,
      correctiveActionPlanDueDate: this.markerForm.controls.correctiveActionPlanDueDate.value,
      markerMitigates: this.markerForm.controls.markerMitigates.value,
      markerDesign: this.markerForm.controls.markerDesign.value,
      markerTimely: this.markerForm.controls.markerTimely.value,
      markerEvidenced: this.markerForm.controls.markerEvidenced.value,

    }

    this.modalService.dismissAll();
  
    this.companyApi.MarkCompanyTaskItem(req).subscribe(data => {

      this.cache.clear();

      this.statusApi.getCompanyStatus(this.apiCompanyId, this.selectedAct, null, this.selectedPeriodId).subscribe((data) => {
        this.status = data;
        this.spinner.stopBackground();

        this.getCompanyAudits(this.currentCompliant);
      });

      
      this.spinner.stopBackground();
    });

  }

  error: string;
  files: AnswerTaskItemDocument[] = new Array();

  fileUpload(event) {
    this.error = null;
    if (event.target.files && event.target.files.length) {
      for (let file of event.target.files) {
        //const [file] = event.target.files;

        // if (file.size > 2147483648) //2MB
        // {
        //   this.answerForm.controls.documents.setValue('');
        //   this.answerForm.controls.documents.setErrors({ 'required': true });
        //   return;
        // }


        // if (file.type != 'application/vnd.ms-excel' && !file.name.toLowerCase().endsWith('.csv')) {
        //   this.answerForm.controls.documents.setValue('');
        //   this.answerForm.controls.documents.setErrors({ 'fileExt': true });

        //   return;
        // }

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          this.files.push({
            data: reader.result,
            filename: file.name
          });
          this.cd.markForCheck();
        };

      }
    }
  }

  removeFile(file) {
    let index = this.files.findIndex(f => f == file);
    if (index >= 0) {
      this.files.splice(index, 1);
      if (this.files.length == 0) {
        this.answerForm.controls.documents.setValue('');
      }
    }
  }

  getDocument(doc: CompanyTaskItemDocument) {
    this.spinner.startBackground();
    //window.open(this.libraryApi.GetDocumentUrl(id));
    this.companyApi.GetTaskItemDocument(doc.id).subscribe((data) => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }


  perPageChange(event) {
    localStorage["PER_PAGE"] = this.perPage;
    this.currentPage = 1;
    this.getData();
  }

  isOverDue(item: CompanyTaskItem){
    if (!item || !item.dueDate){
      return false;
    }

    if (item.compliant === true)
    {
      return false;
    }
    return new Date(item.dueDate).getTime() <= (new Date()).getTime();
  }

  openTextModal(content, text: string){
    this.textModalBody = text;

    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
    });
  }

  statusUpdated(){
    
    this.cache.clear();
    this.getStatus();
  }
}
