<ng-container *ngIf="orgId == 89">


  <div class="text-center alt-colors">




    <div class="d-flex">

      <div class="flex-grow-1">

        <table class=" table table-bordered w-100 impact" *ngIf="orgId == 89">
          <tr>
            <td>
              <div class="cell-content text-primary font-weight-bold">LIKELIHOOD ↓</div>
            </td>

            <td>
              <div class="cell-content green font-weight-bold">Low (0 - 50)</div>
            </td>
            <td>
              <div class="cell-content yellow font-weight-bold">Medium (51 - 100)</div>
            </td>
            <td>
              <div class="cell-content orange font-weight-bold">High (101 – 150)</div>
            </td>
            <td>
              <div class="cell-content red font-weight-bold">Critical (151 – 249)</div>
            </td>
            <td>
              <div class="cell-content dark-red font-weight-bold">Catastrophic (250+)</div>
            </td>

          </tr>
          <tr>
            <td>
              <div class="cell-content blue font-weight-bold">5<br />Almost Certain<br />81% - 100%</div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="5"
                [class.clickable]="!isReadOnly" data-impact="1" [class.selected]="selected(5, 1)">50<br />R22 809 914
              </div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="5"
                [class.clickable]="!isReadOnly" data-impact="2" [class.selected]="selected(5, 2)">100<br />R114 049 570
              </div>
            </td>
            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="5"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(5, 3)">150<br />R228 099 141
              </div>
            </td>
            <td>
              <div class="cell-content red" (click)="click($event)" data-rating="4" data-likelihood="5"
                [class.clickable]="!isReadOnly" data-impact="4" [class.selected]="selected(5, 4)">200<br />R342 148 711
              </div>
            </td>
            <td>
              <div class="cell-content dark-red" (click)="click($event)" data-rating="5" data-likelihood="5"
                [class.clickable]="!isReadOnly" data-impact="5" [class.selected]="selected(5, 5)">250<br />R450 000 000
              </div>
            </td>

          </tr>
          <tr>
            <td>
              <div class="cell-content blue font-weight-bold">4<br />Likely<br />61% - 80%</div>
            </td>

            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="4"
                [class.clickable]="!isReadOnly" data-impact="1" [class.selected]="selected(4, 1)">40<br />R18 704 129
              </div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="4"
                [class.clickable]="!isReadOnly" data-impact="2" [class.selected]="selected(4, 2)">80<br />R93 520 647
              </div>
            </td>
            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="4"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(4, 3)">120<br />R187 041 295
              </div>
            </td>
            <td>
              <div class="cell-content red" (click)="click($event)" data-rating="4" data-likelihood="4"
                [class.clickable]="!isReadOnly" data-impact="4" [class.selected]="selected(4, 4)">160<br />R280 561 943
              </div>
            </td>
            <td>
              <div class="cell-content red" (click)="click($event)" data-rating="4" data-likelihood="4"
                [class.clickable]="!isReadOnly" data-impact="5" [class.selected]="selected(4, 5)">200<br />R374 082 591
              </div>
            </td>

          </tr>
          <tr>
            <td>
              <div class="cell-content blue font-weight-bold">3<br />Possible<br />36% - 60%</div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="3"
                [class.clickable]="!isReadOnly" data-impact="1" [class.selected]="selected(3, 1)">30<br />R13 229 750
              </div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="3"
                [class.clickable]="!isReadOnly" data-impact="2" [class.selected]="selected(3, 2)">60<br />R66 148 750
              </div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="3"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(3, 3)">90<br />R132 297 501
              </div>
            </td>

            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="3"
                [class.clickable]="!isReadOnly" data-impact="4" [class.selected]="selected(3, 4)">120<br />R198 446 252
              </div>
            </td>
            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="3"
                [class.clickable]="!isReadOnly" data-impact="5" [class.selected]="selected(3, 5)">150<br />R264 595 003
              </div>
            </td>




          </tr>
          <tr>
            <td>
              <div class="cell-content blue font-weight-bold">2<br />Unlikely<br />11% - 35%</div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="2"
                [class.clickable]="!isReadOnly" data-impact="1" [class.selected]="selected(2, 1)">20<br />R7 755 370
              </div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="2"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(2, 2)">40<br />R38 776 854
              </div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="2"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(2, 3)">60<br />R77 553 708
              </div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="2"
                [class.clickable]="!isReadOnly" data-impact="4" [class.selected]="selected(2, 4)">80<br />R116 330 562
              </div>
            </td>

            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="2"
                [class.clickable]="!isReadOnly" data-impact="5" [class.selected]="selected(2, 5)">100<br />R155 107 416
              </div>
            </td>




          </tr>
          <tr>
            <td>
              <div class="cell-content blue font-weight-bold">1<br />Rare<br />0% - 10%</div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="1"
                [class.clickable]="!isReadOnly" data-impact="1" [class.selected]="selected(1, 1)">10<br />R2 280 991
              </div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="1"
                [class.clickable]="!isReadOnly" data-impact="2" [class.selected]="selected(1, 2)">20<br />R11 404 957
              </div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="1"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(1, 3)">30<br />R22 809 914
              </div>
            </td>

            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="1"
                [class.clickable]="!isReadOnly" data-impact="4" [class.selected]="selected(1, 4)">40<br />R34 214 871
              </div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="1"
                [class.clickable]="!isReadOnly" data-impact="5" [class.selected]="selected(1, 5)">50<br />R45 619 828
              </div>
            </td>




          </tr>

          <tr>
            <td>
              <div class="cell-content text-primary font-weight-bold">IMPACT →</div>
            </td>

            <td>
              <div class="cell-content blue font-weight-bold">1<br />Insignificant</div>
            </td>
            <td>
              <div class="cell-content blue font-weight-bold">2<br />Minor</div>
            </td>
            <td>
              <div class="cell-content blue font-weight-bold">3<br />Moderate</div>
            </td>
            <td>
              <div class="cell-content blue font-weight-bold">4<br />Major</div>
            </td>
            <td>
              <div class="cell-content blue font-weight-bold">5<br />Significant</div>
            </td>

          </tr>

        </table>

        <small>
        <table class="table table-bordered" style="font-size: 12px;">
          <tbody>
            <tr>
              <td>
                <p><strong>Financial</strong></p>
              </td>
              <td>
                <p>&lt; R0 &ndash; R45&nbsp;619 828</p>
                <p>(&lt;0,5% of Profit)</p>
              </td>
              <td>
                <p>&lt; R45&nbsp;619&nbsp;829 &ndash; R155&nbsp;107 416</p>
                <p>(&lt;2% of Profit)</p>
              </td>
              <td>
                <p>&lt; R155&nbsp;107&nbsp;417 &ndash; R264&nbsp;595 003</p>
                <p>(&lt;3% of Profit)</p>
              </td>
              <td>
                <p>&lt; R264&nbsp;595&nbsp;004 - R374&nbsp;082 591</p>
                <p>(&lt;4% of Profit)</p>
              </td>
              <td>
                <p>&gt; R374&nbsp;082&nbsp;592 (R450m material)</p>
                <p>(5+% of Profit)</p>
              </td>
            </tr>
            
            <tr>
              <td>
                <p><strong>Operations</strong></p>
              </td>
              <td>
                <p>No material impact on ongoing operations and on achievement of business strategy and objectives</p>
              </td>
              <td>
                <p>No material impact on ongoing operations and on achievement of business strategy and objectives</p>
              </td>
              <td>
                <p>Some material impact on ongoing operations and on achievement of business strategy and objectives</p>
              </td>
              <td>
                <p>Disruption to normal operations with a limited effect on achievement of business unit strategy and
                  objectives</p>
              </td>
              <td>
                <p>Loss of ability to sustain ongoing operations.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>Reputational Threat</strong></p>
              </td>
              <td>
                <p>Public concern restricted to local complaints</p>
              </td>
              <td>
                <p>Public concern restricted to local complaints</p>
              </td>
              <td>
                <p>Moderate, adverse, local public media attention / complaints</p>
              </td>
              <td>
                <p>Concerted attention from media and/or heightened community concern</p>
              </td>
              <td>
                <p>Extreme international public/media outcry. Damaging campaign. Social/legal license to operate
                  severely threatened</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>Regulatory and Compliance</strong></p>
              </td>
              <td>
                <p>No legal issues but breach of company guidelines</p>
              </td>
              <td>
                <p>Minor legal issues / non-compliance / breach of company guidelines</p>
              </td>
              <td>
                <p>Moderate legal issue / non-compliance / breach of regulation</p>
              </td>
              <td>
                <p>Serious breach of regulation with report to authority</p>
              </td>
              <td>
                <p>Significant fine /imprisonment. Breach of mandate</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>People</strong></p>
              </td>
              <td>
                <p>No injuries and near miss incidents.</p>
              </td>
              <td>
                <p>First-aid related injuries.</p>
              </td>
              <td>
                <p>Medical treatment related injuries.</p>
              </td>
              <td>
                <p>Reportable and fatal injuries.</p>
              </td>
              <td>
                <p>Multiple fatalities.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>Environment</strong></p>
              </td>
              <td>
                <p>No lasting effect / low-level impact on biological or physical environment. Limited damage to small,
                  low significance area.</p>
              </td>
              <td>
                <p>No lasting effect / low-level impact on biological or physical environment. Minor damage to small,
                  low significance area.</p>
              </td>
              <td>
                <p>Moderate effect on biological or physical environment. Moderate, short-medium term damage to minimal,
                  low significance area.</p>
              </td>
              <td>
                <p>Event that leads to environmental contamination (failure to manage appropriately but contained within
                  firm boundaries).</p>
              </td>
              <td>
                <p>Extreme environmental effect with impairment of ecosystem functions. Long-term, widespread effects on
                  significant area.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>Social and Community</strong></p>
              </td>
              <td>
                <p>Minimal social or cultural impact, insignificant repairable damage to commonplace structures.</p>
              </td>
              <td>
                <p>Low-level social or cultural impact, minor repairable damage to commonplace structures.</p>
              </td>
              <td>
                <p>Moderate medium-term social impact on local population. Moderate damage to heritage.</p>
              </td>
              <td>
                <p>On-going social issues. High damage to valued cultural heritage.</p>
              </td>
              <td>
                <p>Extreme, widespread social impact. Irreparable damage to highly valued cultural heritage.</p>
              </td>
            </tr>
          </tbody>
        </table>
        </small>


        <p><strong>CONTROL EFFECTIVENESS</strong></p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>RISK CONTROL / TREATMENT IDENTIFICATION</strong></p>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>
                <p><strong>100%</strong></p>
              </td>
              <td>
                <p><strong>Fully Effective</strong></p>
              </td>
              <td>
                <p>Controls evaluated and adequate, appropriate, and effective to provide reasonable assurance that the
                  risks are being managed and objectives being met.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>75%</strong></p>
              </td>
              <td>
                <p><strong>Substantially Effective</strong></p>
              </td>
              <td>
                <p>A few control weaknesses were identified; however, existing controls are generally adequate,
                  appropriate, and effective to provide reasonable assurance that risks are being managed and objectives
                  should be met</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>50%</strong></p>
              </td>
              <td>
                <p><strong>Partially Effective</strong></p>
              </td>
              <td>
                <p>Some control weaknesses were identified which, if not appropriately addressed, could in the future
                  result in the entity not achieving its objectives.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>25%</strong></p>
              </td>
              <td>
                <p><strong>Slightly Effective</strong></p>
              </td>
              <td>
                <p>Numerous specific controls weaknesses were noted. Controls evaluated unlikely to provide reasonable
                  assurance that risks are being managed and objectives should be met.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>0%</strong></p>
              </td>
              <td>
                <p><strong>No Control</strong></p>
              </td>
              <td>
                <p>Controls evaluated are inadequate, inappropriate, and ineffective, unable to provide reasonable
                  assurance that risks are being managed and objectives are being met.</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p><strong>&nbsp;</strong></p>
        <p><strong>RESIDUAL RISK RATING</strong></p>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>
                <p><strong>100%</strong></p>
              </td>
              <td>
                <p><strong>Fully Effective</strong></p>
              </td>
              <td>
                <p>The level of risk exposure is significantly reduced and / or minimal after controls were applied and
                  within the approved risk appetite.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>75%</strong></p>
              </td>
              <td>
                <p><strong>Substantially Effective</strong></p>
              </td>
              <td>
                <p>A reasonable and / or acceptable level if risk exposure remained after controls were applied, which
                  require ongoing management attention to ensure that residual risk remains constant.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>50%</strong></p>
              </td>
              <td>
                <p><strong>Partially Effective</strong></p>
              </td>
              <td>
                <p>Inherent risk exposure reduced after controls were applied, which requires ongoing management
                  attention to ensure that residual risk does not increase but decrease.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>25%</strong></p>
              </td>
              <td>
                <p><strong>Slightly Effective</strong></p>
              </td>
              <td>
                <p>Actual levels of risk exposure are minimally reduced after controls were applied, which requires
                  active management attention to ensure that residual risk does not increase but reduce.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>0%</strong></p>
              </td>
              <td>
                <p><strong>No Control</strong></p>
              </td>
              <td>
                <p>Actual level of risk exposure remains the same or higher than the risk appetite after controls are
                  applied and requires consistent management attention to reduce the current residual risk.</p>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>





</ng-container>
<ng-container *ngIf="orgId != 89">



  <div class="text-center">



    <strong>IMPACT</strong>
    <div class="d-flex">
      <div class="d-flex align-items-center px-2">
        <strong>
          L<br />I<br />K<br />E<br />L<br />I<br />H<br />O<br />O<br />D
        </strong>
      </div>
      <div class="flex-grow-1">
        <table class="w-100 impact">
          <tr>
            <td>
              <div class="cell-content">&nbsp;</div>
            </td>
            <td>
              <div class="cell-content blue">5<br />Catastrophic</div>
            </td>
            <td>
              <div class="cell-content blue">4<br />Major</div>
            </td>
            <td>
              <div class="cell-content blue">3<br />Moderate</div>
            </td>
            <td>
              <div class="cell-content blue">2<br />Minor</div>
            </td>
            <td>
              <div class="cell-content blue">1<br />Negligible</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="cell-content blue">5<br />Almost Certain</div>
            </td>
            <td>
              <div class="cell-content red" (click)="click($event)" data-rating="4" data-likelihood="5"
                [class.clickable]="!isReadOnly" data-impact="5" [class.selected]="selected(5, 5)">Extreme<br /> (10)
              </div>
            </td>
            <td>
              <div class="cell-content red" (click)="click($event)" data-rating="4" data-likelihood="5"
                [class.clickable]="!isReadOnly" data-impact="4" [class.selected]="selected(5, 4)">Extreme<br /> (9)
              </div>
            </td>
            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="5"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(5, 3)">Major<br /> (8)</div>
            </td>
            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="5"
                [class.clickable]="!isReadOnly" data-impact="2" [class.selected]="selected(5, 2)">Major<br /> (7)</div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="5"
                [class.clickable]="!isReadOnly" data-impact="1" [class.selected]="selected(5, 1)">Medium<br />(6)</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="cell-content blue">4<br />Likely</div>
            </td>
            <td>
              <div class="cell-content red" (click)="click($event)" data-rating="4" data-likelihood="4"
                [class.clickable]="!isReadOnly" data-impact="5" [class.selected]="selected(4, 5)">Extreme<br /> (9)
              </div>
            </td>
            <td>
              <div class="cell-content red" (click)="click($event)" data-rating="4" data-likelihood="4"
                [class.clickable]="!isReadOnly" data-impact="4" [class.selected]="selected(4, 4)">Extreme<br /> (8)
              </div>
            </td>
            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="4"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(4, 3)">Major<br /> (7)</div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="4"
                [class.clickable]="!isReadOnly" data-impact="2" [class.selected]="selected(4, 2)">Medium<br />(6)</div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="4"
                [class.clickable]="!isReadOnly" data-impact="1" [class.selected]="selected(4, 1)">Medium<br />(5)</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="cell-content blue">3<br />Possible</div>
            </td>
            <td>
              <div class="cell-content red" (click)="click($event)" data-rating="4" data-likelihood="3"
                [class.clickable]="!isReadOnly" data-impact="5" [class.selected]="selected(3, 5)">Extreme<br /> (8)
              </div>
            </td>
            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="3"
                [class.clickable]="!isReadOnly" data-impact="4" [class.selected]="selected(3, 4)">Major<br /> (7)</div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="3"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(3, 3)">Medium<br /> (6)</div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="3"
                [class.clickable]="!isReadOnly" data-impact="2" [class.selected]="selected(3, 2)">Medium<br />(5)</div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="3"
                [class.clickable]="!isReadOnly" data-impact="1" [class.selected]="selected(3, 1)">Minor<br />(4)</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="cell-content blue">2<br />Unlikely</div>
            </td>
            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="2"
                [class.clickable]="!isReadOnly" data-impact="5" [class.selected]="selected(2, 5)">Major<br /> (7)</div>
            </td>
            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="2"
                [class.clickable]="!isReadOnly" data-impact="4" [class.selected]="selected(2, 4)">Major<br /> (6)</div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="2"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(2, 3)">Medium<br />(5)</div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="2"
                [class.clickable]="!isReadOnly" data-impact="2" [class.selected]="selected(2, 2)">Minor<br />(4)</div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="2"
                [class.clickable]="!isReadOnly" data-impact="1" [class.selected]="selected(2, 1)">Minor<br />(3)</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="cell-content blue">1<br />Rare</div>
            </td>
            <td>
              <div class="cell-content orange" (click)="click($event)" data-rating="3" data-likelihood="1"
                [class.clickable]="!isReadOnly" data-impact="5" [class.selected]="selected(1, 5)">Major<br />(6)</div>
            </td>
            <td>
              <div class="cell-content yellow" (click)="click($event)" data-rating="2" data-likelihood="1"
                [class.clickable]="!isReadOnly" data-impact="4" [class.selected]="selected(1, 4)">Medium<br />(5)</div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="1"
                [class.clickable]="!isReadOnly" data-impact="3" [class.selected]="selected(1, 3)">Minor<br />(4)</div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="1"
                [class.clickable]="!isReadOnly" data-impact="2" [class.selected]="selected(1, 2)">Minor<br />(3)</div>
            </td>
            <td>
              <div class="cell-content green" (click)="click($event)" data-rating="1" data-likelihood="1"
                [class.clickable]="!isReadOnly" data-impact="1" [class.selected]="selected(1, 1)">Minor<br />(2)</div>
            </td>
          </tr>
        </table>





      </div>
    </div>
  </div>

  <div class="pt-4">

    <div class="d-flex">
      <div class="d-flex align-items-center px-2">
        <strong>
          K<br />E<br />Y
        </strong>
      </div>
      <div class="flex-grow-1">

        <table class="key">
          <tr>
            <td class="red">Extreme</td>
            <td class="red text-start">Extreme risks that are likely to arise and have potentially serious consequences
              requiring urgent attention</td>
          </tr>
          <tr>
            <td class="orange">Major</td>
            <td class="orange text-start">Major risks that are likely to arise and have potentially serious consequences
              requiring urgent attention or investigation</td>
          </tr>
          <tr>
            <td class="yellow">Medium</td>
            <td class="yellow text-start">Medium risks that are likely to arise or have potentially serious consequences
              requiring attention</td>
          </tr>
          <tr>
            <td class="green">Minor</td>
            <td class="green text-start">Minor risks and low consequences that may be managed by routine procedures</td>
          </tr>
        </table>



      </div>
    </div>
  </div>



</ng-container>