<div class="top-bar p-4">
  <div class="container">
    <ng-select [items]="acts$ | async" bindLabel="name" [multiple]="false" [hideSelected]="true" [loading]="actsLoading"
      [typeahead]="actsInput$" placeholder="No Act selected" (change)="actSelected($event)">
    </ng-select>
  </div>
</div>

<ng-container *ngIf="currentAct">
  <div class="container">
    <div *ngIf="actSections">

      <ng-container *ngIf="!add && !edit">
        <div class="pt-2 text-end">
          <button class="btn btn-primary btn-small" type="button" (click)="addActSection()">ADD NEW SECTION</button>
        </div>
        <div *ngIf="actSections.length == 0" class="p-2 text-center">
          No sections loaded yet.
        </div>
        <ng-container *ngFor="let section of actSections">
          <div class="item p-2 my-2 d-flex flex-row align-items-center">
            <div class="flex-grow-1 px-2">
              <strong>{{section.title}}</strong>
            </div>
            <div class="px-2">
              <a (click)="editActSection(section)" title="Edit">
                <span class="icon icon-small icon-edit-red"></span>
              </a><br />
              <a (click)="archiveActSection(section)" title="Archive">
                <span class="icon icon-small icon-hide-red"></span>
              </a>
            </div>

          </div>
          <div class="p-2">
            <div class="pt-2 text-end">
              <button class="btn btn-primary btn-small" type="button" (click)="addChecklist(section)">ADD NEW
                CHECKLIST ITEM</button>
            </div>
            <div *ngIf="section.checklistItems.length == 0" class="p-2 text-center">
              No checklist items for this section yet.
            </div>
            <div class="item p-2 my-2 d-flex flex-row align-items-center" *ngFor="let item of section.checklistItems">
              <div class="flex-grow-1 px-2">
                <span>{{item.complianceQuery}}</span>
              </div>
              <div class="text-center px-2">
                <strong>Weight</strong><br />
                <span>{{item.weight}}</span>
              </div>
              <div class="text-center px-2">
                <strong>Document Upload</strong><br />
                <span>{{item.documentUpload ? 'Yes' : 'No'}}</span>
              </div>
              <div class="px-2">
                <a (click)="editChecklist(section, item)" title="Edit">
                  <span class="icon icon-small icon-edit-red"></span>
                </a><br />
                <a (click)="archiveChecklist(section, item)" title="Archive">
                  <span class="icon icon-small icon-hide-red"></span>
                </a>
              </div>
            </div>
          </div>
          <hr />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="section && (add || edit)">
        <form [formGroup]="sectionForm" (ngSubmit)="submitSection()">

          <div class="form-group row">
            <label for="title" class="col-12 col-form-label text-startcol-1">Title</label>
            <div class="col-12">
              <input type="text" class="form-control" id="title" formControlName="title" [class.is-invalid]="sectionSubmitted && sectionForm.controls.title.invalid">
              <div class="invalid-feedback text-end" *ngIf="sectionForm.controls.title.errors">
                <div *ngIf="sectionForm.controls.title.errors.required">Required</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="summary" class="col-12 col-form-label text-startcol-1">Summary</label>
            <div class="col-12">
              <input type="text" class="form-control" id="summary" formControlName="summary" [class.is-invalid]="sectionSubmitted && sectionForm.controls.summary.invalid">
              <div class="invalid-feedback text-end" *ngIf="sectionForm.controls.summary.errors">
                <div *ngIf="sectionForm.controls.summary.errors.required">Required</div>
              </div>
            </div>
          </div>
          <div class="text-end">
            <button type="button" class="btn btn-default me-2" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-default">Save</button>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="checklist && (add || edit)">
        <form [formGroup]="checklistForm" (ngSubmit)="submitChecklist()">

          <div class="form-group row">
            <label for="complianceQuery" class="col-12 col-form-label text-startcol-1">Compliance Query</label>
            <div class="col-12">
              <input type="text" class="form-control" id="complianceQuery" formControlName="complianceQuery" [class.is-invalid]="checklistSubmitted && checklistForm.controls.complianceQuery.invalid">
              <div class="invalid-feedback text-end" *ngIf="checklistForm.controls.complianceQuery.errors">
                <div *ngIf="checklistForm.controls.complianceQuery.errors.required">Required</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="description" class="col-12 col-form-label text-startcol-1">Description</label>
            <div class="col-12">
              <input type="text" class="form-control" id="description" formControlName="description">
            </div>
          </div>
          <div class="form-group row">
            <label for="weight" class="col-12 col-form-label text-startcol-1">Weight</label>
            <div class="col-12">
              <ng-select [items]="[1,2,3]" formControlName="weight" [clearable]="false" [dropdownPosition]="'top'" [class.is-invalid]="checklistSubmitted && checklistForm.controls.weight.invalid">
              </ng-select>
              
              <div class="invalid-feedback text-end" *ngIf="checklistForm.controls.weight.errors">
                <div *ngIf="checklistForm.controls.weight.errors.required">Required</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="form-check d-flex align-items-center ms-3">
              <input class="form-check-input" type="checkbox" id="documentUpload" formControlName="documentUpload">
              <label class="form-check-label" for="documentUpload">
                Document Upload?
              </label>
            </div>
          </div>
          <div class="text-end">
            <button type="button" class="btn btn-default me-2" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-default">Save</button>
          </div>
        </form>
      </ng-container>

    </div>
  </div>
</ng-container>