import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { DocumentApiService } from '../../services/document-api.service';
import { LibraryApiService } from '../../services/library-api.service';
import { ListingItemFull } from 'src/app/models/listingItemFull';


@Component({
  selector: 'app-library-list-item',
  templateUrl: './library-list-item.component.html',
  styleUrls: ['./library-list-item.component.scss']
})
export class LibraryListItemComponent implements OnInit {

  public _listing: ListingItemFull;
  public _edit: boolean = false;
  public _select: boolean = false;

  constructor(private spinner: SpinnerService, private libraryApi: LibraryApiService) { }

  @Input()
  set item(listing: ListingItemFull) {
    this._listing = listing;
  }

  @Input()
  set edit(edit: boolean) {
    this._edit = edit;
  }

  @Input()
  set select(select: boolean) {
    this._select = select;
  }

  @Output()
  onEdit = new EventEmitter();

  @Output()
  onSelect = new EventEmitter();

  ngOnInit() {
  }

  editClick() {
    this.onEdit.emit();
  }

  selectClick() {
    if (this._select) {
      this.onSelect.emit();
    }
  }



}
