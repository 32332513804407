<div class="business-unit-list-item d-flex flex-row py-1">
    <div class="d-flex  justify-content-around align-items-center px-2">
      <div>
        <a [routerLink]="['/dashboard/configuration/business-unit/edit', _company.id]" title="Edit">
          <span class="icon icon-small icon-edit-red"></span>
        </a><br />
        <a (click)="archive(_company)" title="Archive">
          <span class="icon icon-small icon-hide-red"></span>
        </a>
      </div>
    </div>
  
    <div class="business-unit-logo d-flex align-items-center justify-content-center">
      <img [src]="_company.logoUrl" [alt]="_company.name" [title]="_company.name" />
    </div>
  
    <div class="d-flex align-items-center px-2">
  
      <h3 class="mb-0">{{_company.name}}</h3>
  
    </div>
  
    
  
    
  
    
    <div class="d-flex align-items-center px-2">
  
      <!-- <div class="icon-link d-flex align-items-center">
        <a [routerLink]="['/dashboard/umbrella-report/', _company.id]" class="icon-link d-flex align-items-center">
          <span class="icon icon-large icon-umbrella-red me-2"></span><span>Review&nbsp;</span><span class="text-danger">Business Unit
            Umbrella Report</span>
        </a>
  
        
  
      </div> -->
    </div>

    <div class="d-flex align-items-center px-2">
     
      <a [routerLink]="['/dashboard/configuration/org-structure', _company.id]" *ngIf="!_complete"
        class="text-danger icon-link d-flex align-items-center">
        <span class="icon icon-large icon-org-structure-red me-2"></span><span class="text-danger">Personalise Org
          Structure</span>
      </a>
      <a [routerLink]="['/dashboard/configuration/org-structure', _company.id]" *ngIf="_complete"
        class="text-success icon-link d-flex align-items-center">
        <span class="icon icon-large icon-org-structure-green me-2"></span><span
          class="text-success">Personalise Org
          Structure</span>
      </a>
  
    </div>
  
    <div class="d-flex align-items-center px-2" *ngIf="_complete">
  
      <div class="icon-link d-flex align-items-center">
       
  
        <a [routerLink]="['/dashboard/configuration/acts', _company.id]" class="icon-link d-flex align-items-center ">
          <span class="icon icon-large icon-acts-red me-2"></span><span>Review&nbsp;</span><span class="text-danger">Business Unit
            Acts</span>
        </a>
  
      </div>
    </div>
  
  
  
  </div>
  