import { Component, OnInit } from '@angular/core';
import { CompanyApiService } from '../../../../services/company-api.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { CompanyRoleOwner } from 'src/app/models/companyRoleOwner';

@Component({
  selector: 'app-org-structure',
  templateUrl: './org-structure.component.html',
  styleUrls: ['./org-structure.component.scss']
})
export class OrgStructureComponent implements OnInit {

  private companyId: number;
  public roleOwners: Array<CompanyRoleOwner>;


  mainForm: UntypedFormGroup;
  items: UntypedFormArray;

  constructor(
    private companyApi: CompanyApiService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private cache: RequestCacheService,
    private formBuilder: UntypedFormBuilder,
    private alert: AlertService

  ) { }

  // createItem(): FormGroup{
  //   return this.formBuilder.group({
  //     department: '',
  //     customTitle: '',
  //     firstname: '',
  //     surname: '',
  //     email: ''
  //   });
  // }

  createItem(item: CompanyRoleOwner): UntypedFormGroup {
    return this.formBuilder.group({
      department: new UntypedFormControl({ value: item.department.name, disabled: true }),
      departmentId: item.department.id,
      customTitle: item.customTitle,
      firstname: [item.user.firstname, Validators.required],
      lastname: [item.user.lastname, Validators.required],
      email: [item.user.email, [Validators.required, Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]]
      // firstname: ['', Validators.required],
      // lastname: ['', Validators.required],
      // email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]]
    });
  }

  ngOnInit() {

    this.mainForm = this.formBuilder.group({
      items: this.formBuilder.array([])
    });

    this.items = this.mainForm.get('items') as UntypedFormArray;
    //this.items.push(this.createItem());

    this.spinner.startBackground();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.companyId = +params.get('id');

      this.companyApi.GetCompanyRoleOwners(this.companyId).subscribe((r) => {
        this.roleOwners = r;

        //let items = this.mainForm.get('items') as FormArray;

        this.roleOwners.forEach(x => this.items.push(this.createItem(x)));

        this.spinner.stopBackground();
      });

    });
  }

  defaultName: string;
  defaultSurname: string;
  defaultEmail: string;

  addDefaultOwner() {

    let items = this.mainForm.get('items') as UntypedFormArray;

    items.controls.forEach(element => {
      element.patchValue(
        {
          firstname: this.defaultName,
          lastname: this.defaultSurname,
          email: this.defaultEmail
        }
      );
    });
  }
  submit() {

    this.spinner.start();

    let data: Array<CompanyRoleOwner> = [];

    this.items.controls.forEach(ro => {
      data.push({
        companyId: this.companyId,
        customTitle: ro.get('customTitle').value,
        department: { id: ro.get('departmentId').value },
        user: {
          firstname: ro.get('firstname').value,
          lastname: ro.get('lastname').value,
          email: ro.get('email').value,
          active: true,
          companyId: this.companyId
        }
      });
    });

    this.companyApi.SubmitCompanyRoleOwners(this.companyId, data).subscribe((r) => {

      if (!r || r.length == 0)
      {
        this.alert.success('Role owners succesfully allocated.', true);
      }
      else{
        let emails = '';

        r.forEach(e => emails = emails + ',' + e);

        emails = emails.substr(1);

        this.alert.success('Role owners partially allocated. The following email addresses are already allocated to different companies: ' + emails, true);
      }
      
      this.cache.clear();

      
      // this.companyApi.GetCompanyRoleOwners(this.companyId).subscribe((r) => {
      //   this.roleOwners = r;

      //   this.items.clear()

      //   this.roleOwners.forEach(x => this.items.push(this.createItem(x)));

      //   this.spinner.stop();
      // });

      let orgId = +localStorage.getItem('CURRENT_ORG_ID');

      if (orgId && orgId > 0) {
        this.router.navigateByUrl(`/dashboard/configuration/company/list/${orgId}`);
      }
      else {
        this.router.navigateByUrl(`/dashboard/configuration/organisation/list`);
      }

    });

  }
  back() {
    window.history.back();
  }

}
