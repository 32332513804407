<div class="mb-1" *ngIf="superAdmin">
  <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.status">
  <div class="row">
    <div class="col-6 px-4">
      <app-puzzle-wheel [showPercentage]="false" [section]="'contracts'">
      </app-puzzle-wheel>
    </div>
    <div class="col-6">
      <div class="detail-block p-4 m-4">
        <div class="logo text-center">
          <img [src]="this.status.logoUrl" class="img-fluid company-logo" />
        </div>
        <hr />
        <div class="detail-block-items px-4 mx-4">
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>GENERIC TEMPLATES</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.contracts.genericTemplates}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.contracts.genericTemplates == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey" (click)="showTemplates(1)"></span></button>
              <button *ngIf="this.status.contracts.genericTemplates > 0" class="btn btn-link icon-link"
                (click)="showTemplates(1)"><span class="icon icon-view-red"></span></button>
            </div>
          </div>

          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>BRANDED TEMPLATES</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.contracts.brandedTemplates}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.contracts.brandedTemplates == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey" (click)="showTemplates(2)"></span></button>
              <button *ngIf="this.status.contracts.brandedTemplates > 0" class="btn btn-link icon-link"
                (click)="showTemplates(2)"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>CREATE NEW DOCUMENT CASE</strong></div>
            <div class="text-end flex-grow-1"></div>
            <div class="text-center px-2">
              <button class="btn btn-link icon-link" (click)="request()"><span
                  class="icon icon-plus-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>DOCUMENTS REQUESTED</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.contracts.contractsRequests}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.contracts.contractsRequests == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="this.status.contracts.contractsRequests > 0" class="btn btn-link icon-link"
                (click)="showContracts(1)"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>DOCUMENTS IN PROGRESS</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.contracts.contractsInProgress}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.contracts.contractsInProgress == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="this.status.contracts.contractsInProgress > 0" class="btn btn-link icon-link"
                (click)="showContracts(2)"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>DOCUMENT REGISTER</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.contracts.contractsConcluded}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.contracts.contractsConcluded == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="this.status.contracts.contractsConcluded > 0" class="btn btn-link icon-link"
                (click)="showRegistry()"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <!-- <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>NEW MESSAGES</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.contracts.newMessages}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.contracts.newMessages == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="this.status.contracts.newMessages > 0" class="btn btn-link icon-link"
                (click)="getCompanyAudits(1)"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>REQUESTED EDITS</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.contracts.requestedEdits}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.contracts.requestedEdits == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="this.status.contracts.requestedEdits > 0" class="btn btn-link icon-link"
                (click)="getCompanyAudits(1)"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>UPLOADED DOCUMENTS</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.contracts.uploadedContracts}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.contracts.uploadedContracts == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="this.status.contracts.uploadedContracts > 0" class="btn btn-link icon-link"
                (click)="getCompanyAudits(1)"><span class="icon icon-view-red"></span></button>
            </div>
          </div> -->


        </div>
      </div>
    </div>
  </div>
</div>