import { Component, OnInit } from '@angular/core';
import { NotificationsApiService } from '../../services/notifications-api.service';
import { RequestCacheService } from '../../services/request-cache.service';
import { Globals } from '../../globals';
import { NotificationMessage } from 'src/app/models/notificationMessage';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: Array<NotificationMessage>;

  count: number = 0;

  constructor(
    private notificationsApi: NotificationsApiService,
    private cache: RequestCacheService,
    public globals: Globals) { }

  ngOnInit() {

    if (this.globals.loggedIn) {
      this.getData();
    }
  }

  getData() {
    this.notificationsApi.List().subscribe(data => {
      this.notifications = data;
      this.count = this.notifications.filter(f => !f.readDateTime).length;
    });
  }
  dropdownToggled(ev) {
    if (ev) {
      let ids = this.notifications.filter(f => !f.readDateTime).map(m => m.id);
      this.notificationsApi.MarkMultipleAsRead(ids).subscribe(() => {
        this.cache.clear();
        this.getData();
      });
    }
  }

  archive(ev, item: NotificationMessage) {
    ev.stopPropagation();
    this.notificationsApi.Delete(item.id).subscribe(() => {
      this.cache.clear();
      this.getData();
    });
    var index = this.notifications.indexOf(item);
    this.notifications.splice(index, 1);
  }
}
