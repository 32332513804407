import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { Observable } from 'rxjs';
import { Contract } from '../models/contract';
import { ContractMessage } from '../models/contractMessage';
import { ContractReminder } from '../models/contractReminder';
import { ContractTemplateBase, ContractTemplate } from '../models/contractTemplate';
import { ContractType } from '../models/contractType';
import { DocumentRegistryItem } from '../models/documentRegistryItem';
import { UpdateContractNotes } from '../models/updateContractNotes';

@Injectable({
  providedIn: 'root'
})
export class ContractsApiService extends ApiService<Contract> {

  _controller: string = 'contracts';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('contracts', http, env);
  }

  AddContractRequest(request: Contract): Observable<Contract> {
    return this.post<Contract, Contract>(`api/${this._controller}/request/add`, request);
  }

  OpenContractRequest(request: Contract): Observable<Contract> {
    return this.post<Contract, Contract>(`api/${this._controller}/request/open`, request);
  }

  ListContractTemplates(companyId: number): Observable<ContractTemplateBase[]> {
    return this.get<ContractTemplateBase[]>(`api/${this._controller}/templates/list/${companyId}`);
  }
  ListContractTemplatesFull(companyId: number): Observable<ContractTemplate[]> {

    if (companyId)
    {
      return this.get<ContractTemplate[]>(`api/${this._controller}/templates/${companyId}`);
    }

    return this.get<ContractTemplate[]>(`api/${this._controller}/templates/`);
    
  }

  AddContractTemplate(data: ContractTemplate, companyId?: number): Observable<ContractTemplate> {
    return this.post<ContractTemplate, ContractTemplate>(`api/${this._controller}/templates/`, data)
  }

  UpdateContractTemplate(id: number, data: ContractTemplate): Observable<ContractTemplate> {
    return this.put<ContractTemplate, ContractTemplate>(`api/${this._controller}/templates/${id}`, data)
  }

  GetContract(id: number): Observable<Contract>{
    return this.get<Contract>(`api/${this._controller}/${id}`);
  }

  ListContracts(companyId?: number, statusId?: number, search?: string, typeId?: number, order?: string): Observable<Array<Contract>> {

    let url = `api/${this._controller}/List/?`;

    if (companyId)
    {
      url = `api/${this._controller}/List/${companyId}?`;
    }

    if (statusId) {
      url = `${url}statusId=${statusId}&`;
    }

    if (search) {
      url = `${url}search=${search}&`;
    }

    if (typeId) {
      url = `${url}typeId=${typeId}&`;
    }

    if (order) {
      url = `${url}order=${order}&`;
    }

    return this.get<Contract[]>(url);
  }

  GetContractMessages(contractId: number){
    return this.get<ContractMessage[]>(`api/${this._controller}/messages/${contractId}`);
  }

  CloseContract(request: Contract): Observable<{}> {
    return this.post<Contract, {}>(`api/${this._controller}/close`, request);
  }

  GetContractReminders(contractId: number): Observable<ContractReminder[]>{
    return this.get<ContractReminder[]>(`api/${this._controller}/reminders/${contractId}`);
  }

  GetDocumentRegistry(companyId?: number): Observable<Array<DocumentRegistryItem>> {
    return this.get<DocumentRegistryItem[]>(`api/${this._controller}/List/${companyId}`);
  }

  AddContractReminder(request: ContractReminder): Observable<{}> {
    return this.post<ContractReminder, {}>(`api/${this._controller}/addreminder`, request);
  }
  UpdateContractReminder(request: ContractReminder): Observable<{}> {
    return this.post<ContractReminder, {}>(`api/${this._controller}/updatereminder`, request);
  }
  DeleteContractReminder(request: ContractReminder): Observable<{}> {
    return this.post<ContractReminder, {}>(`api/${this._controller}/deletereminder`, request);
  }
  UpdateContractNotes(request: UpdateContractNotes): Observable<{}> {
    return this.post<UpdateContractNotes, {}>(`api/${this._controller}/updatenotes`, request);
  } 

  GetContractTypes(): Observable<ContractType[]>{
    return this.get<ContractType[]>(`api/${this._controller}/types`);
  }
}
