import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { TokenService } from '../../../services/token.service';
import { StatusApiService } from '../../../services/status-api.service';
import { CompanyApiService } from '../../../services/company-api.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import $ from 'jquery';
//import { ErrorMessage } from 'ng-bootstrap-form-validation';
import { FileValidator } from '../../../validators/file-input-validator';
import { fileExtCheck } from '../../../validators/file-extention-validator';
import { Router } from '@angular/router';
import { AlertService } from '../../../services/alert.service';
import { AssignLearningModuleUser } from 'src/app/models/assignLearningModuleUsers';
import { CompanyDetailStatus } from 'src/app/models/companyDetailStatus';
import { CompanyLearningModule } from 'src/app/models/companyLearningModule';
import { LearningModuleResults } from 'src/app/models/learningModuleResults';

@Component({
  selector: 'app-elearning',
  templateUrl: './elearning.component.html',
  styleUrls: ['./elearning.component.scss']
})
export class ELearningComponent implements OnInit {

  customErrorMessages: any[] = [
    {
      error: 'required',
      format: (label, error) => `Please select a CSV file to upload.`
    }, {
      error: 'fileExt',
      format: (label, error) => `File must be in CSV format`
    }
  ];

  submitted = false;
  mainForm = this.formBuilder.group({
    list: ['', FileValidator.validate],
    listData: ['', Validators.required]
  });

  listData: string;
  companyId: number;
  apiCompanyId?: number;

  learningModulesAmount: 0;
  status: CompanyDetailStatus;
  showModules: boolean = false;
  showResults: boolean = false;
  error: string;

  companyLearningModules: CompanyLearningModule[];
  selectedCompanyLearningModule: CompanyLearningModule;
  learningModuleResults: LearningModuleResults;


  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;

  currentPage = 1;
  perPageItems = [5, 10, 20, 50, 100];
  perPage = 10;

  isUser: boolean;

  isSuperAdmin = false;
  isOrgAdmin = false;
  isCompanyAdmin = false;

  constructor(
    private spinner: SpinnerService,
    public token: TokenService,
    private statusApi: StatusApiService,
    private companyApi: CompanyApiService,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    private alert: AlertService) { }

  ngOnInit() {

    if (localStorage["PER_PAGE"]) {
      this.perPage = localStorage["PER_PAGE"];
    }

    this.isUser = this.token.isUser();
    this.isSuperAdmin = this.token.isSuperAdmin();
    this.isOrgAdmin = this.token.isOrganisationAdmin();
    this.isCompanyAdmin = this.token.isCompanyAdmin();

    if (this.isCompanyAdmin || this.isUser) {
      this.companyId = this.token.companyId();
      this.apiCompanyId = null;
      if (this.companyId) {
        this.getStatus();
      }
    }
    else if (this.isSuperAdmin || this.isOrgAdmin) {
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }
  }

  getStatus() {

    this.spinner.startBackground();
    this.statusApi.getCompanyStatus(this.apiCompanyId).subscribe((data) => {
      this.status = data;
      this.spinner.stopBackground();
    });

  }
  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = company.id;
      this.getStatus();
    }
    else {
      this.status = null;
    }
  }
  truncate(str?: string, len: number = 300) {
    if (!str) {
      return str;
    }
    if (str && str.length > len)
      return str.substring(0, len) + '...';
    else
      return str;
  };

  modules() {
    window.location.hash = 'details';
    this.showModules = true;
    this.showResults = false;
    this.selectedCompanyLearningModule = null;
    this.spinner.startBackground();

    this.companyApi.GetCompanyAssignedLearningModules(this.apiCompanyId).subscribe(data => {
      this.companyLearningModules = data;
      this.spinner.stopBackground();
    });
  }

  results() {
    window.location.hash = 'details';
    this.showModules = false;
    this.showResults = true;
    this.selectedCompanyLearningModule = null;
    this.spinner.startBackground();

    this.companyApi.GetCompanyAssignedLearningModules(this.apiCompanyId).subscribe(data => {
      this.companyLearningModules = data;
      this.spinner.stopBackground();
    });
  }

  fileUpload(event) {
    this.error = null;

    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) //2MB
      {
        this.mainForm.controls.list.setValue('');
        this.mainForm.controls.list.setErrors({ 'required': true });
        return;
      }


      if (file.type != 'application/vnd.ms-excel' && !file.name.toLowerCase().endsWith('.csv')) {
        this.mainForm.controls.list.setValue('');
        this.mainForm.controls.list.setErrors({ 'fileExt': true });

        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.mainForm.patchValue({
          listData: reader.result
        });

        this.listData = this.mainForm.controls.listData.value;

        $('.custom-file-label').addClass("selected").html(file.name);

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  assign(i: CompanyLearningModule) {
    this.selectedCompanyLearningModule = i;
  }

  viewResults(i: CompanyLearningModule) {

    this.selectedCompanyLearningModule = i;

    this.getResultsData();
  }

  getResultsData() {

    this.spinner.startBackground();

    this.companyApi.GetCompanyLearningModuleResults(this.selectedCompanyLearningModule.learningModule.id, this.apiCompanyId, this.currentPage, this.perPage).subscribe(data => {

      this.learningModuleResults = data;

      this.firstEnabled = this.learningModuleResults.currentPage > 1;
      this.prevEnabled = this.learningModuleResults.currentPage > 1;

      this.nextEnabled = this.learningModuleResults.currentPage < data.totalPages;
      this.lastEnabled = this.learningModuleResults.currentPage < data.totalPages;
      this.spinner.stopBackground();
    });

  }

  first() {
    this.currentPage = 1;
    this.getResultsData();
  }

  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.getResultsData();
  }

  next() {
    if (this.currentPage < this.learningModuleResults.totalPages) {
      this.currentPage++;
    }
    this.getResultsData();
  }

  last() {
    this.currentPage = this.learningModuleResults.totalPages;
    this.getResultsData();
  }

  perPageChange(event) {
    localStorage["PER_PAGE"] = this.perPage;
    this.currentPage = 1;
    this.getResultsData();
  }

  submit() {

    this.submitted = true;

    if (this.mainForm.invalid){
      return;
    }
    
    this.spinner.startBackground();

    let data: AssignLearningModuleUser =
    {
      learningModuleId: this.selectedCompanyLearningModule.learningModule.id,
      listData: this.listData
    }

    this.companyApi.AssignUsersToLearningModule(data, this.apiCompanyId).subscribe(() => {
      this.selectedCompanyLearningModule = null;
      this.spinner.stopBackground();
      this.alert.success('Users successfully assigned');
    });
  }

}
