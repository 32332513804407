import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public roles: string[] = [];
  private cId: number;
  private uId: number;
  private oId: number;
  constructor() {
    this.reload();
  }

  reload() {
    
    let token = localStorage.getItem('CURRENT_TOKEN');

    this.roles = [];
    this.cId = null;
    this.oId = null;

    if (!token) {
      return;
    }

    let tokenParts = token.split('.');

    let decodedToken = JSON.parse(window.atob(tokenParts[1]));
    for (var key in decodedToken) {
      if (decodedToken.hasOwnProperty(key)) {
        if (key == 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role') {
          var data = decodedToken[key];
          if (data instanceof Array)
          {
            data.forEach(d => this.roles.push(d));
          }
          else{
            this.roles.push(data);
          }
          
        }
        if (key == 'CompanyId') {
          this.cId = decodedToken[key];
        }
        if (key == 'OrganisationId') {
          this.oId = decodedToken[key];
        }
        if (key == 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier') {
          this.uId = decodedToken[key];
        }
      }
    }
  }
  hasRole(role): boolean {
    if (!this.roles) {
      return false;
    }

    var r = this.roles.find(f => f == role);

    if (r) {
     return true;
    }
    return false;

    // if (this.roles instanceof Array) {
    //   r = this.roles[0];
    // }
    // else {
    //   r = this.roles;
    // }
    // if (!r) {
    //   return false;
    // }
    // return r == role;
  }

  isCompanyAdmin(): boolean {
    return this.hasRole('Company Administrator');
  }

  isOrganisationAdmin(): boolean {
    return this.hasRole('Organisation Administrator');
  }

  isContractAdmin(): boolean {
    return this.hasRole('Contract Administrator');
  }

  isSuperAdmin(): boolean {
    return this.hasRole('Super Administrator');
  }

  isLibraryUser(): boolean {
    return this.hasRole('Library User');
  }

  isUser(): boolean {
    return this.hasRole('User');
  }

  companyId() {
    return this.cId;
  }

  organisationId() {
    return this.oId;
  }

  userId() {
    return this.uId;
  }

  loggedIn(): boolean {
    return localStorage.getItem('CURRENT_TOKEN') != null;
  }

}
