<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-sm-10 col-md-8 col-lg-6  offset-sm-1 offset-md-2 offset-lg-3 ">
      <form [formGroup]="mainForm" (ngSubmit)="submit()">
        <h3 class="text-center mb-4"><span class="icon icon-plus-red"></span> Begin by adding your <span
            class="text-danger">Organisation</span></h3>
        <div class="form-group row">
          <label for="name" class="col-sm-4 col-form-label text-start text-sm-right">Organisation Name</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" id="name" formControlName="name"
              [class.is-invalid]="submitted && mainForm.controls.name.invalid">
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.name.errors">
              <div *ngIf="mainForm.controls.name.errors.required">Required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="logo" class="col-sm-4 col-form-label text-start text-sm-right">Organisation Logo</label>
          <div class="col-sm-8">

            <input type="file" class="form-control" formControlName="logo" (change)="fileUpload($event)"
              [class.is-invalid]="submitted && mainForm.controls.logoData.invalid">
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.logoData.errors">
              <div *ngIf="mainForm.controls.logoData.errors.required">Required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="companies" class="col-sm-4 col-form-label text-start text-sm-right">Number of Companies</label>
          <div class="col-sm-8">
            <input type="number" min="1" class="form-control" id="companies" formControlName="companies" [class.is-invalid]="submitted && mainForm.controls.companies.invalid">
            <div class="invalid-feedback text-end" *ngIf="mainForm.controls.companies.errors">
              <div *ngIf="mainForm.controls.companies.errors.required">Required</div>
              <div *ngIf="mainForm.controls.companies.errors.min">Invalid</div>
            </div>
          </div>
        </div>
        <div class="text-end"><button type="submit" (click)="setWizard(false)"
            class="btn btn-default">Save</button>&nbsp;<button type="submit" (click)="setWizard(true)"
            class="btn btn-default">Next</button></div>
      </form>
      <hr />
    </div>
  </div>
</div>