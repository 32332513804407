import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SpinnerService } from '../../services/spinner.service';
import { ContactApiService } from '../../services/contact-api.service';
import { AlertService } from '../../services/alert.service';
import { Meta } from '@angular/platform-browser';
import { ContactRequest } from 'src/app/models/contactRequest';

@Component({
  selector: 'app-home-contact',
  templateUrl: './home-contact.component.html',
  styleUrls: ['./home-contact.component.scss']
})
export class HomeContactComponent implements OnInit {

  contactForm = this.formBuilder.group({
    firstname: ['', Validators.required],
    lastname: [''],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: [''],
    message: ['', Validators.required],
  });

  constructor(
    private token: TokenService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private spinner: SpinnerService,
    private contactApi: ContactApiService,
    private alert: AlertService,
    private meta: Meta) { }

  ngOnInit() {

    if (this.token.loggedIn()) {
      this.router.navigateByUrl('/dashboard/contact');
    }

    this.meta.addTags([
      {name: 'description', content: 'Building an effective Compliance Programme can be a daunting task. Compliance needs to be a non-negotiable and pervasive practice that is implemented throughout the organisation and performed by all. Cost of The Legal Team is dependent on the organisation’s requirements. Contact us to get started!'},   
      {name: 'keywords', content: 'Contact Us,Compliance Team,Regulatory and Compliance Needs' }
    ]);
  }

  submitted = false;
  
  submit() {

    this.submitted = true;

    if (this.contactForm.invalid){
      return;
    }

    this.spinner.startBackground();

    let data: ContactRequest = {
      email: this.contactForm.controls.email.value,
      firstname: this.contactForm.controls.firstname.value,
      lastname: this.contactForm.controls.lastname.value,
      message: this.contactForm.controls.message.value,
      phoneNumber: this.contactForm.controls.phoneNumber.value
    };
    this.contactApi.submit(data).subscribe(() => {

      this.contactForm.reset();

      this.spinner.stopBackground();
      this.alert.success("Thank you, your request has been submitted");
    });
  }

}
