<div class="mb-1" *ngIf="superAdmin || orgAdmin">
  <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.status">
  <div class="row">
    <div class="col-6 px-4">
      <app-puzzle-wheel [percentage]="this.status.selfAssessment.percentage" [section]="'self_assessment_checklist'">
      </app-puzzle-wheel>
    </div>
    <div class="col-6">
      <div class="detail-block p-4 m-4">
        <div class="logo text-center">
          <img [src]="this.status.logoUrl" class="img-fluid company-logo" />
        </div>
        <hr />
        <div class="text-center d-flex align-items-center justify-content-center">
          <span class="me-3">Period:</span>
          <ng-select [items]="periods" bindLabel="name" [multiple]="false" [loading]="periodsLoading"
            (change)="periodSelected($event)" [(ngModel)]="selectedPeriod" [clearable]="false">
          </ng-select>
        </div>
        <div class="detail-block-items px-4 mx-4">
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>TOTAL SCORE</strong></div>
            <div class="text-end flex-grow-1"><span class="badge rounded-pill text-bg-light">{{status.selfAssessment.score}}
                out of
                {{status.selfAssessment.totalScore}}</span></div>

          </div>

          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>TOTAL # CHECKLISTS</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.selfAssessment.totalChecklists}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.selfAssessment.totalChecklists == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="this.status.selfAssessment.totalChecklists > 0" class="btn btn-link icon-link"
                (click)="getCompanyChecklistActs()"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>TOTAL # COMPLETED CHECKLISTS</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.selfAssessment.completedChecklists}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.selfAssessment.completedChecklists == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="this.status.selfAssessment.completedChecklists > 0" class="btn btn-link icon-link"
                (click)="getCompanyChecklists(true)"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>TOTAL # INCOMPLETE CHECKLISTS</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.selfAssessment.incompleteChecklists}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="this.status.selfAssessment.incompleteChecklists == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="this.status.selfAssessment.incompleteChecklists > 0" class="btn btn-link icon-link"
                (click)="getCompanyChecklists(false)"><span class="icon icon-view-red"></span></button>
            </div>
          </div>

          <div class="detail-block-item d-flex align-items-center my-4" *ngIf="companyAdmin || superAdmin">
            <div class="text-uppercase text-danger"><strong>% COMPLETED</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.selfAssessment.percentage | percent}}</span></div>
            <div class="text-center px-2">
              <button class="btn btn-link icon-link" (click)="openUserResultsModal(userResultsModal)"><span
                  class="icon icon-view-red"></span></button>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4" *ngIf="result && result.items">
    <div class="col d-flex justify-content-end">
      <div class="text-end pb-2 me-2"><button type="button" class="btn btn-primary" (click)="download('pdf')">DOWNLOAD
          (PDF)</button>
      </div>
      <div class="text-end pb-2"><button type="button" class="btn btn-primary" (click)="download('xlsx')">DOWNLOAD
          (XLSX)</button>
      </div>
    </div>
  </div>
  <div class="row mt-4" class="row" *ngIf="result && result.items">
    <div [class.col-6]="companyAdmin || superAdmin" [class.col-12]="!companyAdmin && !superAdmin">
      <ng-select [items]="acts" bindLabel="name" [multiple]="false" [loading]="actsLoading" placeholder="Filter by Act"
        (change)="actSelected($event)" [(ngModel)]="selectedAct">
      </ng-select>
    </div>
    <div class="col-6" *ngIf="companyAdmin || superAdmin">
      <ng-select [items]="users" bindLabel="name" [multiple]="false" [hideSelected]="true" placeholder="Filter by User"
        (change)="userSelected($event)">
        <ng-template ng-label-tmp let-item="item">
          <span>{{item.firstname}} {{item.lastname}}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <span>{{item.firstname}} {{item.lastname}}</span>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="row mt-4" *ngIf="result && result.items">
    <div class="col">

      <table class="table table-bordered bg-white table-sm company-acts-table">
        <tr>
          <th>ACT</th>
          <th>SECTION</th>
          <th>COMPLIANCE ITEM</th>
          <th>DESCRIPTION</th>
          <th *ngIf="companyAdmin || orgAdmin || superAdmin">ASSIGNED TO</th>
          <th>COMPLIANT</th>
          <th>DOCUMENTS</th>
        </tr>
        <tr *ngFor="let item of result.items">
          <td><span placement="top"
              [ngbTooltip]="item.checklistItem.actSection.act.name">{{truncate(item.checklistItem.actSection.act.name,
              40)}}</span></td>
          <td><span placement="top"
              [ngbTooltip]="item.checklistItem.actSection.title">{{truncate(item.checklistItem.actSection.title,
              40)}}</span></td>
          <td><span placement="top"
              [ngbTooltip]="item.checklistItem.complianceQuery">{{truncate(item.checklistItem.complianceQuery,
              40)}}</span></td>
          <td><span placement="top"
              [ngbTooltip]="item.checklistItem.description">{{truncate(item.checklistItem.description,
              40)}}</span></td>
          <td *ngIf="companyAdmin || orgAdmin || superAdmin">

            <div class="d-flex" *ngIf="item.user">
              <span class="text-blue">{{item.user.firstname}} {{item.user.lastname}}</span>

            </div>
            <div class="d-flex" *ngIf="!item.user">
              <span class="text-red">UNASSIGNED</span>

            </div>
          </td>
          <td>
            <span class="text-red" *ngIf="item.compliant == false">NO</span>
            <span class="text-green" *ngIf="item.compliant == true">YES</span>
            <span class="text-blue" *ngIf="item.compliant == null && item.completedDateTime">N/A</span>
            <div class="d-flex" *ngIf="item.compliant == null && !item.completedDateTime">
              <span class="text-blue">UNDETERMINED</span>
              <a *ngIf="item.user && item.user.id == this.userId" class="ms-1 icon-link"
                (click)="openAnswerModal(answerModal, item)"><span class="icon icon-small icon-edit-red"></span></a>
            </div>
          </td>
          <td><span placement="top">
              <a *ngFor="let doc of item.documents" placement="top" [ngbTooltip]="doc.filename"
                (click)="getDocument(doc)" class="pe-1"><span class="icon icon-view-red"></span></a>
            </span></td>
        </tr>
      </table>
      <div class="pager-bar py-4 mb-1">
        <div class="container text-center d-flex flex-row align-items-center justify-content-center"
          *ngIf="result.items.length > 0">
          <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
          <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
          <span class="px-4">Page {{result.currentPage}} of {{result.totalPages}}</span>
          <div class="px-4 d-flex flex-row align-items-center">
            <div class="pe-2">Items per page:
            </div>
            <div>
              <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
            </div>
          </div>
          <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
          <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
        </div>
      </div>


    </div>
  </div>


  <div class="row mt-4" *ngIf="actResult && actResult.items">
    <div class="col">
      <table class="table table-bordered bg-white table-sm company-acts-table">
        <tr>
          <th>ACT</th>
          <th *ngIf="(companyAdmin || orgAdmin || superAdmin) && !selectedPeriodId">ASSIGN USERS</th>
          <th class="text-center">SCORE</th>
          <th class="text-center">TOTAL</th>
          <th class="text-center">PERCENTAGE</th>
        </tr>
        <tr *ngFor="let item of actResult.items">
          <td><span style="cursor: pointer;" (click)="getCompanyChecklists(null, item.act)" placement="top"
              [ngbTooltip]="item.act.name">{{truncate(item.act.name,
              100)}}</span></td>
          <td *ngIf="(companyAdmin || orgAdmin || superAdmin) && !selectedPeriodId" class="text-center d-flex">
            <a class="ms-1 icon-link" (click)="openAssignModal(assignModal, item)"><span
                class="icon icon-small icon-edit-red"></span></a>
          </td>
          <td class="text-center">{{item.score}}</td>
          <td class="text-center">{{item.total}}</td>
          <td class="text-center">{{item.percentage | percent}}</td>

        </tr>
      </table>
      <div class="pager-bar py-4 mb-1">
        <div class="container text-center d-flex flex-row align-items-center justify-content-center"
          *ngIf="actResult.items.length > 0">
          <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt; First</button>&nbsp;
          <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
          <span class="px-4">Page {{actResult.currentPage}} of {{actResult.totalPages}}</span>
          <div class="px-4 d-flex flex-row align-items-center">
            <div class="pe-2">Items per page:
            </div>
            <div>
              <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
            </div>
          </div>
          <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
          <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last &gt;&gt;</button>&nbsp;
        </div>
      </div>


    </div>
  </div>

</div>

<ng-template #assignModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Assign User</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <ng-select [items]="roleOwners" name="roleOwner" [(ngModel)]="currentRoleOwners" [clearable]="false"
          [multiple]="true">
          <ng-template ng-label-tmp let-item="item">
            <span *ngIf="item.customTitle">{{item.customTitle}}</span>
            <span *ngIf="!item.customTitle">{{item.department.name}}</span>
            <span> ({{item.user.firstname}} {{item.user.lastname}})</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <span *ngIf="item.customTitle">{{item.customTitle}}</span>
            <span *ngIf="!item.customTitle">{{item.department.name}}</span>
            <span> ({{item.user.firstname}} {{item.user.lastname}})</span>
          </ng-template>
        </ng-select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" [disabled]="!currentRoleOwners || disableAssignButton"
      (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<ng-template #answerModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Compliance</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="answerForm" (ngSubmit)="submitAnswer()">
      <!-- <div class="form-group row" [customErrorMessages]="customErrorMessages"> -->
      <div class="form-group row">
        <label for="compliant"
          class="col-12 col-form-label text-startcol-1">{{currentItem.checklistItem.complianceQuery}}</label>
        <div class="col-12">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="compliant" id="compliantTrue" value="true"
              formControlName="compliant" (change)="compliantChange($event)">
            <label class="form-check-label" for="compliantTrue">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="compliant" id="compliantFalse" value="false"
              formControlName="compliant" (change)="compliantChange($event)">
            <label class="form-check-label" for="compliantFalse">No</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="compliant" id="compliantNA" [value]="null"
              formControlName="compliant" (change)="compliantChange($event)">
            <label class="form-check-label" for="compliantNA">N/A</label>
          </div>
          
        </div>
      </div>
      <!-- <div class="form-group row" *ngIf="currentItem.checklistItem.documentUpload"
        [customErrorMessages]="customErrorMessages"> -->
      <div class="form-group row" *ngIf="currentItem.checklistItem.documentUpload">
        <div class="col-12">
          <input type="file" class="form-control" formControlName="documents" (change)="fileUpload($event)" multiple
            [class.is-invalid]="submitted && answerForm.controls.documents.invalid">
          <div class="invalid-feedback text-end" *ngIf="answerForm.controls.documents.errors">
            <div *ngIf="answerForm.controls.documents.errors.required">Required</div>
          </div>
          <div class="mt-2">
            <div class="d-flex mt-1" *ngFor="let file of files">
              <div class="flex-grow-1">{{file.filename}}</div>
              <div><a class="ms-1 icon-link text-danger" (click)="removeFile(file)">Remove</a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end">
        <button type="submit" ngbAutofocus class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>

</ng-template>


<ng-template #userResultsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">User results</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <ng-select [items]="acts" bindLabel="name" [multiple]="false" [loading]="actsLoading" placeholder="Filter by Act"
      (change)="resultsActSelected($event)" [(ngModel)]="selectedAct">
    </ng-select>

    <table class="table table-bordered bg-white table-sm company-acts-table">
      <tr>
        <th>USER</th>
        <th>RESULT</th>
      </tr>
      <tr *ngFor="let item of userResults">
        <td>{{item.firstname}} {{item.lastname}}</td>
        <td>{{item.result}}%</td>
      </tr>
    </table>
  </div>

</ng-template>