import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { Organisation } from '../models/organisation';

@Injectable({
  providedIn: 'root'
})
export class OrganisationApiService extends ApiService<Organisation> {

  _controller: string = 'organisations';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('organisations', http, env);
  }

  public Archive(id: number): Observable<{}>{
    return this.post(`api/${this._controller}/${id}/archive`, {});
  }
}
