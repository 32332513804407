<div class="mb-1">
  <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<ng-container *ngIf="company && companyLearningModules">
  <div class="container">
    <div *ngIf="!companyLearningModules || companyLearningModules.length == 0" class="text-center">No modules</div>
    <div *ngFor="let module of companyLearningModules">
      <div class="py-2 d-flex align-items-center module-item">
        <div class="pe-4 flex-grow-1">
          <div class="flex-grow-1 text-bold"><strong [innerHTML]="module.learningModule.name"></strong></div>
          <div [innerHTML]="module.learningModule.summary"></div>
        </div>
        <button style="min-width: 90px;" *ngIf="!module.assigned" class="btn btn-outline-danger"
          (click)="assign(module)">Assign</button>
        <button style="min-width: 90px;" *ngIf="module.assigned" class="btn btn-outline-success" (click)="unassign(module)">Unassign</button>
        <!-- <div class="form-check d-flex align-items-center">
          <input class="form-check-input" type="checkbox" [(ngModel)]="module.assigned">
          <label class="form-check-label">
            Assigned
          </label>
        </div> -->
      </div>
      <hr />
    </div>
  </div>
</ng-container>