import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { HomeContactComponent } from './pages/home-contact/home-contact.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { UsagePolicyComponent } from './pages/usage-policy/usage-policy.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth-guard';
import { ConfigurationComponent } from './pages/dashboard/configuration/configuration.component';
import { DashboardHomeComponent } from './pages/dashboard/dashboard-home/dashboard-home.component';

import { OrganisationComponent } from './pages/dashboard/configuration/organisation/organisation.component';
import { AddOrganisationComponent } from './pages/dashboard/configuration/organisation/add-organisation/add-organisation.component';
import { EditOrganisationComponent } from './pages/dashboard/configuration/organisation/edit-organisation/edit-organisation.component';
import { ListOrganisationComponent } from './pages/dashboard/configuration/organisation/list-organisation/list-organisation.component';
import { CompanyComponent } from './pages/dashboard/configuration/company/company.component';
import { AddCompanyComponent } from './pages/dashboard/configuration/company/add-company/add-company.component';
import { EditCompanyComponent } from './pages/dashboard/configuration/company/edit-company/edit-company.component';
import { ListCompanyComponent } from './pages/dashboard/configuration/company/list-company/list-company.component';
import { QuestionnaireComponent } from './pages/dashboard/configuration/questionnaire/questionnaire.component';
import { OrgStructureComponent } from './pages/dashboard/configuration/org-structure/org-structure.component';
import { ChoosePasswordComponent } from './pages/choose-password/choose-password.component';
import { LibraryComponent } from './pages/dashboard/library/library.component';
import { UmbrellaReportComponent } from './pages/dashboard/umbrella-report/umbrella-report.component';
import { AdminComponent } from './pages/dashboard/admin/admin.component';
import { DocumentAdminComponent } from './pages/dashboard/admin/document-admin/document-admin.component';
import { AdminHomeComponent } from './pages/dashboard/admin/admin-home/admin-home.component';
import { ActAdminComponent } from './pages/dashboard/admin/act-admin/act-admin.component';
import { UserAdminComponent } from './pages/dashboard/admin/user-admin/user-admin.component';
import { ELearningAdminComponent } from './pages/dashboard/admin/elearning-admin/elearning-admin.component';
import { ELearningComponent } from './pages/dashboard/elearning/elearning.component';
import { ComplianceProgramComponent } from './pages/dashboard/compliance-program/compliance-program.component';
import { SelfAssessmentChecklistComponent } from './pages/dashboard/self-assessment-checklist/self-assessment-checklist.component';
import { ComplianceProgramAdminComponent } from './pages/dashboard/admin/compliance-program-admin/compliance-program-admin.component';
import { ProfileComponent } from './pages/dashboard/profile/profile.component';
import { DashboardContactComponent } from './pages/dashboard/dashboard-contact/dashboard-contact.component';
import { SelfAssessmentAdminComponent } from './pages/dashboard/admin/self-assessment-admin/self-assessment-admin.component';
import { LibraryAdminComponent } from './pages/dashboard/admin/library-admin/library-admin.component';
import { RequestContractComponent } from './pages/request-contract/request-contract.component';
import { ContractsOldComponent } from './pages/dashboard/contracts-old/contracts.component';
import { ContractTemplatesComponent } from './pages/dashboard/contracts-old/contract-templates/contract-templates.component';
import { ContractsHomeComponent } from './pages/dashboard/contracts-old/contracts-home/contracts-home.component';
import { ContractRequestsComponent } from './pages/dashboard/contracts-old/contract-requests/contract-requests.component';
import { ContractListComponent } from './pages/dashboard/contracts-old/contract-list/contract-list.component';
import { ContractOpenComponent } from './pages/dashboard/contracts-old/contract-open/contract-open.component';
import { ContractMessagesComponent } from './pages/dashboard/contracts-old/contract-messages/contract-messages.component';
import { GetComponent } from './pages/get/get.component';
import { ToolkitComponent } from './pages/toolkit/toolkit.component';
import { EnterpriseSupplierDevelopmentComponent } from './pages/enterprise-supplier-development/enterprise-supplier-development.component';
import { DocumentRegistryComponent } from './pages/dashboard/contracts-old/document-registry/document-registry.component';
import { ReportsComponent } from './pages/dashboard/reports/reports.component';
import { PeriodAdminComponent } from './pages/dashboard/admin/period-admin/period-admin.component';
import { UmbrellaReportCompareComponent } from './pages/dashboard/umbrella-report/umbrella-report-compare/umbrella-report-compare.component';
import { CompanyActsComponent } from './pages/dashboard/configuration/acts/company-acts/company-acts.component';
import { DocumentsComponent } from './pages/dashboard/documents/documents.component';
import { ContractsComponent } from './pages/dashboard/documents/contracts/contracts.component';
import { PaiaComponent } from './pages/dashboard/documents/paia/paia.component';
import { PermitsComponent } from './pages/dashboard/documents/permits/permits.component';
import { DocumentsHomeComponent } from './pages/dashboard/documents/documents-home/documents-home.component';
import { PaiaAddEditComponent } from './pages/dashboard/documents/paia/paia-add-edit/paia-add-edit.component';
import { ContractsAddEditComponent } from './pages/dashboard/documents/contracts/contracts-add-edit/contracts-add-edit.component';
import { PermitsAddEditComponent } from './pages/dashboard/documents/permits/permits-add-edit/permits-add-edit.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { FicaComponent } from './pages/dashboard/documents/fica/fica.component';
import { FicaAddEditComponent } from './pages/dashboard/documents/fica/fica-add-edit/fica-add-edit.component';
import { MatterManagementComponent } from './pages/dashboard/documents/matter-management/matter-management.component';
import { MatterManagementAddEditComponent } from './pages/dashboard/documents/matter-management/matter-management-add-edit/matter-management-add-edit.component';
import { PoliciesComponent } from './pages/dashboard/documents/policies/policies.component';
import { PoliciesAddEditComponent } from './pages/dashboard/documents/policies/policies-add-edit/policies-add-edit.component';
const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'get', component: GetComponent },
  { path: 'toolkit', component: ToolkitComponent },
  { path: 'enterprise-supplier-development', component: EnterpriseSupplierDevelopmentComponent },
  { path: 'contact', component: HomeContactComponent },
  { path: 'contact-us', component: HomeContactComponent },
  { path: 'usage-policy', component: UsagePolicyComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'login', component: LoginComponent },
  { path: 'choose-password/:id', component: ChoosePasswordComponent },
  { path: 'request-contract/:id', component: RequestContractComponent },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], children: [
      { path: 'home', component: DashboardHomeComponent },
      {
        path: 'configuration', component: ConfigurationComponent, children: [
          {
            path: 'organisation', component: OrganisationComponent, children: [
              { path: 'add', component: AddOrganisationComponent },
              { path: 'edit/:id', component: EditOrganisationComponent },
              { path: 'list', component: ListOrganisationComponent },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'company', component: CompanyComponent, data: { level: 0 }, children: [
              { path: 'add/:organisationid', data: { level: 0 }, component: AddCompanyComponent },
              { path: 'edit/:id', data: { level: 0 }, component: EditCompanyComponent },
              { path: 'list/:id', component: ListCompanyComponent },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'sub-company', component: CompanyComponent, data: { level: 1 }, children: [
              { path: 'add/:organisationid/:companyid', data: { level: 1 }, component: AddCompanyComponent },
              { path: 'edit/:id', data: { level: 1 }, component: EditCompanyComponent },
            ]
          },
          {
            path: 'business-unit', component: CompanyComponent, data: { level: 2 }, children: [
              { path: 'add/:organisationid/:companyid', data: { level: 2 }, component: AddCompanyComponent },
              { path: 'edit/:id', data: { level: 2 }, component: EditCompanyComponent },
            ]
          },
          {
            path: 'area', component: CompanyComponent, data: { level: 3 }, children: [
              { path: 'add/:organisationid/:companyid', data: { level: 3 }, component: AddCompanyComponent },
              { path: 'edit/:id', data: { level: 3 }, component: EditCompanyComponent },
            ]
          },
          {
            path: 'legal-category', component: CompanyComponent, data: { level: 4 }, children: [
              { path: 'add/:organisationid/:companyid', data: { level: 4 }, component: AddCompanyComponent },
              { path: 'edit/:id', data: { level: 4 }, component: EditCompanyComponent },
            ]
          },
          {
            path: 'questionnaire/:id', component: QuestionnaireComponent
          },
          {
            path: 'org-structure/:id', component: OrgStructureComponent
          },
          {
            path: 'acts/:id', component: CompanyActsComponent
          },
          {
            path: '',
            redirectTo: 'home',
            pathMatch: 'full'
          },
        ]
      },
      { path: 'library/:id', component: LibraryComponent },
      { path: 'library', component: LibraryComponent },
      { path: 'umbrella-report', component: UmbrellaReportComponent },
      { path: 'umbrella-report/:id', component: UmbrellaReportComponent },
      { path: 'umbrella-report-compare', component: UmbrellaReportCompareComponent },
      { path: 'e-learning', component: ELearningComponent },
      { path: 'compliance-program', component: ComplianceProgramComponent },
      { path: 'self-assessment-checklist', component: SelfAssessmentChecklistComponent },
      { path: 'reports', component: ReportsComponent },
      { 
        path: 'documents', component: DocumentsComponent, children: [
          { path: 'home', component: DocumentsHomeComponent },
          { path: 'contracts', component: ComingSoonComponent},
          { path: 'contracts/:id', component: ComingSoonComponent},
          { path: 'paia', component: PaiaComponent},
          { path: 'paia/:id', component: PaiaAddEditComponent },
          { path: 'permits', component: ComingSoonComponent},
          { path: 'permits/:id', component: ComingSoonComponent },
          { path: 'policies', component: ComingSoonComponent},
          { path: 'policies/:id', component: ComingSoonComponent },
          { path: 'fica', component: FicaComponent},
          { path: 'fica/:id', component: FicaAddEditComponent },
          { path: 'matter-management', component: ComingSoonComponent},
          { path: 'matter-management/:id', component: ComingSoonComponent },
          { path: 'legal-entities', component: ComingSoonComponent },
          { path: 'legal-entities/:id', component: ComingSoonComponent },
          {
            path: '',
            redirectTo: 'home',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'contracts_old', component: ContractsOldComponent, children: [
          { path: 'home', component: ContractsHomeComponent },
          { path: 'templates', component: ContractTemplatesComponent },
          { path: 'templates/:id', component: ContractTemplatesComponent },
          { path: 'request', component: ContractRequestsComponent },
          { path: 'request/:id', component: ContractRequestsComponent },
          { path: 'list', component: ContractListComponent },
          { path: 'registry', component: DocumentRegistryComponent },
          { path: 'list/:id', component: ContractListComponent },
          { path: 'messages/:id', component: ContractMessagesComponent },
          { path: 'open/:id', component: ContractOpenComponent },
          {
            path: '',
            redirectTo: 'home',
            pathMatch: 'full'
          },
        ]
      },
      { path: 'profile', component: ProfileComponent },
      { path: 'contact', component: DashboardContactComponent },
      {
        path: 'admin', component: AdminComponent, children: [
          //{ path: 'home', component: AdminHomeComponent },
          { path: 'documents', component: DocumentAdminComponent },
          { path: 'acts', component: ActAdminComponent },
          { path: 'users', component: UserAdminComponent },
          { path: 'compliance-program', component: ComplianceProgramAdminComponent },
          { path: 'e-learning', component: ELearningAdminComponent },
          { path: 'self-assessment', component: SelfAssessmentAdminComponent },
          { path: 'library', component: LibraryAdminComponent },
          { path: 'periods', component: PeriodAdminComponent },

          {
            path: '',
            redirectTo: 'users',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
