<div class="mb-1" *ngIf="isSuperAdmin || isOrgAdmin">
  <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="status">
  <div class="row">
    <div class="col-6 px-4">
      <app-puzzle-wheel [percentage]="status.eLearning.percentage" [section]="'e_learning'"></app-puzzle-wheel>
    </div>
    <div class="col-6">
      <div class="detail-block p-4 m-4">
        <div class="logo text-center">
          <img [src]="status.logoUrl" class="img-fluid company-logo" />
        </div>
        <hr />
        <div class="detail-block-items px-4 mx-4">
          <div class="detail-block-item d-flex align-items-center my-4" *ngIf="!isUser">
            <div class="text-uppercase text-danger"><strong>AVAILABLE MODULES</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.eLearning.availableModules}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="status.eLearning.availableModules == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="status.eLearning.availableModules > 0" class="btn btn-link icon-link"
                (click)="modules()"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div *ngIf="isUser" class="text-uppercase text-danger"><strong>ASSIGNED MODULES</strong></div>
            <div *ngIf="!isUser" class="text-uppercase text-danger"><strong>USERS ASSIGNED TO MODULES</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.eLearning.assignedUserModules}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="status.eLearning.assignedUserModules == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="status.eLearning.assignedUserModules > 0" class="btn btn-link icon-link"
                (click)="results()"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div *ngIf="isUser" class="text-uppercase text-danger"><strong>COMPLETED MODULES</strong></div>
            <div *ngIf="!isUser" class="text-uppercase text-danger"><strong>COMPLETED USER MODULES</strong></div>
            <div class="text-end flex-grow-1"><span
                class="badge rounded-pill text-bg-light">{{status.eLearning.completedUserModules}}</span></div>
            <div class="text-center px-2">
              <button *ngIf="status.eLearning.completedUserModules == 0" class="btn btn-link icon-link"><span
                  class="icon icon-view-grey"></span></button>
              <button *ngIf="status.eLearning.completedUserModules > 0" class="btn btn-link icon-link"
                (click)="results()"><span class="icon icon-view-red"></span></button>
            </div>
          </div>
          <div class="detail-block-item d-flex align-items-center my-4">
            <div class="text-uppercase text-danger"><strong>VIEW RESULTS</strong></div>
            <div class="text-end flex-grow-1">&nbsp;</div>
            <div class="text-center px-2">
              <button class="btn btn-link icon-link" (click)="results()"><span
                  class="icon icon-view-red"></span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4" *ngIf="showModules">
    <div class="col">
      <hr />
      <h5 class="text-danger">AVAILABLE E-LEARNING MODULES</h5>
      <div class="row">
        <div class="col">
          <div *ngFor="let i of companyLearningModules" class="moduleItem p-2 m-2 d-flex align-items-center">
            <div class="flex-grow-1 pe-2">
              <div><strong [innerHtml]="i.learningModule.name"></strong></div>
              <div [innerHtml]="truncate(i.learningModule.summary)"></div>
            </div>
            <div>
              <button class="me-4 btn btn-primary btn-outline btn-small" type="button"
                (click)="assign(i)">ASSIGN</button>
            </div>
          </div>
        </div>
        <div class="col">
          <div *ngIf="selectedCompanyLearningModule">
            <h4>Assign: <span [innerHtml]="selectedCompanyLearningModule.learningModule.name"></span></h4>
            <p>Please upload a list of the users you would like to assign to this module using the provided template!
            </p>
            <p><a class="btn btn-primary me-2" href="/assets/templates/assignModules.csv" target="_blank">Click here</a>
              to
              download the template</p>
            <form [formGroup]="mainForm" (ngSubmit)="submit()">
              <!-- <div class="form-group row" [customErrorMessages]="customErrorMessages"> -->
                <div class="form-group row">
                <div class="col">
                    <input type="file" class="form-control" formControlName="list" (change)="fileUpload($event)" [class.is-invalid]="submitted && mainForm.controls.listData.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.listData.errors">
                      <div *ngIf="mainForm.controls.listData.errors.required">Required</div>
                    </div>
                </div>
              </div>
              <div class="text-end">
                <button type="submit" class="btn btn-default">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4" *ngIf="showResults">
    <div class="col">
      <hr />
      <h5 class="text-danger">E-LEARNING MODULES</h5>
      <div class="row">
        <div class="col">
          <div *ngFor="let i of companyLearningModules" class="moduleItem p-2 m-2 d-flex align-items-center">
            <div class="flex-grow-1 me-2">
              <div><strong [innerHtml]="i.learningModule.name"></strong></div>
              <div [innerHtml]="truncate(i.learningModule.summary)"></div>
            </div>
            <div class="me-2">
              {{i.score | percent }}
            </div>
            <div>
              <button class="me-4 ms-1 btn btn-primary btn-outline btn-small" style="min-width: 110px;" type="button"
                (click)="viewResults(i)">VIEW RESULTS</button>
            </div>
          </div>
        </div>
        <div class="col">
          <div *ngIf="selectedCompanyLearningModule && learningModuleResults">
            <h5 class="text-uppercase">{{selectedCompanyLearningModule.name}} RESULTS</h5>
            <div *ngIf="!learningModuleResults.items || learningModuleResults.items.length == 0">No results</div>
            <div class="d-flex flex-column">
              <div *ngFor="let i of learningModuleResults.items"
                class="moduleItem p-2 m-2 d-flex align-items-center  flex-grow-1">
                <div class="flex-grow-1">
                  <strong>{{i.firstname}} {{i.lastname}}</strong>
                </div>
                <div *ngIf="!i.result">
                  No result
                </div>
                <div *ngIf="i.result || i.result == 0">
                  {{i.result}}%
                </div>
              </div>
              <div class="pager-bar py-4 mb-1" *ngIf="learningModuleResults.items.length > 0">
                <hr />
                <div class="container text-center d-flex flex-row align-items-center justify-content-center">
                  <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt;</button>&nbsp;
                  <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt;</button>&nbsp;
                  <span class="px-4">{{learningModuleResults.currentPage}} of
                    {{learningModuleResults.totalPages}}</span>
                  <div class="px-4 d-flex flex-row align-items-center">
                    <div class="pe-2">Per page:
                    </div>
                    <div>
                      <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                        [clearable]="false" [dropdownPosition]="'top'"></ng-select>
                    </div>
                  </div>
                  <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">&gt;</button>&nbsp;
                  <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">&gt;&gt;</button>&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>