import { Component } from '@angular/core';

@Component({
  selector: 'app-policies-add-edit',
  templateUrl: './policies-add-edit.component.html',
  styleUrl: './policies-add-edit.component.scss'
})
export class PoliciesAddEditComponent {

}
