import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBaseService {

  constructor(protected http: HttpClient, protected env: EnvironmentService) { }

  protected get<O>(path: string): Observable<O> {
    return this.http.get<O>(`${this.env.getApiUrl()}/${path}`).pipe(
      map((r) => {
        console.log(r);
        return r;
      })
    );
  }

  protected post<I, O>(path: string, data: I): Observable<O> {
    return this.http.post<O>(`${this.env.getApiUrl()}/${path}`, data);
  }

  protected put<I, O>(path: string, data: I): Observable<O> {
    return this.http.put<O>(`${this.env.getApiUrl()}/${path}`, data);
  }

}
