import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
//import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { ApiReadOnlyService } from './api-read-only.service';
import { EnvironmentService } from './environment.service';

//@Injectable({
//  providedIn: 'root'
//})
export abstract class ApiService<T> extends ApiReadOnlyService<T>{

  constructor(controller: string, protected http: HttpClient, protected env: EnvironmentService) {
    super(controller, http, env);
  }



  public Add(data: T): Observable<T> {
    return this.post<T, T>(`api/${this._controller}/`, data);
  }

  public Update(id: number, data: T): Observable<T> {
    return this.put<T, T>(`api/${this._controller}/${id}`, data);
  }
}
