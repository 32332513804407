<div class="mb-1" *ngIf="token.isSuperAdmin()">
  <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>

<div class="container py-4" *ngIf="apiCompanyId">
  <div class="row">
    <div class="col-6">
      <app-umbrella-report-widget [companyId]="apiCompanyId" [periodIndex]="0"></app-umbrella-report-widget>
    </div>
    <div class="col-6">
      <app-umbrella-report-widget [companyId]="apiCompanyId" [periodIndex]="1"></app-umbrella-report-widget>
    </div>
  </div>
</div>