<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-12 col-sm-10 col-md-8 col-xl-6  offset-sm-1 offset-md-2 offset-xl-3 ">
      <div class="contact p-4">
        <div class="row">
          <div class="col-12 col-md-4">
            <p class="text-danger">CONTACT US</p>
            <p>
              75 King Street, Berario<br />
              Northcliff, Johannesburg<br />
              2195, South Africa
            </p>
            <p>
              +27 (0) 11 476 3217 (Ext 2)<br />
              alison.lee&#64;mweb.co.za
            </p>
            <p>
              Mon - Fri: 8am to 4pm<br />
              Saturday - Sunday: Close
            </p>
          </div>
          <div class="col-12 col-md-8">
            <form [formGroup]="contactForm" (ngSubmit)="submit()">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="First Name *" formControlName="firstname"
                      [class.is-invalid]="submitted && contactForm.controls.firstname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="contactForm.controls.firstname.errors">
                      <div *ngIf="contactForm.controls.firstname.errors.required">Required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Last Name" formControlName="lastname"
                      [class.is-invalid]="submitted && contactForm.controls.lastname.invalid">
                    <div class="invalid-feedback text-end" *ngIf="contactForm.controls.lastname.errors">
                      <div *ngIf="contactForm.controls.lastname.errors.required">Required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Email *" formControlName="email"
                      [class.is-invalid]="submitted && contactForm.controls.email.invalid">
                    <div class="invalid-feedback text-end" *ngIf="contactForm.controls.email.errors">
                      <div *ngIf="contactForm.controls.email.errors.required">Required</div>
                      <div *ngIf="contactForm.controls.email.errors.email">Invalid</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Phone" formControlName="phoneNumber"
                      [class.is-invalid]="submitted && contactForm.controls.phoneNumber.invalid">
                    <div class="invalid-feedback text-end" *ngIf="contactForm.controls.phoneNumber.errors">
                      <div *ngIf="contactForm.controls.phoneNumber.errors.required">Required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <textarea rows="6" class="form-control" placeholder="Message *" formControlName="message"
                      [class.is-invalid]="submitted && contactForm.controls.message.invalid"></textarea>
                    <div class="invalid-feedback text-end" *ngIf="contactForm.controls.message.errors">
                      <div *ngIf="contactForm.controls.message.errors.required">Required</div>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-danger">Send</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>