import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { StatusApiService } from '../../../services/status-api.service';
import { SpinnerService } from '../../../services/spinner.service';
import { CompanyApiService } from '../../../services/company-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupApiService } from '../../../services/lookup-api.service';
import { RequestCacheService } from '../../../services/request-cache.service';
import { TokenService } from '../../../services/token.service';
import { ActListingResult } from 'src/app/models/actListingResult';
import { CompanyAct } from 'src/app/models/companyAct';
import { CompanyActListResult } from 'src/app/models/companyActListResult';
import { CompanyDetailStatus } from 'src/app/models/companyDetailStatus';
import { Department } from 'src/app/models/department';
import { ImpactRating } from 'src/app/models/impactRating';
import { ReportingRequirementResult } from 'src/app/models/reportingRequirementResult';
import { RiskRating } from 'src/app/models/riskRating';
import { UpdateCompanyActControls } from 'src/app/models/updateCompanyActControls';
import { UpdateCompanyActImpactRating } from 'src/app/models/updateCompanyActImpactRating';
import { UpdateCompanyActImplications } from 'src/app/models/updateCompanyActImplications';
import { UpdateCompanyRiskDescription } from 'src/app/models/updateCompanyRiskDescription';
import { User } from 'src/app/models/user';
import { Period } from 'src/app/models/period';
import { UpdateCompanyActDocumentRetentionPeriod } from 'src/app/models/updateCompanyActDocumentRetentionPeriod';

@Component({
  selector: 'app-umbrella-report',
  templateUrl: './umbrella-report.component.html',
  styleUrls: ['./umbrella-report.component.scss']
})
export class UmbrellaReportComponent implements OnInit {

  textModalBody: string;

  companyId: number;
  apiCompanyId?: number;
  status: CompanyDetailStatus;
  companyActs: CompanyActListResult;
  actListings: ActListingResult;
  //companyPermits: ActListingResult;
  //companyPolicies: ActListingResult;
  companyReportingRequirements: ReportingRequirementResult;
  currentPage: number = 1;

  impactRatings: Array<ImpactRating>;
  currentImpactRating: number;
  currentControls: string;
  currentDocumentRetentionPeriod: number;
  currentImplications: string;
  currentRiskDescription: string;

  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;

  perPageItems = [5, 10, 20, 50, 100];
  perPage = 10;

  actUsers: Array<User>;
  actDepartments: Array<Department>;

  selectedActUser: number;
  selectedActDepartment: number;
  selectedImpactRating: number;
  orderDecending: boolean = false;

  selectedPeriodId?: number = null;
  selectedPeriod: Period;
  periods: Period[];
  periodsLoading = false;

  superAdmin = false;
  orgAdmin = false;

  riskModalTitle = "Update Impact Risk";
  residualModal = false;

  risidualRiskStatus: CompanyDetailStatus = null;

  orgId: number = 0;

  constructor(
    private route: ActivatedRoute,
    private statusApi: StatusApiService,
    private companyApi: CompanyApiService,
    private lookupApi: LookupApiService,
    private spinner: SpinnerService,
    private cache: RequestCacheService,
    private modalService: NgbModal,
    public token: TokenService,
    private router: Router) { }

  ngOnInit() {

    if (localStorage["PER_PAGE"]) {
      this.perPage = localStorage["PER_PAGE"];
    }

    


    this.selectedPeriodId = null
    this.selectedPeriod = { id: 0, name: 'Current' };

    this.superAdmin = this.token.isSuperAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();

    if (this.superAdmin) {
      this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.orgId = this.token.organisationId();
    }

    // this.lookupApi.ListImpactRatings(this.orgId).subscribe((data) => {
    //   this.impactRatings = data;
    // });

    if (this.token.isCompanyAdmin()) {
      this.companyId = this.token.companyId();
      
      if (this.companyId) {

       

        this.loadPeriods();
        this.getStatus();
        this.getActDepartments();
        this.getActUsers();
      }

    }
    else if (this.token.isSuperAdmin() || this.token.isOrganisationAdmin()) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {

          this.loadPeriods();
          this.getStatus();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }
  }

  periodSelected(event) {

    this.selectedPeriodId = this.selectedPeriod ? this.selectedPeriod.id : null;

    this.getStatus();
  }

  private loadPeriods() {
    this.periodsLoading = true;
    return this.companyApi.GetCompanyPeriods(this.apiCompanyId).subscribe(data => {
      let current: Period = { id: 0, name: 'Current' };

      this.periods = [current].concat(data);

      this.periodsLoading = false;
      this.selectedPeriod = current;
    });
  }

  getStatus() {

    this.spinner.startBackground();
    this.statusApi.getCompanyStatus(this.apiCompanyId, null, null, this.selectedPeriodId).subscribe((data) => {
      this.status = data;
      this.spinner.stopBackground();
    });

  }
  companySelected(company) {

    if (company) {

      if (this.superAdmin) {
        this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
      }
      else {
        this.orgId = this.token.organisationId();
      }

      this.lookupApi.ListImpactRatings(this.orgId).subscribe((data) => {
        this.impactRatings = data;
      });

      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.loadPeriods();
      this.getStatus();
      this.getActDepartments();
      this.getActUsers();
    }
    else {
      this.status = null;
    }
  }

  getActDepartments() {
    this.companyApi.GetCompanyActDepartments(this.apiCompanyId).subscribe(data => {
      this.actDepartments = data;
    });
  }

  getActUsers() {
    this.companyApi.GetCompanyActUsers(this.apiCompanyId).subscribe(data => {
      this.actUsers = data;
    });
  }

  actDepartmentChange(evt) {
    this.currentPage = 1;
    this.getData();
  }

  actUserChange(evt) {
    this.currentPage = 1;
    this.getData();
  }

  actImpactRatingChange() {
    this.currentPage = 1;
    this.getData();
  }

  actOrderChange() {
    this.currentPage = 1;
    this.getData();
  }

  truncate(str?: string, len: number = 40) {
    if (!str) {
      return str;
    }
    if (str && str.length > len)
      return str.substring(0, len) + '...';
    else
      return str;
  };

  displayImpactRating(impactRating: ImpactRating, likelihood?: number, impact?: number) {
    if (impactRating && impactRating.name) {
      return this.getRiskLabel(impactRating.name, likelihood, impact);
    }
    else {
      return ' ';
    }
  }

  detailType = 'Acts';

  getCompanyActs() {
    this.getData('Acts');
  }

  getCompanyPermits() {
    this.getData('Permits');
  }

  getCompanyPolicies() {
    this.getData('Policies');
  }

  getCompanyReportingRequirement() {
    this.getData('ReportingRequirements');
  }

  getData(newType = null) {

    if (newType != null) {
      this.detailType = newType;
      this.companyActs = null;
      this.actListings = null;
      //this.companyPermits = null;
      //this.companyPolicies = null;
      this.companyReportingRequirements = null;
      this.currentPage = 1;
    }

    this.spinner.startBackground();

    switch (this.detailType) {
      case 'Acts':

        this.companyApi.GetCompanyActs(this.currentPage, this.perPage, this.apiCompanyId, this.selectedActDepartment, this.selectedActUser, this.selectedImpactRating, this.orderDecending).subscribe((data) => {
          this.companyActs = data;

          this.firstEnabled = this.companyActs.currentPage > 1;
          this.prevEnabled = this.companyActs.currentPage > 1;

          this.nextEnabled = this.companyActs.currentPage < data.totalPages;
          this.lastEnabled = this.companyActs.currentPage < data.totalPages;

          this.spinner.stopBackground();
        });

        break;

      case 'Permits':

        this.companyApi.GetCompanyPermits(this.currentPage, this.perPage, this.apiCompanyId).subscribe((data) => {
          this.actListings = data;

          this.firstEnabled = this.actListings.currentPage > 1;
          this.prevEnabled = this.actListings.currentPage > 1;

          this.nextEnabled = this.actListings.currentPage < data.totalPages;
          this.lastEnabled = this.actListings.currentPage < data.totalPages;

          this.spinner.stopBackground();
        });

        break;

      case 'Policies':

        this.companyApi.GetCompanyPolicies(this.currentPage, this.perPage, this.apiCompanyId).subscribe((data) => {
          this.actListings = data;

          this.firstEnabled = this.actListings.currentPage > 1;
          this.prevEnabled = this.actListings.currentPage > 1;

          this.nextEnabled = this.actListings.currentPage < data.totalPages;
          this.lastEnabled = this.actListings.currentPage < data.totalPages;

          this.spinner.stopBackground();
        });


        break;

      case 'ReportingRequirements':

        this.companyApi.GetCompanyReportingRequirements(this.currentPage, this.perPage, this.apiCompanyId).subscribe((data) => {
          this.companyReportingRequirements = data;

          this.firstEnabled = this.companyReportingRequirements.currentPage > 1;
          this.prevEnabled = this.companyReportingRequirements.currentPage > 1;

          this.nextEnabled = this.companyReportingRequirements.currentPage < data.totalPages;
          this.lastEnabled = this.companyReportingRequirements.currentPage < data.totalPages;

          this.spinner.stopBackground();
        });

        break;
    }


  }

  first() {
    this.currentPage = 1;
    this.getData();
  }

  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.getData();
  }

  next() {

    switch (this.detailType) {
      case 'Acts':
        if (this.currentPage < this.companyActs.totalPages) {
          this.currentPage++;
        }
        break;
      case 'Permits':
      case 'Policies':
        if (this.currentPage < this.actListings.totalPages) {
          this.currentPage++;
        }
        break;
      case 'ReportingRequirements':
        if (this.currentPage < this.companyReportingRequirements.totalPages) {
          this.currentPage++;
        }
        break;
    }

    this.getData();
  }

  last() {

    switch (this.detailType) {
      case 'Acts':
        this.currentPage = this.companyActs.totalPages;
        break;
      case 'Permits':
      case 'Policies':
        this.currentPage = this.actListings.totalPages;
        break;
      case 'ReportingRequirements':
        this.currentPage = this.companyReportingRequirements.totalPages;
        break;
    }

    this.getData();
  }

  newRating: RiskRating = null;
  riskData: any = null;
  currentAct: CompanyAct = null;

  openRiskModal(content, act: CompanyAct) {
    this.currentAct = act;
    this.riskModalTitle = "Update Impact Risk";
    this.residualModal = false;
    this.risidualRiskStatus = null;
    this.newRating = {
      impact: act.impact,
      likelihood: act.likelihood
    };

    this.riskData = {
      controls: act.controls,
      implications: act.implications,
      riskDescription: act.riskDescription
    };

    this.currentImpactRating = act.impactRating.id;
    this.modalService.open(content, { size: "lg" }).result.then((result) => {
      let newId = this.newRating.id;
      let likelihood = this.newRating.likelihood;
      let impact = this.newRating.impact;
      if (newId) {
        let data: UpdateCompanyActImpactRating = {
          actId: act.actId,
          companyId: act.companyId,
          impactRatingId: newId,
          likelihood: likelihood,
          impact: impact
        };

        this.spinner.startBackground();

        this.companyApi.UpdateImpactRating(data).subscribe(() => {
          this.cache.clear();
          this.getData();
          this.getStatus();
        });
      }
    }, (reason) => {
    });
  }

  openResidualRiskModal(content, act: CompanyAct) {
    this.currentAct = act;
    this.riskModalTitle = act.name + " - Residual Risk";
    this.risidualRiskStatus = null;

    this.residualModal = true;
    this.spinner.start();

    this.statusApi.getCompanyStatus(this.apiCompanyId, act.actId).subscribe(data => {
      
      this.spinner.stop();
      this.risidualRiskStatus = data;

      this.newRating = {
        impact: act.residualImpact,
        likelihood: act.residualLikelihood
      };

      this.riskData = {
        controls: act.controls,
        implications: act.implications,
        riskDescription: act.riskDescription
      };

      this.currentImpactRating = act.impactRating.id;
      this.modalService.open(content, { size: "lg" }).result.then((result) => {
        let newId = this.newRating.id;
        let likelihood = this.newRating.likelihood;
        let impact = this.newRating.impact;
        if (newId) {
          let data: UpdateCompanyActImpactRating = {
            actId: act.actId,
            companyId: act.companyId,
            impactRatingId: newId,
            likelihood: likelihood,
            impact: impact
          };

          this.spinner.startBackground();

          this.companyApi.UpdateResidualRiskRating(data).subscribe(() => {
            this.cache.clear();
            this.getData();
            this.getStatus();
          });
        }
      }, (reason) => {
      });


    });


  }

  riskUpdated(ev) {
    this.newRating = ev;
  }
  openControlsModal(content, act: CompanyAct) {
    this.currentControls = act.controls;
    this.modalService.open(content, { size: 'sm' }).result.then((result) => {
      let newControls = this.currentControls;
      let data: UpdateCompanyActControls = {
        actId: act.actId,
        companyId: act.companyId,
        controls: newControls
      };

      this.spinner.startBackground();

      this.companyApi.UpdateControls(data).subscribe(() => {
        this.cache.clear();
        this.getData();
      });
    }, (reason) => {
    });
  }

  openDocumentRetentionPeriodModal(content, act: CompanyAct) {
    this.currentDocumentRetentionPeriod = act.documentRetentionPeriod;
    this.modalService.open(content, { size: 'sm' }).result.then((result) => {
      let newDocumentRetentionPeriod = +this.currentDocumentRetentionPeriod;
      let data: UpdateCompanyActDocumentRetentionPeriod = {
        actId: act.actId,
        companyId: act.companyId,
        documentRetentionPeriod: newDocumentRetentionPeriod
      };

      this.spinner.startBackground();

      this.companyApi.UpdateDocumentRetentionPeriod(data).subscribe(() => {
        this.cache.clear();
        this.getData();
      });
    }, (reason) => {
    });
  }

  openImplicationsModal(content, act: CompanyAct) {
    this.currentImplications = act.implications;
    this.modalService.open(content, { size: 'sm' }).result.then((result) => {
      let newImplications = this.currentImplications;
      let data: UpdateCompanyActImplications = {
        actId: act.actId,
        companyId: act.companyId,
        implications: newImplications
      };

      this.spinner.startBackground();

      this.companyApi.UpdateImplications(data).subscribe(() => {
        this.cache.clear();
        this.getData();
      });
    }, (reason) => {
    });
  }

  openRiskDescriptionModal(content, act: CompanyAct) {
    this.currentRiskDescription = act.riskDescription;
    this.modalService.open(content, { size: 'sm' }).result.then((result) => {
      let newRiskDescription = this.currentRiskDescription;
      let data: UpdateCompanyRiskDescription = {
        actId: act.actId,
        companyId: act.companyId,
        riskDescription: newRiskDescription
      };

      this.spinner.startBackground();

      this.companyApi.UpdateRiskDescription(data).subscribe(() => {
        this.cache.clear();
        this.getData();
      });
    }, (reason) => {
    });
  }

  perPageChange(event) {
    localStorage["PER_PAGE"] = this.perPage;
    this.currentPage = 1;
    this.getData();
  }

  openTextModal(content, text: string) {
    this.textModalBody = text;

    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
    });
  }

  library(ev: Event, id) {
    ev.preventDefault();
    window.location.href = `/dashboard/library#${id}`;

  }

  downloadTasks(ev: Event, actId: number){
    ev.preventDefault();
    this.spinner.startBackground();
    this.companyApi.ExportCompanyTaskItems(this.apiCompanyId, actId, this.selectedPeriodId).subscribe((data) => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }

  getRiskLabel(risk: string, likelihood?: number, impact?: number){

    return this.lookupApi.GetImpactRating(this.orgId, risk, likelihood, impact);
    
  }
}
