import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TaskItem } from '../models/taskItem';
import { ActReportItemListResult } from '../models/actReportItemListResult';

@Injectable({
  providedIn: 'root'
})
export class ReportsApiService extends ApiService<TaskItem> {

  _controller: string = 'reports';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('reports', http, env);
  }

  public ActReport(page: number = 1, perPage: number = 10, companyId?: number, impactRatingId?: number, sortBy?: string, descending?: boolean): Observable<ActReportItemListResult> {

    let url = '';
    if (companyId) {
      url = `api/${this._controller}/${companyId}/actReport/?page=${page}&perPage=${perPage}`;
    }
    else {
      url = `api/${this._controller}/actReport/?page=${page}&perPage=${perPage}`;
    }

    if (impactRatingId) {
      url = url + `&impactRatingId=${impactRatingId}`
    }

    if (sortBy) {
      url = url + `&sortBy=${sortBy}`
    }

    if (descending) {
      url = url + `&descending=${descending}`
    }
    
    return this.get<ActReportItemListResult>(url);    
  }

  public ExportActReport(companyId?: number, impactRatingId?: number, sortBy?: string, descending?: boolean, type?: string, format?: 'xlsx' | 'pdf'): Observable<{}> {

    let url = '';
    if (companyId) {
      url = `${this.env.getApiUrl()}/api/${this._controller}/${companyId}/downloadactreport/?`;
    }
    else {
      url = `${this.env.getApiUrl()}/api/${this._controller}/downloadactreport/?`;
    }

    if (impactRatingId) {
      url = url + `&impactRatingId=${impactRatingId}`
    }

    if (sortBy) {
      url = url + `&sortBy=${sortBy}`
    }

    if (descending) {
      url = url + `&descending=${descending}`
    }

    if (type) {
      url = url + `&type=${type}`
    }

    if (format) {
      url = url + `&format=${format}`
    }
    
    return this.http.get(url, { responseType: 'blob' as 'json' });


  }

  
}
