import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DocumentCorrespondenceAttachment, DocumentCorrespondenceType, DocumentRegion, DocumentTemplate, DocumentTemplateType, MatterManagementDocument, MatterManagementDocumentAppealStatus, MatterManagementDocumentCorrespondence, MatterManagementDocumentReferral, MatterManagementDocumentStatus } from 'src/app/models/documents';
import { DocumentsApiService } from 'src/app/services/documents-api.service';
import { TokenService } from 'src/app/services/token.service';
import { formatDate } from '@angular/common';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-matter-management-add-edit',
  templateUrl: './matter-management-add-edit.component.html',
  styleUrl: './matter-management-add-edit.component.scss'
})
export class MatterManagementAddEditComponent implements OnInit {

  private modalService = inject(NgbModal);
  
  companyId: number;
  orgId: number;
  apiCompanyId?: number;

  userId: number;
  user: boolean = false;
  contractAdmin: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  templateTypes: DocumentTemplateType[];
  documentCorrespondenceTypes: DocumentCorrespondenceType[];

  //regions: DocumentRegion[];
  //facilities: DocumentRegion[];
  statuses: MatterManagementDocumentStatus[];
  appealStatuses: MatterManagementDocumentAppealStatus[];
  referrals: MatterManagementDocumentReferral[];

  submitted = false;


  mainForm = new FormGroup({
    id: new FormControl(0),
    createdAt: new FormControl(),
    lastModified: new FormControl(),
    referenceNumber: new FormControl(),

    documentFilePath: new FormControl(),
    documentFileData: new FormControl(undefined, Validators.required),

    //standardTemplate: new FormControl(undefined, Validators.required),
    //useAsTemplate: new FormControl(false),
    //templateName: new FormControl(undefined),

    //documentTemplateId: new FormControl(),

    recipientFirstname: new FormControl(undefined, Validators.required),
    recipientLastname: new FormControl(undefined, Validators.required),
    recipientContactNumber: new FormControl(undefined, Validators.required),
    recipientEmail: new FormControl(undefined, [Validators.required, Validators.email]),

    //documentRegionId: new FormControl(undefined, Validators.required),
    //documentFacilityId: new FormControl(undefined, Validators.required),

    receivedDate: new FormControl(undefined, Validators.required),
    acknowledgedDate: new FormControl(undefined, Validators.required),

    companyPersonName: new FormControl(),
    companyPersonIDNumber: new FormControl(),

    requestorFirstname: new FormControl(),
    requestorLastname: new FormControl(),
    requestorIDNumber: new FormControl(),
    requestorCompany: new FormControl(),
    requestorCompanyRegistrationNumber: new FormControl(),
    requestorContactNumber: new FormControl(),
    requestorEmail: new FormControl(),
    requestorAddress: new FormControl(),

    requestType: new FormControl(undefined, Validators.required),
    requestDetails: new FormControl(),
    requestReason: new FormControl(),
    incidentDate: new FormControl(),

    matterManagementDocumentStatusId: new FormControl(undefined, Validators.required),
    
    fees: new FormControl(),

    decision: new FormControl(),
    decisionReason: new FormControl(),
    decisionDate: new FormControl(),
    

    recordsLocation: new FormControl(undefined),

    matterManagementDocumentAppealStatusId: new FormControl(undefined),
    matterManagementDocumentReferralId: new FormControl(undefined),


    //infoRequired: new FormControl(),
    //infoRequiredReason: new FormControl(),

    caseOpenedDate: new FormControl(),
    decisionProvidedDate: new FormControl(),
    caseClosedDate: new FormControl(),
    //renewalDate: new FormControl(),

    form2FilePath: new FormControl(),
    form2FileData: new FormControl(),

    letterheadFilePath: new FormControl(),
    letterheadFileData: new FormControl(),

    consentFilePath: new FormControl(),
    consentFileData: new FormControl(),

    kycFilePath: new FormControl(),
    kycFileData: new FormControl(),

    companyId: new FormControl(0),
    company: new FormControl(),
    userId: new FormControl(0),
    user: new FormControl(),

    notes: new FormControl()

    //documentTemplate: new FormControl(),
    //matterManagementDocumentStatus: new FormControl(),
    //documentFacility: new FormControl(),
  });

  currentDocumentId = 0;
  //currentDocument: MatterManagementDocument;

  constructor(
    private documentsApi: DocumentsApiService,
    private route: ActivatedRoute,
    private token: TokenService,
    private router: Router,
    private spinner: SpinnerService,
    private alertService: AlertService) { }

  ngOnInit() {

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();

    if (this.companyAdmin || this.user || this.contractAdmin) {
      this.companyId = this.token.companyId();
      this.apiCompanyId = this.companyId;
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      // this.route.paramMap.subscribe((params: ParamMap) => {
      //   this.companyId = +params.get('companyId');
      //   this.apiCompanyId = this.companyId;
      //   if (this.companyId) {
      //     this.init();
      //   }
      // });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }


    this.route.paramMap.subscribe((params: ParamMap) => {
      this.currentDocumentId = +params.get('id');
      this.init();
    });

    // this.documentsApi.GetRegions().subscribe(data => {
    //   this.regions = data;
    // });
    this.documentsApi.GetMatterManagementStatuses().subscribe(data => {
      this.statuses = data;
    });

    this.documentsApi.GetMatterManagementAppealStatuses().subscribe(data => {
      this.appealStatuses = data;
    });

    this.documentsApi.GetMatterManagementReferrals().subscribe(data => {
      this.referrals = data;
    });

    // this.mainForm.controls.documentRegionId.valueChanges.subscribe(data => {

    //   if (data) {
    //     this.facilities = undefined;
    //     this.mainForm.controls.documentFacilityId.patchValue(undefined);
    //     this.documentsApi.GetFacilities(this.mainForm.controls.documentRegionId.value).subscribe(data => {
    //       this.facilities = data;
    //     })
    //   }
    // });



   
  }

  currentDocument: MatterManagementDocument;
  init() {
    
    if (this.currentDocumentId > 0 && this.apiCompanyId) {
      this.documentsApi.GetDocumentCorrespondenceTypes('MAtterManagement').subscribe(data => {
        this.documentCorrespondenceTypes = data;
      });
      this.documentsApi.GetMatterManagementDocument(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
        if (!data){
          this.router.navigateByUrl('/dashboard/documents/matter-management');
          return;
        }

        this.currentDocument = data;
        
        this.mainForm.patchValue(data);

        this.mainForm.controls.documentFileData.clearValidators();
        this.mainForm.controls.documentFileData.updateValueAndValidity();

        

        this.mainForm.patchValue({
          acknowledgedDate: data.acknowledgedDate ? formatDate(data.acknowledgedDate, 'yyyy-MM-dd', 'en') : undefined,
          //renewalDate: data.renewalDate? formatDate(data.renewalDate, 'yyyy-MM-dd', 'en') : undefined,
          incidentDate: data.incidentDate? formatDate(data.incidentDate, 'yyyy-MM-dd', 'en') : undefined,
          receivedDate: data.receivedDate? formatDate(data.receivedDate, 'yyyy-MM-dd', 'en') : undefined,
          decisionDate: data.decisionDate? formatDate(data.decisionDate, 'yyyy-MM-dd', 'en') : undefined,
          caseClosedDate: data.caseClosedDate? formatDate(data.caseClosedDate, 'yyyy-MM-dd', 'en') : undefined,
          caseOpenedDate: data.caseOpenedDate? formatDate(data.caseOpenedDate, 'yyyy-MM-dd', 'en') : undefined,
          decisionProvidedDate: data.decisionProvidedDate? formatDate(data.decisionProvidedDate, 'yyyy-MM-dd', 'en') : undefined
        });
      })
    }
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    else {
      
    }
  }

  fileUpload(control: FormControl, event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) { //2MB
        control.setValue(undefined);
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        control.patchValue(reader.result);
      };
    }
  }


  submit() {

    console.log(this.apiCompanyId);
    this.mainForm.markAllAsTouched();

    if (!this.mainForm.valid) {
      this.alertService.error('Not all required fields have been completed please check the form.');
      return;
    }

    let document = this.mainForm.value as MatterManagementDocument;
    document.id = this.currentDocumentId;
    document.companyId = this.apiCompanyId;

    this.spinner.startBackground();
    let func: Observable<{}>;

    if (this.currentDocumentId == 0) {
      func = this.documentsApi.AddMatterManagementDocument(document);
      
    } else {
      func = this.documentsApi.UpdateMatterManagementDocument(document);
    }

    func.subscribe(() => {
      this.spinner.stopBackground();
      this.alertService.success('Record succesfully added');
      this.router.navigateByUrl('/dashboard/documents/matter-management');
    }, error => {
      this.spinner.stopBackground();
      this.alertService.error(JSON.stringify(error));
    });
  
  
    
  }

  correspondenceForm: FormGroup;
  correspondenceFormSubmitted = false;

  sendCorrespondence(content: TemplateRef<any>) {
    this.correspondenceForm = new FormGroup({
      name: new FormControl(undefined, Validators.required),
      email: new FormControl(undefined, [Validators.required, Validators.email]),
      sender: new FormControl(undefined, Validators.required),
      documentFileData: new FormControl(undefined, Validators.required),
      documentCorrespondenceTypeId: new FormControl(undefined, Validators.required),
      attachments: new FormArray([])
    });

    this.correspondenceFormSubmitted = false;
    
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				
			},
			(reason) => {
				
			},
		);
  }

  addAttachement() {
    (this.correspondenceForm.controls.attachments as FormArray).push(new FormControl(undefined, Validators.required));
      
    this.correspondenceForm.updateValueAndValidity();
  }

  removeAttachement(index: number) {
    (this.correspondenceForm.controls.attachments as FormArray).removeAt(index);
    this.correspondenceForm.updateValueAndValidity();
  }

  submitCorrespondence(){
    this.correspondenceFormSubmitted = true;
    if (!this.correspondenceForm.valid){
      return;
    }

    this.showCorrespondence = false;

    let attachments: DocumentCorrespondenceAttachment[] = [];

    (this.correspondenceForm.controls.attachments as FormArray).controls.forEach(f => {
        let a: DocumentCorrespondenceAttachment = {
          documentFileData: f.value
        };
        attachments.push(a);
    });

    
    let model: MatterManagementDocumentCorrespondence = {
      documentCorrespondenceTypeId: this.correspondenceForm.controls.documentCorrespondenceTypeId.value,
      matterManagementDocumentId: this.currentDocumentId,
      email: this.correspondenceForm.controls.email.value,
      name: this.correspondenceForm.controls.name.value,
      sender: this.correspondenceForm.controls.sender.value,
      documentFileData: this.correspondenceForm.controls.documentFileData.value,
      attachments: attachments
    };

    this.spinner.start();

    this.documentsApi.addMatterManagementDocumentCorrespondence(model, this.apiCompanyId).subscribe(data => {
      this.spinner.stop();
      this.alertService.success('Correspondence succesfully submitted');
      this.modalService.dismissAll();
    }, error => {
      this.spinner.stop();
      this.alertService.error(JSON.stringify(error));
    })

  }

  correspondence: MatterManagementDocumentCorrespondence[];
  showCorrespondence = false;
  viewCorrespondence(){
    
    this.spinner.startBackground();
    this.documentsApi.getMatterManagementDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      this.showCorrespondence = true;
      this.correspondence = data;
      this.spinner.stopBackground();
    });
  }

  exportCorrespondence(){
    this.spinner.startBackground();
    this.documentsApi.exportMatterManagementDocumentCorrespondence(this.currentDocumentId, this.apiCompanyId).subscribe(data => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }

  hideCorrespondence(){
    this.showCorrespondence = false;
  }

  download(ev: Event, id: number) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getMatterManagementDocumentCorrespondenceFile(id, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadAttachment(ev: Event, attachmentId: number, correspondenceId: number){
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.getMatterManagementDocumentCorrespondenceAttachmentFile(this.currentDocumentId, attachmentId, correspondenceId, this.apiCompanyId).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    });
  }

  downloadDocumentFile(ev: Event, file: string) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetMatterManagementDocumentFile(this.currentDocumentId, this.apiCompanyId, file).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  downloadDocumentHistoryFile(ev: Event, id: number){
    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetMatterManagementDocumentHistoryFile(this.currentDocumentId, this.apiCompanyId, id).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }
  
}
