import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { OrganisationApiService } from '../../../../../services/organisation-api.service';
import { SpinnerService } from '../../../../../services/spinner.service';
import { LookupApiService } from '../../../../../services/lookup-api.service';
import { CompanyApiService } from '../../../../../services/company-api.service';
import { forkJoin } from 'rxjs';
import { EventsService } from '../../../../../services/events.service';
import $ from 'jquery';
import { Company } from 'src/app/models/company';
import { Country } from 'src/app/models/country';
import { EntityType } from 'src/app/models/entityType';
import { Industry } from 'src/app/models/industry';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {

  company: Company;

  countries: Array<Country>;
  industries: Array<Industry>;
  entityTypes: Array<EntityType>;
  ready: boolean = false;
  companyId: number;

  //tradingRegionsList: Array<any> = [];
  //industriesList: Array<any> = [];

  submitted = false;

  mainForm = this.formBuilder.group({
    name: ['', Validators.required],
    logo: [''],
    logoData: ['', Validators.required],
    registrationNumber: ['', Validators.required],
    vatNumber: ['', Validators.required],
    employees: ['', [Validators.required, Validators.min(1)]],
    entityType: ['', Validators.required],
    tradingRegions: ['', Validators.required],
    industries: [''],
    address: ['', Validators.required],
    contactNumber: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]

  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private lookupService: LookupApiService,
    private companyService: CompanyApiService,
    private router: Router,
    private spinner: SpinnerService,
    private events: EventsService
  ) { }



  ngOnInit() {

    // $(document).ready(function () {
    //   $('.custom-file-input').on('change', function () {
    //     let fileName = $(this).val().split('\\').pop();
    //     $(this).next('.custom-file-label').addClass("selected").html(fileName);
    //   });
    // });

    this.spinner.startBackground();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.companyId = +params.get('id');
    });



    forkJoin(
      this.lookupService.ListIndustries(),
      this.lookupService.ListCountries(),
      this.companyService.GetById(this.companyId),
      this.lookupService.ListEntityTypes(),
    ).subscribe((r) => {
      this.industries = r[0];
      this.countries = r[1];
      this.company = r[2];
      this.entityTypes = r[3];

      // this.industries.forEach(f => {
      //   this.industriesList.push({id: f.id, text: f.name });
      // });

      // this.countries.forEach(f => {
      //   this.tradingRegionsList.push({id: f.id, text: f.name });
      // });

      let selectedCountryIds: Array<any> = [];
      this.company.countries.forEach(c => { selectedCountryIds.push(c.id) });

      let selectedIndustryIds: Array<any> = [];
      this.company.industries.forEach(i => { selectedIndustryIds.push(i.id) });

      this.mainForm.patchValue({
        name: this.company.name,
        //logo: ['', Validators.required],
        logoData: this.company.logoUrl,
        registrationNumber: this.company.registrationNumber,
        vatNumber: this.company.vatNumber,
        employees: this.company.numberOfEmployees,
        entityType: this.company.entityType.id,
        tradingRegions: selectedCountryIds,//this.company.countries,
        industries: selectedIndustryIds,//this.company.industries,
        address: this.company.primaryAddress,
        contactNumber: this.company.primaryContactNumber
      });


      //this.mainForm.controls.tradingRegions.setValue(this.company.countries);
      //this.mainForm.controls.industries.setValue(this.company.industries);

      this.ready = true;
      this.spinner.stopBackground();
    });
  }

  fileUpload(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.size > 2147483648) //2MB
      {
        this.mainForm.controls.logo.setValue('');
        this.mainForm.controls.logo.setErrors({ 'required': true });
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.mainForm.patchValue({
          logoData: reader.result
        });

        $('.custom-file-label').addClass("selected").html(file.name);

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  submit() {

    this.submitted = true;

    if (this.mainForm.invalid){
      return;
    }
    this.spinner.start();

    // let cid: Array<number> = [];

    // this.mainForm.controls.tradingRegions.value.forEach(c => {
    //   cid.push(c);
    // });

    // let iid: Array<number> = [];

    // this.mainForm.controls.industries.value.forEach(i => {
    //   iid.push(i);
    // });

    let c = this.countries.filter(c => this.mainForm.controls.tradingRegions.value.indexOf(c.id) >= 0);
    let i = this.industries.filter(c => this.mainForm.controls.industries.value.indexOf(c.id) >= 0);

    this.company.countries = c,
      this.company.industries = i,
      this.company.logoUrl = this.mainForm.controls.logoData.value,
      this.company.name = this.mainForm.controls.name.value,
      this.company.numberOfEmployees = this.mainForm.controls.employees.value,
      this.company.entityType = { id: this.mainForm.controls.entityType.value },
      this.company.primaryAddress = this.mainForm.controls.address.value,
      this.company.primaryContactNumber = this.mainForm.controls.contactNumber.value,
      this.company.registrationNumber = this.mainForm.controls.registrationNumber.value,
      this.company.vatNumber = this.mainForm.controls.vatNumber.value,

      this.companyService.Update(this.companyId, this.company).subscribe((r) => {
        this.events.fireEvent('configuration-submenu-update');
        this.router.navigateByUrl(`/dashboard/configuration/company/list/${this.company.organisation.id}`);
      });
  }

}
