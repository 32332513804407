<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.apiCompanyId">

    <div class="card mb-3" *ngIf="currentDocumentId != 0">
        <div class="card-body">
            <h4 class="card-title">Workflow</h4>
            <div class="mb-3 d-flex justify-content-between align-items-center">
                Correspondence
                <button class="btn btn-primary" (click)="sendCorrespondence(content)">Send</button>
            </div>
            <hr />
            <div class="mb-3 d-flex justify-content-between align-items-center">
                History
                <button class="btn btn-primary" (click)="viewCorrespondence()">View</button>

            </div>
            <div *ngIf="showCorrespondence">

                <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                    <tr>
                        <th>DATE/TIME</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>SENDER</th>
                        <th>TYPE</th>
                        <th>DOCUMENT</th>
                        <th>ATTACHMENTS</th>
                    </tr>
                    <tr *ngFor="let item of correspondence">
                        <td>{{item.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.sender}}</td>
                        <td>{{item.documentCorrespondenceType}}</td>

                        <td class="text-center"><a href="#" (click)="download($event, item.id)"
                                class="d-flex align-items-center"><span class="icon icon-view-red me-2"></span></a></td>
                        <td class="text-center">
                            <div class="d-flex">
                                <a href="#" *ngFor="let attachment of item.attachments"
                                    (click)="downloadAttachment($event, attachment.id, item.id)"
                                    class="d-flex align-items-center">
                                    <span class="icon icon-view-red me-1"></span>
                                </a>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>

    <form [formGroup]="mainForm" (ngSubmit)="submit()">

        <div class="card mb-3" *ngIf="currentDocumentId == 0">
            <div class="card-body">
                <h4 class="card-title">Document Template</h4>

                <div class="mb-3">
                    <label for="standardTemplate" class="form-label">Template Type</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="standardTemplate"
                                id="standardTemplateYes"
                                [class.is-invalid]="mainForm.controls.standardTemplate.touched && mainForm.controls.standardTemplate.invalid"
                                [value]="true" formControlName="standardTemplate">
                            <label class="form-check-label" for="standardTemplateYes">Standard</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="standardTemplate" id="standardTemplateNo"
                                [class.is-invalid]="mainForm.controls.standardTemplate.touched && mainForm.controls.standardTemplate.invalid"
                                [value]="false" formControlName="standardTemplate">
                            <label class="form-check-label" for="standardTemplateNo">Own provided</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.standardTemplate.errors">
                        <div *ngIf="mainForm.controls.standardTemplate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.standardTemplate.value != true">
                    <label for="documentTemplateId" class="form-label">Template</label>
                    <ng-select [items]="templates" bindLabel="name" bindValue="id" id="documentTemplateId"
                        [class.is-invalid]="mainForm.controls.documentTemplateId.touched && mainForm.controls.documentTemplateId.invalid"
                        formControlName="documentTemplateId" placeholder="Select a standard template"
                        [clearable]="true"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentTemplateId.errors">
                        <div *ngIf="mainForm.controls.documentTemplateId.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.standardTemplate.value != false">
                    <label for="documentFileData" class="form-label">Template</label>
                    <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                        (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                        <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" [class.d-none]="mainForm.controls.standardTemplate.value != false" *ngIf="superAdmin">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="useAsTemplate"
                            formControlName="useAsTemplate">
                        <label class="form-check-label" for="useAsTemplate">Load document as a Standard Template</label>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.useAsTemplate.errors">
                        <div *ngIf="mainForm.controls.useAsTemplate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3" *ngIf="mainForm.controls.useAsTemplate.value"
                    [class.d-none]="mainForm.controls.standardTemplate.value != false">
                    <label for="templateName" class="form-label">Template Name</label>
                    <input type="text" class="form-control" id="templateName" formControlName="templateName"
                        [class.is-invalid]="mainForm.controls.templateName.touched && mainForm.controls.templateName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.templateName.errors">
                        <div *ngIf="mainForm.controls.templateName.errors.required">Required</div>
                    </div>
                </div>



            </div>
        </div>

        <div class="card mb-3" *ngIf="currentDocumentId > 0">
            <div class="card-body">
                <h4 class="card-title">Document</h4>

                <div class="mb-3">
                    <label for="documentFileData" class="form-label">Update Document</label>
                    <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                        [class.is-invalid]="mainForm.controls.documentFileData.touched && mainForm.controls.documentFileData.invalid"
                        (change)="fileUpload(mainForm.controls.documentFileData, $event)">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.documentFileData.errors">
                        <div *ngIf="mainForm.controls.documentFileData.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">


                <div class="mb-3">
                    <label for="requestedDate" class="form-label">Date of Instruction/Request</label>
                    <input type="date" class="form-control" id="requestedDate" formControlName="requestedDate"
                        [class.is-invalid]="mainForm.controls.requestedDate.touched && mainForm.controls.requestedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestedDate.errors">
                        <div *ngIf="mainForm.controls.requestedDate.errors.required">Required</div>
                    </div>
                </div>


            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Requestor’s Details</h4>

                <div class="mb-3">
                    <label for="requestorName" class="form-label">Name</label>
                    <input type="text" class="form-control" id="requestorName" formControlName="requestorName"
                        [class.is-invalid]="mainForm.controls.requestorName.touched && mainForm.controls.requestorName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorName.errors">
                        <div *ngIf="mainForm.controls.requestorName.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="requestorDepartment" class="form-label">Department</label>
                    <input type="text" class="form-control" id="requestorDepartment"
                        formControlName="requestorDepartment"
                        [class.is-invalid]="mainForm.controls.requestorDepartment.touched && mainForm.controls.requestorDepartment.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorDepartment.errors">
                        <div *ngIf="mainForm.controls.requestorDepartment.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="requestorEmail" class="form-label">Email</label>
                    <input type="text" class="form-control" id="requestorEmail" formControlName="requestorEmail"
                        [class.is-invalid]="mainForm.controls.requestorEmail.touched && mainForm.controls.requestorEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.requestorEmail.errors">
                        <div *ngIf="mainForm.controls.requestorEmail.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.requestorEmail.errors.email">Invalid</div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Drafted By</h4>

                <div class="mb-3">
                    <label for="draftedByName" class="form-label">Name</label>
                    <input type="text" class="form-control" id="draftedByName" formControlName="draftedByName"
                        [class.is-invalid]="mainForm.controls.draftedByName.touched && mainForm.controls.draftedByName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.draftedByName.errors">
                        <div *ngIf="mainForm.controls.draftedByName.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="draftedByDepartment" class="form-label">Department</label>
                    <input type="text" class="form-control" id="draftedByDepartment"
                        formControlName="draftedByDepartment"
                        [class.is-invalid]="mainForm.controls.draftedByDepartment.touched && mainForm.controls.draftedByDepartment.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.draftedByDepartment.errors">
                        <div *ngIf="mainForm.controls.draftedByDepartment.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="draftedByEmail" class="form-label">Email</label>
                    <input type="text" class="form-control" id="draftedByEmail" formControlName="draftedByEmail"
                        [class.is-invalid]="mainForm.controls.draftedByEmail.touched && mainForm.controls.draftedByEmail.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.draftedByEmail.errors">
                        <div *ngIf="mainForm.controls.draftedByEmail.errors.required">Required</div>
                        <div *ngIf="mainForm.controls.draftedByEmail.errors.email">Invalid</div>
                    </div>
                </div>

            </div>
        </div>



        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">KYC, Due Diligence, FICA or Otherwise</h4>

                <div class="mb-3">
                    <label for="dueDiligenceDocumentFileData" class="form-label">Upload File</label>
                    <input type="file" class="form-control" id="dueDiligenceDocumentFileData"
                        formControlName="dueDiligenceDocumentFileData"
                        (change)="fileUpload(mainForm.controls.dueDiligenceDocumentFileData, $event)">
                </div>

            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Contract Status</h4>

                <div class="mb-3">
                    <label for="contractDocumentStatusId" class="form-label">Current Status</label>
                    <ng-select [items]="statuses" bindLabel="name" bindValue="id" id="contractDocumentStatusId"
                        formControlName="contractDocumentStatusId" placeholder="Select a request status"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.contractDocumentStatusId.touched && mainForm.controls.contractDocumentStatusId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.contractDocumentStatusId.errors">
                        <div *ngIf="mainForm.controls.contractDocumentStatusId.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Contract Details</h4>


                <div class="mb-3">
                    <label for="contractDocumentAgreementTypeId" class="form-label">Agreement Type</label>
                    <ng-select [items]="agreementTypes" bindLabel="name" bindValue="id" id="contractDocumentAgreementTypeId"
                        formControlName="contractDocumentAgreementTypeId" placeholder="Select an agreement type"
                        [clearable]="false"
                        [class.is-invalid]="mainForm.controls.contractDocumentAgreementTypeId.touched && mainForm.controls.contractDocumentAgreementTypeId.invalid"></ng-select>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.contractDocumentAgreementTypeId.errors">
                        <div *ngIf="mainForm.controls.contractDocumentAgreementTypeId.errors.required">Required</div>
                    </div>
                </div>

               

                <div class="mb-3">
                    <label for="contractDetails" class="form-label">Details</label>
                    <input type="text" class="form-control" id="contractDetails" formControlName="contractDetails"
                        [class.is-invalid]="mainForm.controls.contractDetails.touched && mainForm.controls.contractDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.contractDetails.errors">
                        <div *ngIf="mainForm.controls.contractDetails.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="companyStandard" class="form-label">Company Standard</label>
                    <input type="text" class="form-control" id="companyStandard" formControlName="companyStandard"
                        [class.is-invalid]="mainForm.controls.companyStandard.touched && mainForm.controls.companyStandard.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.companyStandard.errors">
                        <div *ngIf="mainForm.controls.companyStandard.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="organisationOwnAgreement" class="form-label">Newly Drafted Agreement</label>
                    <input type="text" class="form-control" id="organisationOwnAgreement"
                        formControlName="organisationOwnAgreement"
                        [class.is-invalid]="mainForm.controls.organisationOwnAgreement.touched && mainForm.controls.organisationOwnAgreement.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.organisationOwnAgreement.errors">
                        <div *ngIf="mainForm.controls.organisationOwnAgreement.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="agreementSuppliedByOtherParty" class="form-label">Agreement supplied by other
                        party</label>
                    <input type="text" class="form-control" id="agreementSuppliedByOtherParty"
                        formControlName="agreementSuppliedByOtherParty"
                        [class.is-invalid]="mainForm.controls.agreementSuppliedByOtherParty.touched && mainForm.controls.agreementSuppliedByOtherParty.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.agreementSuppliedByOtherParty.errors">
                        <div *ngIf="mainForm.controls.agreementSuppliedByOtherParty.errors.required">Required</div>
                    </div>
                </div>



            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Objectives / Purpose</h4>


                <div class="mb-3">
                    <label for="purposeDetails" class="form-label">Details</label>
                    <input type="text" class="form-control" id="purposeDetails" formControlName="purposeDetails"
                        [class.is-invalid]="mainForm.controls.purposeDetails.touched && mainForm.controls.purposeDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.purposeDetails.errors">
                        <div *ngIf="mainForm.controls.purposeDetails.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="serviceSubjectMatterDetails" class="form-label">Service / Subject Matter</label>
                    <input type="text" class="form-control" id="serviceSubjectMatterDetails"
                        formControlName="serviceSubjectMatterDetails"
                        [class.is-invalid]="mainForm.controls.serviceSubjectMatterDetails.touched && mainForm.controls.serviceSubjectMatterDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.serviceSubjectMatterDetails.errors">
                        <div *ngIf="mainForm.controls.serviceSubjectMatterDetails.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="objective" class="form-label">Objective</label>
                    <input type="text" class="form-control" id="objective" formControlName="objective"
                        [class.is-invalid]="mainForm.controls.objective.touched && mainForm.controls.objective.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.objective.errors">
                        <div *ngIf="mainForm.controls.objective.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="inputs" class="form-label">Inputs</label>
                    <input type="text" class="form-control" id="inputs" formControlName="inputs"
                        [class.is-invalid]="mainForm.controls.inputs.touched && mainForm.controls.inputs.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.inputs.errors">
                        <div *ngIf="mainForm.controls.inputs.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="outcomes" class="form-label">Outcomes</label>
                    <input type="text" class="form-control" id="outcomes" formControlName="outcomes"
                        [class.is-invalid]="mainForm.controls.outcomes.touched && mainForm.controls.outcomes.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.outcomes.errors">
                        <div *ngIf="mainForm.controls.outcomes.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Parties to the Agreement</h4>

                <div class="mb-3">
                    <label for="organisationName" class="form-label">Our Organisation Name</label>
                    <input type="text" class="form-control" id="organisationName" formControlName="organisationName"
                        [class.is-invalid]="mainForm.controls.organisationName.touched && mainForm.controls.organisationName.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.organisationName.errors">
                        <div *ngIf="mainForm.controls.organisationName.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="department" class="form-label">Unit/Division/Department</label>
                    <input type="text" class="form-control" id="department" formControlName="department"
                        [class.is-invalid]="mainForm.controls.department.touched && mainForm.controls.department.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.department.errors">
                        <div *ngIf="mainForm.controls.department.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="negotiatingTeam" class="form-label">Negotiating Team</label>
                    <input type="text" class="form-control" id="negotiatingTeam" formControlName="negotiatingTeam"
                        [class.is-invalid]="mainForm.controls.negotiatingTeam.touched && mainForm.controls.negotiatingTeam.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.negotiatingTeam.errors">
                        <div *ngIf="mainForm.controls.negotiatingTeam.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="headOfTeam" class="form-label">Head of Team</label>
                    <input type="text" class="form-control" id="headOfTeam" formControlName="headOfTeam"
                        [class.is-invalid]="mainForm.controls.headOfTeam.touched && mainForm.controls.headOfTeam.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.headOfTeam.errors">
                        <div *ngIf="mainForm.controls.headOfTeam.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="contractsAdministrator" class="form-label">Contracts Administrator</label>
                    <input type="text" class="form-control" id="contractsAdministrator"
                        formControlName="contractsAdministrator"
                        [class.is-invalid]="mainForm.controls.contractsAdministrator.touched && mainForm.controls.contractsAdministrator.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.contractsAdministrator.errors">
                        <div *ngIf="mainForm.controls.contractsAdministrator.errors.required">Required</div>
                    </div>
                </div>

            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Other Contracting Parties</h4>

                <ng-container formArrayName="contractThirdParties">
                    <ng-container
                        *ngFor="let thirdPartyForm of mainForm.controls.contractThirdParties.controls; let i = index">

                        <div [formGroup]="thirdPartyForm" class="mb-2">
                            <h5>Party {{i + 1}}</h5>
                            <input type="hidden" formControlName="id" id="id">
                            <div class="mb-3">
                                <label for="name" class="form-label">Name</label>
                                <input type="text" class="form-control" id="name" formControlName="name"
                                    [class.is-invalid]="thirdPartyForm.controls.name.touched && thirdPartyForm.controls.name.invalid">
                                <div class="invalid-feedback text-end" *ngIf="thirdPartyForm.controls.name.errors">
                                    <div *ngIf="thirdPartyForm.controls.name.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="registrationNumber" class="form-label">Registration Number</label>
                                <input type="text" class="form-control" id="registrationNumber"
                                    formControlName="registrationNumber"
                                    [class.is-invalid]="thirdPartyForm.controls.registrationNumber.touched && thirdPartyForm.controls.registrationNumber.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="thirdPartyForm.controls.registrationNumber.errors">
                                    <div *ngIf="thirdPartyForm.controls.registrationNumber.errors.required">Required
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="ckNumber" class="form-label">CK Number</label>
                                <input type="text" class="form-control" id="ckNumber" formControlName="ckNumber"
                                    [class.is-invalid]="thirdPartyForm.controls.ckNumber.touched && thirdPartyForm.controls.ckNumber.invalid">
                                <div class="invalid-feedback text-end" *ngIf="thirdPartyForm.controls.ckNumber.errors">
                                    <div *ngIf="thirdPartyForm.controls.ckNumber.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="identityNumber" class="form-label">Identity Number</label>
                                <input type="text" class="form-control" id="identityNumber"
                                    formControlName="identityNumber"
                                    [class.is-invalid]="thirdPartyForm.controls.identityNumber.touched && thirdPartyForm.controls.identityNumber.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="thirdPartyForm.controls.identityNumber.errors">
                                    <div *ngIf="thirdPartyForm.controls.identityNumber.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="address" class="form-label">Domicilium Address</label>
                                <input type="text" class="form-control" id="address" formControlName="address"
                                    [class.is-invalid]="thirdPartyForm.controls.address.touched && thirdPartyForm.controls.address.invalid">
                                <div class="invalid-feedback text-end" *ngIf="thirdPartyForm.controls.address.errors">
                                    <div *ngIf="thirdPartyForm.controls.address.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="postal" class="form-label">Postal</label>
                                <input type="text" class="form-control" id="postal" formControlName="postal"
                                    [class.is-invalid]="thirdPartyForm.controls.postal.touched && thirdPartyForm.controls.postal.invalid">
                                <div class="invalid-feedback text-end" *ngIf="thirdPartyForm.controls.postal.errors">
                                    <div *ngIf="thirdPartyForm.controls.postal.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="physical" class="form-label">Physical</label>
                                <input type="text" class="form-control" id="physical" formControlName="physical"
                                    [class.is-invalid]="thirdPartyForm.controls.physical.touched && thirdPartyForm.controls.physical.invalid">
                                <div class="invalid-feedback text-end" *ngIf="thirdPartyForm.controls.physical.errors">
                                    <div *ngIf="thirdPartyForm.controls.physical.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="email" class="form-label">Email</label>
                                <input type="text" class="form-control" id="email" formControlName="email"
                                    [class.is-invalid]="thirdPartyForm.controls.email.touched && thirdPartyForm.controls.email.invalid">
                                <div class="invalid-feedback text-end" *ngIf="thirdPartyForm.controls.email.errors">
                                    <div *ngIf="thirdPartyForm.controls.email.errors.required">Required</div>
                                    <div *ngIf="thirdPartyForm.controls.email.errors.email">Invalid</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="contactPerson" class="form-label">Contact Person</label>
                                <input type="text" class="form-control" id="contactPerson"
                                    formControlName="contactPerson"
                                    [class.is-invalid]="thirdPartyForm.controls.contactPerson.touched && thirdPartyForm.controls.contactPerson.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="thirdPartyForm.controls.contactPerson.errors">
                                    <div *ngIf="thirdPartyForm.controls.contactPerson.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="contactNumber" class="form-label">Contact Number</label>
                                <input type="text" class="form-control" id="contactNumber"
                                    formControlName="contactNumber"
                                    [class.is-invalid]="thirdPartyForm.controls.contactNumber.touched && thirdPartyForm.controls.contactNumber.invalid">
                                <div class="invalid-feedback text-end"
                                    *ngIf="thirdPartyForm.controls.contactNumber.errors">
                                    <div *ngIf="thirdPartyForm.controls.contactNumber.errors.required">Required</div>
                                </div>
                            </div>

                            <div class="text-end" *ngIf="mainForm.controls.contractThirdParties.length > 1">
                                <button type="button" class="btn btn-default"
                                    (click)="removeThirdParty(i)">Remove</button>&nbsp;

                            </div>

                        </div>
                        <hr />
                    </ng-container>
                </ng-container>
                <div class="text-end">
                    <button type="button" class="btn btn-default" (click)="addThirdParty()">Add</button>&nbsp;

                </div>
            </div>
        </div>


        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Deal Value</h4>

                <div class="mb-3">
                    <label for="contractValue" class="form-label">Anticipated value of contract</label>
                    <input type="text" class="form-control" id="contractValue" formControlName="contractValue"
                        [class.is-invalid]="mainForm.controls.contractValue.touched && mainForm.controls.contractValue.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.contractValue.errors">
                        <div *ngIf="mainForm.controls.contractValue.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="contractValuePerMonth" class="form-label">Per month</label>
                    <input type="text" class="form-control" id="contractValuePerMonth"
                        formControlName="contractValuePerMonth"
                        [class.is-invalid]="mainForm.controls.contractValuePerMonth.touched && mainForm.controls.contractValuePerMonth.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.contractValuePerMonth.errors">
                        <div *ngIf="mainForm.controls.contractValuePerMonth.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="contractValuePerAnnum" class="form-label">Per annum</label>
                    <input type="text" class="form-control" id="contractValuePerAnnum"
                        formControlName="contractValuePerAnnum"
                        [class.is-invalid]="mainForm.controls.contractValuePerAnnum.touched && mainForm.controls.contractValuePerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.contractValuePerAnnum.errors">
                        <div *ngIf="mainForm.controls.contractValuePerAnnum.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="contractIncreasePerAnnum" class="form-label">Increase per annum</label>
                    <input type="text" class="form-control" id="contractIncreasePerAnnum"
                        formControlName="contractIncreasePerAnnum"
                        [class.is-invalid]="mainForm.controls.contractIncreasePerAnnum.touched && mainForm.controls.contractIncreasePerAnnum.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.contractIncreasePerAnnum.errors">
                        <div *ngIf="mainForm.controls.contractIncreasePerAnnum.errors.required">Required</div>
                    </div>
                </div>



            </div>
        </div>



        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Duration &amp; Dates</h4>




                <div class="mb-3">
                    <label for="commencementDate" class="form-label">Commencement Date</label>
                    <input type="date" class="form-control" id="commencementDate" formControlName="commencementDate"
                        [class.is-invalid]="mainForm.controls.commencementDate.touched && mainForm.controls.commencementDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.commencementDate.errors">
                        <div *ngIf="mainForm.controls.commencementDate.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="contractDuration" class="form-label">Duration of Contract</label>
                    <input type="text" class="form-control" id="contractDuration" formControlName="contractDuration"
                        [class.is-invalid]="mainForm.controls.contractDuration.touched && mainForm.controls.contractDuration.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.contractDuration.errors">
                        <div *ngIf="mainForm.controls.contractDuration.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="expectedEndDate" class="form-label">Expected End Date</label>
                    <input type="date" class="form-control" id="expectedEndDate" formControlName="expectedEndDate"
                        [class.is-invalid]="mainForm.controls.expectedEndDate.touched && mainForm.controls.expectedEndDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.expectedEndDate.errors">
                        <div *ngIf="mainForm.controls.expectedEndDate.errors.required">Required</div>
                    </div>
                </div>

                <hr />

                <div class="mb-3">
                    <label for="terminationForNoCause" class="form-label">Termination for no cause</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="terminationForNoCause"
                                id="terminationForNoCauseYes" [value]="true" formControlName="terminationForNoCause"
                                [class.is-invalid]="mainForm.controls.terminationForNoCause.touched && mainForm.controls.terminationForNoCause.invalid">
                            <label class="form-check-label" for="terminationForNoCauseYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="terminationForNoCause"
                                id="terminationForNoCauseNo" [value]="false" formControlName="terminationForNoCause"
                                [class.is-invalid]="mainForm.controls.terminationForNoCause.touched && mainForm.controls.terminationForNoCause.invalid">
                            <label class="form-check-label" for="terminationForNoCauseNo">No</label>
                        </div>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.terminationForNoCause.errors">
                        <div *ngIf="mainForm.controls.terminationForNoCause.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="terminationForNoCauseNoticePeriod" class="form-label">Notice Period</label>
                    <input type="text" class="form-control" id="terminationForNoCauseNoticePeriod"
                        formControlName="terminationForNoCauseNoticePeriod"
                        [class.is-invalid]="mainForm.controls.terminationForNoCauseNoticePeriod.touched && mainForm.controls.terminationForNoCauseNoticePeriod.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.terminationForNoCauseNoticePeriod.errors">
                        <div *ngIf="mainForm.controls.terminationForNoCauseNoticePeriod.errors.required">Required</div>
                    </div>
                </div>

                <hr />

                <h5>Other rights to terminate</h5>

                <div class="mb-3">
                    <label for="otherRightsToTerminateDetails" class="form-label">Details</label>
                    <input type="text" class="form-control" id="otherRightsToTerminateDetails"
                        formControlName="otherRightsToTerminateDetails"
                        [class.is-invalid]="mainForm.controls.otherRightsToTerminateDetails.touched && mainForm.controls.otherRightsToTerminateDetails.invalid">
                    <div class="invalid-feedback text-end"
                        *ngIf="mainForm.controls.otherRightsToTerminateDetails.errors">
                        <div *ngIf="mainForm.controls.otherRightsToTerminateDetails.errors.required">Required</div>
                    </div>
                </div>

                <hr />

                <h5>Rights to renew the contract</h5>

                <div class="mb-3">
                    <label for="renewRightOfFirstRefusal" class="form-label">Right Of First Refusal </label>
                    <input type="text" class="form-control" id="renewRightOfFirstRefusal"
                        formControlName="renewRightOfFirstRefusal"
                        [class.is-invalid]="mainForm.controls.renewRightOfFirstRefusal.touched && mainForm.controls.renewRightOfFirstRefusal.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.renewRightOfFirstRefusal.errors">
                        <div *ngIf="mainForm.controls.renewRightOfFirstRefusal.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="renewDetails" class="form-label">Details</label>
                    <input type="text" class="form-control" id="renewDetails" formControlName="renewDetails"
                        [class.is-invalid]="mainForm.controls.renewDetails.touched && mainForm.controls.renewDetails.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.renewDetails.errors">
                        <div *ngIf="mainForm.controls.renewDetails.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="renewOptions" class="form-label">Option</label>
                    <input type="text" class="form-control" id="renewOptions" formControlName="renewOptions"
                        [class.is-invalid]="mainForm.controls.renewOptions.touched && mainForm.controls.renewOptions.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.renewOptions.errors">
                        <div *ngIf="mainForm.controls.renewOptions.errors.required">Required</div>
                    </div>
                </div>


            </div>
        </div>






        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Contract Clauses</h4>

                <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">ARE THE FOLLOWING COVERED IN THIS AGREEMENT?</th>
                            <td class="text-center">DETAIL</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="fw-bold">Term, duration, commencement date and termination </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredTermDurationCommencementDateAndTermination"
                                        id="coveredTermDurationCommencementDateAndTerminationYes" [value]="true"
                                        formControlName="coveredTermDurationCommencementDateAndTermination"
                                        [class.is-invalid]="mainForm.controls.coveredTermDurationCommencementDateAndTermination.touched && mainForm.controls.coveredTermDurationCommencementDateAndTermination.invalid">
                                    <label class="form-check-label"
                                        for="coveredTermDurationCommencementDateAndTerminationYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredTermDurationCommencementDateAndTermination"
                                        id="coveredTermDurationCommencementDateAndTerminationNo" [value]="false"
                                        formControlName="coveredTermDurationCommencementDateAndTermination"
                                        [class.is-invalid]="mainForm.controls.coveredTermDurationCommencementDateAndTermination.touched && mainForm.controls.coveredTermDurationCommencementDateAndTermination.invalid">
                                    <label class="form-check-label"
                                        for="coveredTermDurationCommencementDateAndTerminationNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredTermDurationCommencementDateAndTerminationDetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control"
                                        id="coveredTermDurationCommencementDateAndTerminationDetails"
                                        formControlName="coveredTermDurationCommencementDateAndTerminationDetails"
                                        [class.is-invalid]="mainForm.controls.coveredTermDurationCommencementDateAndTerminationDetails.touched && mainForm.controls.coveredTermDurationCommencementDateAndTerminationDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredTermDurationCommencementDateAndTerminationDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredTermDurationCommencementDateAndTerminationDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Rights to renew </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredRightsToRenew"
                                        id="coveredRightsToRenewYes" [value]="true"
                                        formControlName="coveredRightsToRenew"
                                        [class.is-invalid]="mainForm.controls.coveredRightsToRenew.touched && mainForm.controls.coveredRightsToRenew.invalid">
                                    <label class="form-check-label" for="coveredRightsToRenewYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredRightsToRenew"
                                        id="coveredRightsToRenewNo" [value]="false"
                                        formControlName="coveredRightsToRenew"
                                        [class.is-invalid]="mainForm.controls.coveredRightsToRenew.touched && mainForm.controls.coveredRightsToRenew.invalid">
                                    <label class="form-check-label" for="coveredRightsToRenewNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredRightsToRenewDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredRightsToRenewDetails"
                                        formControlName="coveredRightsToRenewDetails"
                                        [class.is-invalid]="mainForm.controls.coveredRightsToRenewDetails.touched && mainForm.controls.coveredRightsToRenewDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredRightsToRenewDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredRightsToRenewDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">POPIA clause including reference to Operator </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredPOPIAClauseIncludingReferenceToOperator"
                                        id="coveredPOPIAClauseIncludingReferenceToOperatorYes" [value]="true"
                                        formControlName="coveredPOPIAClauseIncludingReferenceToOperator"
                                        [class.is-invalid]="mainForm.controls.coveredPOPIAClauseIncludingReferenceToOperator.touched && mainForm.controls.coveredPOPIAClauseIncludingReferenceToOperator.invalid">
                                    <label class="form-check-label"
                                        for="coveredPOPIAClauseIncludingReferenceToOperatorYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredPOPIAClauseIncludingReferenceToOperator"
                                        id="coveredPOPIAClauseIncludingReferenceToOperatorNo" [value]="false"
                                        formControlName="coveredPOPIAClauseIncludingReferenceToOperator"
                                        [class.is-invalid]="mainForm.controls.coveredPOPIAClauseIncludingReferenceToOperator.touched && mainForm.controls.coveredPOPIAClauseIncludingReferenceToOperator.invalid">
                                    <label class="form-check-label"
                                        for="coveredPOPIAClauseIncludingReferenceToOperatorNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredPOPIAClauseIncludingReferenceToOperatorDetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control"
                                        id="coveredPOPIAClauseIncludingReferenceToOperatorDetails"
                                        formControlName="coveredPOPIAClauseIncludingReferenceToOperatorDetails"
                                        [class.is-invalid]="mainForm.controls.coveredPOPIAClauseIncludingReferenceToOperatorDetails.touched && mainForm.controls.coveredPOPIAClauseIncludingReferenceToOperatorDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredPOPIAClauseIncludingReferenceToOperatorDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredPOPIAClauseIncludingReferenceToOperatorDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Obligations </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredObligations"
                                        id="coveredObligationsYes" [value]="true" formControlName="coveredObligations"
                                        [class.is-invalid]="mainForm.controls.coveredObligations.touched && mainForm.controls.coveredObligations.invalid">
                                    <label class="form-check-label" for="coveredObligationsYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredObligations"
                                        id="coveredObligationsNo" [value]="false" formControlName="coveredObligations"
                                        [class.is-invalid]="mainForm.controls.coveredObligations.touched && mainForm.controls.coveredObligations.invalid">
                                    <label class="form-check-label" for="coveredObligationsNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredObligationsDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredObligationsDetails"
                                        formControlName="coveredObligationsDetails"
                                        [class.is-invalid]="mainForm.controls.coveredObligationsDetails.touched && mainForm.controls.coveredObligationsDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredObligationsDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredObligationsDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Contract fee and payment </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredContractFeeAndPayment"
                                        id="coveredContractFeeAndPaymentYes" [value]="true"
                                        formControlName="coveredContractFeeAndPayment"
                                        [class.is-invalid]="mainForm.controls.coveredContractFeeAndPayment.touched && mainForm.controls.coveredContractFeeAndPayment.invalid">
                                    <label class="form-check-label" for="coveredContractFeeAndPaymentYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredContractFeeAndPayment"
                                        id="coveredContractFeeAndPaymentNo" [value]="false"
                                        formControlName="coveredContractFeeAndPayment"
                                        [class.is-invalid]="mainForm.controls.coveredContractFeeAndPayment.touched && mainForm.controls.coveredContractFeeAndPayment.invalid">
                                    <label class="form-check-label" for="coveredContractFeeAndPaymentNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredContractFeeAndPaymentDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredContractFeeAndPaymentDetails"
                                        formControlName="coveredContractFeeAndPaymentDetails"
                                        [class.is-invalid]="mainForm.controls.coveredContractFeeAndPaymentDetails.touched && mainForm.controls.coveredContractFeeAndPaymentDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredContractFeeAndPaymentDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredContractFeeAndPaymentDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Confidentiality </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredConfidentiality"
                                        id="coveredConfidentialityYes" [value]="true"
                                        formControlName="coveredConfidentiality"
                                        [class.is-invalid]="mainForm.controls.coveredConfidentiality.touched && mainForm.controls.coveredConfidentiality.invalid">
                                    <label class="form-check-label" for="coveredConfidentialityYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredConfidentiality"
                                        id="coveredConfidentialityNo" [value]="false"
                                        formControlName="coveredConfidentiality"
                                        [class.is-invalid]="mainForm.controls.coveredConfidentiality.touched && mainForm.controls.coveredConfidentiality.invalid">
                                    <label class="form-check-label" for="coveredConfidentialityNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredConfidentialityDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredConfidentialityDetails"
                                        formControlName="coveredConfidentialityDetails"
                                        [class.is-invalid]="mainForm.controls.coveredConfidentialityDetails.touched && mainForm.controls.coveredConfidentialityDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredConfidentialityDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredConfidentialityDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Intellectual Property rights </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredIntellectualPropertyRights"
                                        id="coveredIntellectualPropertyRightsYes" [value]="true"
                                        formControlName="coveredIntellectualPropertyRights"
                                        [class.is-invalid]="mainForm.controls.coveredIntellectualPropertyRights.touched && mainForm.controls.coveredIntellectualPropertyRights.invalid">
                                    <label class="form-check-label"
                                        for="coveredIntellectualPropertyRightsYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredIntellectualPropertyRights"
                                        id="coveredIntellectualPropertyRightsNo" [value]="false"
                                        formControlName="coveredIntellectualPropertyRights"
                                        [class.is-invalid]="mainForm.controls.coveredIntellectualPropertyRights.touched && mainForm.controls.coveredIntellectualPropertyRights.invalid">
                                    <label class="form-check-label" for="coveredIntellectualPropertyRightsNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredIntellectualPropertyRightsDetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control"
                                        id="coveredIntellectualPropertyRightsDetails"
                                        formControlName="coveredIntellectualPropertyRightsDetails"
                                        [class.is-invalid]="mainForm.controls.coveredIntellectualPropertyRightsDetails.touched && mainForm.controls.coveredIntellectualPropertyRightsDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredIntellectualPropertyRightsDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredIntellectualPropertyRightsDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Trade marks </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredTrademarks"
                                        id="coveredTrademarksYes" [value]="true" formControlName="coveredTrademarks"
                                        [class.is-invalid]="mainForm.controls.coveredTrademarks.touched && mainForm.controls.coveredTrademarks.invalid">
                                    <label class="form-check-label" for="coveredTrademarksYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredTrademarks"
                                        id="coveredTrademarksNo" [value]="false" formControlName="coveredTrademarks"
                                        [class.is-invalid]="mainForm.controls.coveredTrademarks.touched && mainForm.controls.coveredTrademarks.invalid">
                                    <label class="form-check-label" for="coveredTrademarksNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredTrademarksDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredTrademarksDetails"
                                        formControlName="coveredTrademarksDetails"
                                        [class.is-invalid]="mainForm.controls.coveredTrademarksDetails.touched && mainForm.controls.coveredTrademarksDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredTrademarksDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredTrademarksDetails.errors.required">Required
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Designs </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredDesigns"
                                        id="coveredDesignsYes" [value]="true" formControlName="coveredDesigns"
                                        [class.is-invalid]="mainForm.controls.coveredDesigns.touched && mainForm.controls.coveredDesigns.invalid">
                                    <label class="form-check-label" for="coveredDesignsYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredDesigns"
                                        id="coveredDesignsNo" [value]="false" formControlName="coveredDesigns"
                                        [class.is-invalid]="mainForm.controls.coveredDesigns.touched && mainForm.controls.coveredDesigns.invalid">
                                    <label class="form-check-label" for="coveredDesignsNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredDesignsDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredDesignsDetails"
                                        formControlName="coveredDesignsDetails"
                                        [class.is-invalid]="mainForm.controls.coveredDesignsDetails.touched && mainForm.controls.coveredDesignsDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredDesignsDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredDesignsDetails.errors.required">Required
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Copyright </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredCopyright"
                                        id="coveredCopyrightYes" [value]="true" formControlName="coveredCopyright"
                                        [class.is-invalid]="mainForm.controls.coveredCopyright.touched && mainForm.controls.coveredCopyright.invalid">
                                    <label class="form-check-label" for="coveredCopyrightYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredCopyright"
                                        id="coveredCopyrightNo" [value]="false" formControlName="coveredCopyright"
                                        [class.is-invalid]="mainForm.controls.coveredCopyright.touched && mainForm.controls.coveredCopyright.invalid">
                                    <label class="form-check-label" for="coveredCopyrightNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredCopyrightDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredCopyrightDetails"
                                        formControlName="coveredCopyrightDetails"
                                        [class.is-invalid]="mainForm.controls.coveredCopyrightDetails.touched && mainForm.controls.coveredCopyrightDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredCopyrightDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredCopyrightDetails.errors.required">Required
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">IP protected under a no-compete clause </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredIPProtectedUnderNoCompeteClause"
                                        id="coveredIPProtectedUnderNoCompeteClauseYes" [value]="true"
                                        formControlName="coveredIPProtectedUnderNoCompeteClause"
                                        [class.is-invalid]="mainForm.controls.coveredIPProtectedUnderNoCompeteClause.touched && mainForm.controls.coveredIPProtectedUnderNoCompeteClause.invalid">
                                    <label class="form-check-label"
                                        for="coveredIPProtectedUnderNoCompeteClauseYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredIPProtectedUnderNoCompeteClause"
                                        id="coveredIPProtectedUnderNoCompeteClauseNo" [value]="false"
                                        formControlName="coveredIPProtectedUnderNoCompeteClause"
                                        [class.is-invalid]="mainForm.controls.coveredIPProtectedUnderNoCompeteClause.touched && mainForm.controls.coveredIPProtectedUnderNoCompeteClause.invalid">
                                    <label class="form-check-label"
                                        for="coveredIPProtectedUnderNoCompeteClauseNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredIPProtectedUnderNoCompeteClauseDetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control"
                                        id="coveredIPProtectedUnderNoCompeteClauseDetails"
                                        formControlName="coveredIPProtectedUnderNoCompeteClauseDetails"
                                        [class.is-invalid]="mainForm.controls.coveredIPProtectedUnderNoCompeteClauseDetails.touched && mainForm.controls.coveredIPProtectedUnderNoCompeteClauseDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredIPProtectedUnderNoCompeteClauseDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredIPProtectedUnderNoCompeteClauseDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Employee clause – no employment and restraint of trade </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredEmployeeClause"
                                        id="coveredEmployeeClauseYes" [value]="true"
                                        formControlName="coveredEmployeeClause"
                                        [class.is-invalid]="mainForm.controls.coveredEmployeeClause.touched && mainForm.controls.coveredEmployeeClause.invalid">
                                    <label class="form-check-label" for="coveredEmployeeClauseYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredEmployeeClause"
                                        id="coveredEmployeeClauseNo" [value]="false"
                                        formControlName="coveredEmployeeClause"
                                        [class.is-invalid]="mainForm.controls.coveredEmployeeClause.touched && mainForm.controls.coveredEmployeeClause.invalid">
                                    <label class="form-check-label" for="coveredEmployeeClauseNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredEmployeeClauseDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredEmployeeClauseDetails"
                                        formControlName="coveredEmployeeClauseDetails"
                                        [class.is-invalid]="mainForm.controls.coveredEmployeeClauseDetails.touched && mainForm.controls.coveredEmployeeClauseDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredEmployeeClauseDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredEmployeeClauseDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Independent contractor </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredIndependentContractor"
                                        id="coveredIndependentContractorYes" [value]="true"
                                        formControlName="coveredIndependentContractor"
                                        [class.is-invalid]="mainForm.controls.coveredIndependentContractor.touched && mainForm.controls.coveredIndependentContractor.invalid">
                                    <label class="form-check-label" for="coveredIndependentContractorYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredIndependentContractor"
                                        id="coveredIndependentContractorNo" [value]="false"
                                        formControlName="coveredIndependentContractor"
                                        [class.is-invalid]="mainForm.controls.coveredIndependentContractor.touched && mainForm.controls.coveredIndependentContractor.invalid">
                                    <label class="form-check-label" for="coveredIndependentContractorNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredIndependentContractorDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredIndependentContractorDetails"
                                        formControlName="coveredIndependentContractorDetails"
                                        [class.is-invalid]="mainForm.controls.coveredIndependentContractorDetails.touched && mainForm.controls.coveredIndependentContractorDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredIndependentContractorDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredIndependentContractorDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Insider trading clause </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredInsiderTradingClause"
                                        id="coveredInsiderTradingClauseYes" [value]="true"
                                        formControlName="coveredInsiderTradingClause"
                                        [class.is-invalid]="mainForm.controls.coveredInsiderTradingClause.touched && mainForm.controls.coveredInsiderTradingClause.invalid">
                                    <label class="form-check-label" for="coveredInsiderTradingClauseYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredInsiderTradingClause"
                                        id="coveredInsiderTradingClauseNo" [value]="false"
                                        formControlName="coveredInsiderTradingClause"
                                        [class.is-invalid]="mainForm.controls.coveredInsiderTradingClause.touched && mainForm.controls.coveredInsiderTradingClause.invalid">
                                    <label class="form-check-label" for="coveredInsiderTradingClauseNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredInsiderTradingClauseDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredInsiderTradingClauseDetails"
                                        formControlName="coveredInsiderTradingClauseDetails"
                                        [class.is-invalid]="mainForm.controls.coveredInsiderTradingClauseDetails.touched && mainForm.controls.coveredInsiderTradingClauseDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredInsiderTradingClauseDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredInsiderTradingClauseDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Database use and who owns </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredDatabaseUse"
                                        id="coveredDatabaseUseYes" [value]="true" formControlName="coveredDatabaseUse"
                                        [class.is-invalid]="mainForm.controls.coveredDatabaseUse.touched && mainForm.controls.coveredDatabaseUse.invalid">
                                    <label class="form-check-label" for="coveredDatabaseUseYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredDatabaseUse"
                                        id="coveredDatabaseUseNo" [value]="false" formControlName="coveredDatabaseUse"
                                        [class.is-invalid]="mainForm.controls.coveredDatabaseUse.touched && mainForm.controls.coveredDatabaseUse.invalid">
                                    <label class="form-check-label" for="coveredDatabaseUseNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredDatabaseUseDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredDatabaseUseDetails"
                                        formControlName="coveredDatabaseUseDetails"
                                        [class.is-invalid]="mainForm.controls.coveredDatabaseUseDetails.touched && mainForm.controls.coveredDatabaseUseDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredDatabaseUseDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredDatabaseUseDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Change management clause </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredChangeManagementClause"
                                        id="coveredChangeManagementClauseYes" [value]="true"
                                        formControlName="coveredChangeManagementClause"
                                        [class.is-invalid]="mainForm.controls.coveredChangeManagementClause.touched && mainForm.controls.coveredChangeManagementClause.invalid">
                                    <label class="form-check-label" for="coveredChangeManagementClauseYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredChangeManagementClause"
                                        id="coveredChangeManagementClauseNo" [value]="false"
                                        formControlName="coveredChangeManagementClause"
                                        [class.is-invalid]="mainForm.controls.coveredChangeManagementClause.touched && mainForm.controls.coveredChangeManagementClause.invalid">
                                    <label class="form-check-label" for="coveredChangeManagementClauseNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredChangeManagementClauseDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredChangeManagementClauseDetails"
                                        formControlName="coveredChangeManagementClauseDetails"
                                        [class.is-invalid]="mainForm.controls.coveredChangeManagementClauseDetails.touched && mainForm.controls.coveredChangeManagementClauseDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredChangeManagementClauseDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredChangeManagementClauseDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Management Intervention Clause </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredManagementInterventionClause"
                                        id="coveredManagementInterventionClauseYes" [value]="true"
                                        formControlName="coveredManagementInterventionClause"
                                        [class.is-invalid]="mainForm.controls.coveredManagementInterventionClause.touched && mainForm.controls.coveredManagementInterventionClause.invalid">
                                    <label class="form-check-label"
                                        for="coveredManagementInterventionClauseYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredManagementInterventionClause"
                                        id="coveredManagementInterventionClauseNo" [value]="false"
                                        formControlName="coveredManagementInterventionClause"
                                        [class.is-invalid]="mainForm.controls.coveredManagementInterventionClause.touched && mainForm.controls.coveredManagementInterventionClause.invalid">
                                    <label class="form-check-label"
                                        for="coveredManagementInterventionClauseNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredManagementInterventionClauseDetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control"
                                        id="coveredManagementInterventionClauseDetails"
                                        formControlName="coveredManagementInterventionClauseDetails"
                                        [class.is-invalid]="mainForm.controls.coveredManagementInterventionClauseDetails.touched && mainForm.controls.coveredManagementInterventionClauseDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredManagementInterventionClauseDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredManagementInterventionClauseDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Arbitration </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredArbitration"
                                        id="coveredArbitrationYes" [value]="true" formControlName="coveredArbitration"
                                        [class.is-invalid]="mainForm.controls.coveredArbitration.touched && mainForm.controls.coveredArbitration.invalid">
                                    <label class="form-check-label" for="coveredArbitrationYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredArbitration"
                                        id="coveredArbitrationNo" [value]="false" formControlName="coveredArbitration"
                                        [class.is-invalid]="mainForm.controls.coveredArbitration.touched && mainForm.controls.coveredArbitration.invalid">
                                    <label class="form-check-label" for="coveredArbitrationNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredArbitrationDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredArbitrationDetails"
                                        formControlName="coveredArbitrationDetails"
                                        [class.is-invalid]="mainForm.controls.coveredArbitrationDetails.touched && mainForm.controls.coveredArbitrationDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredArbitrationDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredArbitrationDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Merger, Acquisition Clause </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredMergerAcquisitionClause"
                                        id="coveredMergerAcquisitionClauseYes" [value]="true"
                                        formControlName="coveredMergerAcquisitionClause"
                                        [class.is-invalid]="mainForm.controls.coveredMergerAcquisitionClause.touched && mainForm.controls.coveredMergerAcquisitionClause.invalid">
                                    <label class="form-check-label" for="coveredMergerAcquisitionClauseYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredMergerAcquisitionClause"
                                        id="coveredMergerAcquisitionClauseNo" [value]="false"
                                        formControlName="coveredMergerAcquisitionClause"
                                        [class.is-invalid]="mainForm.controls.coveredMergerAcquisitionClause.touched && mainForm.controls.coveredMergerAcquisitionClause.invalid">
                                    <label class="form-check-label" for="coveredMergerAcquisitionClauseNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredMergerAcquisitionClauseDetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredMergerAcquisitionClauseDetails"
                                        formControlName="coveredMergerAcquisitionClauseDetails"
                                        [class.is-invalid]="mainForm.controls.coveredMergerAcquisitionClauseDetails.touched && mainForm.controls.coveredMergerAcquisitionClauseDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredMergerAcquisitionClauseDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredMergerAcquisitionClauseDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Options to renew or first right of refusal </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredOptionsToRenewOrFirstRightOfRefusal"
                                        id="coveredOptionsToRenewOrFirstRightOfRefusalYes" [value]="true"
                                        formControlName="coveredOptionsToRenewOrFirstRightOfRefusal"
                                        [class.is-invalid]="mainForm.controls.coveredOptionsToRenewOrFirstRightOfRefusal.touched && mainForm.controls.coveredOptionsToRenewOrFirstRightOfRefusal.invalid">
                                    <label class="form-check-label"
                                        for="coveredOptionsToRenewOrFirstRightOfRefusalYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredOptionsToRenewOrFirstRightOfRefusal"
                                        id="coveredOptionsToRenewOrFirstRightOfRefusalNo" [value]="false"
                                        formControlName="coveredOptionsToRenewOrFirstRightOfRefusal"
                                        [class.is-invalid]="mainForm.controls.coveredOptionsToRenewOrFirstRightOfRefusal.touched && mainForm.controls.coveredOptionsToRenewOrFirstRightOfRefusal.invalid">
                                    <label class="form-check-label"
                                        for="coveredOptionsToRenewOrFirstRightOfRefusalNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredOptionsToRenewOrFirstRightOfRefusalDetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control"
                                        id="coveredOptionsToRenewOrFirstRightOfRefusalDetails"
                                        formControlName="coveredOptionsToRenewOrFirstRightOfRefusalDetails"
                                        [class.is-invalid]="mainForm.controls.coveredOptionsToRenewOrFirstRightOfRefusalDetails.touched && mainForm.controls.coveredOptionsToRenewOrFirstRightOfRefusalDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredOptionsToRenewOrFirstRightOfRefusalDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredOptionsToRenewOrFirstRightOfRefusalDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Costs and Escalations </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredCostsAndEscalations"
                                        id="coveredCostsAndEscalationsYes" [value]="true"
                                        formControlName="coveredCostsAndEscalations"
                                        [class.is-invalid]="mainForm.controls.coveredCostsAndEscalations.touched && mainForm.controls.coveredCostsAndEscalations.invalid">
                                    <label class="form-check-label" for="coveredCostsAndEscalationsYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredCostsAndEscalations"
                                        id="coveredCostsAndEscalationsNo" [value]="false"
                                        formControlName="coveredCostsAndEscalations"
                                        [class.is-invalid]="mainForm.controls.coveredCostsAndEscalations.touched && mainForm.controls.coveredCostsAndEscalations.invalid">
                                    <label class="form-check-label" for="coveredCostsAndEscalationsNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredCostsAndEscalationsDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredCostsAndEscalationsDetails"
                                        formControlName="coveredCostsAndEscalationsDetails"
                                        [class.is-invalid]="mainForm.controls.coveredCostsAndEscalationsDetails.touched && mainForm.controls.coveredCostsAndEscalationsDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredCostsAndEscalationsDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredCostsAndEscalationsDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Warranties </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredWarranties"
                                        id="coveredWarrantiesYes" [value]="true" formControlName="coveredWarranties"
                                        [class.is-invalid]="mainForm.controls.coveredWarranties.touched && mainForm.controls.coveredWarranties.invalid">
                                    <label class="form-check-label" for="coveredWarrantiesYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredWarranties"
                                        id="coveredWarrantiesNo" [value]="false" formControlName="coveredWarranties"
                                        [class.is-invalid]="mainForm.controls.coveredWarranties.touched && mainForm.controls.coveredWarranties.invalid">
                                    <label class="form-check-label" for="coveredWarrantiesNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredWarrantiesDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredWarrantiesDetails"
                                        formControlName="coveredWarrantiesDetails"
                                        [class.is-invalid]="mainForm.controls.coveredWarrantiesDetails.touched && mainForm.controls.coveredWarrantiesDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredWarrantiesDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredWarrantiesDetails.errors.required">Required
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Guaranties </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredGuaranties"
                                        id="coveredGuarantiesYes" [value]="true" formControlName="coveredGuaranties"
                                        [class.is-invalid]="mainForm.controls.coveredGuaranties.touched && mainForm.controls.coveredGuaranties.invalid">
                                    <label class="form-check-label" for="coveredGuarantiesYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredGuaranties"
                                        id="coveredGuarantiesNo" [value]="false" formControlName="coveredGuaranties"
                                        [class.is-invalid]="mainForm.controls.coveredGuaranties.touched && mainForm.controls.coveredGuaranties.invalid">
                                    <label class="form-check-label" for="coveredGuarantiesNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredGuarantiesDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredGuarantiesDetails"
                                        formControlName="coveredGuarantiesDetails"
                                        [class.is-invalid]="mainForm.controls.coveredGuarantiesDetails.touched && mainForm.controls.coveredGuarantiesDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredGuarantiesDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredGuarantiesDetails.errors.required">Required
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Insurance </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredInsurance"
                                        id="coveredInsuranceYes" [value]="true" formControlName="coveredInsurance"
                                        [class.is-invalid]="mainForm.controls.coveredInsurance.touched && mainForm.controls.coveredInsurance.invalid">
                                    <label class="form-check-label" for="coveredInsuranceYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredInsurance"
                                        id="coveredInsuranceNo" [value]="false" formControlName="coveredInsurance"
                                        [class.is-invalid]="mainForm.controls.coveredInsurance.touched && mainForm.controls.coveredInsurance.invalid">
                                    <label class="form-check-label" for="coveredInsuranceNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredInsuranceDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredInsuranceDetails"
                                        formControlName="coveredInsuranceDetails"
                                        [class.is-invalid]="mainForm.controls.coveredInsuranceDetails.touched && mainForm.controls.coveredInsuranceDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredInsuranceDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredInsuranceDetails.errors.required">Required
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Indemnities </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredIndemnities"
                                        id="coveredIndemnitiesYes" [value]="true" formControlName="coveredIndemnities"
                                        [class.is-invalid]="mainForm.controls.coveredIndemnities.touched && mainForm.controls.coveredIndemnities.invalid">
                                    <label class="form-check-label" for="coveredIndemnitiesYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredIndemnities"
                                        id="coveredIndemnitiesNo" [value]="false" formControlName="coveredIndemnities"
                                        [class.is-invalid]="mainForm.controls.coveredIndemnities.touched && mainForm.controls.coveredIndemnities.invalid">
                                    <label class="form-check-label" for="coveredIndemnitiesNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredIndemnitiesDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredIndemnitiesDetails"
                                        formControlName="coveredIndemnitiesDetails"
                                        [class.is-invalid]="mainForm.controls.coveredIndemnitiesDetails.touched && mainForm.controls.coveredIndemnitiesDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredIndemnitiesDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredIndemnitiesDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Notice and domicilium or place for service </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredNoticeAndDomicilium"
                                        id="coveredNoticeAndDomiciliumYes" [value]="true"
                                        formControlName="coveredNoticeAndDomicilium"
                                        [class.is-invalid]="mainForm.controls.coveredNoticeAndDomicilium.touched && mainForm.controls.coveredNoticeAndDomicilium.invalid">
                                    <label class="form-check-label" for="coveredNoticeAndDomiciliumYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredNoticeAndDomicilium"
                                        id="coveredNoticeAndDomiciliumNo" [value]="false"
                                        formControlName="coveredNoticeAndDomicilium"
                                        [class.is-invalid]="mainForm.controls.coveredNoticeAndDomicilium.touched && mainForm.controls.coveredNoticeAndDomicilium.invalid">
                                    <label class="form-check-label" for="coveredNoticeAndDomiciliumNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredNoticeAndDomiciliumDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredNoticeAndDomiciliumDetails"
                                        formControlName="coveredNoticeAndDomiciliumDetails"
                                        [class.is-invalid]="mainForm.controls.coveredNoticeAndDomiciliumDetails.touched && mainForm.controls.coveredNoticeAndDomiciliumDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredNoticeAndDomiciliumDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredNoticeAndDomiciliumDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Electronic signature </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredElectronicSignature"
                                        id="coveredElectronicSignatureYes" [value]="true"
                                        formControlName="coveredElectronicSignature"
                                        [class.is-invalid]="mainForm.controls.coveredElectronicSignature.touched && mainForm.controls.coveredElectronicSignature.invalid">
                                    <label class="form-check-label" for="coveredElectronicSignatureYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredElectronicSignature"
                                        id="coveredElectronicSignatureNo" [value]="false"
                                        formControlName="coveredElectronicSignature"
                                        [class.is-invalid]="mainForm.controls.coveredElectronicSignature.touched && mainForm.controls.coveredElectronicSignature.invalid">
                                    <label class="form-check-label" for="coveredElectronicSignatureNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredElectronicSignatureDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredElectronicSignatureDetails"
                                        formControlName="coveredElectronicSignatureDetails"
                                        [class.is-invalid]="mainForm.controls.coveredElectronicSignatureDetails.touched && mainForm.controls.coveredElectronicSignatureDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredElectronicSignatureDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredElectronicSignatureDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Breach and right to terminate </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredBreachAndRightToTerminate"
                                        id="coveredBreachAndRightToTerminateYes" [value]="true"
                                        formControlName="coveredBreachAndRightToTerminate"
                                        [class.is-invalid]="mainForm.controls.coveredBreachAndRightToTerminate.touched && mainForm.controls.coveredBreachAndRightToTerminate.invalid">
                                    <label class="form-check-label"
                                        for="coveredBreachAndRightToTerminateYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredBreachAndRightToTerminate"
                                        id="coveredBreachAndRightToTerminateNo" [value]="false"
                                        formControlName="coveredBreachAndRightToTerminate"
                                        [class.is-invalid]="mainForm.controls.coveredBreachAndRightToTerminate.touched && mainForm.controls.coveredBreachAndRightToTerminate.invalid">
                                    <label class="form-check-label" for="coveredBreachAndRightToTerminateNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredBreachAndRightToTerminateDetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredBreachAndRightToTerminateDetails"
                                        formControlName="coveredBreachAndRightToTerminateDetails"
                                        [class.is-invalid]="mainForm.controls.coveredBreachAndRightToTerminateDetails.touched && mainForm.controls.coveredBreachAndRightToTerminateDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredBreachAndRightToTerminateDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredBreachAndRightToTerminateDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Tax provisions </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredTaxProvisions"
                                        id="coveredTaxProvisionsYes" [value]="true"
                                        formControlName="coveredTaxProvisions"
                                        [class.is-invalid]="mainForm.controls.coveredTaxProvisions.touched && mainForm.controls.coveredTaxProvisions.invalid">
                                    <label class="form-check-label" for="coveredTaxProvisionsYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredTaxProvisions"
                                        id="coveredTaxProvisionsNo" [value]="false"
                                        formControlName="coveredTaxProvisions"
                                        [class.is-invalid]="mainForm.controls.coveredTaxProvisions.touched && mainForm.controls.coveredTaxProvisions.invalid">
                                    <label class="form-check-label" for="coveredTaxProvisionsNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredTaxProvisionsDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredTaxProvisionsDetails"
                                        formControlName="coveredTaxProvisionsDetails"
                                        [class.is-invalid]="mainForm.controls.coveredTaxProvisionsDetails.touched && mainForm.controls.coveredTaxProvisionsDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredTaxProvisionsDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredTaxProvisionsDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Applicable law and Jurisdiction </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredApplicableLawAndJurisdiction"
                                        id="coveredApplicableLawAndJurisdictionYes" [value]="true"
                                        formControlName="coveredApplicableLawAndJurisdiction"
                                        [class.is-invalid]="mainForm.controls.coveredApplicableLawAndJurisdiction.touched && mainForm.controls.coveredApplicableLawAndJurisdiction.invalid">
                                    <label class="form-check-label"
                                        for="coveredApplicableLawAndJurisdictionYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredApplicableLawAndJurisdiction"
                                        id="coveredApplicableLawAndJurisdictionNo" [value]="false"
                                        formControlName="coveredApplicableLawAndJurisdiction"
                                        [class.is-invalid]="mainForm.controls.coveredApplicableLawAndJurisdiction.touched && mainForm.controls.coveredApplicableLawAndJurisdiction.invalid">
                                    <label class="form-check-label"
                                        for="coveredApplicableLawAndJurisdictionNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredApplicableLawAndJurisdictionDetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control"
                                        id="coveredApplicableLawAndJurisdictionDetails"
                                        formControlName="coveredApplicableLawAndJurisdictionDetails"
                                        [class.is-invalid]="mainForm.controls.coveredApplicableLawAndJurisdictionDetails.touched && mainForm.controls.coveredApplicableLawAndJurisdictionDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredApplicableLawAndJurisdictionDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredApplicableLawAndJurisdictionDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Legislation –SHE and sec 37 Indemnity </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredLegislationSHEAndSec37Indemnity"
                                        id="coveredLegislationSHEAndSec37IndemnityYes" [value]="true"
                                        formControlName="coveredLegislationSHEAndSec37Indemnity"
                                        [class.is-invalid]="mainForm.controls.coveredLegislationSHEAndSec37Indemnity.touched && mainForm.controls.coveredLegislationSHEAndSec37Indemnity.invalid">
                                    <label class="form-check-label"
                                        for="coveredLegislationSHEAndSec37IndemnityYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredLegislationSHEAndSec37Indemnity"
                                        id="coveredLegislationSHEAndSec37IndemnityNo" [value]="false"
                                        formControlName="coveredLegislationSHEAndSec37Indemnity"
                                        [class.is-invalid]="mainForm.controls.coveredLegislationSHEAndSec37Indemnity.touched && mainForm.controls.coveredLegislationSHEAndSec37Indemnity.invalid">
                                    <label class="form-check-label"
                                        for="coveredLegislationSHEAndSec37IndemnityNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredLegislationSHEAndSec37IndemnityDetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control"
                                        id="coveredLegislationSHEAndSec37IndemnityDetails"
                                        formControlName="coveredLegislationSHEAndSec37IndemnityDetails"
                                        [class.is-invalid]="mainForm.controls.coveredLegislationSHEAndSec37IndemnityDetails.touched && mainForm.controls.coveredLegislationSHEAndSec37IndemnityDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredLegislationSHEAndSec37IndemnityDetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredLegislationSHEAndSec37IndemnityDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Legislation- Environmental – EIA </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredLegislationEnvironmentalEIA"
                                        id="coveredLegislationEnvironmentalEIAYes" [value]="true"
                                        formControlName="coveredLegislationEnvironmentalEIA"
                                        [class.is-invalid]="mainForm.controls.coveredLegislationEnvironmentalEIA.touched && mainForm.controls.coveredLegislationEnvironmentalEIA.invalid">
                                    <label class="form-check-label"
                                        for="coveredLegislationEnvironmentalEIAYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"
                                        name="coveredLegislationEnvironmentalEIA"
                                        id="coveredLegislationEnvironmentalEIANo" [value]="false"
                                        formControlName="coveredLegislationEnvironmentalEIA"
                                        [class.is-invalid]="mainForm.controls.coveredLegislationEnvironmentalEIA.touched && mainForm.controls.coveredLegislationEnvironmentalEIA.invalid">
                                    <label class="form-check-label"
                                        for="coveredLegislationEnvironmentalEIANo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredLegislationEnvironmentalEIADetails"
                                        class="form-label">Details</label>
                                    <input type="text" class="form-control"
                                        id="coveredLegislationEnvironmentalEIADetails"
                                        formControlName="coveredLegislationEnvironmentalEIADetails"
                                        [class.is-invalid]="mainForm.controls.coveredLegislationEnvironmentalEIADetails.touched && mainForm.controls.coveredLegislationEnvironmentalEIADetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredLegislationEnvironmentalEIADetails.errors">
                                        <div
                                            *ngIf="mainForm.controls.coveredLegislationEnvironmentalEIADetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Legislation – other </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredLegislationOther"
                                        id="coveredLegislationOtherYes" [value]="true"
                                        formControlName="coveredLegislationOther"
                                        [class.is-invalid]="mainForm.controls.coveredLegislationOther.touched && mainForm.controls.coveredLegislationOther.invalid">
                                    <label class="form-check-label" for="coveredLegislationOtherYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredLegislationOther"
                                        id="coveredLegislationOtherNo" [value]="false"
                                        formControlName="coveredLegislationOther"
                                        [class.is-invalid]="mainForm.controls.coveredLegislationOther.touched && mainForm.controls.coveredLegislationOther.invalid">
                                    <label class="form-check-label" for="coveredLegislationOtherNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredLegislationOtherDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredLegislationOtherDetails"
                                        formControlName="coveredLegislationOtherDetails"
                                        [class.is-invalid]="mainForm.controls.coveredLegislationOtherDetails.touched && mainForm.controls.coveredLegislationOtherDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredLegislationOtherDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredLegislationOtherDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Annexures </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredAnnexures"
                                        id="coveredAnnexuresYes" [value]="true" formControlName="coveredAnnexures"
                                        [class.is-invalid]="mainForm.controls.coveredAnnexures.touched && mainForm.controls.coveredAnnexures.invalid">
                                    <label class="form-check-label" for="coveredAnnexuresYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredAnnexures"
                                        id="coveredAnnexuresNo" [value]="false" formControlName="coveredAnnexures"
                                        [class.is-invalid]="mainForm.controls.coveredAnnexures.touched && mainForm.controls.coveredAnnexures.invalid">
                                    <label class="form-check-label" for="coveredAnnexuresNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredAnnexuresDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredAnnexuresDetails"
                                        formControlName="coveredAnnexuresDetails"
                                        [class.is-invalid]="mainForm.controls.coveredAnnexuresDetails.touched && mainForm.controls.coveredAnnexuresDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredAnnexuresDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredAnnexuresDetails.errors.required">Required
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Service levels </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredServiceLevels"
                                        id="coveredServiceLevelsYes" [value]="true"
                                        formControlName="coveredServiceLevels"
                                        [class.is-invalid]="mainForm.controls.coveredServiceLevels.touched && mainForm.controls.coveredServiceLevels.invalid">
                                    <label class="form-check-label" for="coveredServiceLevelsYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredServiceLevels"
                                        id="coveredServiceLevelsNo" [value]="false"
                                        formControlName="coveredServiceLevels"
                                        [class.is-invalid]="mainForm.controls.coveredServiceLevels.touched && mainForm.controls.coveredServiceLevels.invalid">
                                    <label class="form-check-label" for="coveredServiceLevelsNo">No</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredServiceLevelsDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredServiceLevelsDetails"
                                        formControlName="coveredServiceLevelsDetails"
                                        [class.is-invalid]="mainForm.controls.coveredServiceLevelsDetails.touched && mainForm.controls.coveredServiceLevelsDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredServiceLevelsDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredServiceLevelsDetails.errors.required">
                                            Required</div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="fw-bold">Other </td>
                            <td class="d-flex justify-content-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredOther"
                                        id="coveredOtherYes" [value]="true" formControlName="coveredOther"
                                        [class.is-invalid]="mainForm.controls.coveredOther.touched && mainForm.controls.coveredOther.invalid">
                                    <label class="form-check-label" for="coveredOtherYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="coveredOther" id="coveredOtherNo"
                                        [value]="false" formControlName="coveredOther"
                                        [class.is-invalid]="mainForm.controls.coveredOther.touched && mainForm.controls.coveredOther.invalid">
                                    <label class="form-check-label" for="coveredOtherNo">No</label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="mb-3">
                                    <label for="coveredOtherDetails" class="form-label">Details</label>
                                    <input type="text" class="form-control" id="coveredOtherDetails"
                                        formControlName="coveredOtherDetails"
                                        [class.is-invalid]="mainForm.controls.coveredOtherDetails.touched && mainForm.controls.coveredOtherDetails.invalid">
                                    <div class="invalid-feedback text-end"
                                        *ngIf="mainForm.controls.coveredOtherDetails.errors">
                                        <div *ngIf="mainForm.controls.coveredOtherDetails.errors.required">Required
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </tbody>

                </table>
            </div>
        </div>






        <div class="card mb-3">
            <div class="card-body">
                <h4 class="card-title">Other</h4>

                <div class="mb-3">
                    <label for="riskAndOnerousClauses" class="form-label">Risk and onerous clauses to keep an eye
                        on</label>
                    <input type="text" class="form-control" id="riskAndOnerousClauses"
                        formControlName="riskAndOnerousClauses"
                        [class.is-invalid]="mainForm.controls.riskAndOnerousClauses.touched && mainForm.controls.riskAndOnerousClauses.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.riskAndOnerousClauses.errors">
                        <div *ngIf="mainForm.controls.riskAndOnerousClauses.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="complianceActions" class="form-label">Compliance Actions</label>
                    <input type="text" class="form-control" id="complianceActions" formControlName="complianceActions"
                        [class.is-invalid]="mainForm.controls.complianceActions.touched && mainForm.controls.complianceActions.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.complianceActions.errors">
                        <div *ngIf="mainForm.controls.complianceActions.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="serviceLevels" class="form-label">Service Levels</label>
                    <input type="text" class="form-control" id="serviceLevels" formControlName="serviceLevels"
                        [class.is-invalid]="mainForm.controls.serviceLevels.touched && mainForm.controls.serviceLevels.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.serviceLevels.errors">
                        <div *ngIf="mainForm.controls.serviceLevels.errors.required">Required</div>
                    </div>
                </div>


                <div class="mb-3">
                    <label for="milestones" class="form-label">Milestones</label>
                    <input type="text" class="form-control" id="milestones" formControlName="milestones"
                        [class.is-invalid]="mainForm.controls.milestones.touched && mainForm.controls.milestones.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.milestones.errors">
                        <div *ngIf="mainForm.controls.milestones.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="approvedByLegal" class="form-label">Contract Approved By Legal</label>
                    <td>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="approvedByLegal" id="approvedByLegalYes"
                                [value]="true" formControlName="approvedByLegal"
                                [class.is-invalid]="mainForm.controls.approvedByLegal.touched && mainForm.controls.approvedByLegal.invalid">
                            <label class="form-check-label" for="approvedByLegalYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="approvedByLegal" id="approvedByLegalNo"
                                [value]="false" formControlName="approvedByLegal"
                                [class.is-invalid]="mainForm.controls.approvedByLegal.touched && mainForm.controls.approvedByLegal.invalid">
                            <label class="form-check-label" for="approvedByLegalNo">No</label>
                        </div>
                    </td>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.approvedByLegal.errors">
                        <div *ngIf="mainForm.controls.approvedByLegal.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="signedByBothParties" class="form-label">Contract Signed by all parties</label>
                    <td>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="signedByBothParties"
                                id="signedByBothPartiesYes" [value]="true" formControlName="signedByBothParties"
                                [class.is-invalid]="mainForm.controls.signedByBothParties.touched && mainForm.controls.signedByBothParties.invalid">
                            <label class="form-check-label" for="signedByBothPartiesYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="signedByBothParties"
                                id="signedByBothPartiesNo" [value]="false" formControlName="signedByBothParties"
                                [class.is-invalid]="mainForm.controls.signedByBothParties.touched && mainForm.controls.signedByBothParties.invalid">
                            <label class="form-check-label" for="signedByBothPartiesNo">No</label>
                        </div>
                    </td>
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.signedByBothParties.errors">
                        <div *ngIf="mainForm.controls.signedByBothParties.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="signedNames" class="form-label">Names</label>
                    <input type="text" class="form-control" id="signedNames" formControlName="signedNames"
                        [class.is-invalid]="mainForm.controls.signedNames.touched && mainForm.controls.signedNames.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.signedNames.errors">
                        <div *ngIf="mainForm.controls.signedNames.errors.required">Required</div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="signedDate" class="form-label">Date</label>
                    <input type="date" class="form-control" id="signedDate" formControlName="signedDate"
                        [class.is-invalid]="mainForm.controls.signedDate.touched && mainForm.controls.signedDate.invalid">
                    <div class="invalid-feedback text-end" *ngIf="mainForm.controls.signedDate.errors">
                        <div *ngIf="mainForm.controls.signedDate.errors.required">Required</div>
                    </div>
                </div>



            </div>
        </div>





        <div class="text-end">
            <button type="button" class="btn btn-default"
                routerLink="/dashboard/documents/contracts">Cancel</button>&nbsp;
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
    </form>




</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Send Correspondence</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="correspondenceForm" (ngSubmit)="submitCorrespondence()">

            <div class="mb-3">
                <label for="documentCorrespondenceTypeId" class="form-label">Type</label>
                <ng-select [items]="documentCorrespondenceTypes" bindLabel="name" bindValue="id"
                    id="documentCorrespondenceTypeId"
                    [class.is-invalid]="correspondenceForm.controls.documentCorrespondenceTypeId.touched && correspondenceForm.controls.documentCorrespondenceTypeId.invalid"
                    formControlName="documentCorrespondenceTypeId" placeholder="Select a template"></ng-select>
                <div class="invalid-feedback text-end"
                    *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors">
                    <div *ngIf="correspondenceForm.controls.documentCorrespondenceTypeId.errors.required">Required</div>
                </div>

            </div>

            <div class="mb-3">
                <label for="sender" class="form-label">Sender</label>
                <input type="text" class="form-control" id="sender" formControlName="sender"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.sender.touched) && correspondenceForm.controls.sender.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.sender.errors">
                    <div *ngIf="correspondenceForm.controls.sender.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.name.touched) && correspondenceForm.controls.name.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.name.errors">
                    <div *ngIf="correspondenceForm.controls.name.errors.required">Required</div>
                </div>
            </div>
            <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input type="text" class="form-control" id="email" formControlName="email"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.email.touched) && correspondenceForm.controls.email.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.email.errors">
                    <div *ngIf="correspondenceForm.controls.email.errors.required">Required</div>
                    <div *ngIf="correspondenceForm.controls.email.errors.email">Invalid</div>
                </div>
            </div>

            <div class="mb-3">
                <label for="documentFileData" class="form-label">Document</label>
                <input type="file" class="form-control" id="documentFileData" formControlName="documentFileData"
                    (change)="fileUpload(correspondenceForm.controls.documentFileData, $event)"
                    [class.is-invalid]="(correspondenceFormSubmitted || correspondenceForm.controls.documentFileData.touched) && correspondenceForm.controls.documentFileData.invalid">
                <div class="invalid-feedback text-end" *ngIf="correspondenceForm.controls.documentFileData.errors">
                    <div *ngIf="correspondenceForm.controls.documentFileData.errors.required">Required</div>
                </div>
            </div>

            <div *ngFor="let attachment of correspondenceForm.controls.attachments.controls; let i = index"
                formArrayName="attachments">
                <div class="mb-3">
                    <label for="attachmentFileData" class="form-label">Attachment {{i+1}}</label>
                    <div class="input-group">
                        <input type="file" class="form-control" id="attachmentFileData" [formControl]="attachment"
                            (change)="fileUpload(attachment, $event)"
                            [class.is-invalid]="(correspondenceFormSubmitted || attachment.touched) && attachment.invalid">
                        <button type="button" class="btn btn-danger" (click)="removeAttachement(i)">X</button>
                    </div>
                    <div class="invalid-feedback text-end" *ngIf="attachment.errors">
                        <div *ngIf="attachment.errors.required">Required</div>
                    </div>

                </div>

            </div>
            <div class="text-end">
                <button type="button" class="btn btn-link" (click)="addAttachement()">+ Add Attachment</button>
            </div>


            <div class="text-end">
                <button type="button" class="btn btn-default" (click)="modal.dismiss()">Cancel</button>&nbsp;
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>

</ng-template>