<div class="alert-container">
  <div class="container">
    <div *ngIf="message" class="d-flex justify-content-between align-items-center alert alert-dismissable fade show" [ngClass]="{ 'alert-success': message.type === 'success', 'alert-danger': message.type === 'error' }" role="alert">
      {{message.text}}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      
    </div>

    
    
    
  </div>
</div>

