import { Component, OnInit } from '@angular/core';
import { AccountApiService } from '../../../services/account-api.service';
import { SpinnerService } from '../../../services/spinner.service';
import { UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService } from '../../../services/alert.service';
import { Profile } from 'src/app/models/profile';
import { ChangePassword } from 'src/app/models/changePassword';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profile: Profile;
  roles: string;

  profileSubmitted =  false;
  profileForm = this.formBuilder.group({
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    phoneNumber: ['']
  });

  passwordSubmitted =  false;
  passwordForm = this.formBuilder.group({
    oldPassword: ['', Validators.required],
    newPassword: ['', Validators.required],
    newPasswordConfirm: ['', Validators.required]
  });

  constructor(
    private accountApi: AccountApiService,
    private spinner: SpinnerService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.spinner.startBackground();
    this.accountApi.getProfile().subscribe(data => {
      this.profile = data;

      this.updateForm();

      this.spinner.stopBackground();
    });
  }

  updateForm() {
    this.profileForm.patchValue({
      firstname: this.profile.firstname,
      lastname: this.profile.lastname,
      phoneNumber: this.profile.phoneNumber
    });

    this.roles = this.profile.roles.join(', ');
  }

  updateProfile() {

    this.profileSubmitted = true;

    if (this.profileForm.invalid){
      return;
    }

    this.profile.firstname = this.profileForm.controls.firstname.value;
    this.profile.lastname = this.profileForm.controls.lastname.value;
    this.profile.phoneNumber = this.profileForm.controls.phoneNumber.value;


    this.spinner.startBackground();
    this.accountApi.updateProfile(this.profile).subscribe(data => {
      this.profile = data;
      this.updateForm();
      this.alertService.success('Profile updated', false);
      this.spinner.stopBackground();
    });

  }

  changePassword() {

    this.passwordSubmitted = true;

    if (this.passwordForm.invalid){
      return;
    }


    let data: ChangePassword = {
      newPassword: this.passwordForm.controls.newPassword.value,
      oldPassword: this.passwordForm.controls.oldPassword.value
    };
    this.accountApi.changePassword(data).subscribe(data => {
      if (data) {
        this.alertService.success('Password successfully updated');
        this.passwordForm.reset();
      }
      else {
        this.alertService.error('Password could not be updated, please ensure you entered the correct Old Password and that your new password conform the the minimum requirements (6 characters, lowercase, uppercase, digit and non-alphanumeric) ');
      }
    });
  }

  tabChange(evt) {

    if (evt.activeId == 'ngb-tab-0') {
      window.location.hash = 'changepassword';
    }
    else {
      window.location.hash = '';
    }
  }
}
