import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DocumentFacility, DocumentRegion, PaiaDocument, PaiaDocumentAppealStatus, PaiaDocumentReferral, PaiaDocumentReminder, PaiaDocumentResult, PaiaDocumentStatus, UpdatePaiaDocumentNotes } from 'src/app/models/documents';
import { DocumentsApiService } from 'src/app/services/documents-api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-paia',
  templateUrl: './paia.component.html',
  styleUrl: './paia.component.scss'
})
export class PaiaComponent implements OnInit {

  modalRef: NgbModalRef;
  
  companyId: number;
  orgId: number;
  apiCompanyId?: number;

  userId: number;
  user: boolean = false;
  contractAdmin: boolean = false;
  companyAdmin: boolean = false;
  orgAdmin: boolean = false;
  superAdmin: boolean = false;

  result: PaiaDocumentResult;

  firstEnabled: boolean = false;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  lastEnabled: boolean = false;

  currentPage: number = 1;

  perPageItems = [5, 10, 20, 50, 100];
  perPage = 10;

  filterDecision: boolean = undefined;
  filterStatusId: number = undefined;
  filterRegionId: number = undefined;
  filterFacilityId: number = undefined;
  filterAccessGranted: boolean = undefined;
  filterConsent: boolean = undefined;
  filterNewOrExisting: boolean = undefined;
  filterFrom: Date = undefined;
  filterTo: Date = undefined;
  filterAppealStatusId: number = undefined;
  filterPaiaDocumentReferralId: number = undefined;

  statuses: PaiaDocumentStatus[];
  appealStatuses: PaiaDocumentAppealStatus[];
  regions: DocumentRegion[];
  facilities: DocumentFacility[];
  referrals: PaiaDocumentReferral[];

  reminderForm = new FormGroup({
    reminderDateTime: new FormControl('', [Validators.required]),
    reminderText: new FormControl('', [Validators.required])
  });

  constructor(private token: TokenService, private router: Router, private ngZone: NgZone, private route: ActivatedRoute, private documentsApi: DocumentsApiService, private spinner: SpinnerService, private modalService: NgbModal) {

  }

  ngOnInit() {

    this.userId = this.token.userId();
    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    this.companyAdmin = this.token.isCompanyAdmin();
    this.orgAdmin = this.token.isOrganisationAdmin();
    this.superAdmin = this.token.isSuperAdmin();
    
    if (this.companyAdmin || this.user || this.contractAdmin) {
      this.companyId = this.token.companyId();
      this.apiCompanyId = this.companyId;
      if (this.companyId) {
        this.init();
      }

    }
    else if (this.superAdmin || this.orgAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.init();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/home');
    }
  }

  init() {

    if (this.superAdmin) {
      this.orgId = parseInt(localStorage.getItem('SELECTED_ORGANISATION'));
    }
    else {
      this.orgId = this.token.organisationId();
    }

    this.documentsApi.GetPaiaStatuses().subscribe(data => {
      this.statuses = data;
    });

    this.documentsApi.GetPaiaAppealStatuses().subscribe(data => {
      this.appealStatuses = data;
    });

    this.documentsApi.GetRegions().subscribe(data => {
      this.regions = data;
    });

    this.documentsApi.GetPaiaReferrals().subscribe(data => {
      this.referrals = data;
    });

    this.getData();


  }

  getFacilities() {


    if (this.filterRegionId) {
      this.documentsApi.GetFacilities(this.filterRegionId).subscribe(data => {
        this.facilities = data;
      });
    }
    else {
      this.filterFacilityId = undefined;
      this.facilities = undefined;;
    }

    this.getData();
  }
  getData() {
    console.log(this.filterStatusId, this.filterDecision)
    this.documentsApi.GetPaiaDocuments(this.apiCompanyId, this.filterStatusId, this.filterDecision, this.filterRegionId, this.filterFacilityId, this.filterAppealStatusId, this.filterAccessGranted, this.filterConsent, this.filterNewOrExisting, this.filterPaiaDocumentReferralId, this.filterFrom, this.filterTo, this.perPage, this.currentPage).subscribe(data => {
      this.result = data;
    })
  }

  exportData(){
    this.spinner.startBackground();
    this.documentsApi.exportPaiaDocuments(this.apiCompanyId, this.filterStatusId, this.filterDecision, this.filterRegionId, this.filterFacilityId, this.filterAppealStatusId, this.filterAccessGranted, this.filterConsent, this.filterNewOrExisting, this.filterPaiaDocumentReferralId, this.filterFrom, this.filterTo, this.perPage, this.currentPage).subscribe(data => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }

  companySelected(company) {

    if (company) {
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.init();
    }
    else {

    }
  }

  download(ev: Event, id: number, file: string) {

    this.spinner.startBackground();

    ev.preventDefault();
    this.documentsApi.GetPaiaDocumentFile(id, this.apiCompanyId, file).subscribe(data => {

      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();

    })
  }

  first() {
    this.currentPage = 1;
    this.getData();
  }

  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.getData();
  }

  next() {
    if (this.currentPage < this.result.totalPages) {
      this.currentPage++;
    }

    this.getData();
  }

  last() {
    this.currentPage = this.result.totalPages;
    this.getData();
  }

  perPageChange(e: Event) {
    localStorage["PER_PAGE"] = this.perPage;
    this.currentPage = 1;
    this.getData();
  }

  getDecisionLabel(decision: number) {
    switch (decision) {
      case -1:
        return 'PARTIALLY';
      case 0:
        return 'DECLINED';
      case 1:
        return 'APPROVED';
    }

    return '';
  }

  visibleId: number;

  showHide(id) {

    if (this.visibleId == id) {
      this.visibleId = undefined;
    }
    else {
      this.visibleId = id;
    }
  }

  truncate(str?: string) {
    if (!str) {
      return str;
    }
    if (str && str.length > 40)
      return str.substring(0, 40) + '...';
    else
      return str;
  };

  public currentReminders: PaiaDocumentReminder[];
  public currentReminder: PaiaDocumentReminder;
  public currentDocument: PaiaDocument;

  openReminderModal(ev: Event, content: any, document: PaiaDocument){
    ev.preventDefault();

    this.spinner.startBackground();
    this.currentDocument = document;

    this.documentsApi.getPaiaDocumentReminders(document.id, this.apiCompanyId).subscribe((data => {

      this.currentReminders = data;
      this.currentReminder = {
        paiaDocumentId: this.currentDocument.id,
        reminderText: '',
        reminderDateTime: new Date(),
        reminderEmail: ''
      };
      this.spinner.stopBackground();
      this.modalRef = this.modalService.open(content);

      this.modalRef.result.then((result) => {        
      }, (reason) => {
      });

    }));

    
  }

  addReminder(){
    this.spinner.startBackground();
    this.documentsApi.addPaiaDocumentReminder(this.currentReminder, this.apiCompanyId).subscribe(() => {
      this.spinner.stopBackground();
      this.modalRef.close();
      //this.getContracts();
    });
  }


  currentNotes: string;

  openNotesModal(content, contract: PaiaDocument) {
    this.currentNotes = contract.notes;

    this.modalService.open(content, { size: 'sm' }).result.then((result) => {
      let newNotes = this.currentNotes;
      let data: UpdatePaiaDocumentNotes = {
        id: contract.id,
        notes: newNotes
      };

      this.spinner.startBackground();

      this.documentsApi.updatePaiaDocumentNotes(data, this.apiCompanyId).subscribe(() => {
        this.spinner.stopBackground();
        this.getData();
        

      });
    }, (reason) => {
    });
  }
}
