import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TokenService } from '../../../services/token.service';
import { AccountApiService } from '../../../services/account-api.service';
import { ContactApiService } from '../../../services/contact-api.service';
import { SpinnerService } from '../../../services/spinner.service';
import { AlertService } from '../../../services/alert.service';
import { Router } from '@angular/router';
import { ContactRequest } from 'src/app/models/contactRequest';

@Component({
  selector: 'app-dashboard-contact',
  templateUrl: './dashboard-contact.component.html',
  styleUrls: ['./dashboard-contact.component.scss']
})
export class DashboardContactComponent implements OnInit {

  loggedIn: boolean = false;

  submitted = false;

  contactForm = this.formBuilder.group({
    firstname: ['', Validators.required],
    lastname: [''],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: [''],
    message: ['', Validators.required],
  });
  constructor(
    private formBuilder: UntypedFormBuilder,
    private token: TokenService,
    private accountApi: AccountApiService,
    private contactApi: ContactApiService,
    private spinner: SpinnerService,
    private alert: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loggedIn = this.token.loggedIn();

    if (this.loggedIn) {
      this.accountApi.getProfile().subscribe(data => {
        this.contactForm.patchValue({
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          phoneNumber: data.phoneNumber,
        });

        this.contactForm.controls.firstname.disable();
        this.contactForm.controls.lastname.disable();
        this.contactForm.controls.email.disable();
        this.contactForm.controls.phoneNumber.disable();
      });
    }
    else {
      this.router.navigateByUrl('/contact');
    }
  }

  submit() {

    this.submitted = true;

    if (this.contactForm.invalid){
      return;
    }

    this.spinner.startBackground();

    let data: ContactRequest = {
      email: this.contactForm.controls.email.value,
      firstname: this.contactForm.controls.firstname.value,
      lastname: this.contactForm.controls.lastname.value,
      message: this.contactForm.controls.message.value,
      phoneNumber: this.contactForm.controls.phoneNumber.value
    };
    this.contactApi.submit(data).subscribe(() => {

      if (this.loggedIn) {
        this.contactForm.controls.message.reset();
      }
      else {
        this.contactForm.reset();
      }

      this.spinner.stopBackground();
      this.alert.success("Thank you, your request has been submitted");
    });
  }

}
