import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';
import { first } from 'rxjs/operators';
import { SpinnerService } from '../../services/spinner.service';
import { AccountApiService } from '../../services/account-api.service';
import { TokenService } from '../../services/token.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  view: 'login'|'forgot-password'|'mfa'|'setup-mfa' = 'login';
  loginSubmitted = false;
  mfaSubmitted = false;
  forgotPasswordSubmitted = false;

  manualPin?: string;
  qrCode?: string;

  loginForm = this.formBuilder.group({
    email: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ]],
    password: ['', Validators.required]
  });

  forgotPasswordForm = this.formBuilder.group({
    email: ['', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ]]
  });

  mfaForm = this.formBuilder.group({
    pin: ['',  Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private globals: Globals,
    private router: Router,
    private auth: AuthService,
    private alertService: AlertService,
    private spinner: SpinnerService,
    private accountApi: AccountApiService,
    private token: TokenService, 
    private events: EventsService) { }

  ngOnInit() {

    if (localStorage.getItem('CURRENT_TOKEN')) {
      // logged in so return true
      if (this.token.isSuperAdmin()) {
        this.router.navigateByUrl('/dashboard/configuration/organisation/list');
      }
      else if (this.token.isCompanyAdmin() || this.token.isOrganisationAdmin()) {
        this.router.navigateByUrl('/dashboard/umbrella-report');
      }
      
    }
  }

  login() {
    this.loginSubmitted = true;

    if (!this.loginForm.valid){
      return;
    }
    
    this.spinner.start();

    this.auth.login(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value)
      .subscribe({
        next: (data) => {
          
          this.view = 'mfa';
          this.spinner.stop();
        },
        error: (error) => {
          this.spinner.stop();
          if (error.includes('Bad Request') || error.includes('400')) {
            this.alertService.error('Invalid email and/or password');
          }
          else {
            this.alertService.error(error);
          }
        }});

    return false;
  }

  setupMFA(ev: Event){
    ev.preventDefault();
    this.spinner.start();
    this.auth.setupMFA().subscribe({
      next: data => {
        this.spinner.stop();
        this.manualPin = data.manualEntryKey;
        this.qrCode = data.qrCodeUrl;
        this.view = 'setup-mfa';
      },
      error: error => {
        this.spinner.stop();
      }
    });
    return false;
  }

  mfa() {
    this.mfaSubmitted = true;

    if (!this.mfaForm.valid){
      return;
    }
    
    this.spinner.start();

    this.auth.MFA(this.mfaForm.controls['pin'].value)
      .subscribe({
        next: (data) => {
          this.token.reload();
          
          if (this.token.isSuperAdmin()) {
            this.router.navigateByUrl('/dashboard/configuration/organisation/list');
          }else if (this.token.isCompanyAdmin() || this.token.isOrganisationAdmin()) {
            this.router.navigateByUrl('/dashboard/umbrella-report');
          }
          
          else if (this.token.isLibraryUser()) {
            this.router.navigateByUrl('/dashboard/library');
          }
          else {
            this.router.navigateByUrl('/dashboard/self-assessment-checklist');
          }
          this.spinner.stop();
        },
        error: (error) => {
          this.spinner.stop();
          if (error.includes('Bad Request') || error.includes('400')) {
            this.alertService.error('Invalid pin');
          }
          else {
            this.alertService.error(error);
          }
          this.view = 'login';
          this.spinner.stop();
          //this.loading = false;
        }});

    return false;
  }

  forgotPassword() {

    this.forgotPasswordSubmitted = true;

    if (!this.forgotPasswordForm.valid){
      return;
    }


    var email = this.forgotPasswordForm.controls.email.value;

    this.forgotPasswordForm.reset();
    this.spinner.start();
    this.accountApi.resetPassword(email).subscribe(() => {
      this.spinner.stop();
      this.view = 'login';
      this.alertService.success('Please check your email inbox for further instructions.');
    });

  }
}
