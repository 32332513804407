import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { DocumentApiService } from '../../services/document-api.service';
import { Document } from '../../models/document';

@Component({
  selector: 'app-document-list-item',
  templateUrl: './document-list-item.component.html',
  styleUrls: ['./document-list-item.component.scss']
})
export class DocumentListItemComponent implements OnInit {

  public _document: Document;
  public _edit: boolean = false;

  constructor(private spinner: SpinnerService, private documentApi: DocumentApiService) { }

  @Input()
  set document(document: Document) {
    this._document = document;
  }

  @Input()
  set edit(edit: boolean) {
    this._edit = edit;
  }

  @Output()
  onEdit = new EventEmitter();

  ngOnInit() {
  }

  editClick() {
    this.onEdit.emit();
  }

  getDocument(id: number) {

    this.spinner.startBackground();
    //window.open(this.libraryApi.GetDocumentUrl(id));
    this.documentApi.GetDocument(id).subscribe((data) => {
      var url = URL.createObjectURL(data as Blob);
      window.open(url);
      this.spinner.stopBackground();
    });
  }
}
