import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
import { RequestCacheService } from './request-cache.service';
import { TokenService } from './token.service';
import { EnvironmentService } from './environment.service';
import { Token } from '../models/token';
import { GoogleMFASetup } from '../models/googleMfaSetup';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(protected http: HttpClient, protected env: EnvironmentService, private globals: Globals, private cache: RequestCacheService, private token: TokenService, private events: EventsService) { }

  login(username: string, password: string): Observable<string> {
    const url = `${this.env.getApiUrl()}/api/auth/login`;
    const data = { username: username, password: password };


    return this.http.post<Token>(url, data)
      .pipe(map(token => {
        // login successful if there's a jwt token in the response
        if (token && token.accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('TEMP_TOKEN', token.accessToken);
        }

        return token.accessToken;
      }));
  }

  setupMFA(){
    const url = `${this.env.getApiUrl()}/api/auth/setup-mfa`;
    let token = localStorage.getItem('TEMP_TOKEN');
    const data = { token: token };

    return this.http.post<GoogleMFASetup>(url, data);
  }



  MFA(pin: string){
    const url = `${this.env.getApiUrl()}/api/auth/mfa`;
    let token = localStorage.getItem('TEMP_TOKEN');
    localStorage.removeItem('TEMP_TOKEN');
    const data = { token: token, pin: pin };

    return this.http.post<Token>(url, data)
      .pipe(map(token => {
        // login successful if there's a jwt token in the response
        if (token && token.accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('CURRENT_TOKEN', token.accessToken);

          this.globals.loggedIn = true;

          this.events.fireEvent('LOGIN');
        }

        return token.accessToken;
      }));
  }

  
  switchCompany(companyId: number): Observable<string> {
    const url = `${this.env.getApiUrl()}/api/auth/switch-company/${companyId}`;
    


    return this.http.post<Token>(url, {})
      .pipe(map(token => {
        if (token && token.accessToken) {

          this.logout();
          localStorage.setItem('CURRENT_TOKEN', token.accessToken);
          this.globals.loggedIn = true;
        }

        return token.accessToken;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    let perPage = localStorage["PER_PAGE"];

    localStorage.clear();

    if (perPage) {
      localStorage.setItem("PER_PAGE", perPage);
    }

    this.cache.clear();
    this.token.reload();
    this.globals.loggedIn = false;

    this.events.fireEvent('LOGOUT');
  }


}
