import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { SpinnerService } from '../../services/spinner.service';
import { ContactApiService } from '../../services/contact-api.service';
import { AlertService } from '../../services/alert.service';
import { Meta } from '@angular/platform-browser';
import { ContactRequest } from 'src/app/models/contactRequest';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss']
})
export class GetComponent implements OnInit {

  contactForm = this.formBuilder.group({
    firstname: ['', Validators.required],
    lastname: [''],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: [''],
  
  });

  constructor(
    private formBuilder: UntypedFormBuilder, 
    private spinner: SpinnerService, 
    private contactApi: ContactApiService,
    private alert: AlertService,
    private meta: Meta) { }

  ngOnInit() {
    this.meta.addTags([
      {name: 'description', content: 'Organisations may elect to select one or all of the components depending on its needs. The cost of The Legal Team will be dependent on the organisations needs and requirements. Complete your information to get a quote and get started.'},   
      {name: 'keywords', content: 'Regulatory and Compliance Needs,Get Started,Compliance Team ' }
    ]);
  }

  submitted = false;
  
  submit() {

    this.submitted = true;

    if (this.contactForm.invalid){
      return;
    }

    this.spinner.startBackground();

    let data: ContactRequest = {
      email: this.contactForm.controls.email.value,
      firstname: this.contactForm.controls.firstname.value,
      lastname: this.contactForm.controls.lastname.value,
      message: "Request From the 'GET THE LEGAL TEAM' page",
      phoneNumber: this.contactForm.controls.phoneNumber.value
    };
    this.contactApi.submit(data).subscribe(() => {

      this.contactForm.reset();

      this.spinner.stopBackground();
      this.alert.success("Thank you, your request has been submitted");
    });
  }


}
