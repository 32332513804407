import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { ContractDocument, ContractDocumentAgreementType, ContractDocumentCorrespondence, ContractDocumentResult, ContractDocumentStatus, DocumentCorrespondenceType, DocumentFacility, DocumentRegion, DocumentTemplate, DocumentTemplateType, FicaDocument, FicaDocumentCorrespondence, FicaDocumentReminder, FicaDocumentResult, FicaDocumentStatus, MatterManagementDocument, MatterManagementDocumentAppealStatus, MatterManagementDocumentCorrespondence, MatterManagementDocumentReferral, MatterManagementDocumentReminder, MatterManagementDocumentResult, MatterManagementDocumentStatus, PaiaDocument, PaiaDocumentAppealStatus, PaiaDocumentCorrespondence, PaiaDocumentReferral, PaiaDocumentReminder, PaiaDocumentResult, PaiaDocumentStatus, PermitDocument, PermitDocumentCorrespondence, PermitDocumentResult, PermitDocumentStatus, UpdateFicaDocumentNotes, UpdateMatterManagementDocumentNotes, UpdatePaiaDocumentNotes } from '../models/documents';


@Injectable({
  providedIn: 'root'
})
export class DocumentsApiService extends ApiService<Document> {

  _controller: string = 'documents';
  constructor(protected http: HttpClient, protected env: EnvironmentService) {
    super('documents', http, env);
  }

  public GetTemplateTypes() {
    let url = `api/${this._controller}/template-types`;
    return this.get<DocumentTemplateType[]>(url);
  }

  public GetTemplates(companyId: number, documentTemplateTypeid: number) {
    let url = `api/${this._controller}/templates?typeId=${documentTemplateTypeid}&?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    
    return this.get<DocumentTemplate[]>(url);
  }

  public GetRegions() {
    let url = `api/${this._controller}/regions`;
    return this.get<DocumentRegion[]>(url);
  }

  public GetFacilities(regionId?: number) {
    let url = `api/${this._controller}/facilities`;

    if (regionId) {
      url = `${url}?regionId=${regionId}`
    }
    return this.get<DocumentFacility[]>(url);
  }

  public GetDocumentCorrespondenceTypes(type: string) {
    let url = `api/${this._controller}/correspondence-types?type=${type}`;
    return this.get<DocumentCorrespondenceType[]>(url);
  }

  /////////////////////////////////////////////////////////////////////
  //            PAIA
  /////////////////////////////////////////////////////////////////////

  public GetPaiaStatuses() {
    let url = `api/${this._controller}/paia/statuses`;
    return this.get<PaiaDocumentStatus[]>(url);
  }

  public GetPaiaAppealStatuses() {
    let url = `api/${this._controller}/paia/appeal-statuses`;
    return this.get<PaiaDocumentAppealStatus[]>(url);
  }

  public GetPaiaReferrals() {
    let url = `api/${this._controller}/paia/referrals`;
    return this.get<PaiaDocumentReferral[]>(url);
  }

  public GetPaiaDocuments(
    companyId: number = undefined, 
    paiaDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    appealStatusId: number = undefined,
    accessGranted: boolean = undefined,
    consent: boolean = undefined,
    newOrExisting: boolean = undefined,
    paiaDocumentReferralId: number = undefined,
    from: Date = undefined,
    to: Date = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {

    let url = `api/${this._controller}/paia/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (paiaDocumentStatusId != undefined) {
      url = `${url}&paiaDocumentStatusId=${paiaDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (appealStatusId != undefined) {
      url = `${url}&appealStatusId=${appealStatusId}`
    }

    if (accessGranted != undefined) {
      url = `${url}&accessGranted=${accessGranted}`
    }

    if (consent != undefined) {
      url = `${url}&consent=${consent}`
    }

    if (newOrExisting != undefined) {
      url = `${url}&newOrExisting=${newOrExisting}`
    }

    if (paiaDocumentReferralId != undefined) {
      url = `${url}&paiaDocumentReferralId=${paiaDocumentReferralId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<PaiaDocumentResult>(url);
  }

  public exportPaiaDocuments(companyId: number, 
    paiaDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    appealStatusId: number = undefined,
    accessGranted: boolean = undefined,
    consent: boolean = undefined,
    newOrExisting: boolean = undefined,
    paiaDocumentReferralId: number = undefined,
    from: Date = undefined,
    to: Date = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/export/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (paiaDocumentStatusId != undefined) {
      url = `${url}&paiaDocumentStatusId=${paiaDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (appealStatusId != undefined) {
      url = `${url}&appealStatusId=${appealStatusId}`
    }

    if (accessGranted != undefined) {
      url = `${url}&accessGranted=${accessGranted}`
    }

    if (consent != undefined) {
      url = `${url}&consent=${consent}`
    }

    if (newOrExisting != undefined) {
      url = `${url}&newOrExisting=${newOrExisting}`
    }

    if (paiaDocumentReferralId != undefined) {
      url = `${url}&paiaDocumentReferralId=${paiaDocumentReferralId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetPaiaDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/paia/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<PaiaDocument>(url);
  }

  public AddPaiaDocument(document: PaiaDocument) {
    let url = `api/${this._controller}/paia`;
    return this.post<PaiaDocument, {}>(url, document);
  }

  public UpdatePaiaDocument(document: PaiaDocument) {
    let url = `api/${this._controller}/paia`;
    return this.put<PaiaDocument, {}>(url, document);
  }

  public GetPaiaDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetPaiaDocumentHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  

  public getPaiaDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/paia/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<PaiaDocumentCorrespondence[]>(url);
  }

  public exportPaiaDocumentCorrespondence(id: number, companyId: number) {

    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/${id}/correspondence/export?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    //return this.get<PaiaDocumentCorrespondence[]>(url);

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public addPaiaDocumentCorrespondence(data: PaiaDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/paia/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<PaiaDocumentCorrespondence, {}>(url, data);
  }

  public getPaiaDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getPaiaDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/paia/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public updatePaiaDocumentNotes(data: UpdatePaiaDocumentNotes, companyId: number) {
    let url = `api/${this._controller}/paia/notes?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<UpdatePaiaDocumentNotes, {}>(url, data);
  }

  public getPaiaDocumentReminders(id: number, companyId: number) {
    let url = `api/${this._controller}/paia/${id}/reminders?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<PaiaDocumentReminder[]>(url);
  }

  public addPaiaDocumentReminder(data: PaiaDocumentReminder, companyId: number) {
    let url = `api/${this._controller}/paia/reminder?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<PaiaDocumentReminder, {}>(url, data);
  }



  /////////////////////////////////////////////////////////////////////
  //            PERMITS
  /////////////////////////////////////////////////////////////////////

  public GetPermitStatuses() {
    let url = `api/${this._controller}/permits/statuses`;
    return this.get<PermitDocumentStatus[]>(url);
  }

  public GetPermitDocuments(companyId: number, permitDocumentStatusId: number = undefined, granted: boolean = undefined, perPage: number = 5, currentPage: number = 1) {
    let url = `api/${this._controller}/permits/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (permitDocumentStatusId != undefined) {
      url = `${url}&permitDocumentStatusId=${permitDocumentStatusId}`
    }

    if (granted != undefined) {
      url = `${url}&granted=${granted}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<PermitDocumentResult>(url);
  }

  public GetPermitDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/permits/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<PermitDocument>(url);
  }

  public AddPermitDocument(document: PermitDocument) {
    let url = `api/${this._controller}/permits`;
    return this.post<PermitDocument, {}>(url, document);
  }

  public UpdatePermitDocument(document: PermitDocument) {
    let url = `api/${this._controller}/permits`;
    return this.put<PermitDocument, {}>(url, document);
  }

  public GetPermitDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/permits/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }
  public GetPaiaPermitHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/permits/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getPermitDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/permits/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<PermitDocumentCorrespondence[]>(url);
  }

  public addPermitDocumentCorrespondence(data: PermitDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/permits/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<PermitDocumentCorrespondence, {}>(url, data);
  }

  public getPermitDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/permits/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getPermitDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/permits/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  /////////////////////////////////////////////////////////////////////
  //            CONTRACTS
  /////////////////////////////////////////////////////////////////////


  public GetContractStatuses() {
    let url = `api/${this._controller}/contracts/statuses`;
    return this.get<ContractDocumentStatus[]>(url);
  }

  public GetContractAgreementTypes() {
    let url = `api/${this._controller}/contracts/agreement-types`;
    return this.get<ContractDocumentAgreementType[]>(url);
  }

  public GetContractDocuments(companyId: number, contractDocumentStatusId: number = undefined, perPage: number = 5, currentPage: number = 1) {
    let url = `api/${this._controller}/contracts/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (contractDocumentStatusId != undefined) {
      url = `${url}&contractDocumentStatusId=${contractDocumentStatusId}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<ContractDocumentResult>(url);
  }

  public GetContractDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/contracts/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<ContractDocument>(url);
  }

  public AddContractDocument(document: ContractDocument) {
    let url = `api/${this._controller}/contracts`;
    return this.post<ContractDocument, {}>(url, document);
  }

  public UpdateContractDocument(document: ContractDocument) {
    let url = `api/${this._controller}/contracts`;
    return this.put<ContractDocument, {}>(url, document);
  }

  public GetContractDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/contracts/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }
  public GetContractDocumentHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/contracts/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getContractDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/contracts/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<ContractDocumentCorrespondence[]>(url);
  }

  public addContractDocumentCorrespondence(data: ContractDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/contracts/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<ContractDocumentCorrespondence, {}>(url, data);
  }

  public getContractDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/contracts/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getContractDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/contracts/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }


/////////////////////////////////////////////////////////////////////
  //            FICA
  /////////////////////////////////////////////////////////////////////

  public GetFicaStatuses() {
    let url = `api/${this._controller}/fica/statuses`;
    return this.get<FicaDocumentStatus[]>(url);
  }

  public GetFicaDocuments(
    companyId: number = undefined, 
    ficaDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    from: Date = undefined,
    to: Date = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {

    let url = `api/${this._controller}/fica/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (ficaDocumentStatusId != undefined) {
      url = `${url}&ficaDocumentStatusId=${ficaDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<FicaDocumentResult>(url);
  }

  public exportFicaDocuments(companyId: number, 
    ficaDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    from: Date = undefined,
    to: Date = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/export/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (ficaDocumentStatusId != undefined) {
      url = `${url}&ficaDocumentStatusId=${ficaDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetFicaDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/fica/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<FicaDocument>(url);
  }

  public AddFicaDocument(document: FicaDocument) {
    let url = `api/${this._controller}/fica`;
    return this.post<FicaDocument, {}>(url, document);
  }

  public UpdateFicaDocument(document: FicaDocument) {
    let url = `api/${this._controller}/fica`;
    return this.put<FicaDocument, {}>(url, document);
  }

  public GetFicaDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetFicaDocumentHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  

  public getFicaDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/fica/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<FicaDocumentCorrespondence[]>(url);
  }

  public exportFicaDocumentCorrespondence(id: number, companyId: number) {

    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/${id}/correspondence/export?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    //return this.get<FicaDocumentCorrespondence[]>(url);

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public addFicaDocumentCorrespondence(data: FicaDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/fica/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<FicaDocumentCorrespondence, {}>(url, data);
  }

  public getFicaDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getFicaDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/fica/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public updateFicaDocumentNotes(data: UpdateFicaDocumentNotes, companyId: number) {
    let url = `api/${this._controller}/fica/notes?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<UpdateFicaDocumentNotes, {}>(url, data);
  }

  public getFicaDocumentReminders(id: number, companyId: number) {
    let url = `api/${this._controller}/fica/${id}/reminders?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<FicaDocumentReminder[]>(url);
  }

  public addFicaDocumentReminder(data: FicaDocumentReminder, companyId: number) {
    let url = `api/${this._controller}/fica/reminder?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<FicaDocumentReminder, {}>(url, data);
  }



    /////////////////////////////////////////////////////////////////////
  //            MATTER MANAGEMENT
  /////////////////////////////////////////////////////////////////////

  public GetMatterManagementStatuses() {
    let url = `api/${this._controller}/matter-management/statuses`;
    return this.get<MatterManagementDocumentStatus[]>(url);
  }

  public GetMatterManagementAppealStatuses() {
    let url = `api/${this._controller}/matter-management/appeal-statuses`;
    return this.get<MatterManagementDocumentAppealStatus[]>(url);
  }

  public GetMatterManagementReferrals() {
    let url = `api/${this._controller}/matter-management/referrals`;
    return this.get<MatterManagementDocumentReferral[]>(url);
  }

  public GetMatterManagementDocuments(
    companyId: number = undefined, 
    matterManagementDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    appealStatusId: number = undefined,
    matterManagementDocumentReferralId: number = undefined,
    from: Date = undefined,
    to: Date = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {

    let url = `api/${this._controller}/matter-management/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (matterManagementDocumentStatusId != undefined) {
      url = `${url}&matterManagementDocumentStatusId=${matterManagementDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (appealStatusId != undefined) {
      url = `${url}&appealStatusId=${appealStatusId}`
    }

   

    if (matterManagementDocumentReferralId != undefined) {
      url = `${url}&matterManagementDocumentReferralId=${matterManagementDocumentReferralId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.get<MatterManagementDocumentResult>(url);
  }

  public exportMatterManagementDocuments(companyId: number, 
    matterManagementDocumentStatusId: number = undefined, 
    decision: boolean = undefined, 
    documentRegionId: number = undefined, 
    documentFacilityId: number = undefined, 
    appealStatusId: number = undefined,
    matterManagementDocumentReferralId: number = undefined,
    from: Date = undefined,
    to: Date = undefined,
    perPage: number = 5, 
    currentPage: number = 1) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/export/?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    if (matterManagementDocumentStatusId != undefined) {
      url = `${url}&matterManagementDocumentStatusId=${matterManagementDocumentStatusId}`
    }

    if (decision != undefined) {
      url = `${url}&decision=${decision}`
    }

    if (documentRegionId != undefined) {
      url = `${url}&documentRegionId=${documentRegionId}`
    }

    if (documentFacilityId != undefined) {
      url = `${url}&documentFacilityId=${documentFacilityId}`
    }

    if (appealStatusId != undefined) {
      url = `${url}&appealStatusId=${appealStatusId}`
    }

    if (matterManagementDocumentReferralId != undefined) {
      url = `${url}&matterManagementDocumentReferralId=${matterManagementDocumentReferralId}`
    }

    if (from != undefined) {
      url = `${url}&from=${from}`
    }

    if (to != undefined) {
      url = `${url}&to=${to}`
    }

    if (perPage != undefined) {
      url = `${url}&perPage=${perPage}`
    }

    if (currentPage != undefined) {
      url = `${url}&currentPage=${currentPage}`
    }


    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetMatterManagementDocument(id: number, companyId: number) {
    let url = `api/${this._controller}/matter-management/${id}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<MatterManagementDocument>(url);
  }

  public AddMatterManagementDocument(document: MatterManagementDocument) {
    let url = `api/${this._controller}/matter-management`;
    return this.post<MatterManagementDocument, {}>(url, document);
  }

  public UpdateMatterManagementDocument(document: MatterManagementDocument) {
    let url = `api/${this._controller}/matter-management`;
    return this.put<MatterManagementDocument, {}>(url, document);
  }

  public GetMatterManagementDocumentFile(id: number, companyId: number, file: string) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/${file}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public GetMatterManagementDocumentHistoryFile(id: number, companyId: number, historyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/history/${historyId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  

  public getMatterManagementDocumentCorrespondence(id: number, companyId: number) {
    let url = `api/${this._controller}/matter-management/${id}/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    return this.get<MatterManagementDocumentCorrespondence[]>(url);
  }

  public exportMatterManagementDocumentCorrespondence(id: number, companyId: number) {

    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/correspondence/export?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }

    //return this.get<MatterManagementDocumentCorrespondence[]>(url);

    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public addMatterManagementDocumentCorrespondence(data: MatterManagementDocumentCorrespondence, companyId: number) {
    let url = `api/${this._controller}/matter-management/correspondence?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<MatterManagementDocumentCorrespondence, {}>(url, data);
  }

  public getMatterManagementDocumentCorrespondenceFile(id: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/correspondence/download?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public getMatterManagementDocumentCorrespondenceAttachmentFile(id: number, attachmentId: number, correspondenceId: number, companyId: number) {
    let url = `${this.env.getApiUrl()}/api/${this._controller}/matter-management/${id}/correspondence/${correspondenceId}/attachment/${attachmentId}?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  public updateMatterManagementDocumentNotes(data: UpdateMatterManagementDocumentNotes, companyId: number) {
    let url = `api/${this._controller}/matter-management/notes?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<UpdateMatterManagementDocumentNotes, {}>(url, data);
  }

  public getMatterManagementDocumentReminders(id: number, companyId: number) {
    let url = `api/${this._controller}/matter-management/${id}/reminders?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.get<MatterManagementDocumentReminder[]>(url);
  }

  public addMatterManagementDocumentReminder(data: MatterManagementDocumentReminder, companyId: number) {
    let url = `api/${this._controller}/matter-management/reminder?`;
    
    if (companyId != undefined) {
      url = `${url}&companyId=${companyId}`
    }
    return this.post<MatterManagementDocumentReminder, {}>(url, data);
  }



  
}
