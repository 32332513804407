import { Component } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { Globals } from './globals';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { SpinnerService } from './services/spinner.service';
import { TokenService } from './services/token.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'The Legal Team';
  constructor(
    public globals: Globals,
    private router: Router,
    private spinner: SpinnerService,
    private idle: Idle,
    private keepalive: Keepalive,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {

    this.globals.loggedIn = (localStorage.getItem('CURRENT_TOKEN') != null)

    if (this.globals.loggedIn) {
      var timeout = localStorage['ng2Idle.main.expiry'];
      if (timeout) {
        var date = new Date(+timeout);
        if (date < new Date()) {
          this.logout();
          this.reset();
        }
      }
    }



    this.router.events.subscribe(e => {

      if (e instanceof NavigationEnd) {
        this.spinner.stopAll();
      }
    });


    idle.setIdle(30);
    idle.setTimeout(1800);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      //LOGOUT

      if (this.globals.loggedIn) {
        this.logout();
        this.reset();
      }
      else {
        this.reset();
      }
    });

    // // sets the ping interval to 15 seconds
    keepalive.interval(15);

    //keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();

  }

  reset() {
    this.idle.watch();
    //this.idleState = 'Started.';
    //this.timedOut = false;
  }

  logout() {
    this.auth.logout();
    this.router.navigateByUrl('/home');
  }


  isContractRequest() {
    return this.router.url.indexOf('/request-contract/') > -1;
  }

}
