<div class="toolkit h-100 pt-4 d-flex align-items-center text-center">
  <div class="container flex-grow-1 d-flex flex-column">
    <div class="d-flex align-items-center justify-content-center">
      <h1 class="display-4 text-uppercase">The future of legal compliance is now!</h1>
    </div>
    <div class="content-block flex-column mb-4">
      <div class="row">
        <div class="col-12 text-start">
            <h2>ENTERPRISE AND SUPPLIER DEVELOPMENT </h2>

<h5 class="purple">SOME QUESTIONS</h5>
<p>Are you an organization who wants to support small businesses?</p>
<p>More importantly are you interested in getting involved in an initiative that encourages the legal and legitimate operation of small businesses and earn B-BBEE points at the same time? </p>
<p>If you are a current client of THE LEGAL TEAM  or are interested in making use of THE LEGAL TEAM, the one stop compliance and document management software tool (commonly referred to in the business environment as “LegalTech") then you will be able to participate in THE LEGAL TEAM’s Enterprise and Supplier development initiative. </p>

<h5 class="purple">WHAT IS ENTERPRISE / SUPPLIER DEVELOPMENT</h5>
<p><strong><em>According to the Stellenbosch Business School, “Enterprise Development” is defined as "the act of investing time and capital in helping people establish, expand or improve their  businesses. Enterprise development helps people to earn a living; it helps them out of poverty; and it leads to long-term economic growth for themselves, their families and their communities."</em></strong></p>
<p>Enterprise Development (ED) and Supplier Development (SD) is one of the three Priority Elements of the Broad-Based Black Economic Empowerment (B-BBEE) Scorecard. An entity is required to achieve a 40% subminimum of each of the categories on the ED and SD Scorecard (excluding bonus points). </p>
<p><em>i.e. SD 4 points out of 10 points and ED 2 points out of 5 points.</em></p>
<p>Failure to comply with the 40% subminimum leads to a drop of one level on the B-BBEE scorecard.</p>
<p>An Entity may make monetary or non-monetary contributions to a Beneficiary Entity.</p>
<p>The requirements may differ if a company is subject to verification based on a specific Sector Code.</p>

<h5 class="purple">THE ED AND SD BENEFICIARIES MUST MEET THE FOLLOWING CRITERIA:</h5>
<ul>
  <li>The beneficiary is required to be at least 51% black owned;</li>
  <li>The beneficiary must be an Exempt Micro Enterprise (EME) (Turnover under R10 million) or a Qualifying Small Enterprise (QSE) (Turnover less than R50 million), subject to the specific Sector Code.</li>
</ul>

<h5 class="purple">WHAT IS THE DIFFERENCE BETWEEN ENTERPRISE AND SUPPLIER DEVELOPMENT?</h5>
<p>SD contributions are made to entities that already form part of the Measured Entity’s (ME) current supplier chain. </p>
<p>ED contributions are made to entities that are not part of the ME supplier chain.</p>

<h5 class="purple">COMPLIANCE TARGETS FOR THE AMENDED CODES OF GOOD PRACTICE</h5>
<table class="table table-borderd">
  <tr>
    <th>Elements</th>
    <th>Large Enterprise</th>
    <th>Qualifying Small Enterprises</th>
  </tr>
  <tr>
    <td>Supplier Development</td>
    <td>2% Of Net Profit After Tax</td>
    <td>1% Of Net Profit After Tax</td>
  </tr>
  <tr>
    <td>Enterprise Development</td>
    <td>1% Of Net Profit After Tax</td>
    <td>1% Of Net Profit After Tax</td>
  </tr>
</table>

<h5 class="purple">IDENTIFICATION OF SUITABLE BENEFICIARIES:</h5>
<p>When considering who to support, a ME should critically analyse the type of beneficiary. Some factors to consider:</p>
<ul>
  <li>An ED beneficiary should be an EME or QSE that the ME has identified, who they can assist and develop to become part of their supply chain. This has a twofold benefit for the ME, namely (i) to develop a reliable future supplier, and (ii) to gain additional bonus points when that beneficiary is graduated to a supplier;</li>
  <li>When identifying a SD beneficiary, the ME should first look to their existing supply chain, to assess if there are existing black owned EME’s and QSE’s that they can assist and develop. If no suitable beneficiaries are identified they should look for EME’s and QSE’s that can be taken into their supply chain immediately, and developed;</li>
  <li>Often, if the ME cannot identify suitable beneficiaries, the ME appoints an ESD Facilitator to provide mentorship and training programmes to black owned EME’s and QSE’s in order to develop their businesses.</li>
</ul>

<h5 class="purple">DOCUMENTATION REQUIRED BY THE VERIFICATION AGENCY TO EVIDENCE CONTRIBUTIONS FOR ED AND SD</h5>
<p>The following documentation is required to evidence ED and SD contributions:</p>
<ul>
  <li>An ED or SD agreement between the ME and the ED or SD Beneficiary;</li>
  <li>A letter from the ED or SD Beneficiary confirming the value and nature of the assistance received from the ME;</li>
  <li>Sworn EME Affidavit of the ED or SD Beneficiary or Sworn QSE Affidavit of the ED or SD Beneficiary;</li>
  <li>A copy of the identity document of the owner of the ED or SD Beneficiary;</li>
  <li>Proof of the contribution to the ED or SD Beneficiary, evidencing the support given (e.g. invoice and proof of payment of contribution);</li>
  <li>Supplier invoice from the SD Beneficiary and proof of payment of items purchased (Required for SD only).</li>
</ul>

<h5 class="purple">STEPS IN EXECUTING A SUCCESSFUL ENTERPRISE AND SUPPLIER DEVELOPMENT INITIATIVE</h5>
<ul>
  <li><strong>Step 1</strong> - Determine the compliance targets for ED and SD, in order to achieve the maximum points for the Enterprise and Supplier Development Scorecard;</li>
  <li><strong>Step 2</strong> - Identify suitable ED Beneficiaries which could form part of the ME supply chain. By procuring goods and services from the ED Beneficiary in the second year, the ME will be entitled to one bonus point on the Enterprise and Supplier Development Scorecard as the ED Beneficiary has now graduated to a Supplier;</li>
  <li><strong>Step 3</strong> – Identify suitable SD Beneficiaries that can add value to the ME supply chain;</li>
  <li><strong>Step 4</strong> - Monitor and track the progress of the Enterprise and Supplier Development initiative within the ME’s financial period. This will ensure that the ME is able to report more efficiently on the implementation of the ED and SD initiative. If the ED or SD beneficiary is growing and creating jobs, as a result of the ME’s contribution, the ME will be entitled to one bonus point on the Enterprise and Supplier Development Scorecard;</li>
  <li><strong>Step 5</strong> – Be involved in the development of the ED and SD beneficiaries.</li>
</ul>

<h5 class="purple">TYPES OF CONTRIBUTIONS THAT THE ME CAN MAKE TO ED OR SD BENEFICIARIES</h5>
<p>Below are the most common types of contributions that ME’s make to ED or SD Beneficiaries:</p>
<ul>
  <li>Grant Contribution;</li>
  <li>Direct costs incurred by the ME on behalf of the Beneficiary Entity, for example the purchase of a computer for the Beneficiary Entity;</li>
  <li>Discounts in addition to normal business practice;</li>
  <li>Professional services rendered by the ME to the Beneficiary Entity at no cost;</li>
  <li>Overhead costs incurred by the ME on behalf of the Beneficiary Entity, for example providing free rental space to the Beneficiary Entity;</li>
  <li>Loans on favourable terms.</li>
</ul>
<p>For more detail - click here to view full statement </p>
<p><a href="https://www.gov.za/sites/default/files/gcis_document/201905/42496gen304.pdf" target="_blank">STATEMENT 400: THE GENERAL PRINCIPLES FOR MEASURING ENTERPRISE AND SUPPLIER DEVELOPMENT</a></p>

<h5 class="purple">HOW THE INITIATIVE WORKS </h5>
<p>Where the organization subscribes to THE LEGAL TEAM services, the organization will be granted a discount.</p>
<p>The organization will select an SMME / or SME beneficiary.</p>
<p>Note that the beneficiary is required to be at least 51% black owned and an Exempt Micro Enterprise (EME) (Turnover under R10 million) or a Qualifying Small Enterprise (QSE) (Turnover less than R50 million), subject to the specific Sector Code.</p>
<p>An agreement will be entered into with the beneficiary, in terms of which the organization will undertake to pay the subscription fee on behalf of the beneficiary in respect of the use of the services known as the SMMELegalToolKit. </p>
<p>This discounted amount will be deducted off THE LEGAL TEAM subscription fee and be used to pay for the service fee owing by the beneficiary to THE LEGAL TEAM in respect of the use of the services known as the SMMELegalToolKit. </p>
<p>The following documents will be handed to the organization: </p>
<ul>
  <li>A letter from the beneficiary confirming the value and nature of the assistance received from the organization will be provided to the providing organization;</li>
  <li>A Sworn EME Affidavit of the ED or SD Beneficiary or Sworn QSE Affidavit of the ED or SD Beneficiary;</li>
  <li>A copy of the identity document of the owner of the ED or SD Beneficiary;</li>
  <li>Proof of the contribution to the ED or SD Beneficiary, evidencing the support given (e.g. invoice and proof of payment of contribution).</li>
</ul>

<h5 class="purple">WHAT IS THE SMMELEGALTOOLKIT? </h5>
<p><strong>THE LEGAL TEAM SMME/SME LEGALTOOLKIT</strong> is an innovative online portal that houses the information which any person would need when setting up a business. </p>
<p>The SMMELegalToolKit,  in particular, is targeted at small businesses and starts-ups which do not necessarily have the funds available to spend on legal services.</p>
<p>The aim and objective being <strong>EMPOWERING AND DRIVING SMME/SMME LEGAL BUSINESSES ACROSS AFRICA.</strong></p>
<p>For more about the SMMELegalToolKit, <a routerLink="/toolkit">click here</a>.</p>
<p><strong>EMPOWERING AND DRIVING SMME/SMME LEGAL BUSINESSES ACROSS AFRICA</strong></p>

<p><img src="/assets/esd_2.png" class="img-fluid" style="max-width: 40rem;" /></p>
 



        </div>        
      </div>
    </div>
  </div>
</div>