import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { TokenService } from '../../../../services/token.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SpinnerService } from '../../../../services/spinner.service';
import { ContractsApiService } from '../../../../services/contracts-api.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../../../services/alert.service';
import { RequestCacheService } from '../../../../services/request-cache.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UpdateContractNotes } from 'src/app/models/updateContractNotes';
import { Contract } from 'src/app/models/contract';
import { ContractReminder } from 'src/app/models/contractReminder';
import { ContractType } from 'src/app/models/contractType';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-document-registry',
  templateUrl: './document-registry.component.html',
  styleUrls: ['./document-registry.component.scss']
})
export class DocumentRegistryComponent implements OnInit {

  modalRef: NgbModalRef;
  user: boolean = false;
  contractAdmin: boolean = false;
  superAdmin: boolean = false;

  companyId: number;
  apiCompanyId?: number;
  contracts: Contract[];
  company: Company;

  search: string;
  order: string = null;
  selectedDocumentType: number;

  documentTypes: Array<ContractType>;

  currentType?: number;

  currentNotes: string;


  private searchModelChanged: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  searchDebounceTime = 500
  
  reminderForm = this.formBuilder.group({
    reminderDateTime: ['', [Validators.required]],
    reminderText: ['', [Validators.required]]
  });

  constructor(
    public token: TokenService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private router: Router,
    private contractsApi: ContractsApiService,
    private alert: AlertService,
    private cache: RequestCacheService,
    private cdref: ChangeDetectorRef,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngOnInit() {

    // this.apiCompanyId = this.companyId;
    // this.getContracts();

    this.user = this.token.isUser();
    this.contractAdmin = this.token.isContractAdmin();
    //this.companyAdmin = this.token.isCompanyAdmin();
    this.superAdmin = this.token.isSuperAdmin();
    
    this.getDocumentTypes();

    if (this.contractAdmin || this.contractAdmin) {
      this.companyId = this.token.companyId();
      if (this.companyId) {
        this.getContracts();
      }

    }
    else if (this.superAdmin) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = +params.get('id');
        this.apiCompanyId = this.companyId;
        if (this.companyId) {
          this.getContracts();
        }
      });
    }
    else {
      this.router.navigateByUrl('/dashboard/contracts');
    }


    this.searchSubscription = this.searchModelChanged
      .pipe(
        debounceTime(this.searchDebounceTime),
      )
      .subscribe(() => {
        this.getContracts();
      });
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  searchInputChanged(evt) {
    this.searchModelChanged.next("");
  }

  companySelected(company) {

    if (company) {
      this.company = company;
      this.companyId = company.id;
      this.apiCompanyId = this.companyId;
      this.getContracts();
    }
    else {
      this.contracts = null;
    }
  }

  getDocumentTypes() {
    this.contractsApi.GetContractTypes().subscribe(data => {
      this.documentTypes = data;
    });
  }


  getContracts() {
    if (!this.apiCompanyId) {
      //return;
    }
    this.spinner.startBackground();
    this.contractsApi.ListContracts(this.apiCompanyId, 3, this.search, this.selectedDocumentType, this.order).subscribe(data => {
      this.contracts = data;
      this.spinner.stopBackground();
    });
  }

  back() {
    this.router.navigateByUrl('/dashboard/contracts');
  }

  openContract(contract: Contract){
    this.router.navigateByUrl('/dashboard/contracts-old/open/' + contract.id);
  }

  closeContract(contract: Contract){
    this.spinner.startBackground();
    this.contractsApi.CloseContract(contract).subscribe(data => {
      this.spinner.stopBackground();
      this.cache.clear();
      this.router.navigateByUrl('/dashboard/contracts');
    })
  }

  public currentReminders: ContractReminder[];
  public currentReminder: ContractReminder;
  public currentContract: Contract;

  openReminderModal(ev: Event, content: any, contract: Contract){
    ev.preventDefault();

    this.spinner.startBackground();
    this.currentContract = contract;

    this.contractsApi.GetContractReminders(contract.id).subscribe((data => {

      this.currentReminders = data;
      this.currentReminder = {
        contractId: this.currentContract.id,
        reminderText: '',
        reminderDateTime: new Date(),
        reminderEmail: this.currentContract.reminderEmail
      };
      this.spinner.stopBackground();
      this.modalRef = this.modalService.open(content);

      this.modalRef.result.then((result) => {        
      }, (reason) => {
      });

    }));

    
  }

  addReminder(){
    this.spinner.startBackground();
    this.contractsApi.AddContractReminder(this.currentReminder).subscribe(() => {
      this.cache.clear();
      this.spinner.stopBackground();
      this.modalRef.close();
      this.getContracts();
    });
  }
  getDataField(contract: Contract, field: string): string{
    var item = contract.contractDataFields.find(f => f.name == field);
    if (item)
    {
      return item.value;
    }
    return '';
  }

  openNotesModal(content, contract: Contract) {
    this.currentNotes = contract.notes;

    this.modalService.open(content, { size: 'sm' }).result.then((result) => {
      let newNotes = this.currentNotes;
      let data: UpdateContractNotes = {
        contractId: contract.id,
        notes: newNotes
      };

      this.spinner.startBackground();

      this.contractsApi.UpdateContractNotes(data).subscribe(() => {
        this.cache.clear();
        this.getContracts();
      });
    }, (reason) => {
    });
  }

  truncate(str?: string) {
    if (!str) {
      return str;
    }
    if (str && str.length > 40)
      return str.substring(0, 40) + '...';
    else
      return str;
  };
  

  orderChange() {
    this.getContracts();
  }

  documentTypeChange(evt) {
    this.getContracts();
  }

}
