<div class="mb-1" *ngIf="superAdmin || orgAdmin">
    <app-company-selector (selected)="companySelected($event)"></app-company-selector>
</div>
<div class="container py-4" *ngIf="this.apiCompanyId">
    <h2>Contracts</h2>

    <div class="d-flex align-items-end   mb-2">
        <button class="btn btn-primary me-2" routerLink="/dashboard/documents/home">&lt; BACK</button>
        <div class="me-2">
            <label for="filterStatusId">Status:</label>
            <ng-select [(ngModel)]="filterStatusId" (change)="getData()" [clearable]="true" [dropdownPosition]="'top'"
                style="min-width: 250px;" placeholder="All">
                <ng-option *ngFor="let status of statuses" [value]="status.id">{{status.name}}</ng-option>
            </ng-select>
        </div>
        
        <div class="flex-fill">
        </div>

        <button class="btn btn-primary" routerLink="/dashboard/documents/contracts/0">NEW REQUEST</button>


    </div>

    <div class="row">



        <div class="col">

            <table class="table table-bordered bg-white table-sm company-acts-table table-hover">
                <tr>
                    <th class="text-center">REF NO</th>
                    <th class="text-center">DATE</th>
                    <th class="text-center">PARTIES TO THE AGREEMENT </th>
                    <th class="text-center">TYPE</th>
                    <th class="text-center">OTHER CONTRACTING PARTIES </th>
                    <th class="text-center">THIRD PARTY DETAILS</th>
                    <th class="text-center">STATUS</th>
                    <th class="text-center">DURATION &amp; DATES</th>
                    <th class="text-center">DEAL VALUE</th>
                    <th class="text-center">RISK OR ONEROUS ISSUES</th>
                    <th class="text-center">KYC<br/>DD<br/>FICA</th>
                    <th class="text-center">APPROVED BY LEGAL</th>
                    <th class="text-center">SIGNED &amp; UPLOADED</th>
                    <th class="text-center" style="width: 150px;">FILES</th>
                </tr>
                <tr *ngFor="let item of result?.items">
                    <td class="p-2"><a class="p-0"
                            [routerLink]="'/dashboard/documents/contracts/' + item.id">{{item.referenceNumber}}</a>
                    </td>
                    <td>
                        {{item.requestedDate | date:'yyyy-MM-dd'}}
                    </td>
                    
                    <td>
                        {{item.organisationName}}<br />
                        {{item.department}}<br />
                        {{item.negotiatingTeam}}<br />
                        {{item.headOfTeam}}<br />
                        {{item.contractsAdministrator}}<br />
                    </td>
                    <td>
                        {{item.contractDocumentAgreementType}}<br />
                        <strong>Details:</strong><br />
                        {{item.contractDetails}}<br />
                        <strong>Company Standard:</strong><br />
                        {{item.companyStandard}}<br />
                        <strong>Newly drafted agreement:</strong><br />
                        {{item.organisationOwnAgreement}}<br />
                        <strong>Agreement supplied by other party:</strong><br />
                        {{item.agreementSuppliedByOtherParty}}<br />
                    </td>
                    <td>
                        <ng-container *ngIf="item.contractThirdParties && item.contractThirdParties.length > 0">
                            {{item.contractThirdParties[0].name}}<br />
                            {{item.contractThirdParties[0].registrationNumber}}<br />
                            {{item.contractThirdParties[0].ckNumber}}<br />
                            {{item.contractThirdParties[0].identityNumber}}<br />
                            {{item.contractThirdParties[0].address}}<br />
                            {{item.contractThirdParties[0].email}}<br />
                            {{item.contractThirdParties[0].contactPerson}}<br />
                            {{item.contractThirdParties[0].contactNumber}}
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let tp of item.contractThirdParties; let i = index">
                            <ng-container *ngIf="i > 0">
                                {{item.contractThirdParties[i].name}}<br />
                                {{item.contractThirdParties[i].registrationNumber}}<br />
                                {{item.contractThirdParties[i].ckNumber}}<br />
                                {{item.contractThirdParties[i].identityNumber}}<br />
                                {{item.contractThirdParties[i].address}}<br />
                                {{item.contractThirdParties[i].email}}<br />
                                {{item.contractThirdParties[i].contactPerson}}<br />
                                {{item.contractThirdParties[i].contactNumber}}<br />
                                <hr/>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        {{item.contractDocumentStatus}}
                    </td>
                    <td>
                        <strong>Commencement:</strong><br />
                        {{item.commencementDate | date:'yyyy-MM-dd'}}<br />
                        <strong>Duration of Contract:</strong><br />
                        {{item.contractDuration}}<br />
                        <strong>Expecetd End Date:</strong><br />
                        {{item.expectedEndDate | date:'yyyy-MM-dd'}}<br />
                    </td>
                    <td>
                        <strong>Value:</strong><br />
                        {{item.contractValue}}<br />
                        <strong>Monthly:</strong><br />
                        {{item.contractValuePerMonth}}<br />
                        <strong>Cost per Anually:</strong><br />
                        {{item.contractValuePerAnnum}}<br />
                        <strong>Increase:</strong><br />
                        {{item.contractIncreasePerAnnum}}<br />
                    </td>
                    <td>
                        {{item.riskAndOnerousClauses}}
                    </td>
                    <td>
                        {{item.dueDiligenceDocumentFilePath ? 'YES' : 'NO'}}
                    </td>
                    <td>
                        {{item.approvedByLegal ? 'YES' : 'NO'}}
                    </td>
                    <td>
                        {{item.signedByBothParties ? 'YES' : 'NO'}}
                    </td>
                    
                    <td>
                        <a href="#" (click)="download($event, item.id, 'Document')"
                            class="d-flex align-items-center"><span class="icon icon-view-red me-2"></span>Contract</a><br />
                        <a *ngIf="item.dueDiligenceDocumentFilePath" href="#" (click)="download($event, item.id, 'DueDiligence')" class="d-flex align-items-center"><span
                                class="icon icon-view-red me-2"></span>Due Diligence</a><br />
                        
                    </td>
                </tr>

            </table>

            <div class="pager-bar py-4 mb-1" *ngIf="result && result.items">
                <div class="container text-center d-flex flex-row align-items-center justify-content-center"
                    *ngIf="result.items.length > 0">
                    <button class="btn btn-light" (click)="first()" [disabled]="!firstEnabled">&lt;&lt;
                        First</button>&nbsp;
                    <button class="btn btn-light" (click)="prev()" [disabled]="!prevEnabled">&lt; Prev</button>&nbsp;
                    <span class="px-4">Page {{result.currentPage}} of {{result.totalPages}}</span>
                    <div class="px-4 d-flex flex-row align-items-center">
                        <div class="pe-2">Items per page:
                        </div>
                        <div>
                            <ng-select [items]="perPageItems" [(ngModel)]="perPage" (change)="perPageChange($event)"
                                [clearable]="false" [dropdownPosition]="'top'"></ng-select>
                        </div>
                    </div>
                    <button class="btn btn-light" (click)="next()" [disabled]="!nextEnabled">Next &gt;</button>&nbsp;
                    <button class="btn btn-light" (click)="last()" [disabled]="!lastEnabled">Last
                        &gt;&gt;</button>&nbsp;
                </div>
            </div>

        </div>

    </div>
</div>